import { useEffect, useState } from "react";
import { Container } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Circle from 'components/markup/loading/Circle'

import _documents from '_functions/documents'

const DocumentsView = ({match}) => {

    const [redirect, setRedirect] = useState(false)
    const [err, setErr] = useState(false)

    const getReport = async (document_id) => {

        const report = await _documents.getReport(document_id)
        if(report.data) {
            setRedirect(`/dashboard/reports/${report.data._id}`)
        } else {
            setErr(true)
        }

    }

    useEffect(() => {

        getReport(match.params.document_id)

    }, [match.params.document_id])

    return (
        <Container fluid>

            {redirect ? (
                <Redirect to={redirect} />
            ) : err ? (
                <div className="alert alert-warning my-4">
                    <i className="fas fa-exclamation-triangle mr-2 " />
                    The document you are looking for cannot be found.
                </div>
            ) : (
                <Circle />
            )}

        </Container>
    )

}

export default DocumentsView;