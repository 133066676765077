import { useState } from 'react';
import { Card, CardHeader, CardTitle, CardFooter, CardBody, FormGroup, Input, Row, Col } from 'reactstrap';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _companies from '_functions/companies';

const CompanyBranding = ({company, setCompany}) => {

    if(!company.branding) company.branding = {};

    const [color, setColor] = useState(company.branding.color || '');
    const [nameColor, setNameColor] = useState(company.branding.name_color || '');
    const [hideName, setHideName] = useState(company.branding.hide_name || '');
    const [logoWidth, setLogoWidth] = useState(company.branding.logo_width || '');
    const [err, setErr] = useState(false);

    const onSave = async () => {

        setErr(false);

        if(logoWidth) {
            const num = parseInt(logoWidth);
            if(Number.isNaN(num) || num < 50 || num > 400) return setErr('Logo Width must be a valid number between 50 and 300')
        }

        toggleStandardLoader(true);

        const branding = {
            ...company.branding,
            color,
            name_color: nameColor,
            hide_name: hideName,
            logo_width: logoWidth,
        }

        const updated = await _companies.update(company._id, {branding})
        if(updated.success) {
            toggleAlertBS(false, 'Company settings updated successfully.');
        } else {
            toggleAlertBS(false, 'Something went wrong.');
        }

        toggleStandardLoader(false);
    }

    return (
        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Branding</CardTitle>
            </CardHeader>

            <CardBody>

                <Row>
                    <Col lg={6} className="align-self-center">
                        <p className="text-sm mb-3">Upload Your Company Logo</p>
                    </Col>
                    <Col lg={6} className="align-self-center text-left text-sm-right">
                        {company.branding.logo ? <img src={company.branding.logo} alt="..." style={{maxWidth: 200}} className="mb-3 rounded z-depth-2 p-3" /> : null}
                    </Col>
                </Row>

                <DragAndDrop
                    zone_id="documents_1"
                    url={`/v1/api/documents/logo`}
                    onError={(e) => console.log(e)}
                    resizePixels={1200}
                    onUpload={setCompany}
                    defaultValue="Logo" 
                    acceptedFiles="image/jpeg,image/png"
                />

                <hr />

                <FormGroup>
                    <label className="form-control-label">Page Background Color</label>
                    <Input 
                        type="text"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Company Name Color</label>
                    <Input 
                        type="text"
                        value={nameColor}
                        onChange={(e) => setNameColor(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Logo Width (px)</label>
                    <Input 
                        type="text"
                        value={logoWidth}
                        onChange={(e) => setLogoWidth(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Hide Company Name</label>
                    <Input 
                        type="select"
                        value={hideName ? 'true' : 'false'}
                        onChange={(e) => setHideName(e.target.value === 'true' ? true : false)}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </Input>
                </FormGroup>

            </CardBody>

            {err ? (
                <CardFooter>
                    <p className="text-sm mb-0 text-danger">{err}</p>
                </CardFooter>
            ) : null}

            <CardFooter className="text-right">
                <button onClick={onSave} className="btn btn-success"><i className="fas fa-save mr-2 " /> Save</button>
            </CardFooter>

        </Card>
    )

}

export default CompanyBranding;
