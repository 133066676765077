//         if(viewing_user.is_admin) {

import { connect } from 'react-redux'
import { Container, Card, CardHeader, CardFooter, CardTitle } from 'reactstrap';

import AdminLock from 'views/dashboard/pages/_components/AdminLock'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Helmet } from 'react-helmet';

import { formatCredits } from 'utils/currency'

const Pricing = ({CREDITS}) => {

    const amounts = CREDITS.CREDIT_AMOUNTS;

    return (

    
        <AdminLock>

            <Container fluid>
    
                <Helmet>
                    <title>Pricing</title>
                    <meta name="description" content="Pricing" />
                </Helmet>
    
                <DashHeaderOpen 
                    title={<span>Dashboard</span>}
                    breadcrumb_1="Company"
                    breadcrumb_2="Pricing"
                    icon="fas fa-building"
                />

                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">Credits Charges</CardTitle>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Individual</th>
                                    <th className="text-right">Credits</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Financial</td>
                                    <td className="text-right">{formatCredits(amounts.individual_financial)}</td>
                                </tr>
                                <tr>
                                    <td>Pay Stubs</td>
                                    <td className="text-right">{formatCredits(amounts.individual_pay_stubs)}</td>
                                </tr>
                                <tr>
                                    <td>Package</td>
                                    <td className="text-right">{formatCredits(amounts.individual_package_pay_stubs + amounts.individual_financial)}</td>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <th>Joint</th>
                                    <th className="text-right">Credits</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Financial</td>
                                    <td className="text-right">{formatCredits(amounts.joint_financial)}</td>
                                </tr>
                                <tr>
                                    <td>Pay Stubs</td>
                                    <td className="text-right">{formatCredits(amounts.joint_pay_stubs)}</td>
                                </tr>
                                <tr>
                                    <td>Package</td>
                                    <td className="text-right">{formatCredits(amounts.joint_package_pay_stubs + amounts.joint_financial)}</td>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <th>Other</th>
                                    <th className="text-right">Credits</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Bank Statements</td>
                                    <td className="text-right">{formatCredits(amounts.mx_statement)}</td>
                                </tr>
                                <tr>
                                    <td>Pay Stub Overage</td>
                                    <td className="text-right">{formatCredits(amounts.pay_stub_overage)}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </Card>

                <Card className="mt-4">
                    <CardFooter>
                        <CardTitle className="mb-0">Fee Schedule Breakdown</CardTitle>
                    </CardFooter>

                    <CardFooter>

                        <p className="text-sm">Financial Orders begin once an end user has connected a bank, financial app, credit card, loan, etc. Zap Reports attempts to keep the connection to all financial accounts open for 90 days. During this period using the Zap Reports Dashboard updated documents may be requested if the account is eligible to have its data continually synced.</p>

                        <p className="text-sm">Pay Stub Orders begin when an end user connects their first account to an employer service such as a pay roll company, unemployment, disability, etc. Like financial orders, Zap Reports will attempt to keep the connection open for 90 days allowing updated paystubs to be pulled during this time.</p>

                        <p className="text-sm">Pay Stub Orders are limited to ({CREDITS.PAYSTUB_LIMITS.individual[0].limit}) downloads per individual order and ({CREDITS.PAYSTUB_LIMITS.joint[0].limit}) downloads for joint orders. A download is counted when a new connection is made OR when returning to pull updated pay stubs from an existing connection. In the case where additional pay stubs downloads are requested an overage charge of {formatCredits(amounts.pay_stub_overage)} credits is applied.</p>
                        
                        <p className="text-sm">Package discounts for both joint and individual orders are activated automatically when the second order would be placed. For instance, if an individual initiates a financial order for ({formatCredits(amounts.individual_financial)}) credits and later initiates an order for pay stubs the package pricing activates and only ({formatCredits(amounts.individual_package_pay_stubs)}) credits are charged.</p>
                        
                        <p className="text-sm">Accounts are considered joint or individual once a request for documents has been sent out and cannot be changed later. If a request for documents is sent to a Zap Reports contact and they have an assigned partner the account will be assigned the designation of joint and will charge the corresponding credit amounts.</p>
                        
                        <p className="text-sm">If your account is set to enable the pulling of bank statements they will be charged at a rate of ({formatCredits(amounts.mx_statement)}) credits per statement when available by a financial institution.</p>

                    </CardFooter>
                </Card>
                
            </Container>
    
        </AdminLock>
    )
}


const mapStateToProps = state => {
    
    return {
        CREDITS: state.load.CREDITS,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Pricing);
