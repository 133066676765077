import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import Circle from 'components/markup/loading/Circle'

import _credit_purchases from '_functions/credit_purchases'
import { formatMoney, formatCredits } from 'utils/currency';

import ModalToggler from 'components/functional/modals/Toggler'
import ModalUpdateCard from './ModalUpdateCard';

const Overview = ({onSetTab, company}) => {

    const [purchases, setPurchases] = useState(null)

    const getRecentPurchases = useCallback(async () => {
        const foundPurchases = await _credit_purchases.search({limit: 5});
        setPurchases(foundPurchases.data ? foundPurchases.data.documents : []);
    }, [])

    useEffect(() => {
        getRecentPurchases()
    }, [getRecentPurchases])

    return (
        <>

        <Helmet>
            <title>Company Billing</title>
            <meta name="description" content="Company Billing" />
        </Helmet>

        <Card>

            <CardHeader>
                <Row>
                    <Col md={6}>
                        <CardTitle className="mb-0">Default Card</CardTitle>
                    </Col>
                    <Col md={6} className="d-none d-md-block">
                        <CardTitle className="mb-0">
                            Billing Address
                            <Link to="/dashboard/company/settings">
                                <i className="fas fa-edit text-success ml-3 cursor-pointer " />
                            </Link>
                        </CardTitle>
                    </Col>
                </Row>
            </CardHeader>

            <CardHeader>
                <Row>
                    <Col md={6}>
                        <p className="text-sm mb-0">{' '}
                            <b style={styles.divider}>Number:</b> 
                            {company.card_last_four ? '**** **** **** ' + company.card_last_four : '-'}
                        </p>
                        <p className="text-sm mb-0">{' '}
                            <b style={styles.divider}>Expires:</b> 
                            {company.card_expiration ? company.card_expiration : '-'}
                        </p>
                        <ModalToggler component={ModalUpdateCard} company={company}>
                            {company.card_id ? (
                                <button className="btn btn-outline-info mt-3 px-5"><i className="fas fa-plus mr-2 " /> Update Card</button>
                            ) : (
                                <button className="btn btn-outline-success mt-3 px-5"><i className="fas fa-plus mr-2 " /> Add Card</button>
                            )}
                        </ModalToggler>
                       
                    </Col>
                    <Col md={6}>

                        <div className=" d-block d-md-none ">
                            <hr />
                            <CardTitle className="mb-0 mt-4">
                                Billing Address
                                <Link to="/dashboard/company/settings">
                                    <i className="fas fa-edit text-success ml-3 cursor-pointer " />
                                </Link>
                            </CardTitle>
                        </div>

                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Address Line 1:</b>{' '}
                            {company.billing_address_line_1 ? company.billing_address_line_1 : '-'}

                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Address Line 2:</b>{' '}
                            {company.billing_address_line_2 ? company.billing_address_line_2 : '-'}
                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>City:</b>{' '}
                            {company.billing_city ? company.billing_city : '-'}
                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>State:</b>{' '}
                            {company.billing_state ? company.billing_state : '-'}
                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Zip:</b>{' '}
                            {company.billing_postal_code ? company.billing_postal_code : '-'}
                        </p>
                    </Col>
                </Row>
            </CardHeader>

            <CardHeader>
                <CardTitle className="mb-0">
                    Auto Reload 
                    <i className="fas fa-edit ml-3 text-warning cursor-pointer" onClick={() => onSetTab(2)} />
                </CardTitle>
            </CardHeader>

            <CardHeader>
                <p className="text-sm mb-0">
                    {company.reloader_package ? (
                        <span>
                            <i className="fas fa-check text-success mr-2 " /> 
                            Enabled - Package: {company.reloader_package.name}
                        </span>
                    ) : (
                        <span onClick={() => onSetTab(2)}>
                            Not Enabled &mdash; <b className="cursor-pointer text-underline">Click Here</b> To Set Up Auto Recharge On Your Account.
                        </span>
                    )}
                    
                </p>
            </CardHeader>

            <CardHeader>
                <CardTitle className="mb-0">Recent History</CardTitle>
            </CardHeader>

            { purchases && purchases.length ? (
                <div className="table-responsive">
                    <table className="table">
                        
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Tax</th>
                                <th>Total</th>
                                <th>Credits</th>
                                {/* <th>Balance</th> */}
                                <th>Cost Per Credit</th>
                                <th className="text-right">User</th>
                            </tr>
                        </thead>

                        <tbody>
                            {purchases.map(p => (
                                <tr key={p._id}>
                                    <td>{moment(p.date).format('MM/DD/YYYY h:mm A    ')}</td>
                                    <td style={{whiteSpace: 'pre'}}>{formatMoney(p.usd_amount)}</td>
                                    <td style={{whiteSpace: 'pre'}}>{formatMoney(p.usd_tax)}</td>
                                    <td style={{whiteSpace: 'pre'}}>{formatMoney(p.usd_total)}</td>
                                    <td style={{whiteSpace: 'pre'}}>{formatCredits(p.credits)}</td>
                                    {/* <td style={{whiteSpace: 'pre'}}>{formatCredits(p.balance)}</td> */}
                                    <td style={{whiteSpace: 'pre'}}>{formatMoney(p.usd_amount_per_credit)}</td>
                                    <td className="text-capitalize text-right">
                                        {p.user ? p.user.given_name + ' ' + p.user.family_name : 'Auto Loader'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
            ) : purchases ? (
                <CardHeader>
                    <p className="text-sm">Your account has not purchased any credits</p>
                </CardHeader>
            ) : (
                <Circle />
            )}
            
        </Card>
        </>
    )

}

const styles = {
    divider: {
        display: 'inline-block',
        width: 120,
        fontWeight: 'bold'
    }
}


const mapStateToProps = state => {
    return {
        SETTINGS: state.load.SETTINGS,
        company: state.company.company,
    };
};

export default connect(mapStateToProps, '')(Overview);