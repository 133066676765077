
import apiWorker from '../apiWorker'

const forms =  {
    create                  : (data)                => apiWorker('post',   `/v1/api/forms`, data, true),
    update                  : (form_id, data)       => apiWorker('patch',  `/v1/api/forms/${form_id}`, data, true),
    delete                  : (form_id)             => apiWorker('delete', `/v1/api/forms/${form_id}`, null, true),
    search                  : (data)                => apiWorker('post',   `/v1/api/forms/search`, data, true),
    findById                : (form_id)             => apiWorker('get',    `/v1/api/forms/${form_id}`, null, true),
    viewDocument            : (Key)                 => apiWorker('get',    `/v1/api/forms/documents/key?Key=${Key}`, null, true),
    getConfig               : ()                    => apiWorker('get',    `/v1/api/forms/configuration`, null, true),
    getContactAnswers       : (form_id, contact_id) => apiWorker('get',    `/v1/api/forms/answers/${form_id}/${contact_id}`, null, true),
    getContactAnswersAll    : ( contact_id)         => apiWorker('get',    `/v1/api/forms/answers/${contact_id}`, null, true),
    request                 : (form_id, contact_id) => apiWorker('post',    `/v1/api/forms/${form_id}/request/${contact_id}`, null, true),

}

export default forms;