import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _contacts from '_functions/contacts';

const ModalBypassNameVerification = ({showModal, toggleModal, contact, fetchData}) => {

    const [err, setErr] = useState(null);

     const onSave = useCallback(async () => {
        toggleStandardLoader(true);

        const saved = await _contacts.bypassNameVerification(contact._id, {
            bypass_name_verification: !contact.bypass_name_verification
        })
        if(saved.success) {
            fetchData();
            toggleModal();
            toggleAlertBS(false, 'Verification Updated Successfully')
        } else {
            setErr(saved.message);
        }

        toggleStandardLoader(false);
    }, [contact._id, contact.bypass_name_verification, fetchData, toggleModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">
                    {contact.bypass_name_verification ? 'Require Name Verification' : 'Skip Name Verification'}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <p className="text-sm">By default, all contacts who are set to pull financial information must verify their name by typing it <b className="text-dark">twice correctly</b> before proceeding to gather their documents.</p>
                {contact.bypass_name_verification ? (
                    <>
                        <p className="text-sm">
                            This contact is currently set to{' '}
                            <b className="text-dark">SKIP</b>{' '}
                            this verification process and use the name{' '}
                            <b className="text-dark text-underline">{contact.display_name}</b>.
                        </p>
                        <p className="text-sm mb-0">
                            If you wish to require name verification click the button{' '}
                            <b className="text-dark">"Require Verification"</b>{' '}
                            below. 
                        </p>
                    </>
                ) : (
                    <>
                        <p className="text-sm">
                            If you wish to have this contact skip that process and use the name entered here on their file click{' '}
                            <b className="text-dark">"Skip Verification"</b>{' '}
                            below.
                        </p>
                        <p className="text-sm mb-0">
                            Note that before doing this a contact must have both a first and last name and you should{' '}
                            <b className="text-dark text-underline">confirm that the name currently on their file is their correct legal name.</b>
                        </p>
                    </>
                )}
               
            </div>

            {err ? (
                <div className="modal-body bg-secondary border-top">
                    <p className="text-sm font-weight-bold text-danger mb-0">
                        <i className="fas fa-info mr-3 " />
                        {err}
                    </p>
                </div>
            ) : null}

            <div className="modal-footer text-right">
                {contact.bypass_name_verification ? (
                    <button className="btn btn-success" onClick={onSave}>
                        Require Verification
                    </button>
                ) : (
                    <button className="btn btn-success" onClick={onSave}>
                        Skip Verification
                    </button>
                )}
                
            </div>

        </Modal>

    )

}

ModalBypassNameVerification.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalBypassNameVerification
