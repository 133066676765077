import { useState } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container, Alert, Badge } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import formatText from 'utils/formatText';
import _signing_documents from '_functions/signing_documents';

const ContactsAll = () => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await _signing_documents.search({ ...params, isCSV, })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "name",
            text: 'Name',
            headerFormatter: () => {
                return <div>Name</div>
            },

            formatter: (cell, row) => {
                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div style={{whiteSpace: 'pre'}}>
                        <Link className="text-capitalize" to={`/dashboard/signing/documents/${row._id}`}>
                            <b>{row.name}</b>
                        </Link>
                        <div>Sent: {moment.unix(row.created_at).format('MMM Do, YYYY')}</div>
                    </div>
                )
                
            },
        },
       
        {
            dataField: "parties",
            text: 'Parties',
            formatter: (cell, row) => {

                if(row.name === 'no data') return <></>
              
                return row.parties.map((party, i) => {
                    const contact = row.contacts.find(c => c._id === party.contact);
                    return (
                        <div key={i} style={{whiteSpace: 'pre'}}>
                            {
                                contact.display_name ? <span className="text-capitalize">{contact.display_name}</span> :
                                contact.email ? contact.email :
                                contact.phone ? formatText(contact.phone).phone() : 
                                null
                            }
                        </div>
                    )
                })

                
            },
        },
        {
            dataField: "status",
            text: 'Status',
            formatter: (cell, row) => {

                if(row.name === 'no data') return <></>
                if(row.status === 'sent') return <Badge style={styles.badge} color="info"><i className="fas fa-paper-plane mr-2 " /> SENT</Badge>
                if(row.status === 'void') return <Badge style={styles.badge} color="danger"><i className="fas fa-times mr-2 " /> VOID</Badge>
                return <Badge style={styles.badge} color="success"><i className="fas fa-check mr-2 " /> SIGNED</Badge>
                
            },
        },
       
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <Link 
                            className="btn btn-outline-success" 
                            to={`/dashboard/signing/documents/${row._id}`}
                            style={{minWidth: 70}}
                        >
                            <i className="fas fa-eye mr2 " />
                        </Link>
                    </div>
                )
            }
        },
    ]
        

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }



    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Signed Contracts`}</title>
                <meta name="description" content="Signed Contracts" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Signed Contracts"
                breadcrumb_2="All"
                icon="fas fa-users"
            />
    
            <Card className="card-color card-primary">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Document Name"
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>
    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 90
    }
}

export default ContactsAll