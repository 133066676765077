import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, Card, CardBody } from "reactstrap";

const ContactsNavigation = ({company, tab, setTab}) => {

    const getClasses = (tabToCheck) => {
        if(tabToCheck === tab) return 'mb-sm-3 mb-md-0 active'
        return 'mb-sm-3 mb-md-0'
    }

    const tabs = company.forms_enabled ? 
        ['Overview', 'Update', 'Timeline', 'Connect', 'Forms'] :
        ['Overview', 'Update', 'Timeline', 'Connect']

    return (

        <Card id="archk-tutorial-contacts-view-navigation">
            <CardBody>
                <Nav className="nav-fill flex-column flex-sm-row" pills role="tablist" >
                    {tabs.map((t, i) => (
                        <NavItem key={i} id={`archk-tutorial-contacts-navigation-${t.toLowerCase()}`}>
                            <NavLink
                                aria-selected={tab === i}
                                className={getClasses(i)}
                                onClick={e => setTab(i)}
                                role="tab"
                            >
                                {t}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </CardBody>
        </Card>
        
    );

}

const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(ContactsNavigation);