
import { useEffect, useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom'
import { Card, Container, Alert, CardHeader, CardTitle } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import { Link } from 'react-router-dom';
import _form_answers from '_functions/form_answers';
import _forms from '_functions/forms';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import ContactSearchInput from "components/system/Search/ContactsInput";

import Circle from 'components/markup/loading/Circle'

import SendFormRequestModal from 'views/dashboard/pages/_components/SendFormRequestModal'

const FormRequests = ({match, viewing_user}) => {

    const [ hideTable, setHideTable ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ form, setForm ] = useState(null);
    const [ redirect, setRedirect ] = useState(null);
    const [ contact, setContact ] = useState({});
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: null, total_documents: 0 })
    const [ error, setError ] = useState(false)

    const columns = [
        {
            dataField: "contact",
            headerStyle: {width: 400},
            text: "Contact",
            formatter: (cell, row) => (
                row.name === 'No Forms Found' ? 'No submissions were found for this form' :
                row.contact ? (
                    <Link to={`/dashboard/forms/submissions/${row.contact._id}/${form._id}`}>
                        {row.contact.display_name ? row.contact.display_name : 'Contact Name Not Found'}
                    </Link>
                ) : (
                    <Link to={`/dashboard/forms/submissions/deleted_form/${row._id}`}>
                        Contact Not Found
                    </Link>
                )
            )
        },
        {
            dataField: "completion_percent",
            headerStyle: {width: 400},
            text: "%",
            formatter: (cell, row) => (
                row.name === 'No Forms Found' ? '' :
                row.completion_percent === '100.00' ? <b className="text-success"><i className="fas fa-check mr-2 " /> {row.completion_percent + '%'}</b>
                : row.completion_percent + '%'
            )
        },
        {
            dataField: "form",
            headerStyle: {width: 400},
            text: "Form",
            formatter: (cell, row) => (
                row.name === 'No Forms Found' ? '' :
                <Link to={`/dashboard/forms/submissions/${row.contact._id}/${form._id}`}>
                    {form.name}
                </Link>
                
            )
        },

        {
            dataField: "_id",
            headerStyle: {textAlign: 'right'},
            text: "actions",
            headerFormatter: () => {
                return <div id="archk-tutorial-documents-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'No Forms Found' ? '' :
                    <div className="text-right">
                         {/* <button className="btn btn-outline-danger" style={{minWidth: 70}} onClick={() => setFormToDelete(row)}>
                            <i className="fas fa-trash mr2 " />
                        </button> */}

                        {row.contact  ? (
                            <Link 
                                className="btn btn-outline-success" 
                                to={`/dashboard/forms/submissions/${row.contact._id}/${form._id}`}
                                style={{minWidth: 70}}
                            >
                                <i className="fas fa-eye " />
                            </Link>
                        ) : (
                            '-'
                        )}
                    </div>
                )
            }
        },
    ]

    const onRequestSend = useCallback(() => {
        setShowModal(false);
        setHideTable(true);
        setTimeout(() => (
            setHideTable(false)
        ), 500)
    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }

        params.form_id = match.params.form_id

        const query = await _form_answers.search({ ...params, isCSV })
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    }), [sizePerPage, match.params.form_id])


    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    useEffect(() => {
        const fetchForm = async () => {
            const _form = await _forms.findById(match.params.form_id)
            if(_form && _form.data) {
                setForm(_form.data)
            } else {
                setRedirect('/dashboard/forms')
            }
        }

        fetchForm()
    }, [match.params.form_id])

    if(redirect) return <Redirect to={redirect} />

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    if(!form) return <div className="py-4"><Circle /></div>

    return (
        <Container fluid>

            <SendFormRequestModal 
                showModal={showModal}
                toggleModal={() => setShowModal(false)}
                onConfirm={onRequestSend}
                contact={contact}
                form={form}
            />
    
            <Helmet>
                <title>{`All Forms Submissions`}</title>
                <meta name="description" content="Form Submissions" />
            </Helmet>

            <DashHeaderOpen 
                col1Size={5}
                col2Size={7}
                title={<span>Dashboard</span>}
                breadcrumb_1={<Link to="/dashboard/forms/all">Forms</Link>}
                breadcrumb_2="Answers"
                icon="fas fa-edit"
                actionComponent={(
                    <div>
                        <Link className="btn btn-outline-warning" to="/dashboard/forms/all"><i className="fas fa-arrow-left mr-2" />Back</Link>
                        {viewing_user.is_admin ? (
                            <Link className="btn btn-outline-info" to={`/editor/forms/${form._id}`}><i className="fas fa-cogs mr-2" />Update</Link>
                        ) : null}
                        <button onClick={() => setShowModal(true)} className="btn btn-outline-success"><i className="fas fa-paper-plane mr-2" />Request</button>
                    </div>
                )}
            />
    
            <Card className="card-color card-primary">    
                <CardHeader>
                    <CardTitle className="mb-0">FORM: {form.name}</CardTitle>
                </CardHeader>
                {hideTable ? null : (
                    <Table
                        placeholder="Form Name"
                        customSearch={(onChange) => (
                            <ContactSearchInput 
                                value={contact}
                                titleFormatter={() => (
                                    contact && contact._id ? (
                                        <span 
                                            className="cursor-pointer"
                                            onClick={() => { setContact({}); onChange(undefined, 'contact_id') ;}} 
                                        >
                                            <i className="fas fa-trash text-danger mr-2 " />
                                            Clear Search
                                        </span>
                                    ) : 'Search'
                                )}
                                onSelect={(contact) => {
                                    onChange(contact._id, 'contact_id');
                                    setContact(contact);
                                }}
                            />
                        )}
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'No Forms Found'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />
                )}
            </Card>
    
        </Container>
    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(FormRequests);