import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom'
 
import { Card, Container, CardHeader, CardFooter, Row, Col } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import reactHTMLParser from 'react-html-parser'

import { onDownloadDocument } from 'utils/documents';

const FormSubmissionsViewRender = ({form, contact, answers, isAnswers, document}) => {

    const history = useHistory();

    const mapQuestions = (_questions) => {
        return _questions.map((question, i) => (
            <div className="question-wrapper" key={i}>

                {question.is_item ? (
                    <h3>{question.item_name ? question.item_name : `Item #${question.item_number}`}</h3>
                ) : null}

                <h5 className="question-title">
                    {question.has_question_number ? <b>Question {question.question_number}:</b> : null}{' '}
                    {question.name}
                </h5>
                <div className="question-answer-wrapper">
                    {!question.is_signature ? (
                        <div className="answer-icon">
                            <img alt=".." src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE8AAABPCAYAAACqNJiGAAACDUlEQVR4nO3ca46DMAwEYFPt/U/GnahUbVtaXkmc2DOuR1pp/yyrfg0QnOBpnudF2jMp/pY+t1/+8NokniKJp0jiKZJ4iiSeIkx4y/8PTFjwloPfXcOAt4cFAYiOd4bkDoiMV4LjCoiKV4PiBoiI14LhAhhpnmcOGG2SbAqIiKetEZoBoo48CkDk0xYeEP2aBw3IcMOABWS520ICMk1V4ADZ5nlQgIyTZBhA1icMCEDmxzN3QPZnW1fACIUBN8AoVRUXwEglKXNALR7UOqo1YMQdA2aAPfDQRp9YAfYaeXBbISwAb523xqIhDgX8Ux686Z+SZTn6EnKLWVl2B8MT76d3tRdmA5gjry4fgGu8HH1leQHmyGvLA/AbL0dfeZa9kZeAhTk6bRPwOtPZNS8Bj/OwubphJOA2L5OSu20CvvNhUTpVmRJx+/lrCwPrA0R6+L/K7sDRVFW+DxgV8/CM61mSQjyttV/o6WeK/Hg2FE4C4w2Hk6B4JnCS72FsUnXdzjeA3qm+4UXBM4eTIHgucBIAzw1OyPFc4YQYzx1OSPEg4IQQDwZOCJvSaNK9cMHYlKYlQyo+rE1pajKsVMbclKYkQ2uM7E1pzjK8OBuhKc1eTKraUZrSrGO2HBCtnme6jpJvACkSpSmNy8pdhKY0bkue7E1pXNeKmZvSuC+yszalgdidwNiUBmNbh4jcAadTTA4KXQl/AAAAAElFTkSuQmCC" />
                        </div>
                    ) : null}

                    {question.is_signature ? (
                        <img alt="signature" className="signature" src={`data:image/png;base64,${question.answer}`} />
                    ) : question.is_image ? (
                        <img alt="upload" className="upload-image" src={question.answer} />
                    ) : (
                        <p className={`question-answer ${question.is_highlight ? 'highlight-answer' : null} ${question.no_answer_given ? 'no-answer-given' : null}  `}>
                            {question.type === 'address' ? reactHTMLParser(question.answer) : question.answer}
                        </p>
                    )}

                </div>
                <div className={`${question.is_loop_start ? 'item-wrapper' : null}`}>
                    <div className={`${question.is_branch ? 'item-wrapper' : null}`}>
                        {question.children ? mapQuestions(question.children) : null}
                    </div>
                </div>
            </div>

        ))
    }

    if(!contact || !form || !answers) {
        return (
            <Container fluid>
                <div className="alert alert-danger text-center"><i className="fas fa-exclamation-triangle mr-2 " /> This submisson is unable to be viewed</div>
            </Container>
        )
    }
    
    return (
        <Container fluid className="archk-form-answers-view">
    
            <Helmet>
                <title>{`All Forms`}</title>
                <meta name="description" content="Forms" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Forms"
                breadcrumb_2="All"
                icon="fas fa-edit"
                actionComponent={(
                    <div>
                        {document ? (
                            <button onClick={() => onDownloadDocument(document)} className="btn btn-outline-success">
                                <i className="fas fa-download mr-2" /> Download
                            </button>
                        ) : null}
                        <button onClick={() => history.goBack()} className="btn btn-outline-warning">
                            <i className="fas fa-arrow-left mr-2" /> Back
                        </button>
                    </div>
                   
                )}
            />

            {isAnswers ? (
                <Card className="mb-0">   
                    <CardHeader>
                        <p className="text-sm "><i className="fas fa-info-circle mr-2 text-info " /> This page shows the <b className="text-underline">current answers</b> for this contact's form submission. If you are not able to view a full submission it means the contact has not yet submitted the form in its entirety and this page can be used to view the current progress.</p>
                        <p className="text-sm mb-0">Also note that while viewing the current form answers, only forms submissions are available for download.</p>
                    </CardHeader>
                </Card>
            ) : null}
    
            {answers.map((page, i) => (

                <Card className="mb-4" key={i}>   

                    <CardHeader>
                        <Row>
                            <Col lg={6}>
                                <h2 className="text-italic">{form.name}</h2>
                            </Col>
                            <Col lg={6} className="text-left text-md-right">
                                <h2>Form Page {page.page_number}</h2>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardHeader >
                        <Row>
                            <Col lg={6}>
                                {contact.display_name ? <p className="mb--2">{contact.display_name}</p> : null}
                                {contact.email ? <p className="mb--2">{contact.email}</p> : null}
                                {contact.phone ? <p className="mb--2">{contact.phone}</p> : null}
                            </Col>
                            <Col lg={6}>
                                <Row>
                                    <Col xs={6}>
                                        <p className="mb--2">Submitted On:</p>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        {}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <p className="mb--2">Total Pages: </p>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <p className="mb--2">{form.pages}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <p className="mb--2">Reference #: </p>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardFooter className="bg-secondary">
                        {mapQuestions(page.children)}
                    </CardFooter>

                </Card>
            ))}

        </Container>
    )

}

export default FormSubmissionsViewRender;