/*
Documentation

NPM MODULES ADDED
immutability-helper
react-dnd
react-dnd-html5-backend

SCSS NEEDED
page-form.scss
archk-alerts.scss

QUESTION TYPES
text
textarea
select (drop down)
checkbox
yes_no
looper
date
file
address

REDUX
arhck-alerts (in system)


// possible question types
age
number


*/

import React, { useState, useEffect, useCallback, useReducer, useRef } from 'react';
// import { Redirect } from 'react-router-dom';
import { Container, Form, Row, Col } from 'reactstrap';
import Circle from 'components/markup/loading/Circle'

import _forms from '_functions/forms'
import _form_answers from '_functions/form_answers'

import MapQuestions from './MapQuestions'
import StepTracker from './StepTracker'
import PageProgress from './PageProgress'

import getProgressAndErrors from './_utils/getProgressAndErrors'

const useEnhancedReducer = (reducer, initState, initializer) => {
    const lastState = useRef(initState)
    const getState = useCallback(() => lastState.current, [])
    return [
      ...useReducer(
        (state, action) => lastState.current = reducer(state, action),
        initState,
        initializer
      ),
      getState
    ]
  }

const FormPreview = ({match}) => {

    function reducer(state, action) {
        if(action.SET_ALL) {
            delete action.SET_ALL;
            return {...state, ...action}
        }
        return {...state, [action.field]: action.value}
    }

    // const [state, dispatch] = useReducer(reducer, {});
    const [state, dispatch, getState] = useEnhancedReducer(reducer, {});

    const [loaded, setLoaded] = useState(false);
    const [form, setForm] = useState(false);
    const [allQuestions, setAllQuestions] = useState([]);
    const [shownQuestions, setShownQuestions] = useState([]);
    const [page, setPage] = useState(0);
    const [answers, setAnswers] = useState({});
    const [errors, setErrors] = useState([]);

    const onSave = useCallback(async() => {
        const saved = await _form_answers.save({
            company   : '6142341b978502b76fe09c36',
            form   : '62612e51c062c5c4c6086926',
            contact   : '624b172ba9383e60af68e257',
            answers   : getState(),
        })
        if(!saved.success) {
            
        }
    }, [getState])

    const onChange = useCallback((question, e) => {
        const value = e.target ? e.target.value : e;
        dispatch({field: question.id, value})
    }, [dispatch])

    const onSetPage = useCallback((page, questions, ignoreState) => {
        const _questions = questions ? JSON.parse(JSON.stringify(questions)) : JSON.parse(JSON.stringify(allQuestions))
        const filteredQuestions = _questions.filter(q => q.page === page);
        setPage(page)
        setShownQuestions(filteredQuestions)
        window.scrollTo(0,0)
        if(!ignoreState) setAnswers(state)
    }, [allQuestions, state])

    const moveForward = useCallback(() => {
        const _state = getState()
        const calculate = getProgressAndErrors(shownQuestions, _state)
        setErrors(calculate.errors)

        if(calculate.errors.length) {
            let el = document.getElementById(calculate.errors[0]);
            if(el) {
                if(el.getAttribute('type') === 'hidden') el = el.parentElement;
                el.scrollIntoView({block: 'center', })
            }
        } else {
            if(page + 1 < form.pages) {
                onSetPage(page + 1)
            } else {
                console.log('submit')
            }
        }
    }, [shownQuestions, getState, onSetPage, form.pages, page])
    
    const moveBack = useCallback(() => {
        if(page - 1 >= 0)  onSetPage(page - 1)
    }, [onSetPage, page])

    const setStartingPage = useCallback((questions, answers, form) => {
        const tryPages = (page = 0) => {
            console.log(form.pages)
            // if we are done on all pages, show the first page
            if(page + 1 > form.pages) return onSetPage(0, questions, true)
            const _questions = questions.filter(p => p.page === page)
            const calculate = getProgressAndErrors(_questions, answers)
            // if we have errors on a page, stop and show that page
            if(calculate.errors.length) {
                return onSetPage(page, questions, true)
            } else {
                // try to show the next page if it has errors, if not
                // continue looping through pages
                tryPages(page + 1)
            }
        }
        tryPages()
    }, [onSetPage])
    
     // return form from database if _id is set
     const fetchData = useCallback(async(_id) => {
         const values = await Promise.all([
            _forms.findById(_id),
            _form_answers.find('6142341b978502b76fe09c36', '62612e51c062c5c4c6086926', '624b172ba9383e60af68e257')
         ])
        const form = values[0]
        const formAnswers = values[1]

        console.log(values)

        if(form.success && formAnswers.success) {
            const _form = form.data ? form.data : {};
            const _questions = form.data && form.data.questions ? form.data.questions : []
            const _answers = formAnswers.data && formAnswers.data.answers ? formAnswers.data.answers : {};

            if(!form.data) {

            }

            setAllQuestions(_questions)
            setStartingPage(_questions, _answers, _form)
            setLoaded(true);
            setForm({
                company           : _form.company,
                name              : _form.name,
                active            : _form.active,
                deleted           : _form.deleted,
                send_emails_to    : _form.send_emails_to,
                pages             : _form.pages,
                redirect_url      : _form.redirect_url,
                submissions       : _form.submissions,
            })
            if(Object.keys(_answers).length) {
                dispatch({..._answers, SET_ALL: true})
                setAnswers(_answers)
            }

        } else {
            // setRedirect('/dashboard/forms');
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        const _id = match.params.form_id
        fetchData(_id);

        const interval = setInterval(onSave, 2000)

        return () => {
            clearInterval(interval)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // zap dashboard specific
    useEffect(() => {
        const sidebar   = document.querySelector('.navbar-vertical')
        const navbar    = document.getElementById('desktop-navbar-top');
        const footer    = document.getElementById('dashboard-footer');
        const wave      = document.querySelector('.wave')
        const mainContent      = document.querySelector('.main-content')

        sidebar.style.display   = 'none'
        navbar.style.display    = 'none'
        footer.style.display    = 'none'
        wave.style.display      = 'none'
        mainContent.classList.remove('content-wrapper')

        return () => {
            sidebar.style.display   = 'block'
            navbar.style.display    = 'block'
            footer.style.display    = 'block'
            wave.style.display      = 'block'
            mainContent.classList.add('content-wrapper')
        }
    }, [])

    // if(redirect) return <Redirect to={redirect} />
    if(!loaded || !shownQuestions.length) return <div className="py-6"><Circle /></div>

    return (
        <div id="page-form-action" className="py-3" >

            <PageProgress 
                form={form}
                state={state}
                currentPage={page + 1}
                _questions={shownQuestions}
            />

            <div className="main-wrapper">

                <div className='borde my-4 pt-4 '>
                    <Container className="z-depth border-right border border-left bg-white">
                        <StepTracker 
                            form={form}
                            currentPage={page + 1}
                            onChangeStep={(page) => onSetPage(page)}
                            moveForward={moveForward}
                            moveBack={moveBack}
                        />
                    </Container>
                </div>

                <Container className="z-depth- border bg-white">
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <MapQuestions 
                            _questions={shownQuestions} 
                            onChange={onChange}
                            answers={answers}
                            form={form}
                            errors={errors}
                        />
                    </Form>
                </Container>

                <div className="bord'er-top pt-4 mt-4 pb-4 bg-white">
                    <Container>
                        <Row>
                            <Col xs={6}>
                                {page !== 0 ? (
                                    <button onClick={moveBack} className="px-4 btn btn-info"><i className="fas fa-arrow-left mr-2" /> Go Back</button>
                                ) : null}
                            </Col>
                            <Col xs={6} className="text-right">
                                <button onClick={moveForward} className="px-4 btn btn-success">Next Page <i className="fas fa-arrow-right ml-2 " /></button>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>

        </div>
    )

}

export default FormPreview