import { Modal } from "reactstrap";

const MXConnect = ({showModal, toggleModal, selectedMX, selectedPinwheel, selectedYodlee, onConfirm}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
    >

        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Create Report</h5>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleModal(false)} >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        {selectedMX.length || selectedPinwheel.length || selectedYodlee.length ? (
            <div>
                
                <div className="modal-body bg-secondary">
                    <p className="text-sm mb-0">Your Report Will Be Created With The Following Documents:</p>
                </div>

                <div className="modal-body">
                    <ol className="my-3">
                        {selectedPinwheel.length ? selectedPinwheel.map((account, i) => (
                            <li className="bg-white" key={i}>
                                {account.platform}
                            </li>
                        )) : null}
                        {selectedYodlee.length ? selectedYodlee.map((account, i) => (
                            <li className="bg-white" key={i}>
                                {account.accountName}
                            </li>
                        )) : null}
                        {selectedMX.length ? selectedMX.map((member, i) => (
                            <li className="bg-white" key={i}>
                                {member.name}
                            </li>
                        )) : null}
                    </ol>
                </div>

                <div className="card-footer text-right">
                    <button onClick={() => onConfirm(false)} className="btn btn-outline-success"><i className="fas fa-file mr-2 " /> Create</button>
                </div>

            </div>
        ) : (
            <div>
                <div className="modal-body">
                    <p className="text-sm mb-0">
                        <i className="fas fa-exclamation-triangle text-danger mr-2 " />
                        No Accounts Have Been Selected To Create A Report With
                    </p>
                </div>
                <div className="card-footer text-right">
                    <button onClick={toggleModal} className="btn btn-outline-warning">Close</button>
                </div>

            </div>
        )}

    </Modal>
)

export default MXConnect