import { connect } from 'react-redux';
import { useEffect, useState, useCallback } from 'react'

import socket from 'sockets';
import { NOTIFICATION_CREATED, NOTIFICATION_UPDATED } from 'sockets/events';

import _notifications from '_functions/notifications';

import NavbarMobile from './NavbarMobile'
import NavbarDesktop from './NavbarDesktop'


const Navbar = ({showMobileNav, toggleSidenav, viewing_user}) => {

	const [notifications, setNotifications] = useState(0);

	const onNotificationCreated = useCallback(() => {
		setNotifications(notifications + 1)
	}, [notifications])

	const onNotificationUpdated = useCallback((data) => {
		if(viewing_user.company === data.company) {
			if(data.marked_seen_at) {
				setNotifications(notifications - 1 < 0 ? 0 : notifications - 1)
			} else {
				setNotifications(notifications + 1)
			}
		}
	}, [notifications, viewing_user.company])

	const fetchNotifications = useCallback(async () => {
        const data = await _notifications.search({limit: 1, unseen: true})
        if(data.data) setNotifications(data.data.total_documents)
    }, [])

    useEffect(() => {
        fetchNotifications()
    }, [fetchNotifications])

	useEffect(() => {
		socket.on(NOTIFICATION_CREATED, onNotificationCreated);
        socket.on(NOTIFICATION_UPDATED, onNotificationUpdated);
		return () => {
			socket.off(NOTIFICATION_CREATED, onNotificationCreated);
        	socket.off(NOTIFICATION_UPDATED, onNotificationUpdated);	
		}
	}, [onNotificationCreated, onNotificationUpdated])
	
	return showMobileNav ? (
		<NavbarMobile 
			toggleSidenav={toggleSidenav} 
			notifications={notifications}
		/>
	) : (
		<NavbarDesktop 
			notifications={notifications} 
		/>
	)

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    device: state.device.info,
	};
};

export default connect(mapStateToProps, '')(Navbar);