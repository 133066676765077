
function formatObject (obj) {

    this.obj = obj

}

// capitalize each word in a string
formatObject.prototype.name = function () {

    const obj = this.obj

    if(!obj) return "No Name"

    if(obj.given_name && obj.family_name) {

        return obj.given_name + ' ' + obj.family_name

    } else if(obj.given_name) {

        return obj.given_name

    } else if(obj.family_name) {

        return obj.family_name

    }

    return "No Name"


}

const exports = (string) => new formatObject(string)


export default exports