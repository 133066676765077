/*
Documentation

this page holds all the routes for the auth layout

*/

import Forgot from '../pages/Forgot/index';
import Register from '../pages/Register/index';
import Login from '../pages/Login';
import LoginCompany from '../pages/LoginCompany/index';
import Logout from '../pages/Logout';

const routes = [

    {
        path: "/register",
        component: Register,
    },

    {
        path: "/forgot/:company_name",
        component: Forgot,
    },
    
    {
        path: "/login/:company_name",
        component: LoginCompany,
    },
    {
        path: "/login",
        component: Login,
    },
    
    {
        path: "/logout",
        component: Logout,
    },
    {
        path: "",
        component: Login,
    },

]

export default routes;