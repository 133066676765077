import { useState, useEffect, useCallback } from 'react';
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Circle from 'components/markup/loading/Circle';
import ModalToggler from 'components/functional/modals/Toggler'

import { formatMoney, formatCredits } from 'utils/currency';
import _packages from '_functions/packages';

import ModalPurchase from './ModalPurchase';

const BillingAddCredits = ({SETTINGS, company}) => {

    const [packageToBuy, setPackageToBuy] = useState({});
    const [packages, setPackages] = useState(null);
    const [err, setErr] = useState(null);
    const [loading, setLoading] = useState(true);

    const formattedCredits = company.credits ? formatCredits(company.credits) : 0

    const fetchPackages = useCallback(async () => {

        const foundPackages = await _packages.find();
        if(foundPackages.success) {
            setPackages(foundPackages.data);
            setLoading(false);
        } else {
            setErr(true)
        }

    }, [])

    useEffect(() => {
        fetchPackages()
    }, [fetchPackages])

    if(err) return <div className="alert alert-danger">Something went wrong, please try again.</div>
    if(loading) return <Circle />

    return (
        <>

        <Helmet>
            <title>Add Credits</title>
            <meta name="description" content="Add Credits" />
        </Helmet>

        <Card>
            <CardHeader>
                <Row>
                    <Col xs={6} className="align-self-center">
                        <CardTitle className="mb-0">Add Credits</CardTitle>
                    </Col>
                    <Col xs={6} className="align-self-center text-right">
                        <span className="d-none d-sm-inline-block">Balance: </span>
                        <Badge 
                            color={formattedCredits > 5 ? 'success' : formattedCredits ? 'warning' : 'danger'} 
                            className="ml-3 px-4"
                        >
                            <b>{formattedCredits}</b>
                        </Badge>
                    </Col>
                </Row>
            </CardHeader>

            {!company.card_token ? (
                <div className="alert alert-warning mb-0">
                    <i className="fas fa-info-circle mr-2 " /> You must add a default card to your account before you can purchase credits.
                </div>
            ) : null}

            {packages.length ? (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Package</th>
                                <th>Cost (USD)</th>
                                <th>Credits</th>
                                <th>Free Credits</th>
                                <th>Cost / Credit</th>
                                <th className="text-right">Purchase</th>
                            </tr>
                        </thead>
                        <tbody>
                            {packages.map(p => (
                                <tr key={p._id}>
                                    <td>{p.name}</td>
                                    <td>{formatMoney(p.amount)}</td>
                                    <td>{formatCredits(p.credits)}</td>
                                    <td>{formatCredits(p.free_credits)}</td>
                                    <td>{formatMoney((p.amount / (p.credits + p.free_credits)) * SETTINGS.decimalMultiplier )}</td>
                                    <td className="text-right">
                                        {!company.card_id ? '-' : (
                                            <ModalToggler component={ModalPurchase} company={company} packageToBuy={packageToBuy}>
                                                <button onClick={() => setPackageToBuy(p)} className="btn btn-outline-success">
                                                    <i className="fas fa-plus " /> Add
                                                </button>
                                            </ModalToggler>
                                        )}
                                        
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <CardBody>
                    <p className="text-sm">There are no credits available for purchase at the moment</p>
                </CardBody>
            )}


        </Card>
        </>
    )

}

const mapStateToProps = state => {
    return {
        SETTINGS: state.load.SETTINGS,
        company: state.company.company,
    };
};

export default connect(mapStateToProps, '')(BillingAddCredits);