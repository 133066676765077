import * as actionTypes from '../../actions';
import store from '../../index';
import _companies from '_functions/companies';

export const setCompany = async() => {
    return new Promise( async resolve => {

        const company_id = store.getState().auth.viewing_user.company;
        const company = await _companies.findById(company_id)

        if(company.success) {

            store.dispatch({ type: actionTypes.SET_COMPANY, payload: company.data });
            resolve({success: true});

        } else {

            resolve({success: false});

        }

    })

}