/*
Documentation

this file handles password recovery by sending a hashed security code to a users email
it then verifies the code and lets them update their password

*/

import React from "react";
import PropTypes from 'prop-types'
import { Card, CardHeader, CardBody, CardTitle, CardFooter, Form, FormGroup, Input } from "reactstrap";

import _auth from '_functions/auth';

class ForgotConfirmEmail extends React.Component {

	state = {
        error: false,
        showEmail: true,
        showCodeVerification: false,
        showPasswordUpdate: false,
        success: null,
        canTry: true
    };

    //on enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
            document.getElementById("showEmailButton").click();
		}
	}

    onSendCode = async (e) => {

        e.preventDefault();

        this.setState({error: null, canTry: false})

        const email = this.state.email;
        const company = this.props.company;
        if(!email) return this.setState({error: 'You must add your email before Proceeding', canTry: true});

        //send a security code to the server
        //do not console.log this response in production as it holds
        //the code that was created and hashed
        const createCode = await _auth.security_code.create({email, company: company._id})

        if(createCode.success) {
            this.props.onConfirmedEmail(email)
        } else {
            this.setState({error: createCode.message, canTry: true})
        }

    }

    componentDidMount = () => {
        const input = document.getElementById('forgot-email-input')
        if(input) input.focus();
    }

	render() {

        const { email, error, canTry } = this.state

		return (

            <Form role="form">

                <Card className="bg-white border-0 mb-0 z-depth-2">

                    <CardHeader>
                        <CardTitle className="mb-0">Forgot Password - Step 1 of 3</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <FormGroup>
                            <label className="form-control-label">Enter Your Email</label>
                            <Input
                                id="forgot-email-input"
                                onKeyDown={(e) => this._handleKeyDown(e)}
                                placeholder="Email"
                                type="email"
                                name="email"
                                value={email	 || ''}
                                onChange={(e) => this.setState({email: e.target.value})}
                            />
                        </FormGroup>

                    </CardBody>

                    {error && (
                        <CardFooter>
                            <p className="text-sm text-danger font-weight-bold my-2 ">{error}</p>
                        </CardFooter>
                    )}

                    <CardFooter className="text-right">

                        <button disabled={!canTry} id="showEmailButton" onClick={this.onSendCode} className="btn btn-outline-success">
                            Send Recovery Code
                        </button>
                    </CardFooter>

                </Card>

            </Form>

		);
	}
}

ForgotConfirmEmail.propTypes = {
    onConfirmedEmail : PropTypes.func.isRequired
}

export default ForgotConfirmEmail

