import { useEffect, useState, useCallback } from 'react'
import { Modal } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Row, Col, Input, FormGroup } from 'reactstrap';

import _contacts from '_functions/contacts';
import { toggleStandardLoader } from 'store/functions/system/system';
import { getUrlParameter } from 'utils/urls';

import A from 'components/markup/links/A'
import Integrations from './Integrations';

const ModalCreateContact = ({showModal, toggleModal, joint}) => {

    const [givenName,   setGivenName]                   = useState(getUrlParameter('given_name') || '')
    const [familyName,  setFamilyName]                  = useState(getUrlParameter('family_name') || '')
    const [email,       setEmail]                       = useState(getUrlParameter('email') || '')
    const [phone,       setPhone]                       = useState(getUrlParameter('phone') || '')
    
    const [partnerGivenName,    setPartnerGivenName]    = useState(getUrlParameter('partner_given_name') || '')
    const [partnerFamilyName,   setPartnerFamilyName]   = useState(getUrlParameter('partner_family_name') || '')
    const [partnerEmail,        setPartnerEmail]        = useState(getUrlParameter('partner_email') || '')
    const [partnerPhone,        setPartnerPhone]        = useState(getUrlParameter('partner_phone') || '')
    
    const [duplicates,  setDuplicates]                  = useState([])
    const [errors,      setErrors]                      = useState(false)
    const [redirect,    setRedirect]                    = useState(false)
    
    const [integrations, setIntegrations] = useState(() => {
        const intuidesk_case_id = getUrlParameter('intuidesk_case_id');
        if(intuidesk_case_id) {
            return [{ name: 'intuidesk', values: { case_id: intuidesk_case_id} }]
        }
        
        const clio_matter_id = getUrlParameter('clio_matter_id');
        if(clio_matter_id) {
            return [{ name: 'clio', values: { matter_id: clio_matter_id} }]
        }
        const zoho_contact_id = getUrlParameter('zoho_contact_id');
        if(zoho_contact_id) {
            return [{ name: 'zoho', values: { contact_id: zoho_contact_id} }]
        }
        const zoho_deal_id = getUrlParameter('zoho_deal_id');
        if(zoho_deal_id) {
            return [{ name: 'zoho', values: { deal_id: zoho_deal_id} }]
        }
        return []
    })

    const [partnerIntegrations, setPartnerIntegrations] = useState(() => {
        const partner_intuidesk_case_id = getUrlParameter('partner_intuidesk_case_id');
        if(partner_intuidesk_case_id) {
            return [{ name: 'intuidesk', values: { case_id: partner_intuidesk_case_id} }]
        }
        const partner_clio_matter_id = getUrlParameter('partner_clio_matter_id');
        if(partner_clio_matter_id) {
            return [{ name: 'clio', values: { matter_id: partner_clio_matter_id} }]
        }
        const partner_zoho_contact_id = getUrlParameter('partner_zoho_contact_id');
        if(partner_zoho_contact_id) {
            return [{ name: 'clio', values: { contact_id: partner_zoho_contact_id} }]
        }
        const partner_zoho_deal_id = getUrlParameter('partner_zoho_deal_id');
        if(partner_zoho_deal_id) {
            return [{ name: 'clio', values: { deal_id: partner_zoho_deal_id} }]
        }
        return []
    })

    const onCreate = async () => {

        setErrors(false)

        if(!familyName && !email && !phone) {
            return setErrors([`A contact must have either a last name, email, or phone field.`])
        }
        
        if(joint) {
            if(!partnerFamilyName && !partnerEmail && !partnerPhone) {
                return setErrors([`A partner must have either a last name, email, or phone field.`])
            }
        }
       
        // toggle loaders and update/create the contacts
        toggleStandardLoader(true)

        const fields = {
            given_name    : givenName,
            family_name   : familyName,
            email         : email,
            phone         : phone,
            integrations,

            partner_given_name    : partnerGivenName,
            partner_family_name   : partnerFamilyName,
            partner_email         : partnerEmail,
            partner_phone         : partnerPhone,
            partner_integrations  : partnerIntegrations
        }

        let saved;

        if(joint) {
            saved = await _contacts.createJoint(fields, true);
        } else {
            saved = await _contacts.create(fields, true);
        }

        toggleStandardLoader(false)

        if(saved.success) {
            toggleModal();
            setRedirect(`/dashboard/contacts/${saved.data.contact ? saved.data.contact._id : saved.data._id}?showRequest=true`)
        } else {
            return setErrors(saved.message);
        }
        
    }

    const searchContacts = useCallback(async (email, phone) => {

        let promises = []

        if(email) promises.push( _contacts.search({searchString: email, page: 1, skip: 0, sizePerPage: 10, limit: 10}))
        if(phone) promises.push( _contacts.search({searchString: phone, page: 1, skip: 0, sizePerPage: 10, limit: 10}))

        if(!promises.length) return;

        const values = await Promise.all(promises);

        let _duplicates = [];
        if(values[0].data && values[0].data.documents) {
            _duplicates = _duplicates.concat(values[0].data.documents);
        }
        if(values[1] && values[1].data && values[1].data.documents) {
            _duplicates = _duplicates.concat(values[1].data.documents);
        }

        setDuplicates(_duplicates)

    }, [])


    const onIntegrationChange = useCallback((_integrations) => {
        setIntegrations(_integrations);
    }, [])

    const onPartnerIntegrationChange = useCallback((_integrations) => {
        setPartnerIntegrations(_integrations);
    }, [])

    useEffect(() => {

        if(showModal === true){

            searchContacts(getUrlParameter('email'), getUrlParameter('phone'))

            const tryFocus = (tries = 0) => {

                if(!showModal) return;

                const input = document.getElementById('create-contact-first-name')

                if(input) {
                    input.focus();
                } else {
                    setTimeout(() => {
                        tryFocus(tries + 1)
                    }, 200)
                }

            }

            tryFocus();

        }

    }, [showModal, searchContacts])

    // clear redirect after redirection happens
    useEffect(() => {
        if(redirect) {
            setRedirect(false);
            setGivenName('');
            setFamilyName('');
            setEmail('');
            setPhone('');
            setPartnerGivenName('');
            setPartnerFamilyName('');
            setPartnerEmail('');
            setPartnerPhone('');
            setIntegrations([]);
            setPartnerIntegrations([]);
        }
    }, [redirect])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => toggleModal(false)}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    {joint ? 'Add A Client And Their Partner' : 'Add A Client'}
                </h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleModal(false)} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom">
                <p className="text-sm mb-0">
                    <i className="fas fa-info-circle text-success mr-2 " />{' '}
                    A contact {joint ? ' and their partner ' : ''} must have either a last name, phone, or email field.
                </p>
            </div>

            {duplicates.length ? (
                <div className="modal-body bg-secondary border-bottom">
                    <p className="font-weight-bold text-dark text-uppercase">Potential Duplicates</p>
                    {duplicates.map(contact => (
                        <A key={contact._id} href={`/dashboard/contacts/${contact._id}`}>
                            <p className="text-sm mb-0">
                                {contact.display_name}
                                {contact.email ? <span> - {contact.email}</span> :null}
                                {contact.phone ? <span> - {contact.phone}</span> :null}
                            </p>
                        </A>
                    ))}
                </div>
            ) : null}

            {joint ? (
                 <div className="modal-body border-bottom">

                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">First Name</label>
                                <Input 
                                    id="create-contact-first-name"
                                    type="text"
                                    value={givenName}
                                    onChange={(e) => setGivenName(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label className="form-control-label">Last Name</label>
                                <Input 
                                    type="text"
                                    value={familyName}
                                    onChange={(e) => setFamilyName(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label className="form-control-label">Email</label>
                                <Input 
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label className="form-control-label">Phone</label>
                                <Input 
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </FormGroup>

                            <Integrations 
                                integrations={integrations}
                                onIntegrationChange={onIntegrationChange}
                            />

                        </Col>

                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">Partner's First Name</label>
                                <Input 
                                    type="text"
                                    value={partnerGivenName}
                                    onChange={(e) => setPartnerGivenName(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label className="form-control-label">Partner's Last Name</label>
                                <Input 
                                    type="text"
                                    value={partnerFamilyName}
                                    onChange={(e) => setPartnerFamilyName(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label className="form-control-label">Partner's Email</label>
                                <Input 
                                    type="text"
                                    value={partnerEmail}
                                    onChange={(e) => setPartnerEmail(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label className="form-control-label">Partner's Phone</label>
                                <Input 
                                    type="text"
                                    value={partnerPhone}
                                    onChange={(e) => setPartnerPhone(e.target.value)}
                                />
                            </FormGroup>

                            <Integrations 
                                integrations={partnerIntegrations}
                                onIntegrationChange={onPartnerIntegrationChange}
                            />
                        </Col>

                    </Row>
    
                
    
                </div>
            ) : (
                <div className="modal-body border-bottom">

                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">First Name</label>
                                <Input 
                                    id="create-contact-first-name"
                                    type="text"
                                    value={givenName}
                                    onChange={(e) => setGivenName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">Last Name</label>
                                <Input 
                                    type="text"
                                    value={familyName}
                                    onChange={(e) => setFamilyName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">Email</label>
                                <Input 
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">Phone</label>
                                <Input 
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Integrations 
                        integrations={integrations}
                        onIntegrationChange={onIntegrationChange}
                    />

                </div>
            )}
           

            {errors ? (
                <div className="modal-body border-bottom">
                    {errors.map((err, i) => (
                        <p key={i} className="text-sm font-weight-bold text-danger mb-0"><i className="fas fa-exclamation mr-2 " /> {err}</p>
                    )) }
                </div>
            ) : null}

            <div className="modal-footer text-right">
                <button onClick={() => toggleModal(false)} className="btn btn-outline-warning">
                    <i className="fas fa-arrow-left mr-2 " /> Cancel
                </button>
                <button onClick={onCreate} className="btn btn-success">
                    <i className="fas fa-check mr-2 " /> Create Contact
                </button>
            </div>

        </Modal>
    )
}

export default ModalCreateContact