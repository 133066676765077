import apiWorker from '../apiWorker'

const companies =  {

    findByName            : (name)              =>  apiWorker('get', `/v1/api/companies/name/${name}`, null),
    findById              : (company_id)        =>  apiWorker('get', `/v1/api/companies/${company_id}`, null),
    update                : (company_id, data)  =>  apiWorker('patch', `/v1/api/companies/${company_id}`, data, true),
    rollKeys              : ()                  =>  apiWorker('post', `/v1/api/companies/rollKeys`),
    setIntegration        : (company_id, data)  =>  apiWorker('patch', `/v1/api/companies/${company_id}/setIntegration`, data, true),
    setReloader           : (company_id, data)  =>  apiWorker('patch', `/v1/api/companies/${company_id}/setReloader`, data, true),
    setMessaging          : (company_id, data)  =>  apiWorker('patch', `/v1/api/companies/${company_id}/setMessaging`, data, true),
    acceptAgreement       : ()                  =>  apiWorker('post', `/v1/api/companies/agreement`, null, true),
    getRegistrationLink   : ()                  =>  apiWorker('get', `/v1/api/companies/registration`, null, true),

}

export default companies;