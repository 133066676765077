import { useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, Container, Badge, Alert, UncontrolledDropdown, DropdownToggle,DropdownItem, DropdownMenu } from "reactstrap";

import Table, { downloadCSV } from 'components/functional/tables/Standard';
import React from "react";
import { Link } from 'react-router-dom';
import _documents from '_functions/documents';
import moment from 'moment'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import { onDownloadDocument, getDownloadName } from 'utils/documents'

import { getUrlParameter } from 'utils/urls'
import { docIsNotDownloaded } from 'utils/helpers'

import { needsExtension } from 'utils/documents'

const DocumentsAll = ({device}) => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: null, total_documents: 0 })
    const [ error, setError ] = useState(false)
    const [ status, setStatus ] = useState(getUrlParameter('filter') || 'completed')

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage, status }

        const filterStatus = params.status !== undefined ? params.status : status
        const query = await _documents.search({ ...params, status: filterStatus, isCSV })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    const onStatusChange = (e) => {

        e.preventDefault();
        let status = e.target.innerHTML.toLowerCase();
        let searchString = document.querySelector('.dataTables_filter input').value

        if(status === 'all') status = '';

        setStatus(status)
        query({status, searchString: searchString, page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage })
    }

    // similar to query however it does not pass in a limit or skip
    // and once the data is fetched it will trigger the browser to download
    // the newly created file
    const onDownloadCSV = async (params) => {
        const data = await fetchData(params, true);
        downloadCSV(data, { name: 'documents' });
    }

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = device.info.isMD ? [
        {
            dataField: "name",
            headerStyle: {width: 400},
            text: "name",
            headerFormatter: () => {
                return <div id="archk-tutorial-documents-name">Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                const downloadExpired = needsExtension(row);

                let message = row.messages[row.messages.length -1];
                if(message && message.length > 40) message = message.slice(0,40) + '...'

                const icon = docIsNotDownloaded(row) ? (
                    <Badge id={`document-new-${row._id}`} color="success" className="mr-2 d-inline-block"> 
                        New <i className="fas fa-exclamation " />
                    </Badge> 
                ) : ''

                return (

                    <>
                    <span className={downloadExpired ? 'text-muted' : ''}>
                        {row.contact ? (
                            <Link className={downloadExpired ? 'text-muted' : ''} to={`/dashboard/contacts/${row.contact._id}`} style={{whiteSpace: 'pre-line'}}>
                                {icon}
                                {getDownloadName(row, row.contact)}
                            </Link>
                        ) : (
                            <span>
                                {icon}
                                {getDownloadName(row, row.contact)}
                            </span>
                        )}
                    </span>
                   
    
                    <div style={{maxWidth: 200, whiteSpace: 'pre-lne'}} className="text-sm text-muted">
                        <i className="fas fa-comments mr-2 text-info " /> {message}
                    </div>
                    </>
                )
            }
        },
        {
            dataField: "status",
            text: 'Status',
            headerFormatter: (cell, row) => (
                <div id="archk-tutorial-documents-status">
                    <UncontrolledDropdown>

                            <DropdownToggle caret>{status ? status : 'Status'}</DropdownToggle>

                            <DropdownMenu>
                                <DropdownItem onClick={onStatusChange}>All</DropdownItem>
                                <DropdownItem onClick={onStatusChange}>Completed</DropdownItem>
                                <DropdownItem onClick={onStatusChange}>Failed</DropdownItem>
                                <DropdownItem onClick={onStatusChange}>In Queue</DropdownItem>
                                <DropdownItem onClick={onStatusChange}>Pending</DropdownItem>
                            </DropdownMenu>

                    </UncontrolledDropdown>
                </div>
            ),
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.status === 'failed' ? (
                    <Badge color="danger" >{row.status}</Badge>
                ) : row.status === 'completed' ? (
                    <Badge color="success" >{row.status}</Badge>
                ) : row.status === 'needs verification' ? (
                    <Badge color="warning" >Verifying</Badge>
                ) : (
                    <Badge color="warning" >{row.status}</Badge>
                )
            )
        },
        // {
        //     dataField: "retries",
        //     text: "Attempts",
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' :
        //         row.status === 'completed' ? row.retries + 1 : row.retries
        //     )
        // },
        {
            dataField: "next_retry_at",
            text: "Attempting / Finished",
            headerStyle: {textAlign: 'right'},
            headerFormatter: () => {
                let text;
                if(!status) text = 'Attempting / Finished'
                if(status === 'completed') text = 'Finished At'
                text = 'Next Attempt'

                return (
                    <div id="archk-tutorial-documents-date">
                        {text}
                    </div>
                )
            },
            formatter: (cell, row) => (
                <div className="text-right">
                    {row.name === 'no data' ? '' :
                    row.status === 'needs verification' ? '-' :
                    row.status !== 'failed' && row.status !== 'completed' ? (
                        <div>
                            <div>{moment.unix(row.next_retry_at).format('MM/DD/YYYY')}</div>
                            <div>{moment.unix(row.next_retry_at).format('h:mm A')}</div>
                        </div>
                    ) : row.finished_at ? (
                        <span>
                            <div>{moment.unix(row.finished_at).format('MM/DD/YYYY')}</div>
                            <div>{moment.unix(row.finished_at).format('h:mm A')}</div>
                        </span>
                    ) : '-'}
                </div>
            )
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right'},
            text: "actions",
            headerFormatter: () => {
                return <div id="archk-tutorial-documents-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        {row.status === 'completed' ? (
                             <>
                             {row.contact ? (
                                 <Link 
                                     className="btn btn-outline-warning" 
                                     to={`/dashboard/analyze/${row.contact._id}?document=${row._id}`}
                                     style={{minWidth: 70}}
                                 >
                                     <i className="fas fa-chart-line mr2 " />
                                 </Link>
                             ) : null }
                             <button className="btn btn-outline-success" style={{minWidth: 70}} onClick={() => onDownloadDocument(row, row.contact)}>
                                 <i className="fas fa-download mr2 " />
                             </button>
                            </>
                        ) : '-'}
                    </div>
                )
            }
        },
    ] : [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => {

                const sliceLength = device.info.isLG ? 100 : device.info.isMD ? 60 : device.info.isSM ? 40 : 15

                let name = getDownloadName(row, row.contact);
                if(name && name.length > sliceLength) name = name.slice(0,sliceLength) + '...'

                return (
                    row.name === 'no data' ? 'No Documents Created' : 
                    <div>
                        {
                            row.status === 'failed' ? (
                                <Badge color="danger" className="tex-sm" style={styles.badgeMobile}><i className="fas fa-times " /></Badge>
                            ) : row.status === 'completed' ? (
                                <Badge color="success" className="tex-sm" style={styles.badgeMobile}><i className="fas fa-check " /></Badge>
                            ) : (
                                <Badge color="warning" className="tex-sm" style={styles.badgeMobile}><i className="fas fa-sync " /></Badge>
                            )
                        }
                        <Link to={`/dashboard/documents/${row._id}`}>
                            {name}
                        </Link>
                    </div>
                )
            }
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                row.status === 'completed' ? (
                    <div className="text-right">
                        <button 
                            className="btn btn-outline-success" 
                            onClick={() => onDownloadDocument(row, row.contact)}
                            style={{minWidth: 70}}
                        >
                            <i className="fas fa-download mr2 " />
                        </button>
                    </div>
                ) : (
                    <div className="text-right">-</div>
                )
            )
        },

    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Documents`}</title>
                <meta name="description" content="Documents" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Documents"
                breadcrumb_2="All"
                icon="fas fa-file"
            />
    
            <Card className="card-color card-primary">    
                <Table
                    placeholder="Document Name"
                    downloadCSV={onDownloadCSV}
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>
    
        </Container>
    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 120
    },
    badgeMobile: {
        display: 'inline-block',
        width: 30,
        marginRight: 10
    }
}

const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(DocumentsAll);
