import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardFooter, CardTitle } from "reactstrap";

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import Integrations from "../ModalCreate/Integrations";

import Circle from 'components/markup/loading/Circle';

// import States from 'components/markup/inputs/States';
// import Countries from 'components/markup/inputs/Countries';

const ContactsUpdate = ({loading, contact, onInputChange, onSave, errors, company}) => {

    if(loading) return <Circle />

    return (

        <Card className="mb-0">

            <CardHeader>

                <Row>
                    <Col lg={6}>
                        <StandardFormGroup
                            obj={contact}
                            objName="contact"
                            onChange={onInputChange}
                            field="given_name"
                            title="First Name"
                        />
                    </Col>
                    <Col lg={6}>
                        <StandardFormGroup
                            obj={contact}
                            objName="contact"
                            onChange={onInputChange}
                            field="family_name"
                            title="Last Name"
                        />
                    </Col>
                </Row>

            </CardHeader>

            <CardHeader>
                <CardTitle className="mb-0">Contact Information</CardTitle>
            </CardHeader>
            
            <CardHeader>

                <StandardFormGroup
                    obj={contact}
                    objName="contact"
                    onChange={onInputChange}
                    field="email"
                    title="Email"
                />

                <StandardFormGroup
                    obj={contact}
                    objName="contact"
                    onChange={onInputChange}
                    field="phone"
                    title="Phone"
                    type="number"
                />

                {/* <StandardFormGroup
                    obj={contact}
                    objName="contact"
                    onChange={onInputChange}
                    field="fax_number"
                    title="Fax Number"
                    type="tel"
                /> */}

            </CardHeader>

            {/* <CardHeader>
                <CardTitle className="mb-0">Address</CardTitle>
            </CardHeader>

            <CardHeader>
                
                <Row>
                    <Col lg={6}>
                        <StandardFormGroup
                            obj={contact}
                            objName="contact"
                            onChange={onInputChange}
                            field="address_line_1"
                            title="Address Line 1 "
                        />
                    </Col>
                    <Col lg={6}>
                        <StandardFormGroup
                            obj={contact}
                            objName="contact"
                            onChange={onInputChange}
                            field="address_line_2"
                            title="Address Line 2 "
                        />
                    </Col>
                </Row>
                
                <StandardFormGroup
                    obj={contact}
                    objName="contact"
                    onChange={onInputChange}
                    field="city"
                    title="City"
                />
                <StandardFormGroup
                    obj={contact}
                    objName="contact"
                    onChange={onInputChange}
                    field="state"
                    title="State"
                    type="select"
                >
                    <option value="">Select State</option>
                    <States />
                </StandardFormGroup>
                <StandardFormGroup
                    obj={contact}
                    objName="contact"
                    onChange={onInputChange}
                    field="postal_code"
                    title="Zip code "
                />
                <StandardFormGroup
                    obj={contact}
                    objName="contact"
                    onChange={onInputChange}
                    type="select"
                    field="country"
                    title="Country "
                >
                    <option></option>
                    <Countries />
                </StandardFormGroup>
            </CardHeader> */}

            {company.integrations && company.integrations.find(i => i.enabled === true) ? (
                <>
                <CardHeader>
                    <CardTitle className="mb-0">Contact Integrations</CardTitle>
                </CardHeader>

                <CardHeader>
                    <Integrations integrations={contact.integrations} onIntegrationChange={(integrations) => onInputChange(contact, 'integrations', integrations)} />
                </CardHeader>
                </>
            ) : null}

            {errors ? (
                <CardFooter>
                    {errors.map((err, i) => (
                        <p key={i} className="text-sm font-weight-bold text-danger mb-0"><i className="fas fa-exclamation mr-2 " /> {err}</p>
                    )) }
                </CardFooter>
            ) : null}
            
            <CardFooter className="text-right">
                <button className="btn btn-success" onClick={onSave} >
                Update Contact
                </button>  
            </CardFooter>

        </Card>

    );

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(ContactsUpdate);