import FormsEdit    from '../pages/forms/Edit/index';
import Signing    from '../pages/signing/Edit/index';

const routes = () =>  [

    {
        path: "/forms/:form_id",
        component: FormsEdit,
    },
    {
        path: "/signing/:template_id",
        component: Signing,
    },
   
]

export default routes;
