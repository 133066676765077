import { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container } from "reactstrap";

import _contacts from '_functions/contacts';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import { getUrlParameter, setUrlParameter } from "utils/urls";
import { Link } from 'react-router-dom'

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'


import Update from './Update';
import Overview from './Overview';
import Timeline from './Timeline';
import Navigation from './Navigation';
import Connect from './Connect';
import Header from './Header';
import ModalCredits from './ModalCredits';
import ModalPurchases from './ModalPurchases';
import Forms from './Forms';

import SendRequestModal from 'views/dashboard/pages/_components/SendRequestModal'

import LoadingTable from 'components/markup/loading/Table.js'
import SendFormRequestModal from 'views/dashboard/pages/_components/SendFormRequestModal'
import ModalToggler from 'components/functional/modals/Toggler'

const ContactsView = ({match, company}) => {

    const [ token, setToken ] = useState(null)
    const [ showFormModal, setShowFormModal ] = useState(false)
    const [ showRequestModal, setShowRequestModal ] = useState(false)
    const [ contact, setContact ] = useState({})
    const [ accessCode, setAccessCode ] = useState(null)
    const [ documents, setDocuments ] = useState([])
    const [ contactPurchases, setContactPurchases ] = useState(null)
    const [ loading, setLoading ] = useState({})
    const [ tab, setTab ] = useState(0)
    const [ err, setErr ] = useState(false)
    const [ errors, setErrors ] = useState(false)

    const setContactName = useCallback((contact) => {
        try {

            const name = contact.display_name.split(' ');
            if(name[0]) contact.given_name = name[0]
            if(name[1]) contact.family_name = name[1]
            
            return contact;

        } catch(e) {
            return contact
        }

    }, [])

    const fetchData = useCallback(async () => {
        const contact = await _contacts.view(match.params.contact_id)
        if(!contact.data) return setErr(true);

        let _documents = contact.data.documents;
        if(contact.data.partner_documents) _documents = _documents.concat(contact.data.partner_documents)

        let _purchases = contact.data.purchases;
        if(contact.data.partner_purchases) _purchases = _purchases.concat(contact.data.partner_purchases)

        const formattedContact = setContactName(contact.data.contact)
        setAccessCode(contact.data.contact.access_code)
        setContact(formattedContact)
        setDocuments(_documents)
        setContactPurchases(_purchases)
        setToken(contact.data.token)
        setLoading(false)
    }, [match.params.contact_id, setContactName])


    const onSetTab = useCallback((tab) => {
        setTab(tab);
        setUrlParameter('nav', tab)
        if(tab === 0) fetchData();
    }, [fetchData])

    const onInputChange = useCallback((object, field, value) => {
        const newContact = Object.assign({}, contact)
        newContact[field] = value;
        setContact(newContact);
    }, [contact])

    const onFormRequested = useCallback(() => {
        toggleAlertBS(false, 'Form Request Successfully Sent')
        onSetTab(4);
        const button = document.getElementById('archk-toggle-form-request');
        if(button) button.click();
    }, [onSetTab])

    const onSave = useCallback(async () => {

        setErrors(false)
        //copy state
        let newState = Object.assign({}, contact);
        //assume we have no errors

        if(!newState.family_name && !newState.email && !newState.phone) {
            return setErrors([`A contact must have either a last name, email, or phone field.`])
        }

        setContact(newState)

        // toggle loaders and update/create the contacts
        toggleStandardLoader(true)
        let saved = await _contacts.update(newState._id, newState, true);
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Contact Updated Successfully")
            fetchData();
        } else {
            return setErrors(saved.message);
        }
    
    }, [contact, fetchData])

    useEffect(() => {
        fetchData();

        const nav = parseInt(getUrlParameter('nav'));
        if(nav && !Number.isNaN(nav)) setTab(nav)

    }, [fetchData])

    useEffect(() => {
        if(contact._id && getUrlParameter('showRequest') === 'true') {
            if(company.forms_enabled) {
                setShowFormModal(true)
            } else {
                setShowRequestModal(true)
            }
        }
    }, [contact._id, company.forms_enabled])

    return (

        <Container fluid>

            

            <Helmet>
                <title>View Contact</title>
                <meta name="description" content="Cases Create" />
            </Helmet>

            <SendRequestModal 
                showModal={showRequestModal}
                toggleModal={() => setShowRequestModal(false)}
                onConfirm={fetchData}
                contact={contact}
            />

           {err ? (
               <div className="alert alert-warning my-4 mb-0">
                   <i className="fas fa-exclamation-triangle mr-2 " />
                   The contact you are looking for cannot be found.
               </div>
           ) : loading ? (
               <LoadingTable rows={10} />
           )  : (

                <>

                <DashHeaderOpen 
                    title={<span>Dashboard</span>}
                    col1Size={6}
                    col2Size={6}
                    breadcrumb_1={(
                        <span className="text-dark font-weight-bold">
                            {contact.display_name ? contact.display_name : contact.email ? contact.email : contact.phone ? contact.phone : '-'}
                        </span>
                    )}
                    breadcrumb_2={(
                        <span className="cursor-pointer">
                            {contact.contact_purchases ? (
                            <ModalToggler component={ModalPurchases} contact={contact} contactPurchases={contactPurchases} >
                                <i className="fas fa-info-circle text-info mr-2" /> Purchases
                            </ModalToggler>
                            ) : (
                            <ModalToggler component={ModalCredits} contact={contact}>
                                <i className="fas fa-info-circle text-info mr-2" /> Credits
                            </ModalToggler>
                            )}
                            
                        </span>
                    )}
                    icon="fas fa-user"
                    actionComponent={(
                        <>
                    
                            <Link to="/dashboard/contacts" className="btn btn-outline-warning"><i className="fas fa-arrow-left mr-2 " />Back</Link>
                            
                            <ModalToggler component={SendFormRequestModal} showModal={showFormModal}  onConfirm={onFormRequested} contact={contact}>
                                <button id="archk-toggle-form-request" className="btn btn-info"><i className="fas fa-plus mr-2 " />Form</button>
                            </ModalToggler>

                            <button onClick={() => setShowRequestModal(true)} className="btn btn-success ml--0 ml-md-3"><i className="fas fa-paper-plane mr-2  " />Request</button>
                        </>
                    )}
                />

                <Navigation 
                    tab={tab}
                    setTab={onSetTab}
                />

                <Header 
                    contact={contact}
                    token={token}
                    fetchData={fetchData}
                />

                {tab === 0 ? (
                    <Overview 
                        contact={contact}
                        documents={documents}
                        fetchData={fetchData}
                    />
                ) : tab === 1 ? (
                    <Update
                        match={match}
                        contact={contact}
                        onInputChange={onInputChange}
                        onSave={onSave}
                        errors={errors}
                    />
                )  : tab === 2 ? (
                    <Timeline 
                        contact={contact}
                    />
                ) : tab === 3 ? (
                    <Connect 
                        contact={contact} 
                        setTab={onSetTab} 
                        fetchData={fetchData}
                    />
                ) : tab === 4 ? (
                    <Forms 
                        contact_id={contact._id} 
                        contact={contact} 
                        setTab={onSetTab} 
                        fetchData={fetchData}
                        accessCode={accessCode}
                    />
                ) : null}

                </>

           )}
                
        </Container>

    );

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(ContactsView);