/*
Documentation

this page handles creating a users account

*/

import keys from 'keys';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from "react-router-dom";
import { Card, CardBody, CardTitle, CardHeader, CardFooter, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import { toggleStandardLoader } from 'store/functions/system/system';
import _channel_partners from '_functions/channel_partners';
import _users from '_functions/users';
import _auth from '_functions/auth';
import { getUrlParameter, setUrlParameter } from 'utils/urls';

class Register extends React.Component {

	state = {
        reset: false,
        email: '',
        password: '',
        partner: null
    };

    isValidating = false;

	//on keypress of enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("signUpButton").click();
		}
	}

	onSignUp = async (e) => {

        e.preventDefault();

        if(this.isValidating) return;

        this.isValidating = true;


        this.setState({
            error: false,
            emailError: false,
            passwordError: false,
            passwordConfirmError: false,
            company_nameError: false,
            given_nameError: false,
            family_nameError: false,
        })

		const { email, password, passwordConfirm, company_name, given_name, family_name, partner } = this.state;
        let errors = 0;

        if(!email)           { errors++; this.setState({emailError: true}) }
        if(!password)        { errors++; this.setState({passwordError: true}) }
        if(!passwordConfirm) { errors++; this.setState({passwordConfirmError: true}) }
        if(!company_name)    { errors++; this.setState({company_nameError: true}) }
        if(!given_name)      { errors++; this.setState({given_nameError: true}) }
        if(!email)           { errors++; this.setState({emailError: true}) }
        if(!family_name)     { errors++; this.setState({family_nameError: true}) }

        if(errors) return this.isValidating = false

        toggleStandardLoader(true)

        const created = await _users.create({
            email, 
            password, 
            passwordConfirm, 
            company_name, 
            given_name, 
            family_name,
            channel_partner: partner && partner._id ? partner._id : undefined 
        })

        if(!created.success) {
            toggleStandardLoader(false)

            this.isValidating = false
            return this.setState({error: created.message})
        }

        const loggedIn = await _auth.login({email, password, company: created.data.company})

        toggleStandardLoader(false)

        this.isValidating = false

        if(loggedIn.success) {

            await setViewingUser();
            const redirect = getUrlParameter('redirect')

			this.setState({shouldRedirect: redirect ? redirect : '/dashboard/home'})

        } else {

            this.setState({error: loggedIn.message});

        }

    }

    componentDidMount = async () => {

        if(getUrlParameter('reset')) {
            this.setState({reset: true})
            setUrlParameter('reset', '#')
        }

        const input = document.getElementById('register-first-name');
        if(input) input.focus();

        const channel_partner = getUrlParameter('p');
        if(channel_partner) {
            const partner = await _channel_partners.findByName(channel_partner);
            if(partner && partner.data) this.setState({partner: partner.data})
        }

    }

	render() {

        const { error, shouldRedirect, email, password, passwordConfirm, given_name, family_name, company_name, partner } = this.state
		if(shouldRedirect) return <Redirect to={shouldRedirect} />

		return (

		<>

			<Helmet>
				<title>Login</title>
				<meta name="description" content="Login" />
			</Helmet>

            <div className="header  py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 250}} alt="..."  src={keys.LOGO_WHITE} />
                                <p className="font-weight-bold mt-4 pt-1 mb-0">
                                    {partner && partner.name && !partner.internal ? (
                                        <span>In Partnership With <b className="text-dark text-underline">{partner.name}</b></span> 
                                    ): `Create Account`}
                                </p>

                                {partner && partner.logo && !partner.internal  ? <img src={partner.logo} className="mt-4 pt-2" style={{width: 100}} alt={partner.name} /> : null}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">

                        <Form role="form">

                            <Card className="bg-white  mb-0 z-depth-2" >

                                <CardHeader>
                                    <CardTitle className="mb-0">Register Your Account!</CardTitle>
                                    <p className="text-sm mb-0">Let's do this, create your free account below.</p>
                                </CardHeader>

                                <CardBody className="bg-secondary">

                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <label className="form-control-label">
                                                    <span className={this.state.given_nameError ? 'text-danger' : null} >My First Name*</span>
                                                </label>
                                                <Input
                                                    id="register-first-name"
                                                    name="fname"
                                                    onKeyDown={this._handleKeyDown}
                                                    type="text"
                                                    value={given_name || ''}
                                                    onChange={(e) => this.setState({given_name: e.target.value})}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <label className="form-control-label">
                                                    <span className={this.state.family_nameError ? 'text-danger' : null} >My Last Name*</span>
                                                </label>

                                                <Input
                                                    name="lname"
                                                    onKeyDown={this._handleKeyDown}
                                                    type="text"
                                                    value={family_name || ''}
                                                    onChange={(e) => this.setState({family_name: e.target.value})}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <FormGroup>
                                        <label className="form-control-label">
                                            <span className={this.state.emailError ? 'text-danger' : null} >My Email*</span>
                                        </label>
                                        <Input
                                            name="email"
                                            onKeyDown={this._handleKeyDown}
                                            type="email"
                                            value={email || ''}
                                            onChange={(e) => this.setState({email: e.target.value})}
                                        />
                                    </FormGroup>

                                </CardBody>

                                <CardFooter className="bg-secondary">

                                    <FormGroup>
                                        <label className="form-control-label">
                                            <span className={this.state.company_nameError ? 'text-danger' : null} >Company Name*</span>
                                        </label>
                                        <Input
                                            onKeyDown={this._handleKeyDown}
                                            type="company_name"
                                            value={company_name || ''}
                                            onChange={(e) => this.setState({company_name: e.target.value})}
                                        />
                                    </FormGroup>

                                </CardFooter>

                                <CardFooter className="bg-secondary">

                                    <FormGroup>
                                        <label className="form-control-label">
                                            <span className={this.state.passwordError ? 'text-danger' : null} >Password*</span>
                                        </label>
                                        <Input
                                            onKeyDown={this._handleKeyDown}
                                            type="password"
                                            value={password || ''}
                                            onChange={(e) => this.setState({password: e.target.value})}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <label className="form-control-label">
                                            <span className={this.state.passwordConfirmError ? 'text-danger' : null} >Confirm Password*</span>
                                        </label>
                                        <Input
                                            onKeyDown={this._handleKeyDown}
                                            type="password"
                                            value={passwordConfirm || ''}
                                            onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                                        />
                                    </FormGroup>


                                </CardFooter>

                                {error && (
                                    <CardFooter>
                                        <p className="mb-0 text-sm text-danger font-weight-bold">{error}</p>
                                    </CardFooter>
                                )}

                                <CardFooter className="text-right">

                                    <button id="signUpButton" onClick={this.onSignUp} className="btn btn-success">
                                        Create My Account
                                    </button>

                                </CardFooter>
                            </Card>

                        </Form>


						<div className="mt-4 text-center">
							<Link to="/auth/login" >
                                <p className="text-sm font-weight-bold">Login</p>
							</Link>
						</div>

					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

export default Register

