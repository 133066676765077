import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, CardHeader, CardBody, CardFooter, CardTitle, FormGroup, Input } from 'reactstrap';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _companies from '_functions/companies';

import States from 'components/markup/inputs/States'

const required_form_fields = [
    'name',
    'email',
    'phone',
    'billing_address_line_1',
    'billing_city',
    'billing_state',
    'billing_postal_code',
]

const SettingsInfoDetails = ({company, setCompany}) => {

    const [apiErr, setApiErr] = useState(0);
    const [errs, setErrs] = useState([]);
    const [state, setState] = useState({
        name                      : '',
        email                      : '',
        phone                      : '',
        billing_address_line_1    : '',
        billing_address_line_2    : '',
        billing_city              : '',
        billing_state             : '',
        billing_postal_code       : '',
    })

    const onUpdate = async () => {

        let _errs = []

        required_form_fields.forEach((field) => {
            if(!state[field]) _errs.push(field)
            if(field === 'billing_state' && state[field] === 'false') _errs.push(field)
        })

        setErrs(_errs);
        if(_errs.length) return

        toggleStandardLoader(true)

        const updated = await _companies.update(company._id, state)
        if(updated.success) {
            toggleAlertBS(false, 'Company settings updated successfully.')
            setCompany()
        } else {
            setApiErr(updated.message)
        }

        toggleStandardLoader(false)


    }

    const onInputChange = useCallback((field, value) => {
        const _state = JSON.parse(JSON.stringify(state));
        _state[field] = value
        setState(_state)
    }, [state])

    useEffect(() => {
        const _company = JSON.parse(JSON.stringify(company));

        setState({
            name                      : _company.name || '',
            email                      : _company.email || '',
            phone                      : _company.phone || '',
            billing_address_line_1    : _company.billing_address_line_1 || '',
            billing_address_line_2    : _company.billing_address_line_2 || '',
            billing_city              : _company.billing_city || '',
            billing_state             : _company.billing_state || '',
            billing_postal_code       : _company.billing_postal_code || '',
        })
    }, [company])

    return (
        <div className="border-left">

            <CardHeader>
                <CardTitle className="mb-0">Company Details</CardTitle>
            </CardHeader>

            <CardBody>

                <FormGroup>
                    <label className="text-sm mb-0">Company Name *</label>
                    <p className="text-sm mb-0">
                        <span>
                            <i className="fas fa-exclamation-triangle text-warning mr-2 " />
                            Changing your Company Name will also change the client portal and dashboard links associated with your account. 
                        </span>
                    </p>
                    <Input 
                        type="text"
                        value={state.name}
                        onChange={(e) => onInputChange('name', e.target.value)}
                        invalid={errs.includes('name') ? true : false}
                    />
                </FormGroup>

                <Row>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="text-sm mb-0">Email*</label>
                            <Input 
                                type="text"
                                value={state.email}
                                onChange={(e) => onInputChange('email', e.target.value)}
                                invalid={errs.includes('email') ? true : false}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="text-sm mb-0">Phone*</label>
                            <Input 
                                type="text"
                                value={state.phone}
                                onChange={(e) => onInputChange('phone', e.target.value)}
                                invalid={errs.includes('phone') ? true : false}
                            />
                        </FormGroup>
                    </Col>

                </Row>

            </CardBody>

            <CardFooter>
                <CardTitle className="mb-0">Company Billing Address</CardTitle>
            </CardFooter>

            <CardFooter>

                <Row>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="text-sm mb-0">Address Line 1*</label>
                            <Input 
                                type="text"
                                value={state.billing_address_line_1}
                                onChange={(e) => onInputChange('billing_address_line_1', e.target.value)}
                                invalid={errs.includes('billing_address_line_1') ? true : false}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="text-sm mb-0">Address Line 2*</label>
                            <Input 
                                type="text"
                                value={state.billing_address_line_2}
                                onChange={(e) => onInputChange('billing_address_line_2', e.target.value)}
                                invalid={errs.includes('billing_address_line_2') ? true : false}
                            />
                        </FormGroup>
                    </Col>

                </Row>

                <Row>
                    <Col lg={4}>
                        <FormGroup>
                            <label className="text-sm mb-0">City*</label>
                            <Input 
                                type="text"
                                value={state.billing_city}
                                onChange={(e) => onInputChange('billing_city', e.target.value)}
                                invalid={errs.includes('billing_city') ? true : false}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup>
                            <label className="text-sm mb-0">State*</label>
                            <Input 
                                type="select"
                                value={state.billing_state}
                                onChange={(e) => onInputChange('billing_state', e.target.value)}
                                invalid={errs.includes('billing_state') ? true : false}
                            >
                                <option value="false"></option>
                                <States />
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup>
                            <label className="text-sm mb-0">Postal Code *</label>
                            <Input 
                                type="text"
                                value={state.billing_postal_code}
                                onChange={(e) => onInputChange('billing_postal_code', e.target.value)}
                                invalid={errs.includes('billing_postal_code') ? true : false}
                            />
                        </FormGroup>
                    </Col>
                </Row>

            </CardFooter>
            
            {apiErr ? (
                <CardFooter>
                    {apiErr.map((e, i) => <p key={i} className="text-sm text-danger font-weight-bold mb-0">{e}</p>)}
                </CardFooter>
            ) : null}

            <CardFooter className="text-right">
                <button onClick={onUpdate} className="btn btn-success">
                    <i className="fas fa-save mr-2 " /> Save
                </button>
            </CardFooter>
        
        </div>

    )
}

export default SettingsInfoDetails;