import apiWorker from '../apiWorker'

const singing_templates =  {
    search    : (data)                      => apiWorker('post',  `/v1/signing/templates/search`, data),
    findById  : (signing_template_id)       => apiWorker('get',   `/v1/signing/templates/${signing_template_id}`, null, true),
    delete    : (signing_template_id)       => apiWorker('delete',   `/v1/signing/templates/${signing_template_id}`, null, true),
    update    : (signing_template_id, data) => apiWorker('patch', `/v1/signing/templates/${signing_template_id}`, data, true),
    getConfig : ()                          => apiWorker('get', `/v1/signing/templates/configuration`, null, true),
}

export default singing_templates;