import { useCallback } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Container, FormGroup, Input, Badge } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import A from 'components/markup/links/A'

import moment from 'moment';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import Dots from 'components/markup/loading/Dots';

import { Helmet } from 'react-helmet';

import AnalyzePayStub from './PayStub'
import AnalyzeFinancial from './Financial'

const Markup = ({isSingle, isDownload, contact, documentID, onDocumentIDChange, analytics, downloaded, onDownloadSingle, onDownloadAll}) => {

    const history = useHistory();

    const formatCurrency = useCallback((number) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
    }, [])

    const renderDocAnalysis = useCallback((analysis) => {

        if(!analysis) {
            return <div className="no-data border-warning p-3 px-4 border-radius-50 text-warning">There is no analysis available for this document.</div>
        }

        if(analysis.document.type === 'pinwheel') return (
            <AnalyzePayStub 
                key={analysis._id}
                analysis={analysis}
                isDownload={isDownload}
                contact={contact}
                formatCurrency={formatCurrency}
                containerProps={isSingle ? null : {
                    documentName: analysis.document.name,
                    finishedAt: analysis.document.finished_at
                }}
            />
        )  

        if(analysis.document.type === 'mx' || analysis.document.type === 'yodlee') return (
            <AnalyzeFinancial  
                key={analysis._id}
                analysis={analysis}
                isDownload={isDownload}
                contact={contact}
                formatCurrency={formatCurrency}
                containerProps={isSingle? null : {
                    documentName: analysis.document.name,
                    finishedAt: analysis.document.finished_at
                }}
            />
        )
        
        return <div className="no-data border-warning p-3 px-4 border-radius-50 text-warning">There is no data to show.</div>

    }, [formatCurrency, contact, isSingle, isDownload])


    if(isSingle) {

        const analytic = analytics.find(a => a.document._id === documentID);

        return (
            <div className={isDownload ? "archk-analysis download" : "archk-analysis"} >
                <Container fluid >

                    <Helmet>
                        <title>{contact.display_name}</title>
                        <meta name="description" content="Contacts" />
                    </Helmet>

                    {isDownload ? (
                        <span>
                        {downloaded ? (
                            <div>
                                <p className="mt-3 text-center mb-0">Your download has finished and should appear any second.</p>
                                <p className="text-center">If you do not see your download try <b className="text-underline cursor-pointer" onClick={onDownloadSingle}>clicking here</b>.</p>
                            </div>
                        ) : (
                            <p className="mt-3 text-center text-sm">Starting Your Download<Dots /> </p>   
                        )}
                        <div className="text-center mt-1 mb-4"><Badge color="info"><i className="fas fa-info mr-2 " /> BETA</Badge></div>
                        </span>
                    ) : (
                        <DashHeaderOpen 
                            title={<span>Dashboard</span>}
                            breadcrumb_1={(<Badge color="success"><i className="fas fa-info mr-2 " /> BETA</Badge>)}
                            breadcrumb_2="Analysis"
                            breadcrumb_3={contact.display_name}
                            icon="fas fa-chart-line"
                            actionComponent={(
                                <button onClick={() => history.goBack()} className="btn btn-outline-warning">
                                    <i className="fas fa-arrow-left mr-2 " /> Back
                                </button>
                            )}
                        />

                    )}

                    <div id="to-download">
                        <Card>

                            {isDownload ? (
                                <CardHeader>
                                    <CardTitle className="mb-0 text-center">
                                        {contact.display_name} - {analytic.document.name} - {moment.unix(analytic.document.finished_at).format('MM/DD/YY')}
                                    </CardTitle>
                                </CardHeader>
                            ) : (
                                <CardHeader>
                                    <Row>
                                        <Col lg={7} className="align-self-center">
                                            <CardTitle className="mb-0">
                                                {contact.display_name}
                                                <A className="btn btn-outline-warning ml-3" href={`/dashboard/analyze/${contact._id}/download/${documentID}`}>
                                                    <i className="fas fa-download mr-2 " /> Download
                                                </A>

                                                <Link to={`/dashboard/analyze/${contact._id}/full`} className="btn btn-outline-info">
                                                    <i className="fas fa-search-plus mr-2 " />
                                                    Full Analysis
                                                </Link>
                                            </CardTitle>
                                        </Col>
                                        <Col lg={5} className="align-self-center">
                                            <FormGroup className="mb-0 my-3 my-lg-0">
                                                <Input
                                                    type="select"
                                                    value={documentID}
                                                    onChange={onDocumentIDChange}
                                                >
                                                    {analytics.map(a => (
                                                        <option key={a.document._id} value={a.document._id} >
                                                            {moment.unix(a.document.finished_at).format('MM/DD/YY')} - {a.document.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            )}
                            
                            <CardBody>
                                {renderDocAnalysis(analytics.find(a => a.document._id === documentID))}
                            </CardBody>

                        </Card>
                    </div>

                </Container>
            </div>
        );
    }

    return (
        <div className={isDownload ? "archk-analysis download" : "archk-analysis"}>

        <Container fluid>

            <Helmet>
                <title>{contact.display_name}</title>
                <meta name="description" content="Contacts" />
            </Helmet>

            {isDownload ? (
                <span>
                {downloaded ? (
                    <div>
                         <p className="mt-3 text-center mb-0">Your download has finished and should appear any second.</p>
                        <p className="text-center">If you do not see your download try <b className="text-underline cursor-pointer" onClick={onDownloadAll}>clicking here</b>.</p>
                    </div>
                ) : (
                    <p className="mt-3 text-center text-sm">Starting Your Download<Dots /> </p>   
                )}
                <div className="text-center mt-1 mb-4"><Badge color="info"><i className="fas fa-info mr-2 " /> BETA</Badge></div>
                </span>
            ) : (
                <DashHeaderOpen 
                    title={<span>Dashboard</span>}
                    breadcrumb_1={(<Badge color="success"><i className="fas fa-info mr-2 " /> BETA</Badge>)}
                    breadcrumb_2="Full Analysis"
                    breadcrumb_3={contact.display_name}
                    icon="fas fa-chart-line"
                    actionComponent={(
                        <>
                        <A className="btn btn-outline-info ml-3" href={`/dashboard/analyze/${contact._id}/download/all`}>
                            <i className="fas fa-download mr-2 " /> Download
                        </A>
                        <button onClick={() => history.goBack()} className="btn btn-outline-warning">
                            <i className="fas fa-arrow-left mr-2 " /> Back
                        </button>
                        </>
                    )}
                />
            )}

            {analytics.map(analytic => (
                <div key={analytic._id} className="print-wrapper">
                    {renderDocAnalysis(analytic)}
                </div>
            ))}
            
        </Container>
    </div>
    )

}

export default Markup