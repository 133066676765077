const simulateTabOnEnter = (e, passIfNull) => {
    const isEnterButton = e.keyCode === 13 ? true :false;
    const isAnInput = e.target.nodeName === 'INPUT' || e.target.nodeName === 'SELECT' ? true :false;
    const inputHasValue = e.target.value && e.target.value !== '__FALSE__' ? true :false;

    const hasAValidValue = passIfNull || inputHasValue;

    if (isEnterButton && isAnInput && hasAValidValue) {
        var form = e.target.form;
        var index = Array.prototype.indexOf.call(form, e.target);
        if(index === -1) return;

        const el = form.elements[index + 1];
        if(!el) return;

        el.focus();
        e.preventDefault();
        el.scrollIntoView({block: 'center', behavior: 'smooth'})
    }
}

export default simulateTabOnEnter;