/*
Documentation

this page holds all the routes for the dashboard layout

*/


import AccountSettings  from '../pages/account/Settings';
import AccountMFA       from '../pages/account/MFA';

import CompanySettings  from '../pages/company/Settings';
import CompanyBilling   from '../pages/company/Billing';
import CompanyPricing   from '../pages/company/Pricing';

import DevelopmentSimulator     from '../pages/development/Simulator';

import DocumentsAll     from '../pages/documents/All';
import DocumentsView    from '../pages/documents/View';

import FormsAll    from '../pages/forms/All/index';
import FormsPreview    from '../pages/forms/Preview/index';
import FormsRequests   from '../pages/forms/Requests';
import FormsAccess   from '../pages/forms/Access';

import FormSubmissions    from '../pages/form_submissions/All';
import FormSubmissionsView    from '../pages/form_submissions/View/Router';
import FormSubmissionsAnswers    from '../pages/form_submissions/View/Answers';
import FormSubmissionsSubmissions    from '../pages/form_submissions/View/Submissions';

import Dashboard        from '../pages/dashboard/index';

import AnalyzePageSingle        from '../pages/analyze/PageSingle';
import AnalyzePageAll           from '../pages/analyze/PageAll';
import AnalyzePageDownload      from '../pages/analyze/PageDownload';
import AnalyzePageDownloadAll   from '../pages/analyze/PageDownloadAll';

import ContactsAll      from '../pages/contacts/All';
import ContactsView     from '../pages/contacts/View';

import NotificationsAll     from '../pages/notifications/All';
import NotificationsView    from '../pages/notifications/View';

import SigningAccess    from '../pages/signing/Access';
import SigningTemplates    from '../pages/signing/Templates/All';
import SigningDocumentsAll    from '../pages/signing/Documents/All';
import SigningDocumentsView    from '../pages/signing/Documents/View';

import ReviewsAll    from '../pages/reviews/All';
import ReviewsAccess    from '../pages/reviews/Access';
import ReviewsEdit    from '../pages/reviews/Edit';


import UploadCSV    from '../pages/upload/CSV';

// import ReportsAll     from '../pages/reports/All';
// import ReportsView     from '../pages/reports/View';

import UsersAll     from '../pages/users/All';

const routes = () =>  [


    // /***********************
    // DASHBOARD
    // ************************/
    // {
    //     path: "/invoices",
    //     component: InvoicesAll,
    // },

    /***********************
    DEVELOPMENT
    ************************/
    {
        path: "/development/simulator",
        component: DevelopmentSimulator,
    },
    
    /***********************
    FORMS
    ************************/
    {
        path: "/forms/all",
        component: FormsAll,
    },
    {
        path: "/forms/access",
        component: FormsAccess,
    },
    {
        path: "/forms/submissions",
        component: FormSubmissions,
    },
    {
        path: "/forms/submissions/deleted/:form_answers_id",
        component: FormSubmissionsView,
    },
    {
        path: "/forms/submissions/answers/:form_answers_id",
        component: FormSubmissionsAnswers,
    },
    {
        path: "/forms/submissions/:form_submission_id",
        component: FormSubmissionsSubmissions,
    },
    {
        path: "/forms/submissions/:contact_id/:form_id",
        component: FormSubmissionsView,
    },
    {
        path: "/forms/preview/:form_id",
        component: FormsPreview,
    },
    {
        path: "/forms/requests/:form_id",
        component: FormsRequests,
    },

    /***********************
    DOCUMENTS
    ************************/
    {
        path: "/documents",
        component: DocumentsAll,
    },
    {
        path: "/documents/:document_id",
        component: DocumentsView,
    },
    /***********************
    ANALYSIS
    ************************/
    {
        path: "/analyze/:contact_id",
        component: AnalyzePageSingle,
    },
    {
        path: "/analyze/:contact_id/full",
        component: AnalyzePageAll,
    },
    {
        path: "/analyze/:contact_id/download/all",
        component: AnalyzePageDownloadAll,
    },
    {
        path: "/analyze/:contact_id/download/:document_id",
        component: AnalyzePageDownload,
    },
  
    /***********************
    REPORTS
    ************************/
    // {
    //     path: "/reports",
    //     component: ReportsAll,
    // },
    // {
    //     path: "/reports/:report_id",
    //     component: ReportsView,
    // },

    /***********************
    ACCOUNT
    ************************/
    {
        path: "/settings/mfa",
        component: AccountMFA,
    },
    {
        path: "/settings",
        component: AccountSettings,
    },

    {
        path: "/company/settings",
        component: CompanySettings,
    },
    {
        path: "/company/billing",
        component: CompanyBilling,
    },
    {
        path: "/company/pricing",
        component: CompanyPricing,
    },
    /***********************
    Users
    ************************/
    {
        path: "/users",
        component: UsersAll,
    },


    /***********************
    Notifications
    ************************/
    {
        path: "/notifications/all",
        component: NotificationsAll,
    },
    {
        path: "/notifications/:notification_id",
        component: NotificationsView,
    },
    
    /***********************
    Signing
    ************************/
    {
        path: "/signing/access",
        component: SigningAccess,
    },
    {
        path: "/signing/templates/all",
        component: SigningTemplates,
    },
    {
        path: "/signing/documents/all",
        component: SigningDocumentsAll,
    },
    {
        path: "/signing/documents/:signing_document_id",
        component: SigningDocumentsView,
    },
    
    /***********************
    REVIEWS
    ************************/
    {
        path: "/reviews/all",
        component: ReviewsAll,
    },
    {
        path: "/reviews/access",
        component: ReviewsAccess,
    },
    {
        path: "/reviews/:review_link",
        component: ReviewsEdit,
    },

    /***********************
    CONTACTS
    ************************/
    {
        path: "/contacts",
        component: ContactsAll,
    },
    {
        path: "/contacts/:contact_id",
        component: ContactsView,
    },

    /***********************
    UPLOAD
    ************************/
    {
        path: "/upload/csv",
        component: UploadCSV,
    },
    /***********************
    DASHBOARD
    ************************/
    {
        path: "/home",
        component: Dashboard,
    },

    {
        path: "/",
        component: Dashboard,
    },

   
]

export default routes;
