import keys from 'keys';
import React, { useState, useCallback, useEffect } from 'react';
import { CardHeader, CardBody, CardFooter, CardTitle, FormGroup, Input, UncontrolledTooltip } from 'reactstrap';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _companies from '_functions/companies';

import { getCompanyLink } from 'utils/helpers'
import { CopyToClipboard } from "react-copy-to-clipboard";

import Circle from 'components/markup/loading/Circle';

const SettingsInfoRegistration = ({company, setCompany}) => {

    const [apiErr, setApiErr] = useState(0);
    const [copied, setCopied] = useState(false);
    const [registrationLink, setRegistrationLink] = useState(null);
    const [clientRegistration, setClientRegistration] = useState('0')

    const onUpdate = async () => {
        toggleStandardLoader(true)

        const updated = await _companies.update(company._id, {
            client_registration: clientRegistration,
        })
        if(updated.success) {
            toggleAlertBS(false, 'Company settings updated successfully.')
            setCompany()
        } else {
            setApiErr(updated.message)
        }

        toggleStandardLoader(false)
    }

    const onCopy = useCallback(() => {
        setCopied('copied')
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }, [])

    const onRegistrationChange = useCallback((e) => {
        const value = e.target.value;

        let transaction_threshold = false
        if(value === "1") transaction_threshold = true;

        setClientRegistration(transaction_threshold)
    }, [])
   
    const fetchRegistrationLink = useCallback(async (e) => {
        const link = await _companies.getRegistrationLink()
        if(link.data) setRegistrationLink(link.data)
    }, [])


    useEffect(() => {
        const _company = JSON.parse(JSON.stringify(company));
        setClientRegistration(_company.client_registration)
        fetchRegistrationLink();
    }, [company, fetchRegistrationLink])

    if(!registrationLink) return <div className="py-6"><Circle /></div>


    return (
        <div className="border-left">

            <CardHeader>
                <CardTitle className="mb-0">Client Registration</CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary">
                <p className="text-sm">Client Registration allows anyone to sign up to pull their documents under your account with a link that is unique to your company.</p>
                <p className="text-sm">Once a contact signs up they will be billed based on your default billing type either running a credit deduction upon successfully linking their accounts or after entering their payment information.</p>
            </CardHeader>

            {clientRegistration === true || clientRegistration === '1' ? (

                <>

                <CardHeader>
                    <CardTitle className="mb-0">
                        Your Current Registration Link:  
                        <CopyToClipboard
                            text={`${keys.REPORT_URL}/${getCompanyLink(company)}/register?a=${registrationLink.iv}&b=${registrationLink.content}`}
                            onCopy={onCopy}
                        >
                            <i id="copy-registration" className="fas fa-copy ml-2 text-success cursor-pointer " />
                        </CopyToClipboard>
                        <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="copy-registration"
                        >
                            {copied === "copied"
                            ? "Registration Copied!"
                            : "Copy To Clipboard"}
                        </UncontrolledTooltip>
                    </CardTitle>
                </CardHeader>
                <CardHeader>
                    <p className="text-sm">
                        {registrationLink.iv ? (
                            <span>
                                {`${keys.REPORT_URL}/${getCompanyLink(company)}/register?a=${registrationLink.iv}&b=${registrationLink.content}`}
                            </span>
                        ) : (
                            'No Registration Link Found, Please Refresh Your Page'
                        )}
                    </p>
                </CardHeader>

                </>
            ) : null }

            <CardBody>

                <FormGroup>
                    <label className="form-control-label">Allow Client Registration</label>
                    <Input
                        type="select"
                        onChange={onRegistrationChange}
                        value={clientRegistration === true || clientRegistration === '1' ? '1' : 0}
                    >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </Input>
                </FormGroup>

            </CardBody>
            
            {apiErr ? (
                <CardFooter>
                    {apiErr.map((e, i) => <p key={i} className="text-sm text-danger font-weight-bold mb-0">{e}</p>)}
                </CardFooter>
            ) : null}

            <CardFooter className="text-right">
                <button onClick={onUpdate} className="btn btn-success">
                    <i className="fas fa-save mr-2 " /> Save
                </button>
            </CardFooter>
        
        </div>

    )
}

export default SettingsInfoRegistration;