

import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom'

const ClioDeactivate = () => {

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        setRedirect(`/dashboard/company/settings?tab=4`)
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return <div></div>
}

export default ClioDeactivate