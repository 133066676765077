import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup, Row, Col } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import setQuestionWithId from '../_utils/setQuestionWithId'

import _forms from '_functions/forms';

const Text = ({question, answers, onChange, form, loopIndex, errors}) => {

    const [err, setErr] = useState(false)
    const [preview, setPreview] = useState({})
    const [_question, _setQuestion] = useState({})
    const [id] = useState(uuidv4())

    const hasErr = errors.includes(_question.id) && !preview.document ? true : false;

    const fetchDocument = useCallback(async (value) => {
        const document = await _forms.viewDocument(value)
        if(document.data && document.data.document) {
            setPreview(document.data)
        }
    }, [])

    const onUpload = useCallback((e) => {
        setErr(false)

        try {
            const data = JSON.parse(e.xhr.response)

            if(data.message && data.message.length) {
                return setErr('Please try again.')
            }
            onChange(_question, data.data.document)
            fetchDocument(data.data.document)
        } catch(e) {
            setErr('Please try again.')
        }

    }, [_question, onChange, fetchDocument])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        const value = answers[formattedQuestion.id];
        _setQuestion(formattedQuestion);
        if(value) fetchDocument(value)
    }, [question, loopIndex, answers, fetchDocument])

    if(!form._id) return <></>;

    return (
        <FormGroup id={_question.id}>        
            <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                {preview && preview.document ? <i className="fas fa-check-circle text-success mr-2 " /> : null} {_question.name}
            </label>
            {question.description ? (
                <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
            ) : null}
            {err ? <p className="text-sm mb-0 text-warning font-weight-bold">{err}</p> : null}

            {preview.document && preview.document.includes('image') ? (
                <Row className="mb-3 border-top border-bottom py-3">
                    <Col sm={6} className="align-self-center">
                        <p className="text-sm mb-0">Uploaded File:</p>
                        <p className=" mb-0 font-weight-bold">{preview.name}</p>
                    </Col>
                    <Col sm={6} className="align-self-center text-right">
                        <img src={preview.document} className="border z-depth-1" style={{maxHeight: 75, maxWidth: '100%'}} alt="uploaded file" />
                    </Col>
                </Row>
            ) : null}
            <DragAndDrop
                zone_id={id}
                url={`/v1/api/forms/documents/${form._id}`}
                onError={(e) => () => {
                    if(e.message) {
                        setErr(e.message)
                    } else {
                        setErr('Please try again.')
                    }
                }}
                resizePixels={1200}
                onUpload={onUpload}
                defaultValue="Logo" 
                uploadOne={true}
            />
            
        </FormGroup>  
    )
}

export default memo(Text);