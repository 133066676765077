import React, { useState, useCallback, useEffect } from "react";
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, CardBody, Row } from "reactstrap";
import _integrations from '_functions/integrations';
import _documents from '_functions/documents'
import _contacts from '_functions/contacts'
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import Circle from 'components/markup/loading/Circle'

import Pinwheel from './Pinwheel'
import ModalConfirm from './ModalConfirm'
import MX from './MX/index';
import Yodlee from './Yodlee';

const Connect = ({contact, company, setTab, fetchData}) => {

    const [members, setMembers] = useState(null);
    const [err, setErr] = useState(null);
    const [accounts, setAccounts] = useState(contact.pinwheel_accounts ? contact.pinwheel_accounts : [])
    const [yodleeAccounts, setYodleeAccounts] = useState(null)
    const [selectedMX, setSelectedMx] = useState([])
    const [selectedPinwheel, setSelectedPinwheel] = useState([]);
    const [selectedYodlee, setSelectedYodlee] = useState([]);
    const [showModal, toggleModal] = useState(false);
    const [activating, setIsActivating] = useState(false);
    const [showMX, setShowMX] = useState(false);

    const onSetAccounts = useCallback((newAccounts) => {
        setAccounts(newAccounts)
        _contacts.setEmployment(contact._id, {accounts: newAccounts})
    }, [contact._id])

    const onConfirm = async (fetchBankStatements) => {

        toggleStandardLoader(true);

        const created = await _documents.createList({
            contact             : contact._id,
            pinwheel_accounts   : selectedPinwheel,
            mx_accounts         : selectedMX,
            yodlee_accounts     : selectedYodlee,
            fetch_statements    : fetchBankStatements
        })

        toggleStandardLoader(false);

        if(created.success) {
            setTab(0)
            toggleAlertBS(false, `Report Successfully Created`);
            fetchData();
        } else {
            toggleAlertBS(true, `Something Went Wrong`);
        }

    }

    const onMXChecked = useCallback((account) => {
        let newMX = JSON.parse(JSON.stringify(selectedMX));

        if(newMX.find(a => a.guid === account.guid)) {
            newMX = newMX.filter(a => a.guid !== account.guid);
        } else {
            newMX.push(account);
        }
        
        setSelectedMx(newMX)
    }, [selectedMX])

    const onYodleeChecked = useCallback((account) => {
        let newYodlee = JSON.parse(JSON.stringify(selectedYodlee));

        if(newYodlee.find(a => a.id === account.id)) {
            newYodlee = newYodlee.filter(a => a.id !== account.id);
        } else {
            newYodlee.push(account);
        }
        
        setSelectedYodlee(newYodlee)
    }, [selectedYodlee])

    const onPinwheelChecked = useCallback((account) => {
        let newPinwheel = JSON.parse(JSON.stringify(selectedPinwheel));

        if(newPinwheel.find(a => a.account_id === account.account_id)) {
            newPinwheel = newPinwheel.filter(a => a.account_id !== account.account_id);
        } else {
            newPinwheel.push(account);
        }
        
        setSelectedPinwheel(newPinwheel)
    }, [selectedPinwheel])

    const enableUser = useCallback(() => {
        // this will try to set the users status to active again, success and data from 
        // the call can be ignored
        _integrations.mx.request_url({
            contact_id: contact._id,
            company_id: company._id,
            access_code: contact.access_code,
        })

        setIsActivating(true)
    }, [company._id, contact._id, contact.access_code])

    const fetchMembers = useCallback(async (params) => {

        if(params && params.isRefresh) {
            setErr(false)
        }

        // this will force an mx member to be created if one is not already
        // without this the page will always sit on loading
        if(!contact.mx_guid) {
            await _integrations.mx.request_url({
                contact_id: contact._id,
                company_id: company._id,
                access_code: contact.access_code,
            })
        }

        const values = await Promise.all([
            _integrations.mx.members.list({
                contact_id: contact._id, 
                company_id: company._id, 
                access_code: contact.access_code, 
            }),
            _integrations.mx.accounts.list({
                contact_id: contact._id, 
                company_id: company._id, 
                access_code: contact.access_code, 
            })
        ])

        const foundMembers = values[0];
        const accountList = values[1];

        if(foundMembers.data && accountList.data) {

            if(foundMembers.data.find(m => m.connection_status === 'DISABLED')) {
                enableUser();
            } else {
                setIsActivating(false)
            }

            foundMembers.data.forEach(member => {
                member.accountList = accountList.data.filter(a => a.member_guid === member.guid && a.type !== 'CREDIT_CARD')
            })

            setMembers(foundMembers.data);

        } else {

            // use deleted in mx, recreate them here and then re-fetch the contact
            if(foundMembers.message && foundMembers.message.error && foundMembers.message.error.message.includes('We were unable to find the record you requested.')) {
                const created = await _integrations.mx.users.create(contact)
                if(created.success) return fetchData()
            }

            // if user is disabled enable them
            if(foundMembers.message && foundMembers.message.error && foundMembers.message.error.message.includes('This user has been disabled')) {
                enableUser();
            }
            
            // show an error
            setErr(true)
        }

    }, [company._id, contact, enableUser, fetchData])

    // const fetchYodleeAccounts = useCallback(async (params) => {

    //     if(params && params.isRefresh) {
    //         setErr(false)
    //     }


    //     const accounts = await _integrations.yodlee.accounts.find({
    //         contact_id: contact._id, 
    //         company_id: company._id, 
    //         access_code: contact.access_code, 
    //     })

    //     if(!accounts.success) return  setErr(true)

    //     if(accounts.data) {
    //         const filteredAccounts = accounts.data.filter(a => a.partner || a.CONTAINER === 'creditCard' ? false : true)
    //         setYodleeAccounts(filteredAccounts)
    //     } else {
    //         setYodleeAccounts([])

    //     }


    // }, [company._id, contact])

    useEffect(() => {
        if(contact.accepted_terms_at) {
            fetchMembers({});
            // fetchYodleeAccounts({})
        }
    }, [fetchMembers, contact.accepted_terms_at])

    if(!contact.accepted_terms_at) return (
        <Card className="bg-white border-0 mb-0 text-center" >

            <CardHeader>
                <CardTitle className="mb-0"><i className="fas fa-info-circle text-info mr-2 " /> Terms Of Use Needed</CardTitle>
            </CardHeader>

            <CardBody>
                <p className="mb">This contact has not accepted the Zap Reports Terms Of Use and documents cannot be generated for their account.</p>
                <p className="mb-0">Once a contact has agreed to the terms of use this page can be used to pull additional documents.</p>
            </CardBody>

        </Card>
    )

    if(showMX) return (
        <MX 
            foundCompany={company}
            foundContact={contact}
            fetchData={fetchData}
            setShowMX={setShowMX}
        />
    )

    if((!members) && !err) return <div className="mt-5"><Circle /></div>

    if(err) return (
        <Card className="bg-white border-0 mb-0 text-center" >

            <CardHeader>
                <CardTitle className="mb-0"><i className="fas fa-info-circle text-info mr-2 " /> Authenticating Account</CardTitle>
            </CardHeader>

            <CardBody>
                <p className="mb">This account is currently being authenticated, please check back in a few minutes or try refreshing your page.</p>
                <p className="mb-0">
                    <span onClick={() => window.location.reload()} className="cursor-pointer"><i className="fas fa-sync text-info " /> Refresh</span>
                </p>
            </CardBody>

        </Card>
    )
   

    return (

        <Card className="bg-white border-0 mb-0 z-depth-4" >

            {activating ? (
                <CardHeader>
                    <div className="alert alert-info">This account is in the process of gathering data. Please check back in a few minutes</div>
                </CardHeader>
            ) : null}

            <CardHeader>
                <Row className="text-sm-left text-center">
                    <Col md={4} className="align-self-center">
                        <CardTitle className="mb-0">Generate Report</CardTitle>
                    </Col>
                    <Col md={8} className="align-self-center text-sm-right text-center">
                        {company.accept_financial ? (
                            <>
                            {/* <Yodlee 
                                contact={contact}
                                company={company}
                                fetchYodleeAccounts={fetchYodleeAccounts}
                            /> */}
                            <button className="btn btn-outline-success btn-md-block mb-3 mb-md-0" onClick={() => setShowMX(true)}>
                                Connect Banks
                            </button>
                            <button className="btn btn-outline-purple btn-md-block mb-3 mb-md-0" onClick={() => setShowMX(true)}>
                                Link Finances
                            </button>
                            </>
                        ) : null}
                        {company.accept_employment ? (
                            <Pinwheel 
                                contact={contact}
                                accounts={accounts} 
                                onSetAccounts={onSetAccounts}
                            />
                        ) : null}
                       
                       
                    </Col>
                </Row>
            </CardHeader>

            {company.accept_employment ? (
                <div className="table-responsive">
                    <table className="table">

                        <thead>
                            <tr>
                                <th>Employment</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                        {accounts.length ? accounts.map((account, i) => (
                            account.partner ? null :
                            <tr key={i}>
                                <td>{account.platform} {account.partner ? <b> - PARTNER</b> : null}</td>
                                <td></td>
                                <td className="text-right">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id={`pinwheel-checkbox-${account.account_id}`}
                                            type="checkbox"
                                            onChange={() => onPinwheelChecked(account)}
                                        />
                                        <label className="custom-control-label" htmlFor={`pinwheel-checkbox-${account.account_id}`}>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td className="text-warning font-weight-bold">No Payroll Accounts Found.</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            ) : null}


            {company.accept_financial ? (
                <>
                {/* <div className="table-responsive">
                    <table className="table">
    
                        <thead>
                            <tr>
                                <th>Banking Institutions</th>
                                <th></th>
                            </tr>
                        </thead>
                        
                        <tbody>
                        {yodleeAccounts.length && yodleeAccounts.some(a => !a.partner) ? yodleeAccounts.map((account, i) => (
                            <tr key={i}>
                                <td>{account.accountName}</td>
                                <td className="text-right">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id={`yodlee-account-checkbox-${account.id}`}
                                            type="checkbox"
                                            onChange={() => onYodleeChecked(account)}
                                        />
                                        <label className="custom-control-label" htmlFor={`yodlee-account-checkbox-${account.id}`}></label>
                                    </div>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td className="text-warning font-weight-bold">No Banking Institutions Found.</td>
                                <td></td>
                            </tr>
                        )}
    
                        </tbody>
                    </table>
                </div> */}

                <div className="table-responsive">
                    <table className="table">
    
                        <thead>
                            <tr>
                                <th>Financial Accounts</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        
                        <tbody>
                        {members.length && members.some(m => m.accountList && m.accountList.length) ? members.map((member, i) => (
                            member.partner ? null : !member.accountList || (member.accountList && !member.accountList.length) ? null :
                            <React.Fragment key={i}>
                            <tr key={i}>
                                <td>{member.name}</td>
                                <td></td>
                                <td className="text-right">
                                    {member.connection_status === 'CONNECTED' ? (
                                        <i className="fas fa-thumbs-up text-success  mr-4" />
                                    ) : <i className="fas fa-times text-danger mr-4" />}
                                </td>
                            </tr>
    
                            {member.connection_status === 'CONNECTED' && member.accountList.length ? member.accountList.map((account, i) => (
                                <tr key={i}>
                                    <td style={{paddingLeft: 40}}>{account.name}</td>
                                    <td>{member.connection_status === 'DISABLED' ? 'GATHERING DATA' : member.connection_status}</td>
                                    <td className="text-right">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                id={`account-checkbox-${account.guid}`}
                                                type="checkbox"
                                                onChange={() => onMXChecked(account)}
                                            />
                                            <label className="custom-control-label" htmlFor={`account-checkbox-${account.guid}`}></label>
                                        </div>
                                    </td>
                                </tr>
                            )) : null}
    
                            </React.Fragment>
                        )) : (
                            <tr>
                                <td className="text-warning font-weight-bold">No Financial Institutions Found.</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
    
                        </tbody>
                    </table>
                </div>
                </>
            ) : null}
            
            <CardFooter className="text-right">
                <button  onClick={() => toggleModal(true)} className=" btn btn-success ">
                    Create Report
                </button>

            </CardFooter>

            <ModalConfirm 
                showModal={showModal}
                toggleModal={() => toggleModal(false)}
                selectedMX={selectedMX}
                selectedPinwheel={selectedPinwheel}
                selectedYodlee={selectedYodlee}
                onConfirm={onConfirm}
                contact={contact}
                company={company}
            />
        </Card>

    )
}



const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(Connect);

