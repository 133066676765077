import { useCallback } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';

const Integrations = ({integrations, onIntegrationChange, company}) => {

    const onSetIntegrations = useCallback((name, field, value) => {
        const _integrations = JSON.parse(JSON.stringify(integrations));
        const index = _integrations.findIndex(i => i.name === name)

        if(index !== -1) {
            _integrations[index].values[field] = value;
        } else {
            const obj = { name, values: {} };
            obj.values[field] = value;
            _integrations.push(obj)
        }
        onIntegrationChange(_integrations);
    }, [integrations, onIntegrationChange])

    const getField = (integration, field) => {

        const _integration = integrations.find(i => i.name === integration);

        if(_integration && _integration.values && _integration.values[field]) {
            return _integration.values[field];
        }

        return ''

    }

    const isEnabled = (integration) => {
        const _integration = company.integrations && company.integrations.find(i => i.name === integration);
        if(_integration) return _integration.enabled;
        return false;
    }

    return (
       <>
       {isEnabled('intuidesk') ? (
        <FormGroup>
            <label className="form-control-label">Intuidesk Case ID</label>
            <Input 
                type="text"
                value={getField('intuidesk', 'case_id')}
                onChange={(e) => onSetIntegrations('intuidesk', 'case_id', e.target.value)}
            />
        </FormGroup>
       ) : null}
       {isEnabled('allmand law') ? (
        <FormGroup>
            <label className="form-control-label">Allmand Law Contact ID</label>
            <Input 
                type="text"
                value={getField('allmand law', 'contact_id')}
                onChange={(e) => onSetIntegrations('allmand law', 'contact_id', e.target.value)}
            />
        </FormGroup>
       ) : null}
       
       {isEnabled('clio') ? (
        <FormGroup>
            <label className="form-control-label">Clio Matter ID</label>
            <Input 
                type="text"
                value={getField('clio', 'matter_id')}
                onChange={(e) => onSetIntegrations('clio', 'matter_id', e.target.value)}
            />
        </FormGroup>
       ) : null}
       
       {isEnabled('zoho') ? (
        <>
        <FormGroup>
            <label className="form-control-label">Zoho Contact ID</label>
            <Input 
                type="text"
                value={getField('zoho', 'contact_id')}
                onChange={(e) => onSetIntegrations('zoho', 'contact_id', e.target.value)}
            />
        </FormGroup>
        <FormGroup>
            <label className="form-control-label">Zoho Deal ID</label>
            <Input 
                type="text"
                value={getField('zoho', 'deal_id')}
                onChange={(e) => onSetIntegrations('zoho', 'deal_id', e.target.value)}
            />
        </FormGroup>
        </>
       ) : null}
      
       </>
    )
}

const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(Integrations);