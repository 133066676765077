import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import moment from 'moment'

import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const ModalFinancialAlerts = ({showModal, toggleModal, analysis, formatCurrency}) => {

    const [data, setData] = useState([])
  
    const columns = [
        {
            dataField: "date",
            text: "Date",
            sort: true,
            sortValue : (row) => parseInt(moment(row).format('X')),
            formatter: (cell, row) => <small>{row.date}</small>
        },
        {
            dataField: "amount",
            text: "Amount",
            sort: true,
            formatter: (cell, row) => <small>{formatCurrency(row.amount)}</small>
        },
        {
            dataField: "type",
            text: "Credit / Debit",
            headerStyle: {textAlign: 'right', },
            formatter: (cell, row) => (
                <div className="text-right"><small>{row.type}</small></div>
            )
        },
       
    ]

    useEffect(() => {
        if(showModal) {
            const alerts = JSON.parse(JSON.stringify(analysis.alerts))
            alerts.sort((a, b) => Math.abs(a.amount) > Math.abs(b.amount) ? -1 : 1);
            setData(alerts)
        }
    }, [analysis.alerts, showModal])
   
    return (
        <Modal
            className="modal-dialog-centered modal-table"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">
                    <i className="fas fa-chart-line mr-2 text-success " /> Transaction Alerts
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {data && data.length ? (

                <ToolkitProvider
                    keyField="_id"
                    data={data}
                    columns={columns}
                    search
                >
                {props => (
                    <div className={`table-not-fixed table-responsive  table-vertical-align `}>
                        <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory({
                                sizePerPage: 10,
                                sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => null
                            })}
                            bootstrap4={true}
                            bordered={false}
                        />
                    </div>
                )}
                </ToolkitProvider> 
            ) : (
                <div className="modal-body">
                    <p className="text-sm">There are no transaction alerts to show for this document.</p>
                </div>
            )}

            <div className="modal-footer text-right">
                <button className="btn btn-outline-warning" onClick={toggleModal}>Close</button>
            </div>

        </Modal>

    )

}

ModalFinancialAlerts.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalFinancialAlerts
