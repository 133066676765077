import { useState } from 'react';
import { Modal } from 'reactstrap';

const ErrorModal = ({size, text}) => {

    const [isOpen, setIsOpen] = useState(true)

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={isOpen}
            toggle={() => setIsOpen(false)}
            size={size ? size : 'md'}
        >
            <div className="pb-5 pt-5 text-center">
                <i className="fas fa-exclamation-triangle mr-2 text-warning font-weight-bold " />
                {text ? text : 'Something Went Wrong, Please Try Again'}
            </div>
        </Modal>
    )
    

}
export default ErrorModal;