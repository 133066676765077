import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar/index.js";
import Sidebar from "./components/Sidebar/index.js";
import Notifications from "./components/Notifications/index.js";
import routes from "./routes";

import _auth from '_functions/auth'
import { setCompany } from 'store/functions/company/company'
import { load } from 'store/functions/load'
import { getCompanyLink } from 'utils/helpers';

import socket from 'sockets';
import { CREDIT_EVENT, JOIN_COMPANY_ROOM } from 'sockets/events';

import ArchkTutorial from 'components/functional/tutorial'
import ArchkAlerts from 'components/functional/alerts'

class Dashboard extends React.Component {
    state = {
        loaded: false,
        sidenavOpen: true,
        showMobileNav: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/dashboard${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    toggleSidenav = e => {

        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.remove("noScroll");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");   
            document.body.classList.add("noScroll");


        }

        this.setState({ sidenavOpen: !this.state.sidenavOpen });
    };

    setScreenSize = () => {

        const { device } = this.props

        if(!device.isLG) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
            this.setState({ sidenavOpen: false, showMobileNav: true });
        } else {
            document.body.classList.add("g-sidenav-pinned");
            this.setState({ sidenavOpen: true, showMobileNav: false });
        }

    }

    onCreditChange = (data) => {
        // double check company match
        if(this.props.viewing_user.company === data.company) {
            setCompany()
        }
    }

    onSocketReconnect = () => {
        socket.emit(JOIN_COMPANY_ROOM, this.props.viewing_user.company)
    }

    // when window comes into focus make sure we still have a logged in user
    // if force a redirect to login page
    checkLogin = async () => {
        const user = await _auth.viewing_user();
        if(!user.data) {
            const link = this.props.company && this.props.company._id ? getCompanyLink(this.props.company) : ''
            return window.location.href = `/auth/login/${link}?redirect=${window.location.pathname}`
        }
    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({redirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setScreenSize)
        window.removeEventListener('focus', this.checkLogin)
        socket.off(CREDIT_EVENT, this.onCreditChange);
        socket.off("connect", this.onSocketReconnect);
    }

    componentDidMount = async () => {

        if(!this.props.load.CREDITS.CREDIT_AMOUNTS || !this.props.company._id) {
            await Promise.all([
                setCompany(),
                load()
            ])
        }

        socket.on(CREDIT_EVENT, this.onCreditChange);
        socket.on("connect", this.onSocketReconnect);

        this.setScreenSize()
        this.setState({loaded: true})

        window.addEventListener('resize', this.setScreenSize)
        window.addEventListener('focus', this.checkLogin)
        
    }

    render() {

        const { redirect, loaded, sidenavOpen, showMobileNav } = this.state
        const { device, company, tutorial } = this.props;

        if(redirect) return <Redirect to={redirect} />;
        if(!loaded) return <div />;
        if(!company.agreement_accepted_at) return <Redirect to="/actions/agreement" />;
        if(company.banned) return <Redirect to="/actions/banned" />;

        return (
            <div className="layout-sidebar layout-sidebar-right">

                <ArchkTutorial 
                    isOpen={tutorial.showTutorial}
                    steps={tutorial.steps}
                />

                <ArchkAlerts />

                <Sidebar 
                    {...this.props}
                    toggleSidenav={this.toggleSidenav}
                    sidenavOpen={sidenavOpen}
                />

                <div className={`bg-toggler ${device.currentScreenWidth < 1000 && sidenavOpen ? 'show' : null}`} onClick={this.toggleSidenav}/>

                <div className="wave" />

                <div className="main-content content-wrapper" ref="mainContent" onClick={this.closeSidenav} >

                    <Navbar {...this.props} showMobileNav={showMobileNav} toggleSidenav={this.toggleSidenav} />

                    <Notifications />

                    <div className="dashboard-content">
                        <Switch>
                            {this.getRoutes(routes)}
                            <Redirect from="*" to="/dashboard/home" />
                        </Switch>
                    </div>

                    <Footer />

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    device: state.device.info,
	    company: state.company.company,
	    tutorial: state.tutorial.tutorial,
	    load: state.load,
	};
};

export default connect(mapStateToProps, '')(Dashboard);

