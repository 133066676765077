import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import formatText from 'utils/formatText';

import _forms from '_functions/forms';

import ContactSearchInput from "components/system/Search/ContactsInput";
import FormSearchInput from "components/system/Search/FormsInput";

import Circle from 'components/markup/loading/Circle'

const SendFormRequestModal = ({showModal, toggleModal, form, contact, onConfirm}) => {

    const [hideInput, setHideInput] = useState(false)
    const [formErr, setFormErr] = useState(false)
    const [contactErr, setContactErr] = useState(false)
    const [selectedContact, setSelectedContact] = useState({})
    const [selectedForm, setSelectedForm] = useState({})

    const getContactIdentifier = useCallback(_contact => {
        if(_contact.display_name) return _contact.display_name;
        if(_contact.email) return _contact.email;
        return formatText(_contact.phone).phone();
    }, [])

    const onConfirmPressed = useCallback(async () => {

        if(!selectedContact._id) return setContactErr(<span><i className="fas fa-arrow-up mr-2 " /> Please select a contact above before proceeding.</span>)
        setContactErr(false);

        if(!selectedForm._id) return setFormErr(<span><i className="fas fa-arrow-up mr-2 " /> Please select a form above before proceeding.</span>)
        setFormErr(false);

        toggleStandardLoader(true);        
        const sent = await _forms.request(selectedForm._id, selectedContact._id);
        toggleStandardLoader(false);

        if(sent.success) {
            toggleAlertBS(false, `Form Invitations Were Successfully Sent.`);
        } else {
            toggleAlertBS(true, `Something Went Wrong Send The Form Invitation.`)
        }

        if(onConfirm) onConfirm();

    }, [selectedForm, selectedContact._id, onConfirm])

    useEffect(() => {
        if(showModal) {
            setHideInput(true);
            setTimeout(() => {setHideInput(false) }, 250);

            setSelectedForm(form ? form : {});
            setSelectedContact(contact ? contact : {});
        } else {
            setFormErr(false);
            setContactErr(false);
        }
    }, [showModal, form, contact])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Send Form Request</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {selectedForm._id ? (
                <div className="modal-body bg-secondary">
                    <p className="text-sm mb-0">
                        Sending a form request out to a contact will notify them to proceed in filling out the form:{' '}
                        <b className="text-underline">{selectedForm.name}</b>
                    </p>
                </div>
            ) : null}

            <div className="modal-body border-top">
                {hideInput ? <Circle /> : (
                    <FormSearchInput 
                        value={selectedForm}
                        titleFormatter={() => selectedForm._id ? 'Selected Form: ' + selectedForm.name : 'Please Search For A Form Below'}
                        onSelect={(_form) => setSelectedForm(_form)}
                    />
                )}
            </div>

            {formErr ? (
                <div className="modal-body border-top text-warning">{formErr}</div>
            ) : null}

            <div className="modal-body border-top">
                {hideInput ? <Circle /> : (
                    <ContactSearchInput 
                        value={selectedContact}
                        titleFormatter={() => selectedContact._id ? 'Selected Contact: ' + getContactIdentifier(selectedContact) : 'Please Search For A Client Below'}
                        onSelect={(contact) => setSelectedContact(contact)}
                    />
                )}
            </div>

            {contactErr ? (
                <div className="modal-body border-top text-warning">{contactErr}</div>
            ) : null}
            
            {selectedContact._id ? (
                <>
                    <div className="modal-body bg-secondary border-top">

                        {!selectedContact.given_name || !selectedContact.family_name ? (
                            <p className="text-sm mb-4">
                                <i className="fas fa-exclamation-triangle mr-2 text-warning " /> This contact does not have both a first and last name on file. If possible add these fields before proceeding.
                            </p>
                        ) : null}
        
                        {!selectedContact.email && !selectedContact.phone ? (
                            <p className="text-sm">
                                <i className="fas fa-exclamation-triangle mr-2 text-warning " /> This contact does not have an email or phone on file. No notifications will be sent if you proceed.
                            </p>
                        ) : (
                            <div>
                                <p className="text-sm mb-0">Request notifications will be sent to: {' '}</p>
                                <p className="text-sm mb-0">
                                    {selectedContact.email ? selectedContact.email : ''}{' '}
                                    {selectedContact.phone ? selectedContact.email ? ` and ${formatText(selectedContact.phone).phone()}` : formatText(selectedContact.phone).phone() : null}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="modal-body bg-secondary border-top">
                        <p className="mb-0 ">
                            Selected Contact:{' '}
                            <b className="text-success font-weight-bold">
                            {selectedContact.display_name ? selectedContact.display_name : selectedContact.email ? selectedContact.email : formatText(selectedContact.phone).phone()}
                            </b>
                        </p>
                    </div>

                </>
            ) : null}

            <div className="modal-footer text-right">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" /> back
                </button>
                <button className="btn btn-success" onClick={onConfirmPressed}>
                    <i className="fas fa-paper-plane mr-2" /> Send
                </button>
            </div>

        </Modal>

    )

}

SendFormRequestModal.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default SendFormRequestModal
