/*
Documentation

this should wrap all logged in routes
runs logic to redirect users if they are not logged in

*/


import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { getCompanyLink } from 'utils/helpers';

import socket from 'sockets';
import { LOGOUT } from 'sockets/events';
import store from 'store';


const onLogout = (data) => { 

    const { user_id } = data;
    const state = store.getState();

    const viewing_user = state.auth.viewing_user;

    // if the user who just logged out matches the logged in
    // user on the current page log them out. This means when
    // a user logs out in one tab ALL open tabs are logged out.
    if(viewing_user && viewing_user._id === user_id) {
        window.location.href = "/auth/logout"  
    }

}

const LoggedInRoute = ({viewing_user, children, company}) => {

    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        //if the user is not logged in redirect them
        if(!viewing_user) {
            // store.dispatch({ type: actionTypes.SET_LOGIN_REDIRECT, payload: window.location.pathname + window.location.search });
            const link = company && company._id ? getCompanyLink(company) : ''
            return window.location.href = `/auth/login/${link}?redirect=${window.location.pathname}`
            // return setRedirect (`/auth/login?redirect=${window.location.pathname}`)
        }

        socket.on(LOGOUT, onLogout);

        setLoaded(true)

        return () => {
            socket.off(LOGOUT, onLogout);
        }


    }, [viewing_user, company])

    if(!loaded) return <div />

    return children

}

const mapStateToProps = state => {
	return {
        company: state.company.company,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(LoggedInRoute);
