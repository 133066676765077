import { memo } from 'react';
import { Row, Col, FormGroup, Input, UncontrolledTooltip } from 'reactstrap';

import InputAnswers from './InputAnswers'
;
import ModalToggler from 'components/functional/modals/Toggler'

import ModalExplainTypes from './ModalExplainTypes';

const ColEdit = ({question, onInputChange, questionRequiresSelectAnswers, unsavedQuestion, errs, config}) =>  {

    if(!question.id) return (
        <Col lg={4} className="col col-selected">

            <Row className="archk-col-header">
                <Col lg={6} className="align-self-center">
                    <h3>Edit Question</h3>
                </Col>
            </Row>

            <p className="text-sm mb-0 ">No question selected for editing.</p>

        </Col>
    )

    const hasNameErr = errs.includes('name') && (!question.name || question.name.length < config.MIN_NAME_LENGTH) ? true : false;

    return (

        <Col lg={4} className="col col-selected">

            <Row className="archk-col-header">
                <Col lg={5} className="align-self-center pr-0">
                    <h3>Edit Question </h3>
                </Col>
                <Col lg={7} className="align-self-center pl-0">
                    <h3 className="text-right">
                    {unsavedQuestion ?
                        <small className="text-warning font-weight-bold">
                            <span> <i className="fas fa-flag mr-2 ml-2 " /> Unsaved Changes</span> 
                        </small>   
                    : null}
                    </h3>
                </Col>
            </Row>

            <FormGroup>
                <label className="form-control-label">
                    Question Type *{' '}
                    <ModalToggler component={ModalExplainTypes} config={config} type={question.type}>
                        <i className="fas fa-info-circle text-info cursor-pointer ml-2" />
                    </ModalToggler>
                </label>
                <Input 
                    type="select"
                    value={question.type}
                    onChange={(e) => onInputChange(e, 'type')}
                >
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="phone">Phone Number</option>
                    <option value="textarea">Large Text</option>
                    <option value="yes-no">Yes or No</option>
                    <option value="select">Drop Down</option>
                    <option value="state">Select State</option>
                    <option value="checkbox">Checkbox</option>
                    <option value="address">Address</option>
                    <option value="date">Date</option>
                    <option value="upload">Upload</option>
                    <option value="loop">Answer Loop</option>
                    <option value="month-loop">Month Loop</option>
                    <option value="two-week-loop">Two Week Loop</option>
                    <option value="one-week-loop">One Week Loop</option>
                    {/* <option value="social-security">Social Security Number</option> */}
                    <option value="signature">Signature</option>
                </Input>
            </FormGroup>

            <Row className="archk-col-header p-0 mt-0">
                <hr  className='my-1' />
            </Row>

            <FormGroup>
                <label className="form-control-label">
                    Question Name *{' '}
                    <i id="archk-icon-question-name" className="fas fa-info-circle text-info ml-2 mr-2" /> {' '}
                    <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target="archk-icon-question-name"
                    >
                        {`This is the text of the question to be presented to your users.`}
                    </UncontrolledTooltip>
                    {' '}
                    {question.name && question.name.length >= config.MAX_NAME_LENGTH ? `(Question names are limited to a maximum of ${config.MAX_NAME_LENGTH} characters.)` : null}
                </label>
                {hasNameErr ? (
                    <p className="text-sm text-danger mb-0">You must include a name for this question of at least {config.MIN_NAME_LENGTH} characters.</p>
                ) : null}
                <Input 
                    style={{minHeight: 120, maxHeight: 400}}
                    id="archk-question-name"
                    type="textarea"
                    valid={!hasNameErr}
                    invalid={hasNameErr}
                    value={question.name}
                    placeholder={
                        question.type === 'text' ? `example: What is your full name?` :
                        question.type === 'number' ? `example: How many vehicles do you own?` :
                        question.type === 'phone' ? `example: What is your phone number?` :
                        question.type === 'textarea' ? `example: Please describe your loss in income this past year.` :
                        question.type === 'yes-no' ? `example: Are you currently married?` :
                        question.type === 'select' ? `example: Please select your income bracket.` :
                        question.type === 'state' ? `example: What state do you live in?` :
                        question.type === 'checkbox' ? `example: Please check all the boxes that apply to you.` :
                        question.type === 'address' ? `example: Please enter your address below.` :
                        question.type === 'date' ? `example: What was the date of your accident?` :
                        question.type === 'upload' ? `example: Please upload a picture of your drivers license.` :
                        question.type === 'loop' ? `example: Do you currently have any open bank accounts? If so how many?` :
                        question.type === 'month-loop' ? `example: What month are you filling out this form for?` :
                        question.type === 'two-week-loop' ? `example: What week are you filling out this form for?` :
                        question.type === 'one-week-loop' ? `example: What week are you filling out this form for?` :
                        question.type === 'social-security' ? `example: What is your social security number?` :
                        question.type === 'signature' ? `example: Please add your signature below.` :
                        null
                    }
                    onChange={(e) => onInputChange(e, 'name')}
                />
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">
                    Optional Description{' '}
                    <i id="archk-icon-question-description" className="fas fa-info-circle text-info ml-2 mr-2" /> {' '}
                    <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target="archk-icon-question-description"
                    >
                        {`An optional description to explain the question in more depth.`}
                    </UncontrolledTooltip>
                    {' '}
                    {question.description && question.description.length >= config.MAX_DESCRIPTION_LENGTH ? `(Question descriptions are limited to a maximum of ${config.MAX_DESCRIPTION_LENGTH} characters.)` : null}
                </label>
                <Input 
                    style={{minHeight: 120, maxHeight: 400}}
                    id="archk-question-description"
                    type="textarea"
                    valid={question.description && question.description.length >= config.MIN_NAME_LENGTH ? true : false}
                    value={question.description}
                    placeholder={
                        question.type === 'text' ? `example: Make sure to include your middle name if you have one.` :
                        question.type === 'number' ? `example: Do not include any vehicles you are currently leasing` :
                        question.type === 'phone' ? `example: make sure to enter your cell phone number` :
                        question.type === 'textarea' ? `example: If this does not apply to you enter N/A.` :
                        question.type === 'yes-no' ? `example: Select "Married" if you are currently separated but not divorced.` :
                        question.type === 'select' ? `example: If you make over $100,000 a year select the option $100,000+.` :
                        question.type === 'select' ? `example: This should be the state as shown on your drivers license.` :
                        question.type === 'checkbox' ? `example: If none apply you may leave this question blank.` :
                        question.type === 'address' ? `example: Make sure you put your legal address and not where you may be living temporarily.` :
                        question.type === 'date' ? `example: If you do not know leave this question blank.` :
                        question.type === 'upload' ? `example: Make sure it is not blurry and includes your entire license.` :
                        question.type === 'loop' ? `example: Make sure to includes accounts like Venmo and PayPal.` :
                        question.type === 'month-loop' ? `example: Select one month per form submission.` :
                        question.type === 'two-week-loop' ? `example: Select one week per form submission.` :
                        question.type === 'one-week-loop' ? `example: Select one week per form submission.` :
                        question.type === 'social-security' ? `example: This should be a 9 digit number.` :
                        question.type === 'signature' ? `example: Make sure it is legible.` :
                        null
                    }
                    onChange={(e) => onInputChange(e, 'description')}
                />
            </FormGroup>

            {questionRequiresSelectAnswers(question) ? (
                <>
                    <Row className="archk-col-header p-0 mt-0">
                        <hr  className='my-1' />
                    </Row>

                    <InputAnswers 
                        answers={question.answers}
                        onChange={(answers) => onInputChange(answers, 'answers')}
                        errs={errs}
                        config={config}
                    />
                </>
            ) : null}
            
        </Col>
       
    )
};

export default memo(ColEdit)
