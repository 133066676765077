export const docIsNotDownloaded = (document) => {

    if(!document) return false;

    const feb16th2022 = 1644987600;

    // don't show document before the update
    if(document.created_at < feb16th2022) return false;

    if(document.status === 'completed' && !document.seen) return true

    return false;

}

export const getCompanyLink = (company) => {
    if(!company) return '';
    return company.custom_link ? company.custom_link : company.name.toLowerCase()
}