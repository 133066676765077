import { useEffect, useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import { Container, Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col } from 'reactstrap';

import { getUrlParameter } from 'utils/urls';
import _integrations from '_functions/integrations';

import logo from 'assets/img/brand/ZapReportsLogo.png'
import Circle from 'components/markup/loading/Circle'


const ClioAdd = () => {

    const [redirect, setRedirect] = useState(false);
    const [err, setErr] = useState(false);

    const getToken = useCallback(async () => {
        const subject_url = getUrlParameter('subject_url');

        try {

            const matter_id = subject_url.split('/')[4]

            const getRedirect = await _integrations.clio.getContactRedirect(matter_id)
            if(getRedirect.data && getRedirect.data.redirect) {
                setRedirect('/dashboard' + getRedirect.data.redirect)
            } else {
                setErr(true);
            }

        } catch(e) {
            console.log(e)
            setErr(true);
        }
    }, [])

    useEffect(() => {
        getToken()
    }, [getToken])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Container fluid>

            <Helmet>
                <title>{`Clio Zap Reports`}</title>
                <meta name="description" content="Clio Zap Reports" />
            </Helmet>

            <div className="header  pt-5 pb-6 pb-md-8 py-lg-8 pt-lg-9">
                <Container className="pb-4">
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">
                                <img style={{width: 200}} src={logo} alt="Zap Reports"/>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
                
            <Container className="mt--9 pb-5">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">

                        <Card className="text-center" >

                            <CardHeader>
                                <CardTitle className="flow-icon-header mb-0">Fetching Information</CardTitle>
                            </CardHeader>

                            <CardBody>
                            
                                {err ? (
                                    <p className="text-sm mb-0">
                                        Something went wrong connecting your Clio account with Zap Reports. Please Try Again.
                                    </p>
                                ) : (
                                    <div className="py-6 pb-5">
                                            <Circle />
                                    </div>
                                )}
                                
                            </CardBody>
                            <CardFooter>
                                <p className="text-sm mb-0">This should only take a few seconds.</p>
                            </CardFooter>
                        </Card>

                    </Col>
                </Row>
            </Container>

        </Container>
    )
}

export default ClioAdd