const focusNextQuestionFromSelect = (value, _question) => {
    if(!value) return;
    var form = document.querySelector('form');
    var index = Array.prototype.indexOf.call(form, document.getElementById(_question.id));
    if(index === -1) return;
    const el = form.elements[index + 1];
    if(!el) return;
    el.focus();

    el.scrollIntoView({block: 'center', behavior: 'smooth'})
}

export default focusNextQuestionFromSelect;