import { Modal } from 'reactstrap';
import Circle from './Circle';

const LoadingModal = ({size, text}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={true}
        toggle={() => {}}
        size={size ? size : 'md'}
    >
        <div className="pb-5 pt-6 text-center">
            <Circle />
            {text ? text : 'Loading'}
        </div>
    </Modal>
)

export default LoadingModal;