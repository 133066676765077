import { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import { Modal, Row, Col, FormGroup, Input } from "reactstrap";

import { setCompany } from 'store/functions/company/company'
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import { formatMoney, formatCredits } from 'utils/currency';
import _companies from '_functions/companies'
import _credit_purchases from '_functions/credit_purchases'
import _sales_tax from '_functions/sales_tax'

import LoadingModal from 'components/markup/loading/Modal'
import ErrorModal from 'components/markup/errors/Modal'

const ModalPurchase = ({showModal, toggleModal, packageToBuy, company, SETTINGS}) => {


    const [tax, setTax] = useState(null);
    const [taxErr, setTaxErr] = useState(false);
    const [checked, setChecked] = useState(false);
    const [loaderChecked, setLoaderChecked] = useState(false);
    const [checkedErr, setCheckedErr] = useState(false);
    const [threshold, setThreshold] = useState(false);

    const totalCredits = packageToBuy.credits + packageToBuy.free_credits;

    const getTax = useCallback(async () => {
        const tax = await _sales_tax.calculate(packageToBuy._id)
        if(tax.data) {
            setTax(tax.data.taxAmount);
        } else {
            setTaxErr(true);
            toggleModal()
        }

    }, [packageToBuy._id, toggleModal])

    const onPurchase = useCallback(async () => {

        if(!checked) {
            return setCheckedErr(true);
        } else {
            setCheckedErr(false)
        }

        toggleStandardLoader(true);
        const action = await _credit_purchases.create({package: packageToBuy._id});

        if(action.success && action.data) {

            if(loaderChecked) {

                const reloader = await _companies.setReloader(company._id, {
                    reloader_package: packageToBuy._id,
                    reloader_threshold: parseInt(threshold)
                });

                if(reloader.success && reloader.data) {
                    toggleAlertBS(false, `Credits Purchased Successfully And Auto Loader Set Up.`);
                    toggleModal();
                    setCompany();
                } else {
                    toggleAlertBS('info', `Credits purchases successfully but something went wrong setting up your account to auto load credits.`);
                }

            } else {
                toggleAlertBS(false, `Credits Purchased Successfully`);
                toggleModal();
                setCompany();
            }

        } else {
            if(action.message && action.message[0] === "State is required to make a purchase") {
                toggleAlertBS('info', `You must add your company billing address before making a purchase.`);
            } else if(action.message && action.message[0] === "Your company does not have a valid payment method on file") {
                toggleAlertBS('info', `Your company does not have a valid payment method on file`);
            } else {
                toggleAlertBS(true, `Your purchase was not successfully, please check your card information and try again.`);
            }
        }

        toggleStandardLoader(false);

    }, [checked, toggleModal, packageToBuy._id, loaderChecked, company._id, threshold])

    useEffect(() => {
        if(showModal === true) {
            setChecked(false);
            setCheckedErr(false)
            setTaxErr(false)
            setTax(null)
            getTax()
        }
    }, [showModal, getTax])
    
    if(taxErr) return <ErrorModal isOpen={!tax} />
    if(showModal && tax === null) return <LoadingModal isOpen={!tax} text="Calculating Tax" />

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add Account Credits</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <Row>
                    <Col lg={6}>
                        <h4 className="mb-0"><b style={styles.divider}>Package:</b> {packageToBuy.name}</h4>

                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Cost / Credit</b>
                            {formatMoney(((packageToBuy.amount) / (totalCredits)) * SETTINGS.decimalMultiplier )}
                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Credits</b>
                            {formatCredits(packageToBuy.credits)}
                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Free Credits</b>
                            {formatCredits(packageToBuy.free_credits)}
                        </p>
                       
                    </Col>
                    <Col lg={6}>

                        <h4 className="mb-0"> <b style={styles.divider}>Amount:</b> {formatMoney(packageToBuy.amount)}</h4>

                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Tax - {company.billing_state}</b>
                            {formatMoney(tax )}
                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Card On File</b>
                            ...1234
                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Effective</b>
                            Immediately
                        </p>

                    </Col>
                </Row>

            </div>

            <div className="modal-body border-top">

                <Row>
                    <Col lg={6}>
                        <h4 className="mb-0"> <b style={styles.divider}>Total Credits:</b> {formatCredits(totalCredits)}</h4>   
                    </Col>
                    <Col lg={6}>
                        <h4 className="mb-0"> <b style={styles.divider}>Total Cost:</b> {formatMoney(packageToBuy.amount + tax)}</h4>   
                    </Col>
                </Row>

            </div>

            <div className="modal-body border-top bg-secondary">
                <p className="text-sm mb-0">By purchasing the above package your account's available credit balance will immediately go up by <b>{formatCredits(totalCredits)}</b> and you authorize Zap Reports to charge the card on file for you account <b>{formatMoney(packageToBuy.amount + tax)}.</b></p>
            </div>

            <div className="modal-body border-top bg-secondary">
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id="purchase-credits-checkbox"
                        type="checkbox"
                        onChange={() => setChecked(!checked)}
                        defaultChecked={checked}
                    />
                    <label className="custom-control-label" htmlFor="purchase-credits-checkbox">
                        I agree to the credit purchase above.
                    </label>
                </div>
                {checkedErr ? (
                    <p className="text-sm mb-0 text-warning font-weight-bold">
                        <i className="fas fa-arrow-up mr-2 " />
                        You must agree to the terms above before proceeding.
                    </p>
                ) : null}
            </div>

            {!company.reloader_package && packageToBuy.can_reload ? (
                <div>
                     <div className="modal-body border-top bg-secondary">
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="purchase-credits-loader-checkbox"
                                type="checkbox"
                                onChange={() => setLoaderChecked(!loaderChecked)}
                                defaultChecked={loaderChecked}
                            />
                            <label className="custom-control-label" htmlFor="purchase-credits-loader-checkbox">
                                Use this package to auto load my account with credits.
                            </label>
                        </div>
                    </div>

                    {loaderChecked ? (
                        <div className="modal-body border-top bg-secondary">
                            <FormGroup>
                                <label className="form-control-label">Purchase package whenever credit balance reaches the following balance or lower</label>
                                <Input 
                                    value={threshold}
                                    type="select"
                                    onChange={(e) => setThreshold(e.target.value)}
                                >
                                    <option vale="10">10</option>
                                    <option vale="25">25</option>
                                    <option vale="50">50</option>
                                </Input>
                            </FormGroup>
                        </div>
                    ) : null}
                </div>
            ) : null    }

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onPurchase}>
                    Purchase Credits
                </button>
            </div>

        </Modal>

    )

}

const styles = {
    divider: {
        display: 'inline-block',
        width: 110
    }
}

ModalPurchase.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}


const mapStateToProps = state => {
    return {
        SETTINGS: state.load.SETTINGS,
        SALES_TAX: state.load.SALES_TAX,
    };
};

export default connect(mapStateToProps, '')(ModalPurchase);