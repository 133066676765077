/*
Documentation

This page handles updating a users profile

*/

import { useEffect, useState } from 'react';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Helmet } from 'react-helmet';
import { Container } from "reactstrap";
import { getUrlParameter, setUrlParameter } from 'utils/urls'


import Navigation from './Navigation';

import AddCredits from './AddCredits';
import Overview from './Overview';
import Orders from './Orders';
import Reloader from './Reloader';
import History from './History';

import AdminLock from 'views/dashboard/pages/_components/AdminLock'

const CompanyBilling = () => {

    const [tab, setTab] = useState(0);

    const onSetTab = (tab) => {
        setTab(tab)
        setUrlParameter('nav', tab)
    }
    
    useEffect(() => {
        const tab = parseInt(getUrlParameter('nav'));
        if(tab === 0 || tab === 1 || tab === 2 || tab === 3 || tab === 4) setTab(parseInt(tab))
    }, [])

    return (

        <AdminLock>

            <Container fluid>

                <Helmet>
                    <title>Company Billing</title>
                    <meta name="description" content="Company Billing" />
                </Helmet>

                <DashHeaderOpen 
                    title={<span>Dashboard</span>}
                    breadcrumb_1="Company"
                    breadcrumb_2="Billing"
                    icon="fas fa-building"
                />

                <Navigation 
                    tab={tab}
                    onSetTab={onSetTab}
                />

                {tab === 0 ? (
                    <Overview onSetTab={onSetTab} />
                ) : tab === 1 ? (
                    <AddCredits />
                ) : tab === 2 ? (
                    <Reloader />
                ) : tab === 3 ? (
                    <Orders />
                ) : tab === 4 ? (
                    <History />
                ) : null}

            </Container>

        </AdminLock>
    )

}

export default CompanyBilling
