import { useState, useCallback, } from 'react';
import { Helmet } from 'react-helmet';

import { Card, CardHeader, CardFooter, CardBody, FormGroup, Input, Row, Col, Container, Badge } from 'reactstrap';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import _uploads from '_functions/uploads'

const Selector = ({ title, value, onChange, info }) => (
    <Row className="border-bottom py-3 px-4">
        <Col md={6} className="align-self-center">
            <p className="text-sm mb-0">{title}</p>
        </Col>
        <Col md={6} className="align-self-center">
            <FormGroup className="mb-0">
                <Input
                    type="select"
                    value={value}
                    invalid={value === '__false__'}
                    onChange={onChange}
                >
                    <option value="__false__">Select CSV Field</option>
                    {info.fields.map((field, i) => (
                        <option key={i} value={field}>{field}</option>
                    ))}
                </Input>
            </FormGroup>
        </Col>
    </Row>
)

const UploadCSV = () => {

    const [view, setView] = useState('upload');
    const [info, setInfo] = useState(false);
    
    const [requestOnCreate, setRequestOnCreate] = useState(true);

    const [nameIsSeparate, setNameIsSeparate] = useState(true)
    
    const [firstNameField, setFirstNameField] = useState('__false__');
    const [lastNameField, setLastNameField] = useState('__false__');
    const [fullNameField, setFullNameField] = useState('__false__');
    
    const [emailField, setEmailField] = useState('__false__');
    const [phoneField, setPhoneField] = useState('__false__');

    const onUploadError = useCallback((e) => {
        if(e && e.message && e.message[0]) return toggleAlertBS('info', e.message[0])
        toggleAlertBS('info', 'Please Upload A Valid File')
    }, [])
    
    const onUpload = useCallback((call) => {
        try {
            const data = JSON.parse(call.xhr.response)
            setInfo(data.data);
            setView('map');
        } catch(e) {
            toggleAlertBS('info', 'Please Upload A Valid File')
        }
    }, [])

    const onConfirmFields = () => {
        if(
            firstNameField === '__false__' && 
            lastNameField === '__false__' && 
            fullNameField === '__false__' && 
            emailField === '__false__' && 
            phoneField === '__false__'
        ) return toggleAlertBS('info', 'At least one field needs to be mapped for a bulk csv import')

        setView('confirm')
    }

    const onBulkUpload = async () => {
        toggleStandardLoader(true)
        const data = await _uploads.csv({
            json: info.json,
            requestOnCreate,
            field_map: {
                firstNameField,
                lastNameField,
                fullNameField,
                emailField,
                phoneField,
                nameIsSeparate
            }
        })
        toggleStandardLoader(false)

        if(data.success && data.data) {
            toggleAlertBS(false, `Your import is all set, ${data.data.queued} contacts are in queue to be created and should start showing up on your dashboard within the next few minutes.`);
            setView('upload');  
        } else {
            toggleAlertBS(true, 'An Error occurred uploading this csv');
        }
        
    }

    return (
        <Container fluid>

            <Helmet>
                <title>{`Upload CSV`}</title>
                <meta name="description" content="Upload CSV" />
            </Helmet>

            <DashHeaderOpen 
                id="archk-tutorial-documents"
                title={<span>Upload</span>}
                breadcrumb_1={(<Badge color="success"><i className="fas fa-info mr-2 " /> BETA</Badge>)}
                breadcrumb_2="CSV File"
                icon="fas fa-file"
            />
            
            <Card>

                <CardHeader className="bg-secondary">
                   {view === 'upload' ? (
                        <p className="text-sm mb-0">To bulk import contacts into Zap Reports upload a CSV file below.</p>
                   ) : view === 'map' ?  (
                        <p className="text-sm mb-0">Please match the fields below to the columns inside the csv you just uploaded.</p>
                   ) : (
                        <p className="text-sm mb-0">Please confirm the following bulk import is ready.</p>
                   )}
                </CardHeader>

                {view === 'map' ? (
                    <CardHeader>
                         <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="confirm-terms-of-service"
                                type="checkbox"
                                checked={nameIsSeparate}
                                onChange={(e) => setNameIsSeparate(!nameIsSeparate)}
                            />
                            <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                                This CSV splits a contact's name into first and last
                            </label>
                        </div>
                    </CardHeader>
                ) : null}

                {view === 'upload' ? (
                        <CardBody>
                    <DragAndDrop
                        zone_id="documents_1"
                        url={`/v1/api/upload/fields`}
                        onError={onUploadError}
                        resizePixels={1200}
                        onUpload={onUpload}
                        defaultValue="CSV" 
                    />
                        </CardBody>
                ) : view === 'map' ? (
                    <div>
                        
                        {nameIsSeparate ? (
                            <div>
                                <Selector 
                                    title="First Name"
                                    value={firstNameField}
                                    onChange={(e) => setFirstNameField(e.target.value)}
                                    info={info}
                                />
                                <Selector 
                                    title="Last Name"
                                    value={lastNameField}
                                    onChange={(e) => setLastNameField(e.target.value)}
                                    info={info}
                                />
                            </div>
                        ) : (
                            <Selector 
                                title="Full Name"
                                value={fullNameField}
                                onChange={(e) => setFullNameField(e.target.value)}
                                info={info}
                            />
                        )}

                        <Selector 
                            title="Email"
                            value={emailField}
                            onChange={(e) => setEmailField(e.target.value)}
                            info={info}
                        />
                        <Selector 
                            title="Phone"
                            value={phoneField}
                            onChange={(e) => setPhoneField(e.target.value)}
                            info={info}
                        />

                        <CardFooter className="text-right">
                            <button className="btn btn-outline-info" onClick={onConfirmFields}>Next <i className="fas fa-arrow-right ml-2 " /></button>
                        </CardFooter>
                        
                    </div>
                ) : (
                    <div>
                        <CardBody>
                            <p className="text-sm">The following fields will be mapped from the csv to <b className="text-success text-underline">{info.length}</b> contacts:</p>
                            {fullNameField !== '__false__' && !nameIsSeparate ? 
                                <p className="text-sm mb-0">
                                    <span style={{display: 'inline-block', width: 80}}>Full Name</span>{' '} 
                                    <i className="fas fa-arrow-right text-warning mx-3 " />{' '}
                                    CSV FIELD:{' '}
                                    <b className="text-info">{fullNameField}</b>
                                </p> 
                            : null}

                            {firstNameField !== '__false__' && nameIsSeparate ? 
                                <p className="text-sm mb-0">
                                    <span style={{display: 'inline-block', width: 80}}>First Name</span>{' '} 
                                    <i className="fas fa-arrow-right text-warning mx-3 " />{' '}
                                    CSV FIELD:{' '}
                                    <b className="text-info">{firstNameField}</b>
                                </p> 
                            : null}

                            {lastNameField !== '__false__' && nameIsSeparate ? 
                                <p className="text-sm mb-0">
                                    <span style={{display: 'inline-block', width: 80}}>Last Name</span>{' '} 
                                    <i className="fas fa-arrow-right text-warning mx-3 " />{' '}
                                    CSV FIELD:{' '}
                                    <b className="text-info">{lastNameField}</b>
                                </p> 
                            : null}

                            {emailField !== '__false__' ? 
                                <p className="text-sm mb-0">
                                    <span style={{display: 'inline-block', width: 80}}>Email</span>{' '} 
                                    <i className="fas fa-arrow-right text-warning mx-3 " />{' '}
                                    CSV FIELD:{' '}
                                    <b className="text-info">{emailField}</b>
                                </p> 
                            : null}

                            {phoneField !== '__false__' ? 
                                <p className="text-sm mb-0">
                                    <span style={{display: 'inline-block', width: 80}}>Phone</span>{' '} 
                                    <i className="fas fa-arrow-right text-warning mx-3 " />{' '}
                                    CSV FIELD:{' '}
                                    <b className="text-info">{phoneField}</b>
                                </p> 
                            : null}

                        </CardBody>

                        <CardFooter className="bg-secondary">
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info " /> Note that upon upload any duplicate contacts by email or phone will not be created and that this upload cannot be undone.</p>
                        </CardFooter>

                        <CardHeader>
                            <div className="custom-control custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id="confirm-terms-of-service"
                                        type="checkbox"
                                        checked={requestOnCreate}
                                        onChange={(e) => setRequestOnCreate(!requestOnCreate)}
                                    />
                                    <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                                        Send a request for documents out to all created contacts
                                    </label>
                                </div>
                        </CardHeader>
                        
                        <CardFooter className="text-right">
                            <button className="btn btn-outline-warning" onClick={() => setView('map')}><i className="fas fa-arrow-left mr-2 " /> Back</button>
                            <button className="btn btn-success" onClick={onBulkUpload}>Bulk Import</button>
                        </CardFooter>
                        
                    </div>
                    
                )}
            
            </Card>

        </Container>
    )

}

export default UploadCSV;
