import { useCallback } from 'react'
import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import { formatMoney } from 'utils/currency';

import _contact_purchases from '_functions/contact_purchases';
import moment from 'moment';

import { toggleAlertBS } from 'store/functions/system/system';

const ModalCredits = ({showModal, toggleModal, contact, contactPurchases}) => {

    const getTotalAmount = useCallback((purchases) => {
        let amount = 0;
        purchases.forEach(p => amount += p.usd_total);
        return amount;
    }, [])

    const onDownload = useCallback(async () => {
        toggleAlertBS('info', `Your download has started, please do not refresh your page.`)
        await _contact_purchases.download(contact._id)
    }, [contact._id])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Contact Purchases</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                {contactPurchases && contactPurchases.length ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Card Last Four</th>
                                    <th>Card Expiration</th>
                                    <th className="text-right">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contactPurchases.map((purchase) => (
                                    <tr key={purchase._id}>
                                        <td>
                                            {moment(purchase.date).format('MM/DD/YYYY - h:mm A')}
                                        </td>
                                        <td className="text-capitalize">
                                            {purchase.card_last_four}
                                        </td>
                                       
                                        <td>
                                            {purchase.card_expiration}
                                        </td>
                                        <td className="text-right">
                                            {formatMoney(purchase.usd_total)}
                                        </td>
                                    </tr>
                                ))}
                               <tr>
                                    <td className="font-weight-bold text-dark">TOTAL</td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right font-weight-bold text-dark">{formatMoney(getTotalAmount(contactPurchases))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="text-sm mb-0">This contact has not purchased access to the client portal.</p>
                )}

            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-outline-success" onClick={onDownload}>
                    <i className="fas fa-download mr-2 " />
                    Download
                </button>
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )

}

ModalCredits.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalCredits
