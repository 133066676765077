import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import { formatCredits, formatMoney } from 'utils/currency';
import { toggleAlertBS } from 'store/functions/system/system';

import _credit_events from '_functions/credit_events';
import moment from 'moment';

import Circle from 'components/markup/loading/Circle'

const ModalCredits = ({showModal, toggleModal, contact}) => {

    const [creditEvents, setCreditEvents] = useState(null);
    const [err, setError] = useState(false);

    const fetchCreditEvents = useCallback(async (state) => {
        const events = await _credit_events.search({contact: contact._id})
        if(events.data) {
            setCreditEvents(events.data.documents)
        } else {
            setError(true);
        }
    }, [contact._id])

    const getTotalCredits = useCallback((events) => {
        let total = 0;
        events.forEach(event => total += event.credits )
        return total;
    }, [])
    const getTotalUsd = useCallback((events) => {
        let total = 0;
        events.forEach(event => total += event.usd_amount )
        return total;
    }, [])

    const onDownload = useCallback(async () => {
        toggleAlertBS('info', `Your download has started, please do not refresh your page.`)
        await _credit_events.download(contact._id)
    }, [contact._id])

    useEffect(() => {
        if(showModal && contact._id) fetchCreditEvents()
    }, [fetchCreditEvents, showModal, contact._id])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Contact Credit History</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                {err ? (
                    <p className="text-sm mb-0 text-danger">Something's not right, please try again shortly.</p>
                ) : creditEvents && creditEvents.length ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th className="text-right">Credits</th>
                                    <th className="text-right">USD</th>
                                </tr>
                            </thead>
                            <tbody>
                                {creditEvents.map((event, i) => (
                                    <tr key={i}>
                                        <td>
                                            {moment(event.date).format('MM/DD/YYYY - h:mm A')}
                                            {event.contacts && event.contacts.length ? event.contacts.map((contact, i) => (
                                                <div>{contact.display_name}</div>
                                            )) : null}
                                        </td>
                                        <td className="text-capitalize">
                                            {event.type === 'fize_connection' ? 'Auto Policy' : event.type === 'yodlee_statement' || event.type === 'mx_statement' ? 'Bank Statement' : event.type.replace(/_/g, ' ')}
                                        </td>
                                       
                                        <td className="text-right">
                                            {event.credits < 0 ? <b className="text-success">{formatCredits(event.credits * -1)}</b> : formatCredits(event.credits)}
                                        </td>
                                        <td className="text-right">
                                            {event.usd_amount < 0 ? <b className="text-success">{formatMoney(event.usd_amount * -1)}</b> : formatMoney(event.usd_amount)}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="font-weight-bold text-dark">TOTAL</td>
                                    <td></td>
                                    <td className="text-right font-weight-bold text-dark">{formatCredits(getTotalCredits(creditEvents))}</td>
                                    <td className="text-right font-weight-bold text-dark">{formatMoney(getTotalUsd(creditEvents))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : creditEvents ? (
                    <p className="text-sm mb-0">No credit events have been found for this contact.</p>
                ) : (
                    <div className="pt-5 pb-4"><Circle /></div>
                )}

            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-outline-success" onClick={onDownload}>
                    <i className="fas fa-download mr-2 " />
                    Download
                </button>
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )

}

ModalCredits.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalCredits
