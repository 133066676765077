
import * as actionTypes from '../../actions';
import store from '../../index';
import _load from '_functions/load';

export const load = async() => {
    return new Promise( async resolve => {

        const loaded = await _load.standard();

        if(loaded.success) {

            store.dispatch({ type: actionTypes.SET_LOADED, payload: loaded.data });
            resolve({success: true});

        } else {

            resolve({success: false});

        }

    })

}
