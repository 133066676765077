import setQuestionWithId from './setQuestionWithId'

const questionDoesBranch = (question, answer) => {
    if(question.type === 'checkbox') {
        if(answer && answer.some(v =>question.branch_on_answers.includes(v) )) {
            return true;
        }
    } else if(question.branch_on_answer) {
        if(question.branch_on_answers.includes(answer)) {
            return true;
        }
    }
  
    return false;
}

const isValidAddress = (question, answer) => {
    if(question.type === 'address' && answer) {
        if(answer.address_line_1 && answer.city && answer.state && answer.zip) {
            return true;
        }
    }
    return false;
}

const isValidCheckbox = (question, answer) => {
    if(question.type === 'checkbox' && answer && answer.length) return true;
    return false;
}

const isValidQuestion = (answer) => {
    if(answer) return true;
    return false;
}

const calculate = (_questions, state) => {
    let total = 0;
    let complete = 0;
    let errors = [];

    const _setProgress = (questions, loopIndex = 0) => {
        for (let i = 0; i < questions.length; i++) {
            const question = setQuestionWithId(questions[i], loopIndex);

            if(!question.required) continue;
            const answer = state[question.id] && state[question.id] === '__FALSE__' ? '' : state[question.id];
            total++;

            let isValid = false;

            switch (question.type) {
                case 'address': isValid = isValidAddress(question, answer); break;
                case 'checkbox': isValid = isValidCheckbox(question, answer); break;
                default: isValid = isValidQuestion(answer); break;
            }

            if(questionDoesBranch(question, answer)) {

                let _loopIndex = loopIndex;

                if(question.type === 'loop') {
                    const number = parseInt(answer);

                    for (let ii = 0; ii < number; ii++) {
                        _setProgress(question.questions, ii + 1);
                    }
                } else {
                    _setProgress(question.questions, _loopIndex);
                }
            }

            if(isValid) {
                complete++;
            } else {
                errors.push(question.id);
            }
            
        }

    }

    _setProgress(_questions)

    const progress = ((complete / total) * 100 ).toFixed(2);
    return { progress, errors }

}

export default calculate;