import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";

import routes from "./routes";

import _auth from '_functions/auth'
import { setCompany } from 'store/functions/company/company'
import { load } from 'store/functions/load'
import { getCompanyLink } from 'utils/helpers';

import ArchkTutorial from 'components/functional/tutorial'
import ArchkAlerts from 'components/functional/alerts'

class Dashboard extends React.Component {
    state = {
        loaded: false,
        sidenavOpen: true,
        showMobileNav: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/editor${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

 
    // when window comes into focus make sure we still have a logged in user
    // if force a redirect to login page
    checkLogin = async () => {
        const user = await _auth.viewing_user();
        if(!user.data) {
            const link = this.props.company && this.props.company._id ? getCompanyLink(this.props.company) : ''
            return window.location.href = `/auth/login/${link}?redirect=${window.location.pathname}`
        }
    }

    setScreenSize = () => {

        const { device } = this.props

        if(!device.isLG) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
            this.setState({ sidenavOpen: false, showMobileNav: true });
        } else {
            document.body.classList.add("g-sidenav-pinned");
            this.setState({ sidenavOpen: true, showMobileNav: false });
        }

    }

    componentDidCatch = (e) => {
        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({redirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setScreenSize)
        window.removeEventListener('focus', this.checkLogin)
    }

    componentDidMount = async () => {
        if(!this.props.load.CREDITS.CREDIT_AMOUNTS || !this.props.company._id) {
            await Promise.all([
                setCompany(),
                load()
            ])
        }

        this.setScreenSize()
        this.setState({loaded: true})

        window.addEventListener('resize', this.setScreenSize)
        window.addEventListener('focus', this.checkLogin)
        
    }

    render() {

        const { redirect, loaded } = this.state
        const { company, tutorial } = this.props;

        if(redirect) return <Redirect to={redirect} />;
        if(!loaded) return <div />;
        if(!company.agreement_accepted_at) return <Redirect to="/actions/agreement" />;

        return (
            <div>
                  <ArchkTutorial 
                    isOpen={tutorial.showTutorial}
                    steps={tutorial.steps}
                />

                <ArchkAlerts />

                <div className="editor-content">
                    <Switch>
                        {this.getRoutes(routes)}
                        <Redirect from="*" to="/dashboard/home" />
                    </Switch>
                </div>

            </div>
            
        );
    }
}

const mapStateToProps = state => {
	return {
	    device: state.device.info,
	    company: state.company.company,
	    tutorial: state.tutorial.tutorial,
	    load: state.load,
	};
};

export default connect(mapStateToProps, '')(Dashboard);
