import { useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, Container, Badge, Alert } from "reactstrap";

import Table from 'components/functional/tables/Standard';
import { Link } from 'react-router-dom';
import _notifications from '_functions/notifications';
import moment from 'moment'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import formatText from 'utils/formatText'
import { onDownloadDocument, getDownloadName } from 'utils/documents'

const NotificationsAll = ({device}) => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: null, total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await _notifications.search({ ...params, isCSV })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    const toggleSeen = (row) => {
        // toggle via backend
        _notifications.seen(row._id)
        
        // copy state
        const newState = JSON.parse(JSON.stringify(tableData.data));
        // assume success
        const notification = newState.find(d => d._id === row._id);
        notification.marked_seen_at = row.marked_seen_at ? 0 : 123;

        // update table row
        setTableData({...tableData, data: newState})
    }

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = device.info.isMD ? [
        {
            dataField: '_id',
            text: "Seen",
            headerStyle:{width: 30},
            formatExtraData: {
                toggleSeen
            },
            formatter: (cell, row, rowIndex, formatter) => (
                <i 
                    className={row.marked_seen_at ? "fas fa-check-circle text-success cursor-pointer" : "fas fa-eye cursor-pointer" }
                    onClick={() => formatter.toggleSeen(row)}
                />
            )
        },
        {
            dataField: "date",
            text: "Date",
            cellStyle: {background: 'red'},
            formatter: (cell, row) => (
                <div>
                    <div>{moment(row.date).format('MM/DD/YYYY')}</div>
                    <div>{moment(row.date).format('h:mm A')}</div>
                </div>
            )
        },
        {
            dataField: "category",
            text: "Category",
            formatter: (cell, row) => (
                <span className="text-capitalize">{row.category}</span>
            )
        },
        {
            dataField: "subject",
            text: "Subject",
            formatter: (cell, row) => (
                row.subject
            )
        },
        {
            dataField: "body",
            text: "Notification",
            formatter: (cell, row) => {

                const stripped = formatText(row.body).stripHTML();
                if(stripped && stripped.length > 20) return stripped.slice(0,20) + '...'
                return stripped;
            }
        },
        {
            dataField: "created_at",
            text: "Actions",
            isDummyField: true,
            headerStyle: {textAlign: 'right'},

            formatter: (cell, row) => (
                <div className="text-right">
                    <Link to={`/dashboard/notifications/${row._id}`} className="btn btn-success">
                        View
                    </Link>
                </div>
            )
        },
    ] : [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => {

                const sliceLength = device.info.isLG ? 100 : device.info.isMD ? 60 : device.info.isSM ? 40 : 15

                let name = getDownloadName(row, row.contact);
                if(name && name.length > sliceLength) name = name.slice(0,sliceLength) + '...'

                return (
                    <div>
                        {
                            row.status === 'failed' ? (
                                <Badge color="danger" className="tex-sm" style={styles.badgeMobile}><i className="fas fa-times " /></Badge>
                            ) : row.status === 'completed' ? (
                                <Badge color="success" className="tex-sm" style={styles.badgeMobile}><i className="fas fa-check " /></Badge>
                            ) : (
                                <Badge color="warning" className="tex-sm" style={styles.badgeMobile}><i className="fas fa-sync " /></Badge>
                            )
                        }
                        <Link to={`/dashboard/documents/${row._id}`}>
                            {name}
                        </Link>
                    </div>
                )
            }
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                row.status === 'completed' ? (
                    <div className="text-right">
                        <button 
                            className="btn btn-outline-success" 
                            onClick={() => onDownloadDocument(row, row.contact)}
                            style={{minWidth: 70}}
                        >
                            <i className="fas fa-download mr2 " />
                        </button>
                    </div>
                ) : '-'
            )
        },

    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Notifications`}</title>
                <meta name="description" content="Notifications" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Notifications"
                breadcrumb_2="All"
                icon="fas fa-file"
            />
    
            <Card className="card-color card-primary">    
                <Table
                    placeholder="Notifications"
                    hideSearch={true}
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    rowClasses={(row, rowIndex) => {
                        return row.marked_seen_at ? 'notification-read' : 'notification-unread';
                    }}
                />
            </Card>
    
        </Container>
    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 120
    },
    badgeMobile: {
        display: 'inline-block',
        width: 30,
        marginRight: 10
    }
}

const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(NotificationsAll);
