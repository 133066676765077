import { useState, useEffect, useCallback } from 'react';
import { FormGroup, Input, CardHeader, CardTitle, CardFooter, } from "reactstrap";
import emailValidator from 'email-validator';

import _companies from '_functions/companies';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const SettingsInfoDailyEmail = ({company, setCompany}) => {

    const [email, setEmail] = useState('');
    const [dailyEmails, setDailyEmails] = useState([])
    const [err, setErr] = useState(false)

    const onAddEmail = useCallback((email) => {
        if(!email) return;
        if(!emailValidator.validate(email)) return setErr(`${email} is not a valid email address.`);

        let emails = JSON.parse(JSON.stringify(dailyEmails));
        if(emails.length > 10) return setErr('A maximum of 10 emails can be set to receive daily reports.')
        if(!emails.includes(email)) {
            emails.push(email)
            setDailyEmails(emails);
            setEmail('')
            setErr(false);
        }
    }, [dailyEmails])

    const onRemoveEmail = useCallback((email) => {
        let emails = JSON.parse(JSON.stringify(dailyEmails));
        emails = emails.filter(e => e !== email)
        setDailyEmails(emails);
    }, [dailyEmails])

      //on keypress of enter simulate the form being submitted for better UI
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') onAddEmail(email)
	}, [email, onAddEmail])

    const onSave = useCallback(async () => {

        toggleStandardLoader(true);
        const updated = await _companies.update(company._id, {daily_emails: dailyEmails})
        toggleStandardLoader(false);

        if(updated.success) {
            toggleAlertBS(false, 'Company settings updated successfully.')
            setCompany()
        } else {
            setErr(`Something's not right, please try again.`)
        }

    }, [dailyEmails, company._id, setCompany])

    useEffect(() => {
        setDailyEmails(company.daily_emails ? company.daily_emails : []);
    }, [company.daily_emails])

    return (

        <div className="border-left">

            <CardHeader>
                <CardTitle className="mb-0">Daily Report Overview</CardTitle>
            </CardHeader>

            <CardHeader>
                <p className="mb-0">Daily Report overviews get emailed out everyday between 8-10 AM EST. These emails include a list of all contacts that have new documents available for download over the last 24 hours.</p>
            </CardHeader>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Email Address</th>
                            <th className="text-right">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dailyEmails && dailyEmails.length ? dailyEmails.map((email, i) => (
                            <tr key={i}>
                                <td>{email}</td>
                                <td className="text-right">
                                    <button className="btn btn-outline-danger btn-sm" onClick={() => onRemoveEmail(email)}> 
                                        <i className="fas fa-trash mr-2 " /> Remove 
                                    </button>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No email addresses have been added. </td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <CardFooter>
                <FormGroup>
                    <label className="form-control-label">Daily Overview Email - Press <code>ENTER</code> to add</label>
                    <Input 
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={_handleKeyDown}
                    />
                </FormGroup>
            </CardFooter>

            {err ? (
                <CardFooter>
                    <p className="text-sm mb-0 text-danger">{err}</p>
                </CardFooter>
            ) : null}

            <CardFooter className="text-right">
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2 " /> Save
                </button>
            </CardFooter>
        </div>
        
    );

}

export default SettingsInfoDailyEmail;