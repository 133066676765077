import { useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Card, Container, Modal, Alert, Badge, UncontrolledDropdown, DropdownToggle,DropdownItem, DropdownMenu } from "reactstrap";
import moment from 'moment';

import Table, { downloadCSV } from 'components/functional/tables/Standard';
import NameCircle from 'components/markup/misc/NameCircle';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import ModalToggler from 'components/functional/modals/Toggler'
import ModalCreate from './ModalCreate'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system'
import { getUrlParameter } from "utils/urls";

import formatText from 'utils/formatText';
import _contacts from '_functions/contacts';

const ContactsAll = ({device}) => {

    const [ reportStatus, setReportStatus ] = useState(getUrlParameter('filter'));
    const [ showModal, setShowModal ] = useState(false);
    const [ selectedRows, setSelectedRows ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        let filterStatus = params.reportStatus !== undefined ? params.reportStatus : reportStatus;

        if(filterStatus === 'none') filterStatus = 'null'
        if(filterStatus === 'finished') filterStatus = 'complete'

        const query = await _contacts.search({ ...params, isCSV, report_status: filterStatus })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // similar to query however it does not pass in a limit or skip
    // and once the data is fetched it will trigger the browser to download
    // the newly created file
    const onDownloadCSV = async (params) => {
        const data = await fetchData(params, true);
        downloadCSV(data, { name: 'contacts' });
    }

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const onDelete = () => {

        if(selectedRows && selectedRows.length) {

            toggleStandardLoader(true);

            let counter = 0;
            let passed  = 0;

            // for each contact run the endpoint to delete them
            selectedRows.forEach(async contact => {

                const deleted = await _contacts.delete(contact._id);
                
                // see how many passed deletion
                if(deleted.success) passed++;
                counter++;

                // when all deletion events ran
                if(counter === selectedRows.length) {

                    // matched passed deletion to total contacts, if they match we have total success
                    if(passed === selectedRows.length) {
                        toggleAlertBS(false, 'Contacts Deleted Successfully');
                    } else {
                        toggleAlertBS(true, 'Some or all of the specified contacts were not deleted.')
                    }

                    // kill the modal, stop the load, fire the onFinish prop function
                    setShowModal(false)
                    toggleStandardLoader(false);
                    query();

                }

            })

        }

    }

    const showCreateModal = () => {
        if(getUrlParameter('given_name') || getUrlParameter('family_name') ||  getUrlParameter('email') ||  getUrlParameter('phone')) {
            return true
        }
    }

    const onStatusChange = (e) => {

        e.preventDefault();
        let status = e.target.innerHTML.toLowerCase();
        let searchString = document.querySelector('.dataTables_filter input').value

        if(status === 'all') status = '';

        setReportStatus(status)
        query({reportStatus: status, searchString: searchString, page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage })
    }


    const columns = device.info.isMD ? [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-name">Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <Link className="text-capitalize" to={`/dashboard/contacts/${row._id}`}>
                        <NameCircle width={27} contact={row} style={{marginRight: 5}} />{' '}
                        {row.display_name}
                    </Link>
                )
                
            },
        },
        {
            dataField: "email",
            text: 'Contact',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-contacts">Contacts</div>
            },
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                <div>
                    {row.email && <div key={row._id}><i className="text-muted fas fa-envelope mr-2" /> {row.email}</div>}
                    <div>
                        {row.phone && <div key={row.created_at} > <i className="text-muted fas fa-mobile mr-2" /> {formatText(row.phone).phone()} </div>}
                    </div>
                </div>
            )
        },

        {
            text: 'Status',
            dataField: "report_status",
            headerStyle: { width: 150 },
            headerFormatter: (cell, row) => (
                <div id="archk-tutorial-contacts-status">
                    <UncontrolledDropdown>

                            <DropdownToggle caret>{reportStatus ? reportStatus : 'Status'}</DropdownToggle>

                            <DropdownMenu>
                                <DropdownItem onClick={onStatusChange}>All</DropdownItem>
                                <DropdownItem onClick={onStatusChange}>Finished</DropdownItem>
                                <DropdownItem onClick={onStatusChange}>In Progress</DropdownItem>
                                <DropdownItem onClick={onStatusChange}>Requested</DropdownItem>
                                <DropdownItem onClick={onStatusChange}>None</DropdownItem>
                            </DropdownMenu>

                    </UncontrolledDropdown>
                  </div>
            ),
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                row.report_status === 'null' ? '-' :
                row.report_status === 'requested'   ? <Badge color="warning" style={styles.badge}>Requested</Badge> : 
                row.report_status === 'in progress' ? <Badge color="info" style={styles.badge}>In Progress</Badge> : 
                row.report_status === 'complete'    ? <Badge color="success" style={styles.badge}>COMPLETE</Badge>
                : '-'
            )
        },
        {
            dataField: "mx_expires_at",
            headerStyle: { width: 90 },
            text: 'Expires In',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-expires">Expires In</div>
            },
            formatter: (cell, row) => {
                if(row.name === 'no data') return '';

                const hasPinwheel   = row.pinwheel_expires_at && row.pinwheel_first_pulled_at ? true : false;
                const hasYodlee     = row.yodlee_expires_at && row.yodlee_first_pulled_at ? true : false;
                const hasMX         = row.mx_expires_at && row.mx_first_pulled_at ? true : false;
                const hasPurchase   = row.contact_purchased_at ? true : false;

                const day = 86400;

        

                const purchase_expires_at = row.contact_purchased_at + (day * 90)

                if(hasPinwheel || hasYodlee || hasMX || hasPurchase) {

                    let date;

                    // get the first non 0 date;
                    if(hasYodlee && row.yodlee_expires_at) {
                        date = row.yodlee_expires_at;
                    } else if(hasMX && row.mx_expires_at) {
                        date = row.mx_expires_at;
                    } else if(hasPinwheel && row.pinwheel_expires_at) {
                        date = row.pinwheel_expires_at;
                    } else if(row.contact_purchased_at) {
                        date = purchase_expires_at;
                    } 

                    // if another date is less than the date we found use the date expiring soonest
                    if(hasPinwheel && row.pinwheel_expires_at < date) date = row.pinwheel_expires_at;
                    if(hasMX && row.mx_expires_at < date) date = row.mx_expires_at;
                    if(hasYodlee && row.yodlee_expires_at < date) date = row.yodlee_expires_at;
                    if(row.contact_purchased_at > 0 && purchase_expires_at < date) date = purchase_expires_at;

                    const unix = Math.floor(new Date() / 1000)
                    const date1 = moment()
                    const date2 = moment.unix(date)

                    let metric = 'Days';

                    if(date < unix + 3600) {
                        metric = 'Minutes'
                    } else if(date < unix + 86400) {
                        metric =  'Hours'
                    }

                    const diff = date2.diff(date1, metric)  

                    return diff + ' ' + metric;
                }
                return '-'
            }

        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <Link to={`/dashboard/contacts/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        <i onClick={() => {
                            setSelectedRows([row])
                            setShowModal(true)
                        }} className="fas fa-trash text-danger cursor-pointer"></i>
                    </div>
                )
            }
        },
    ] : [
        {
            dataField: "given_name",
            text: "Name",
            formatter: (cell, row) => (
                row.name === 'no data' ? 'No Clients Added' : 
                <div>
                    {   
                        row.report_status === 'null' ? 
                            <Badge color="success" style={styles.badgeMobile}>-</Badge> :
                        row.report_status === 'requested'   ? 
                            <Badge color="warning" style={styles.badgeMobile}><i className="fas fa-paper-plane " /></Badge> : 
                        row.report_status === 'in progress' ? 
                            <Badge color="info" style={styles.badgeMobile}><i className="fas fa-hourglass " /></Badge> : 
                        row.report_status === 'complete'    ? 
                            <Badge color="success" style={styles.badgeMobile}><i className="fas fa-check " /></Badge>
                        :   <Badge color="success" style={styles.badgeMobile}>-</Badge>
                    }

                    <Link to={`/dashboard/contacts/${row._id}`} className="text-capitalize">
                        {row.display_name}
                    </Link>
                </div>
            ),
        },
        
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right', width: 85},
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' : 
                    <div className="text-right">
                        <Link to={`/dashboard/contacts/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        <i onClick={() => {
                            setSelectedRows([row])
                            setShowModal(true)
                        }} className="fas fa-trash text-danger cursor-pointer"></i>
                    </div>
                )
            }
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }



    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Contacts`}</title>
                <meta name="description" content="Contacts" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Contacts"
                breadcrumb_2="All"
                icon="fas fa-users"
                actionComponent={(
                    <ModalToggler component={ModalCreate} showModal={showCreateModal()}>
                        <button className="btn btn-success"><i className="fas fa-plus mr-2 " />Add New</button>
                    </ModalToggler>
                )}
            />
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Name, Email, or Phone"
                    downloadCSV={onDownloadCSV}
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSelect={setSelectedRows}
                    onDeletePressed={() => setShowModal(true)}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => setShowModal(false)}
                size="lg"
            >

                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Delete Contacts</h5>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setShowModal(false)} >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body border-bottom">
                    <p className="text-sm mb-0">
                        <i className="fas fa-exclamation-triangle mr-2 text-danger " />
                        You are about to delete the following contacts, are you sure you wish to proceed?
                    </p>
                </div>

                <div className="table-responsive px-3 bg-secondary">
                    <table className="table mb-0">

                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th className="text-right">Phone</th>
                            </tr>
                        </thead>

                        <tbody>
                            {selectedRows.length && selectedRows[0] !== false ? selectedRows.map(contact => (
                                <tr key={contact._id}>
                                    <td>{contact.display_name}</td>
                                    <td>{contact.email ? contact.email : '-'}</td>
                                    <td className="text-right">{contact.phone ? formatText(contact.phone).phone() : '-'}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>You have not selected any contacts</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

                <div className="modal-footer text-right">
                    <button onClick={() => setShowModal(false)} className="btn btn-neutral">
                        <i className="fas fa-arrow-left mr-2 " /> Cancel
                    </button>
                    <button disabled={selectedRows.length ? false : true} onClick={onDelete} className="btn btn-danger">
                        <i className="fas fa-trash mr-2 " /> Delete Contacts
                    </button>
                </div>

            </Modal>
    
        </Container>
    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 120
    },
    badgeMobile: {
        display: 'inline-block',
        width: 30,
        marginRight: 10
    }
}

const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(ContactsAll);
