import { useEffect, useState, useCallback } from 'react'
import { useHistory, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Card, Container, CardBody, CardHeader, CardTitle, CardFooter, FormGroup, Input } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import Circle from 'components/markup/loading/Circle';

import _review_links from '_functions/review_links'
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

const FormsAccess = ({company, match}) => {
    const history = useHistory();

    const [loaded, setLoaded] = useState(false);

    const [_id, setId]                    = useState('');
    const [err, setErr]                   = useState('');
    const [redirect, setRedirect]         = useState('');

    const [name, setName]                 = useState('');
    const [redirectUrl, setRedirectUrl]   = useState('');
    const [emailUrl, setEmailUrl]         = useState(company.email_reply_to ? company.email_reply_to : '');

    const onSave = async () => {

        if(!name || !redirectUrl || !emailUrl) {
            return setErr('Please fill out all required fields.')
        } else {
            setErr(false);
        }

        if(_id) {
            toggleStandardLoader(true)
            const created = await _review_links.update(_id, {
                name,
                redirect_url: redirectUrl,
                email_url: emailUrl
            })
            toggleStandardLoader(false)
            if(!created.success) return toggleAlertBS(true, `Please try again`);
            toggleAlertBS(false, `Review Link Updated Successfully`);

        } else {
            toggleStandardLoader(true)
            const created = await _review_links.create({
                name,
                redirect_url: redirectUrl,
                email_url: emailUrl
            })
            toggleStandardLoader(false)
            if(!created.success) return toggleAlertBS(true, `Please try again`);
            toggleAlertBS(false, `Review Link Created Successfully`);
            setRedirect('/dashboard/reviews/all')

        }
    }

    const fetchData = useCallback(async (_id) => {

        const data = await _review_links.findById(_id);
        if(!data.data) return setRedirect('/dashboard/reviews/all');

        setId(data.data._id);
        setName(data.data.name);
        setRedirectUrl(data.data.redirect_url);
        setEmailUrl(data.data.email_url);
        setLoaded(true);

    }, [])

    useEffect(() => {
        const _id = match.params.review_link;
        if(_id === 'new') return setLoaded(true);
        fetchData(_id)
    }, [match, fetchData])

    if(redirect) return <Redirect to={redirect} />
    if(!loaded) return <div className="py-6"><Circle /></div>

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Access`}</title>
                <meta name="description" content="Form Submissions" />
            </Helmet>
    
            <DashHeaderOpen 
                col1Size={5}
                col2Size={7}
                title={<span>Dashboard</span>}
                breadcrumb_1="Review Link"
                icon="fas fa-edit"
                actionComponent={(
                    <button onClick={history.goBack} className="btn btn-outline-warning"><i className="fas fa-arrow-left mr-2" /> Back</button>
                )}
            />
    
            <Card className="card-color card-primary">    

                <CardHeader>
                    <CardTitle className="mb-0">{_id ? 'Update Review Link' : "Add A Review Link"}</CardTitle>
                </CardHeader>

                <CardBody>
                    <FormGroup>
                        <label className="form-control-label">Review Link Name</label>
                        <Input 
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label className="form-control-label">Redirect URL (This should take your client to the link at which to review you if they are a satisfied client)</label>
                        <Input 
                            type="text"
                            value={redirectUrl}
                            onChange={(e) => setRedirectUrl(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label className="form-control-label">What Email Should Bad Reviews Be Sent To?</label>
                        <Input 
                            type="text"
                            value={emailUrl}
                            onChange={(e) => setEmailUrl(e.target.value)}
                        />
                    </FormGroup>
                </CardBody>

                {err ? (
                    <CardFooter>
                        <p className="text-danger text-sm font-weight-bold mb-0">{err}</p>
                    </CardFooter>
                ) : null}

                <CardFooter className="text-right">
                    <button onClick={onSave} className="btn btn-success"><i className="fas fa-save mr-2 " /> Save</button>
                </CardFooter>

            </Card>
    
        </Container>
    )
}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(FormsAccess);