import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";

import routes from "./routes";

import { setCompany } from 'store/functions/company/company'

import socket from 'sockets';
import { CREDIT_EVENT } from 'sockets/events';

class Redirects extends React.Component {
    state = {
        loaded: false,
        sidenavOpen: true,
        showMobileNav: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/redirects${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({redirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    componentWillUnmount = () => {
        socket.off(CREDIT_EVENT, this.onCreditChange);
    }

    componentDidMount = async () => {

        await Promise.all([
            setCompany(),
        ])

        socket.on(CREDIT_EVENT, this.onCreditChange);

        this.setState({loaded: true})

    }

    render() {

        const { redirect, loaded } = this.state
        const { company } = this.props;

        if(redirect) return <Redirect to={redirect} />;
        if(!loaded) return <div />;
        if(!company.agreement_accepted_at) return <Redirect to="/actions/agreement" />;

        return (
            <div className="">

                <div className="wave" />

                <div className="main-content content-wrapper" ref="mainContent" onClick={this.closeSidenav} >

                    <div className="dashboard-content">
                        <Switch>
                            {this.getRoutes(routes)}
                            <Redirect from="*" to="/dashboard/home" />
                        </Switch>
                    </div>

                    {/* <Footer /> */}

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    device: state.device.info,
	    company: state.company.company,
	    tutorial: state.tutorial.tutorial,
	};
};

export default connect(mapStateToProps, '')(Redirects);
