import { Card, CardHeader, CardTitle } from "reactstrap";
import TableDocuments from 'views/dashboard/pages/_components/Tables/Documents';

const DashboardDocuments = ({info}) => (
    <Card className="mt-4" id="archk-tutorial-documents">
        <CardHeader>
            <CardTitle className="mb-0 text-capitalize">Recent Completed Documents</CardTitle>
        </CardHeader>

        <TableDocuments documents={info.documents} />

    </Card>
)

export default DashboardDocuments