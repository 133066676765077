import { useEffect, useState } from "react";
import { Col, Container, Progress, Row } from "reactstrap";

import getProgressAndErrors from './_utils/getProgressAndErrors'

const PageProgress = ({_questions, state, currentPage}) => {

    const [progress, setProgress] = useState(0);
   
    useEffect(() => {
        const calculate = getProgressAndErrors(_questions, state)
        setProgress(calculate.progress)
    }, [_questions, state])

    return (
        <div className="page-progress py-4 border-bottom z-depth-1">
            <Container>
                <Row>
                    <Col lg={2} md={3} className="align-self-center">
                        <h2 className="text-sm mb-0">Page Progress</h2>
                    </Col>
                    <Col  lg={8} md={7} className="align-self-center ">
                        <Progress className="mb-0" max="100" value={progress} color="success" />
                    </Col>
                    <Col  lg={2} md={2} className="align-self-center text-right">
                        <h2 className="text-sm mb-0">
                            <span className={currentPage === 10 ? 'mr-6' : 'mr-5'}>Page:</span> 
                            <b className="text-info" style={{position: 'absolute', top: -18, fontSize: 36, right: 10}}>{currentPage}</b>
                        </h2>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default PageProgress