import { useState } from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

import { Line } from 'react-chartjs-2';

import moment from 'moment';

import ModalPayStubAnalysis from './ModalPayStubAnalysis'

ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler );

const WrappedContainer = ({containerProps, contact, children}) => {

    if(!containerProps) return children

    return (
        <Card>
            <CardHeader>
                <Row>
                    <Col lg={6} className="align-self-center">
                        <CardTitle className="mb-0">{contact.display_name}</CardTitle>
                    </Col>
                    <Col lg={6} className="align-self-center text-right">
                        {moment.unix(containerProps.finishedAt).format('MM/DD/YY')} - {containerProps.documentName}
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                {children}
            </CardBody>
        </Card>
    )

}

const AnalyzePayStub = ({analysis, formatCurrency, containerProps, contact, isDownload}) => {

    const [showModal, setShowModal] = useState(false)

    const recommended = analysis.recommended ? analysis.pay_stubs.find(p => p.id === analysis.recommended) : null;

    return (
        <WrappedContainer containerProps={containerProps} contact={contact}>

            {isDownload ? (
                <div className="white-out" />
             ) : (
                <ModalPayStubAnalysis 
                    contact={contact}
                    analysis={analysis}
                    showModal={showModal}
                    toggleModal={() => setShowModal(false)}
                />
             )}

            <Row>
                <div className="col-auto">

                    {isDownload ? (
                         <div className="wrapper-left-section">
                             <Row>
                                <Col xs={4}>
                                    <h6 className="mb--1">Lowest Pay</h6>
                                    <p className="text-sm mb--1">{formatCurrency(analysis.pay_lowest.amount)}</p>
                                    <p className="text-sm mb--1"><small>{moment(analysis.pay_lowest.date).format('MM/DD/YYYY')}</small></p>
                                </Col>

                                <Col xs={4} className="text-center">
                                    <h6 className="mb--1">Average Pay</h6>
                                    <p className="text-sm mb-0">{formatCurrency(analysis.pay_average)}</p>
                                </Col>

                                <Col xs={4} className="text-right">
                                    <h6 className="mb--1">Highest Pay</h6>
                                    <p className="text-sm mb--1">{formatCurrency(analysis.pay_highest.amount)}</p>
                                    <p className="text-sm mb--1"><small>{moment(analysis.pay_highest.date).format('MM/DD/YYYY')}</small></p>
                                </Col>
                             </Row>                           
                        </div>

                    ) : (
                        <>
                        <div className="wrapper-left-section">
                            <h6 className="mb--1">Average Pay</h6>
                            <p className="text-sm mb-0">{formatCurrency(analysis.pay_average)}</p>

                            <h6 className="mb--1">Highest Pay</h6>
                            <p className="text-sm mb-0">
                                {formatCurrency(analysis.pay_highest.amount)}{' - '} 
                                <small>{moment(analysis.pay_highest.date).format('MM/DD/YYYY')}</small>
                            </p>

                            <h6 className="mb--1">Lowest Pay</h6>
                            <p className="text-sm mb-0">
                                {formatCurrency(analysis.pay_lowest.amount)}{' - '} 
                                <small>{moment(analysis.pay_lowest.date).format('MM/DD/YYYY')}</small>
                            </p>
                            {recommended ? (
                                <>
                                <h6 className="mb--1">Recommended Pay Stub Amount</h6>
                                <p className="text-sm mb-0">
                                    {formatCurrency(recommended.gross_pay_amount / 100)}{' - '} 
                                    <small>{moment(recommended.pay_date).format('MM/DD/YYYY')}</small>
                                </p>
                            </>
                            ) : null}
                        </div>

                        <div className="wrapper-left-section">
                            <button onClick={() => setShowModal(true)} className="btn  btn-outline-success btn-block">
                                <i className="fas fa-search " /> Analyze
                            </button>
                        </div>
                        
                        {/* Used to add border */}
                        <div className="wrapper-left-section" />

                        </>
                    )}

                </div>

                <Col>
                    <div className="chart-wrapper border-left">
                        <div className="chart-container">
                            <Line 
                                options={{
                                    responsive: true,
                                    animation: false,
                                    pointHitRadius: 25,
                                    elements: {
                                        line: {
                                            tension: .3,
                                        },
                                    },
                                    scales: {
                                        y: {
                                            ticks: {
                                                callback: (value) =>  '$' + value
                                            },
                                            title: {
                                                display: true,
                                                text: 'Gross Amount'
                                            }
                                        },
                                        x: {
                                            ticks: {
                                                color: (obj) => {
                                                    const stub = analysis.pay_stubs[analysis.pay_stubs.length - 1- obj.index]
                                                    if(recommended && stub && recommended.pay_date === stub.pay_date) {
                                                        return '#2dce89'
                                                    }
                                                    
                                                },
                                                // callback: (value, index) => {
                                                //     const stub = analysis.pay_stubs[analysis.pay_stubs.length - 1- index]
                                                //     if(stub)return moment(stub.pay_date).format('MMM Do, YYYY')
                                                // }
                                            },
                                            title: {
                                                display: true,
                                                text: 'Date Of Income'
                                            }
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: 'Income Report Analysis',
                                        },
                                        tooltip: {
                                            usePointStyle: true,
                                            callbacks: {
                                                label: function(context) {
                                                    let label = context.dataset.label || '';
                                                    if (label) label += ': ';
                                                    if (context.parsed.y !== null) {
                                                        label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                                                    }
                                                    return label;
                                                },
                                                labelPointStyle: function(context) {
                                                    return {
                                                        pointStyle: 'circle',
                                                        rotation: 0
                                                    };
                                                }
                                            }
                                        }
                                    },
                                }} 
                                data={JSON.parse(analysis.graph)}
                                // data={{"labels":["2021-01-02","2021-01-16","2021-01-30","2021-02-13","2021-02-27","2021-03-13","2021-03-27","2021-04-10","2021-04-24","2021-05-08","2021-05-22","2021-06-05","2021-06-19","2021-07-03","2021-07-17","2021-07-31","2021-08-14","2021-10-01","2021-10-15","2021-10-29","2021-11-12","2021-11-21","2021-12-05","2021-12-19"],"datasets":[{"fill":{"target":true,"below":"rgba(45, 206, 137, .1)"},"label":"Income Report","borderColor":"rgb(44, 196, 228)","backgroundColor":"rgba(44, 196, 228, .1)","pointBackgroundColor":"rgb(44, 196, 228)","data":[1000,0,600,500,400,500,750,500,250,500,550,450,500,500,1000,0,1300,250,250,950,500,0,750,500]},{"label":"Average Pay","pointStyle":"dash","borderColor":"rgb(251, 99, 64)","backgroundColor":"rgba(251, 99, 64, .5)","data":[500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500]}]}}
                        
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </WrappedContainer>
    )

}

export default AnalyzePayStub