import { useEffect, useState } from 'react'
import { Modal } from 'reactstrap';
import { Row, Col, Input, FormGroup } from 'reactstrap';

import _users from '_functions/users';
import { toggleStandardLoader } from 'store/functions/system/system';

import { connect } from 'react-redux';

const ModalUpdateUser = ({showModal, toggleModal, userToUpdate, onUpdate, viewing_user}) => {

    const [givenName, setGivenName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [id, setId] = useState('')
    const [isAdmin, setIsAdmin] = useState('')
    const [deleted, setDeleted] = useState('')
    const [errors, setErrors] = useState(false)

    const onSave = async () => {

        setErrors(false)

        if(!givenName || !familyName || !email || !phone) {
            return setErrors([`A user must have a last name, email, and phone field.`])
        }
    
        toggleStandardLoader(true)

        const fields = {
            given_name: givenName,
            family_name: familyName,
            email: email,
            phone: phone,
            is_admin: isAdmin === 'yes' ? true : false,
            deleted: deleted === 'yes' ? true : false,
        }

        let saved = await _users.update(id, fields, true);

        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(!saved.success) {
            return setErrors(saved.message);
        } else {
            onUpdate()
        }
        
    }

    useEffect(() => {

        if(!userToUpdate || !showModal) return;

        setGivenName(userToUpdate.given_name);
        setFamilyName(userToUpdate.family_name);
        setEmail(userToUpdate.email);
        setPhone(userToUpdate.phone);
        setIsAdmin(userToUpdate.is_admin ? 'yes' : 'no');
        setDeleted(userToUpdate.deleted ? 'yes' : 'no');
        setId(userToUpdate._id);

        const tryFocus = (tries = 0) => {

            if(!showModal) return;

            const input = document.getElementById('update-user-first-name')

            if(input) {
                input.focus();
            } else {
                setTimeout(() => {
                    tryFocus(tries + 1)
                }, 200)
            }

        }

        tryFocus();

    }, [showModal, userToUpdate])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => toggleModal(false)}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Create A User</h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleModal(false)} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom">
                <p className="text-sm mb-0">
                    <i className="fas fa-info-circle text-success mr-2 " />{' '}
                    To create a user they must have a first name, last name, email and phone.
                </p>
            </div>

            <div className="modal-body border-bottom">

                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">First Name*</label>
                            <Input 
                                id="update-user-first-name"
                                type="text"
                                value={givenName}
                                onChange={(e) => setGivenName(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Last Name*</label>
                            <Input 
                                type="text"
                                value={familyName}
                                onChange={(e) => setFamilyName(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Email*</label>
                            <Input 
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Phone*</label>
                            <Input 
                                type="text"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <hr />

                <FormGroup>
                    <label className="form-control-label">Has Admin Privilege*</label>
                    <Input 
                        type="select"
                        value={isAdmin}
                        onChange={(e) => setIsAdmin(e.target.value)}
                    >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </Input>
                </FormGroup>

                {/* Users cannot delete themselves */}
                {userToUpdate && userToUpdate._id !== viewing_user._id ? (
                    <FormGroup>
                        <label className="form-control-label">Is Disabled*</label>
                        <Input 
                            type="select"
                            value={deleted}
                            onChange={(e) => setDeleted(e.target.value)}
                        >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </Input>
                    </FormGroup>
                ) : null}

            </div>

            {errors ? (
                <div className="modal-body border-bottom">
                    {errors.map((err, i) => (
                        <p key={i} className="text-sm font-weight-bold text-danger mb-0"><i className="fas fa-exclamation mr-2 " /> {err}</p>
                    )) }
                </div>
            ) : null}

            <div className="modal-footer text-right">
                <button onClick={() => toggleModal(false)} className="btn btn-outline-warning">
                    <i className="fas fa-arrow-left mr-2 " /> Cancel
                </button>
                <button onClick={onSave} className="btn btn-success">
                    <i className="fas fa-check mr-2 " /> Update User
                </button>
            </div>

        </Modal>
    )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(ModalUpdateUser);
