/*
Documentation

This page handles updating a users profile

*/

import States from 'components/markup/inputs/States';
import React from "react";

import { connect } from 'react-redux';
import { CardFooter, Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import _users from  '_functions/users';

const required_form_fields = [
    'given_name',
    'family_name',
    'email',
    'phone',

    'address_line_1',
    'city',
    'state',
    'postal_code'
]

class AccountSettings extends React.Component {

    state = {
        user: Object.assign({}, this.props.viewing_user),
    }

    onSave = async (e) => {

        e.preventDefault();

        this.setState({error: false})

        //copy state
        let newState = Object.assign({}, this.state.user);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ user: newState });
        if(errors) return;

        toggleStandardLoader(true)

        const updated = await _users.update(this.props.viewing_user._id, newState)

        toggleStandardLoader(false)

        if(updated.success) {

            toggleAlertBS(false, 'Your profile has been updated successfully.')
            setViewingUser()

        } else {

            this.setState({error: updated.message})

        }

    }

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state.user);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }

        this.setState({ user: newState });
    };

    render() {

        const { user, error } = this.state

        return (

            <Form>
                <Card>
                    <CardBody>

                        <h6 className="heading-small text-muted mb-4">User information</h6>

                        <Row>

                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="given_name" >First name</label>
                                    <Input
                                        id="given_name"
                                        value={user.given_name || ''}
                                        type="text"
                                        valid={ user.given_nameState === "valid" }
                                        invalid={ user.given_nameState === "invalid" }
                                        onChange={e => this.onInputChange(e, "given_name") }
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="family_name" >Last name</label>
                                    <Input
                                        id="family_name"
                                        value={user.family_name || ''}
                                        type="text"
                                        valid={ user.family_nameState === "valid" }
                                        invalid={ user.family_nameState === "invalid" }
                                        onChange={e => this.onInputChange(e, "family_name") }
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>

                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="email">Email address</label>
                                    <Input
                                        id="email"
                                        value={user.email || ''}
                                        type="email"
                                        valid={ user.emailState === "valid" }
                                        invalid={ user.emailState === "invalid" }
                                        onChange={e => this.onInputChange(e, "email") }
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="phone">Phone</label>
                                    <Input
                                        id="phone"
                                        value={user.phone || ''}
                                        type="tel"
                                        valid={ user.phoneState === "valid" }
                                        invalid={ user.phoneState === "invalid" }
                                        onChange={e => this.onInputChange(e, "phone") }

                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>
                            </Col>

                        </Row>

                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">Address</h6>

                        <Row>

                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="address_line_1" >Address Line 1</label>
                                    <Input
                                        id="address_line_1"
                                        value={user.address_line_1 || ''}
                                        type="text"
                                        valid={ user.address_line_1State === "valid" }
                                        invalid={ user.address_line_1State === "invalid" }
                                        onChange={e => this.onInputChange(e, "address_line_1") }
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>
                            </Col>

                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="address_line_2" >Address Line 2</label>
                                    <Input
                                        id="address_line_2"
                                        value={user.address_line_2 || ''}
                                        type="text"
                                        valid={ user.address_line_2State === "valid" }
                                        invalid={ user.address_line_2State === "invalid" }
                                        onChange={e => this.onInputChange(e, "address_line_2") }
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>

                            <Col lg="4">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="city" >City</label>
                                    <Input
                                        id="city"
                                        value={user.city || ''}
                                        type="text"
                                        valid={ user.cityState === "valid" }
                                        invalid={ user.cityState === "invalid" }
                                        onChange={e => this.onInputChange(e, "city") }
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>
                            </Col>

                            <Col lg="4">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="state" >State</label>
                                    <Input
                                        id="state"
                                        type="select"
                                        value={user.state || ''}
                                        valid={ user.stateState === "valid" }
                                        invalid={ user.stateState === "invalid" }
                                        onChange={e => this.onInputChange(e, "state") }
                                    >
                                        <option value="">Select State</option>
                                        <States />
                                    </Input>
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>
                            </Col>

                            <Col lg="4">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="postal_code" >Postal code</label>
                                    <Input
                                        id="postal_code"
                                        value={user.postal_code || ''}
                                        type="text"
                                        valid={ user.postal_codeState === "valid" }
                                        invalid={ user.postal_codeState === "invalid" }
                                        onChange={e => this.onInputChange(e, "postal_code") }
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </FormGroup>
                            </Col>

                        </Row>

                    </CardBody>

                    {error && (
                        <CardFooter className="text-right">
                            <p className="text-sm text-danger font-weight-bold mb-0">{error}</p>
                        </CardFooter>
                    )}

                    <CardFooter className="text-right">
                        <button className="btn btn-success" onClick={this.onSave} >
                            Save Profile
                        </button>
                    </CardFooter>

                </Card>

            </Form>

        );
    }
}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);
