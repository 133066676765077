import apiWorker from '../apiWorker'

const review_links =  {
    create    : (data)              => apiWorker('post',   `/v1/reviews/links`, data, true),
    search    : (data)              => apiWorker('post',   `/v1/reviews/links/search`, data, true),
    delete    : (review_link)       => apiWorker('delete', `/v1/reviews/links/${review_link}`, null, true),
    update    : (review_link, data) => apiWorker('patch',  `/v1/reviews/links/${review_link}`, data, true),
    findById  : (review_link)       => apiWorker('get',    `/v1/reviews/links/${review_link}`, null, true),
}

export default review_links;