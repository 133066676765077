import apiWorker from '../apiWorker'

const contacts = {
    
    search                    : (data)                        =>  apiWorker('post',  `/v1/api/contacts/search`, data),
    find                      : (query)                       =>  apiWorker('get',   `/v1/api/contacts${query}`),
    findById                  : (contact_id, hideErr)         =>  apiWorker('get',   `/v1/api/contacts/${contact_id}`, null, hideErr),
    create                    : (data, hideErr)               =>  apiWorker('post',  `/v1/api/contacts`, data, hideErr),
    createJoint               : (data, hideErr)               =>  apiWorker('post',  `/v1/api/contacts/joint`, data, hideErr),
    update                    : (contact_id, data, hideErr)   =>  apiWorker('patch', `/v1/api/contacts/${contact_id}`, data, hideErr),
    delete                    : (contact_id)                  =>  apiWorker('delete', `/v1/api/contacts/${contact_id}`,),
    request                   : (contact_id)                  =>  apiWorker('post', `/v1/api/contacts/request/${contact_id}`,),
    setEmployment             : (contact_id, data)            =>  apiWorker('post', `/v1/api/contacts/set_employment/${contact_id}`, data),
    setBilling                : (contact_id, data)            =>  apiWorker('patch', `/v1/api/contacts/set_billing/${contact_id}`, data),
    bypassNameVerification    : (contact_id, data)            =>  apiWorker('patch', `/v1/api/contacts/bypass_name_verification/${contact_id}`, data),
    setPartner                : (contact_id, partner_id)      =>  apiWorker('post', `/v1/api/contacts/set_partner/${contact_id}/${partner_id}`, {}, true),
    removePartner             : (contact_id)                  =>  apiWorker('post', `/v1/api/contacts/remove_partner/${contact_id}`, {}, true),
    view                      : (contact_id)                  =>  apiWorker('get', `/v1/api/contacts/view/${contact_id}`, null, true),

}

export default contacts;