/*
Documentation

this file handles password recovery by sending a hashed security code to a users email
it then verifies the code and lets them update their password

*/

import React from "react";
import PropTypes from 'prop-types'
import { Card, CardHeader, CardBody, CardTitle, CardFooter, Form, FormGroup, Input } from "reactstrap";
import _auth from '_functions/auth';

class ForgotUpdatePassword extends React.Component {

	state = {
        error: false,
        showEmail: true,
        showCodeVerification: false,
        showPasswordUpdate: false,
        success: null
    };

    //on enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {

            document.getElementById("showPasswordUpdateButton").click();

		}
	}

    onPasswordSet = async (e) => {

        e.preventDefault();

        this.setState({error: null})

        const { password, passwordConfirm } = this.state
        const { code, email, onPasswordUpdate, company } = this.props

        //update the users password
        const updatePassword =  await _auth.password.reset({
            password,
            passwordConfirm,
            email,
            code,
            company: company._id
        })

        if(updatePassword.success) {
            onPasswordUpdate()
        } else {
            this.setState({error: updatePassword.message})
        }

    }

    componentDidMount = () => {
        const input = document.getElementById('forgot-password-input')
        if(input) input.focus();
    }

	render() {

        const { error, password, passwordConfirm } = this.state

		return (

		    <Form>

                <Card className="bg-white border-0 mb-0 z-depth-2">

                    <CardHeader>
                        <CardTitle className="mb-0">Forgot Password - Step 3 of 3</CardTitle>
                    </CardHeader>

                    <CardBody>


                        <FormGroup>
                            <label className="form-control-label">Enter your new password.</label>
                            <Input
                                id="forgot-password-input"
                                onKeyDown={(e) => this._handleKeyDown(e)}
                                placeholder=""
                                type="password"
                                value={password	 || ''}
                                onChange={(e) => this.setState({password: e.target.value})}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Confirm Password.</label>
                            <Input
                                onKeyDown={(e) => this._handleKeyDown(e)}
                                placeholder=""
                                type="password"
                                value={passwordConfirm	 || ''}
                                onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                            />
                        </FormGroup>

                    </CardBody>

                    {error && (
                        <CardFooter>
                            <p className="text-sm text-danger font-weight-bold my-2 ">{error}</p>
                        </CardFooter>
                    )}

                    <CardFooter className="text-right">
                        <button id="showPasswordUpdateButton" onClick={this.onPasswordSet} className="my-4 btn btn-outline-success">
                            Update Password
                        </button>
                    </CardFooter>

               </Card>

            </Form>

        );

	}
}

ForgotUpdatePassword.propTypes = {
    code                : PropTypes.string.isRequired,
    email               : PropTypes.string.isRequired,
}

export default ForgotUpdatePassword

