/*
Documentation

This page handles updating a users profile

*/

import keys from 'keys';

import {useState, useCallback} from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Container, Row, Col, CardHeader, Card, CardFooter, CardBody, FormGroup, Input, Badge, Modal } from "reactstrap";

import Circle from 'components/markup/loading/Circle';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import A from 'components/markup/links/A'

import  _connect from '_functions/connect';

import AdminLock from 'views/dashboard/pages/_components/AdminLock'

const DevelopmentSimulator = ({company}) => {

    const [showModal, setShowModal] = useState(null);
    const [loading, setLoading] = useState(null);
    const [err, setErr] = useState(null);
    const [payload, setPayload] = useState(`{"contact_id": "123456789"}`);
    const [response, setResponse] = useState(null);
    const [integration, setIntegration] = useState(company.integrations ? company.integrations[0] : {});

    const sendRequest = useCallback(async () => {
        setErr(null)

        let data;

        const _err = `An Error occurred, please check the api_endpoint and that a valid JSON payload was sent.`;

        try {
            data = JSON.parse(payload)
        } catch(e) {
            return setErr(_err)
        }

        setLoading(true)
        const request = await _connect.sendPayStub(integration.name, data)
        setLoading(false);

        if(request.data && request.data.status) {
            setResponse(request.data)
        } else {
            setErr(_err)
        }

    }, [integration.name, payload])

    const getFormattedResponse = useCallback((response) => {
        try {
            return JSON.stringify(response, null, 2)
        } catch(e) {}
        try {
            return response.toString();
        } catch(e) {}

        return response;
    }, [])

    const onIntegrationChange = useCallback((e) => {
        const foundIntegration = company.integrations.find(i => i.name === e.target.value)
        if(foundIntegration) setIntegration(foundIntegration)
    }, [company.integrations])

    if(!company.integrations || (company.integrations && !company.integrations.length)) {
        return (
            <div className="alert alert-info text-white text-center">
                To Enable Integrations On Your Account Please Contact john@zapreports.com.
            </div>
        )
    }


    return (

        <AdminLock>

            <Container fluid>

                <Helmet>
                    <title>Company Settings</title>
                    <meta name="description" content="Company Settings" />
                </Helmet>

                <DashHeaderOpen 
                    title={<span>Development</span>}
                    breadcrumb_1={(<Badge color="success"><i className="fas fa-info mr-2 " /> BETA</Badge>)}
                    breadcrumb_2="Simulator"
                    breadcrumb_3={(
                        <span onClick={() => setShowModal(true)} className="cursor-pointer">
                            <i className="fas fa-info-circle text-info mr-2" />
                            What Is This?
                        </span>
                    )}
                    icon="fas fa-building"
                />

                <Card>
                    <Row className="archk-internal-sidebar">
                        <div className='col-auto' style={{width: 200}}>
                            <div className="py-2 px-3">

                                <FormGroup>
                                    <label className="form-control-label">Integration To Test</label>
                                    <Input 
                                        type="select"
                                        style={{borderRadius: 0}}
                                        value={integration.name}
                                        onChange={onIntegrationChange}
                                    >
                                        {company.integrations.map((integration, i) => (
                                            <option key={i} value={integration.name}>{integration.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                
                                <FormGroup>
                                    <label className="form-control-label">JSON Payload</label>
                                    <Input 
                                        type="textarea"
                                        style={{borderRadius: 0}}
                                        value={payload}
                                        onChange={(e) => setPayload(e.target.value)}
                                    />
                                </FormGroup>

                            </div>
                        </div>

                        <Col className="border-left">
                            <CardHeader>
                                <p className="text-sm mb-0">
                                    Endpoint: {' '}
                                    {integration.fields ? integration.fields.api_endpoint : 'Not Set Up'}
                                </p>
                            </CardHeader>

                            <CardBody style={{minHeight: 200}}>
                                {loading ? (
                                    <Circle />
                                ) : err ? (
                                    <p className="text-sm mb-0 text-warning">{err}</p>
                                ) : response ? (
                                    <div>
                                        <p className="text-sm mb-0 text-info">Response From Endpoint:</p>
                                        <pre>{getFormattedResponse(response)}</pre>
                                    </div>
                                ) :
                                    <p className="text-sm mb-0">Press "Send Request" below to simulate a document being sent to your servers.</p>
                                }
                            </CardBody>

                            <CardFooter className="text-right">
                                <button onClick={sendRequest} className="btn btn-success"><i className="fas fa-paper-plane mr-2 " /> Send Request</button>
                            </CardFooter>

                        </Col>

                    </Row>
                </Card>
              
            </Container>

            <Modal
                    className="modal-dialog-centered"
                    isOpen={showModal}
                    toggle={() => setShowModal(false)}
                    size="lg"
                >
        
                    <div className="modal-header">
                        <h5 className="modal-title">API Simulator</h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowModal(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
        
                    <div className="modal-body">
                        <p className="text-sm">The API simulator can be used to send a test document of to your servers using the endpoint setup via webhooks.</p>
                        <p className="text-sm">You can add a JSON payload to send over test values along with the document.</p>
                        <p className="text-sm mb-0">
                            <A href={`${keys.SITE_URL}/docs`} className="text-info">View Webhook Documentation</A>
                        </p>
                    </div>
        
                    <div className="modal-footer text-right">
                        <button className="btn btn-outline-warning" onClick={() => setShowModal(false)}>
                            <i className="fas fa-arrow-left mr-2" />
                            Close
                        </button>
                    </div>
        
                </Modal>

        </AdminLock>

    );
}

const mapStateToProps = state => {
    return {
        company: state.company.company,
    };
};

export default connect(mapStateToProps, '')(DevelopmentSimulator);
