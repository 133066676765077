import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import { connect } from 'react-redux';
import keys from 'keys';
import A from 'components/markup/links/A'
import { formatCredits } from 'utils/currency';
import { getCompanyLink } from 'utils/helpers';

import { showTutorial, closeTutorial } from 'store/functions/tutorial'

import ModalSearch from 'components/functional/modals/Search'

import { useLocation } from 'react-router-dom';

import ModalCreate from '../../../../pages/contacts/ModalCreate'

import ModalToggler from 'components/functional/modals/Toggler'

import { getUrlParameter } from "utils/urls";


const NavbarDesktop = ({company, notifications}) => {

    const location = useLocation();
    const [tutorial, setTutorial] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const formattedCredits = company.credits ? formatCredits(company.credits) : 0;
    const maxPortalLength = 50

    const companyLink = getCompanyLink(company)

    let portalURL = `${keys.REPORT_URL}/${companyLink}`;
    let portalLink = portalURL;

    if(portalURL.length > maxPortalLength) {
        portalURL = portalURL.slice(0, 26) + '.....' + portalURL.slice(portalURL.length - (maxPortalLength - 26), portalURL.length)
    }

    useEffect(() => {
        const p = location.pathname;

        let tutorial = false;

        if(p === '/dashboard/home') {
            tutorial = '/dashboard/home';
        } else if(p === '/dashboard/contacts') {
            tutorial = '/dashboard/contacts';
        } else if(p.includes('/dashboard/contacts/')) {
            tutorial = '/dashboard/contacts/view';
        } else if(p === '/dashboard/documents') {
            tutorial = '/dashboard/documents';
        }  
        
        if(window.location.href.includes('/dashboard/home?o=true')) {
            showTutorial('TUTORIAL_START')
        }

        if(!tutorial) closeTutorial();
        setTutorial(tutorial)
    }, [location.pathname])

    return (

        <Container id="desktop-navbar-top" fluid>

            <div className="header">

                <div className="header-body">
                    <Row className="align-items-center pt-4 pb-3">

                        <Col lg="8" >
                            <ul>
                                <li>
                                    <A href={portalLink} className="navbar-portal-link" ><i className="fas fa-link mr-2 text-info " /> {portalURL}</A>
                                </li>
                                <li className="ml-4">
                                    <Badge 
                                        color={formattedCredits > 5 ? 'success' : formattedCredits ? 'warning' : 'danger'} 
                                        className="px-5"
                                    >
                                        Credits: 
                                        <b className="ml-3">{formattedCredits}</b>
                                    </Badge>
                                </li>
                                {tutorial ? (
                                    <li className="ml-4">
                                        <i id="archk-tutorial-icon" onClick={() => showTutorial(tutorial)} className="cursor-pointer fas fa-info-circle text-info p-2" style={{position: 'relative', top: 2}} /> 
                                    </li>
                                ) : null}
                                
                            </ul>
                           
                        </Col>

                        <Col lg="4"  className="text-right">

                            <ul className="navbar-icons">

                                <li>
                                    <Link to="/dashboard/notifications/all" className="circle-wrapper z-depth-2">
                                        <i className="fas fa-comments " />
                                        <span className="circle-wrapper-marker z-depth-1">{notifications > 99 ? '99+' : notifications}</span>
                                    </Link>
                                </li>
                               
                            </ul>

                            <Nav className="align-items-center ml-auto ml-md-0 pl-5 "  navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                        Company <i className="fas fa-caret-down ml-2 " />
                                    </DropdownToggle>

                                    <DropdownMenu right>
                                        <div className="dropdown-content">
                                            <DropdownItem  className="noti-title" header tag="div">
                                                <h6 className="text-overflow m-0">My Account</h6>
                                            </DropdownItem>
                                                    
                                            <DropdownItem onClick={() => setShowModal(true)} tag="div" className="text-body">
                                                <i className="fas fa-search " />
                                                <span>Search Providers</span>
                                            </DropdownItem>

                                            <DropdownItem tag={Link} to="/dashboard/settings" >
                                                <i className="ni ni-single-02" />
                                                <span>Settings</span>
                                            </DropdownItem>

                                            <DropdownItem tag={Link} to="/auth/logout" >
                                                <i className="ni ni-user-run" />
                                                <span>Logout</span>
                                            </DropdownItem>
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </Nav>

                        </Col>


                    </Row>
                </div>

            </div>

            <div className="pb-4 border-bottom text-center">
                <Row>
                    <Col lg={6} >
                        <ModalToggler component={ModalCreate} showModal={getUrlParameter('invite') === 'individual' ? true : false}>
                            <button id="archk-add-individual" className="btn btn-success btn-lg btn-block py-4 ">
                                <span className="display-4"><i className="fas fa-user-plus mr-2 " /> Add New Client</span>
                            </button>
                        </ModalToggler>
                    </Col>
                    <Col lg={6} className="text-right">
                        <ModalToggler component={ModalCreate} showModal={getUrlParameter('invite') === 'joint' ? true : false} joint={true}>
                            <button id="archk-add-joint" className="btn btn-info btn-lg btn-block py-4 ">
                                <span className="display-4">
                                    <i className="fas fa-user-friends mr--3" />
                                    <i className="fas fa-plus mr-2 " style={{fontSize: 16, position: 'relative', top: -8}} />  
                                    Add Joint Client
                                </span>
                            </button>
                        </ModalToggler>
                    </Col>
                </Row>
            </div>

            <ModalSearch 
                showModal={showModal}
                toggleModal={() => setShowModal(false)}
            />

        </Container>

    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(NavbarDesktop);
