import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, CardBody, CardHeader, CardTitle } from "reactstrap";

import { Link, useHistory } from 'react-router-dom';
import _forms from '_functions/forms';
import { onDownloadDocument } from 'utils/documents';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import Circle from 'components/markup/loading/Circle'

import moment from 'moment';

const FormSubmissionsViewRouter = ({match}) => {

    const history = useHistory();

    const [state, setState] = useState({});
    const { answers, submissions, contact, form } = state;

    const fetchData = useCallback(async () => {
        const data = await _forms.getContactAnswers(match.params.form_id, match.params.contact_id)
        setState({...data.data, loaded: true})
    }, [match.params.form_id, match.params.contact_id])


    useEffect(() => {
        fetchData();
    }, [fetchData])

    if(!answers) return <div className="py-6"><Circle /></div>

    if(!contact || !form || !answers || !submissions) {
        return (
            <Container fluid>
                <div className="alert alert-danger text-center"><i className="fas fa-exclamation-triangle mr-2 " /> This submisson is unable to be viewed</div>
            </Container>
        )
    }
  
    return (
        <Container fluid className="archk-form-answers-view">
    
            <Helmet>
                <title>{`All Forms`}</title>
                <meta name="description" content="Forms" />
            </Helmet>


            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Forms"
                breadcrumb_2={contact.display_name ? contact.display_name : contact.phone ? contact.phone : contact.email }
                icon="fas fa-comments"
                actionComponent={(
                    <button onClick={() => history.goBack()} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2" /> Back
                    </button>
                )}
            />

            <Card className="mb-4">    
                <CardHeader>
                    <CardTitle className="mb-0">{form.name}</CardTitle>
                </CardHeader>

                {submissions.length || answers.length ? (
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        Type
                                    </th>
                                    <th className="text-right">
                                        Updated/Submitted
                                    </th>
                                    <th className="text-right">
                                        View
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {submissions.length ? submissions.map((submission, i) => (
                                    <tr key={i}>
                                        <td>
                                            <Link 
                                                to={`/dashboard/forms/submissions/${submission._id}`}
                                                style={{minWidth: 70}}
                                            >
                                                Form Submission
                                            </Link>
                                        </td>

                                        <td className="text-right">{moment.unix(submission.submitted_at).format('MMM Do, YYYY - h:mm A')}</td>
                                        <td className="text-right">
                                            {submission.document ? (
                                                <button className="btn btn-outline-success" style={{minWidth: 70}} onClick={() => onDownloadDocument(submission.document)}>
                                                    <i className="fas fa-download mr2 " />
                                                </button>
                                            ) : (
                                                <span className="d-inline-block" style={{minWidth: 70}}></span>
                                            )}
                                            <Link 
                                                className="btn btn-outline-info" 
                                                to={`/dashboard/forms/submissions/${submission._id}`}
                                                style={{minWidth: 70}}
                                            >
                                                <i className="fas fa-eye " />
                                            </Link>
                                          
                                           
                                        </td>
                                    </tr>
                                )) : null}
                                {answers.length ? answers.map((answer, i) => (
                                    <tr key={i}>
                                        <td className="text-dark font-weight-bold">
                                        <Link 
                                            to={`/dashboard/forms/submissions/answers/${answer._id}`}
                                            style={{minWidth: 70}}
                                        >
                                            Current Answers ({answer.completion_percent}%)
                                            </Link>
                                        </td>
                                        <td className="text-right">{moment.unix(answer.updated_at).format('MMM Do, YYYY - h:mm A')}</td>
                                        <td className="text-right">
                                            <Link 
                                                className="btn btn-outline-info" 
                                                to={`/dashboard/forms/submissions/answers/${answer._id}`}
                                                style={{minWidth: 70}}
                                            >
                                                <i className="fas fa-eye " />
                                            </Link>
                                        </td>
                                    </tr>
                                )) : null}
                               
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <CardBody>
                        <p className="text-sm mb-0">There are no submissions for this contact.</p>
                    </CardBody>
                )}

            </Card>


        </Container>
    )

}

export default FormSubmissionsViewRouter;