import { memo, useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap';

const StepTracker = ({currentPage, onChangeStep, form, moveForward, moveBack}) => {

    const [steps, setSteps] = useState([]);

    const canChangeStep = onChangeStep && typeof onChangeStep === 'function'

    useEffect(() => {
        let _steps = [];

        for (let i = 0; i < form.pages; i++) {
            if(i +1 === form.pages) {
                _steps.push({name: 'Finish'})
            } else {
                _steps.push({name: `Page ${i + 1}`})
            }         
        }

        setSteps(_steps);
    }, [form.pages])

    useEffect(() => {
        console.log('render')
    }, [onChangeStep])

    

    return (
        <div className="py-4">

            <div className="border-bottom pb-4">
                <Container fluid>
                    <Row>
                        <Col xs={6} className="align-self-center">
                            <h2 className="my-0">{form.name}</h2>
                        </Col>
                        <Col xs={6} className="align-self-center text-right">
                            <small>Total Pages: {form.pages}</small>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className="step-tracker py-3">
                <ul className="tracker">

                    {steps.map((s, i) => (
                        <li key={i} className={currentPage > i + 1 ? 'finished' : currentPage === i + 1 ? ' current ' : null}>
                            <span 
                                className={canChangeStep ? "cursor-pointer wrapper" : "wrapper" }
                                onClick={() => {
                                    if(canChangeStep) {
                                        onChangeStep(i)
                                    }
                                }
                            }>
                                <span className="done"><i className="fas fa-check" /></span>
                                <span className="not-done">{i + 1}</span>
                                <span className="connection" />
                                <span className="finished-connection" />
                            </span>
                            <p className="font-weight-bold">{s.name}</p>
                        </li>
                    ))}

                </ul>
            </div>

            <div className="border-top pt-4">
                <Container fluid>
                    <Row>
                        <Col xs={6}>
                            {currentPage - 1 !== 0 ? (
                                <button onClick={moveBack} className="px-4 btn btn-info"><i className="fas fa-arrow-left mr-2" /> Go Back</button>
                            ) : null}
                        </Col>
                        <Col xs={6} className="text-right">
                            <button onClick={moveForward} className="px-4 btn btn-success">Next Page <i className="fas fa-arrow-right ml-2 " /></button>
                        </Col>
                    </Row>
                </Container>
            </div>
           
        </div>
    );

}

export default memo(StepTracker);