import PropTypes from 'prop-types';
import React from "react";
import ObjectSearchInput from 'components/functional/inputs/ObjectSearchInput'
import _contacts from '_functions/contacts';
import * as _ from 'underscore';
import formatObject from 'utils/formatObject';
import formatText from 'utils/formatText';

class ContactSearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            showResults: false,
            selected: {},
            dataToShow: [],
        }
       this.queryContactsThrottled = _.throttle(this.queryContacts, 750)
    }


    onSelect = (selected) => {

        this.setState({selected, filter: '', showResults: false})
        this.props.onSelect(selected)

    }

    queryContacts = async (value) => {

        const contacts = await _contacts.search({
            searchString: value,
            limit: 50,
        })

        if(contacts.success) {
            this.setState({dataToShow: contacts.data.documents, showResults: this.state.filter ? true : false})
        }

    }

    onChange = async (value) => {

        this.setState({filter: value})

        if(value) {
            this.queryContactsThrottled(value)
        } else {
            this.setState({filter: null, showResults: false})
        }

    }


    componentDidMount = () => {
        const { value } = this.props;
        this.setState({ selected: value ? value : {} });
        this.queryContacts();
    }

    render() {

        const { dataToShow, selected } = this.state
        const { titleFormatter, hideTitle } = this.props

        return (
            <ObjectSearchInput 
                data={dataToShow}
                onChange={this.onChange}
                hideTitle={hideTitle}
                onSelect={this.onSelect}
                placeholderFormatter={selected && selected._id ? selected.display_name : 'Type To Search'}
                titleFormatter={
                    titleFormatter ? titleFormatter(selected) : 
                        selected ? <span>Selected Contact: <b className="text-success text-capitalize">{selected.display_name}</b></span> : 
                        'Select Contact'
                }
                resultsFormatter={(result) => (
                    <li key={result._id} onClick={() => this.onSelect(result)}>
                        <p className="text-sm mb-0 text-capitalize">
                            <i className="fas fa-user mr-2" />
                            {formatObject(result).name() === 'Not Found' ? 'Name Not Found' : formatObject(result).name()}
                        </p>
                        {result.phone ? 
                            <p className="text-sm mb-0 text-muted">
                                <i className="fas fa-phone mr-2" />
                                {formatText(result.phone).phone()}
                            </p> 
                        : null}
                        {result.email ? 
                            <p className="text-sm mb-0 text-muted">
                                <i className="fas fa-envelope mr-2" />
                                {result.email} 
                            </p> 
                        : null }
                    </li>
                )}
            />
        );
    }
}

ContactSearchInput.propTypes = {
    onSelect        : PropTypes.func.isRequired,
    titleFormatter  : PropTypes.func,
    wrapperStyles   : PropTypes.object,
    formGroupStyles : PropTypes.object,
    value           : PropTypes.object,
    hideTitle       : PropTypes.bool,
    title           : PropTypes.string,
}

export default ContactSearchInput
