import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, Alert } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import { Link } from 'react-router-dom';
import _form_answers from '_functions/form_answers';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import formatText from 'utils/formatText';


const DocumentsAll = () => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: null, total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }

        const query = await _form_answers.search({ ...params, isCSV })
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    }), [sizePerPage])


    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "contact",
            headerStyle: {width: 400},
            text: "Contact",
            formatter: (cell, row) => (
                row.name === 'No Submissions Found' ? 'No Submissions Found' :
                row.form && row.contact ? (
                    <Link to={`/dashboard/forms/submissions/${row.contact._id}/${row.form._id}`}>
                        {row.contact.display_name ? row.contact.display_name : row.contact.email ? row.contact.email : formatText(row.contact.phone).phone()}
                    </Link>
                ) : (
                    <Link to={`/dashboard/forms/submissions/deleted_form/${row._id}`}>
                        Contact Not Found
                    </Link>
                )
            )
        },
        {
            dataField: "completion_percent",
            headerStyle: {width: 400},
            text: "%",
            formatter: (cell, row) => (
                row.name === 'No Submissions Found' ? '' :
                row.completion_percent === '100.00' ? <b className="text-success"><i className="fas fa-check mr-2 " /> {row.completion_percent + '%'}</b>
                : row.completion_percent + '%'
            )
        },
        {
            dataField: "form",
            headerStyle: {width: 400},
            text: "Form",
            formatter: (cell, row) => (
                row.name === 'No Submissions Found' ? '' :
                row.form ? (
                    <Link to={`/dashboard/forms/submissions/${row.contact._id}/${row.form._id}`}>
                        {row.form.name}
                    </Link>
                ) : (
                    <Link to={`/dashboard/forms/submissions/deleted_form/${row._id}`}>
                        Form Not Found
                    </Link>
                )
                
            )
        },

        {
            dataField: "_id",
            headerStyle: {textAlign: 'right'},
            text: "actions",
            headerFormatter: () => {
                return <div id="archk-tutorial-documents-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'No Submissions Found' ? '' :
                    <div className="text-right">
                         {/* <button className="btn btn-outline-danger" style={{minWidth: 70}} onClick={() => setFormToDelete(row)}>
                            <i className="fas fa-trash mr2 " />
                        </button> */}

                        {row.contact && row.form ? (
                            <Link 
                                className="btn btn-outline-success" 
                                to={`/dashboard/forms/submissions/${row.contact._id}/${row.form._id}`}
                                style={{minWidth: 70}}
                            >
                                <i className="fas fa-eye " />
                            </Link>
                        ) : (
                            '-'
                        )}
                    </div>
                )
            }
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Forms Submissions`}</title>
                <meta name="description" content="Form Submissions" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Form Submissions"
                breadcrumb_2="All"
                icon="fas fa-edit"
            />
    
            <Card className="card-color card-primary">    
                <Table
                    placeholder="Form Name"
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'No Submissions Found'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>
    
        </Container>
    )

}

export default DocumentsAll;