import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import keys from 'keys';

import { Modal, FormGroup, Input, Row, Col } from "reactstrap";

import { setCompany } from 'store/functions/company/company'
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import _payment_methods from '_functions/payment_methods'
import _companies from '_functions/companies'

import Circle from 'components/markup/loading/Circle';

const ModalUpdateCard = ({showModal, toggleModal, company}) => {

    const [billingAddress1, setBillingAddress1]       = useState(company.billing_address_line_1 || '');
    const [billingAddress2, setBillingAddress2]       = useState(company.billing_address_line_2 || '');
    const [billingCity, setBillingCity]               = useState(company.billing_city || '');
    const [billingState, setBillingState]             = useState(company.billing_state || '');
    const [billingPostalCode, setBillingPostalCode]   = useState(company.billing_postal_code || '');

    const [iframeLoading, setIframeLoading] = useState(false);
    const [paymentIframe, setPaymentIframe] = useState(false);
    const [errs, setErrs] = useState([]);
    const [addressErrs, setAddressErrs] = useState([]);

    const onAddCard = useCallback(() => {

        const _errs = [];

        setErrs([]);

        toggleStandardLoader(true);

        paymentIframe.encrypt().success( async function (res) {

            const updated = await _payment_methods.create({
                billing_postal_code: billingPostalCode,
                card_id: res.eToken,
                card_last_four: res.masked.number.slice(-4),
                card_expiration: res.masked.expy.slice(0, 2) + '/' + res.masked.expy.slice(2, 4)
            })

            if(updated.success) {
                toggleAlertBS(false, `Card information updated successfully.`);
                setCompany();
                toggleModal();
            } else {
                 toggleAlertBS(true, `Card Declined, Please Try Again.`);
            }

            toggleStandardLoader(false);

        }).failure( function (err) {
            toggleStandardLoader(false);

            _errs.push(err.message)
            setErrs(_errs)

        }).invalidInput( function (data) {
            toggleStandardLoader(false);

            for ( var i = 0; i < data.invalidInputs.length; i++ ){
                const err = data.invalidInputs[i];
                _errs.push(err.field);
            }

            setErrs(_errs)

        });

    }, [paymentIframe, toggleModal, billingPostalCode])

    const onSave = useCallback(() => {

        const _addressErrs = [];

        setErrs([]);
        setAddressErrs([]);

        if(!billingAddress1) _addressErrs.push('billingAddress1');
        if(!billingCity) _addressErrs.push('billingCity');
        if(!billingState) _addressErrs.push('billingState');
        if(!billingPostalCode) _addressErrs.push('billingPostalCode');

        if(_addressErrs.length) return setAddressErrs(_addressErrs);

        _companies.update(company._id, {
            billing_address_line_1: billingAddress1,
            billing_address_line_2: billingAddress2,
            billing_city: billingCity,
            billing_state: billingState,
            billing_postal_code: billingPostalCode,
        })

        onAddCard();

    }, [billingAddress1, billingCity, billingState, billingPostalCode, company._id, billingAddress2, onAddCard])

    useEffect(() => {

        if(!showModal) {
            setErrs([])
            setIframeLoading(true)
            return setPaymentIframe(null)
        }

        const loadFrame = (tries = 0) => {
            const div = document.getElementById('iframe_container')
            if(!div) {
                if(tries > 50) return;
                return setTimeout(() => loadFrame(tries + 1), 250)
            }

            try {

                const iframe = new window.PaymentiFrame({
                    create: true,
                    iframeId: "payment_iframe",
                    settings: {
                        account           : keys.BLUE_FIN_ACCOUNT_ID,
                        parentId          : "iframe_container",
                        lang              : "en",
                        cvv               : "required",
                        width             : "100%",
                        height            : "125px",
                        showFrame         : false,
                        devServer         : keys.BLUE_FIN_API_URL,
                        onload            : () => setIframeLoading(false),
                        numberLabel       : 1,
                        expyLabel         : 1,
                        cvvLabel          : 9,
                        layout            : 'layout_2',
                        inputWidth        : '20em',
                        inputStyle        : 1,
                        labelFontSize     : 'font_size_14',
                        labelFontFamily   : 'font_family_14',
                        labelFontColor    : '#abcd12',
                        inputFontSize     : 'font_size_14',
                        inputFontFamily   : 'font_family_14',
                        inputFontColor    : '#cc6633',
                        number_label      : 'Card Number ss',
                        expy              : 'double_input',
                        css: {

                            "class_label"  : "font-weight: 300; display: block; margin-bottom: .24rem; color: #525f7f; font-size: 0.9625rem; font-weight: 600; font-family: Helvetica",
                            "class_label:focus"  : "color: red",

                            "class_input_box": "display: block; width: 100%; box-sizing: border-box; padding: 0.3rem 0.3rem; line-height: 1.5; color: #8898aa; border: 1px solid #dee2e6; box-shadow: 0 3px 2px rgb(233 236 239 / 5%); font-size: 0.935rem; background: #f6f9fc; border-radius: 16px; padding-left: 16px; padding-right: 16px; transition: all .3s;",
                            "class_input_box:focus" : "border: solid 1px #fb6340; outline: none; border-radius: 0px",

                            "id_expy_input_month"  : "display: block; width: 48%; float: left; box-sizing: border-box; padding: 0.3rem 0.3rem; line-height: 1.5; color: #8898aa; border: 1px solid #dee2e6; box-shadow: 0 3px 2px rgb(233 236 239 / 5%); font-size: 0.935rem; background: #f6f9fc; border-radius: 16px; padding-left: 16px; padding-right: 16px; transition: all .3s;",
                            "id_expy_input_month:focus" : "border: solid 1px #fb6340; outline: none; border-radius: 0px",
                            "id_expy_input_month::placeholder"  : "color: #adb5bd",

                            "id_expy_input_year"   : "display: block; width: 48%; float: right; box-sizing: border-box; padding: 0.3rem 0.3rem; line-height: 1.5; color: #8898aa; border: 1px solid #dee2e6; box-shadow: 0 3px 2px rgb(233 236 239 / 5%); font-size: 0.935rem; background: #f6f9fc; border-radius: 16px; padding-left: 16px; padding-right: 16px; transition: all .3s;",
                            "id_expy_input_year:focus" : "border: solid 1px #fb6340; outline: none; border-radius: 0px",
                            "id_expy_input_year::placeholder"  : "color: #adb5bd",

                            "id_number_input::placeholder"  : "color: #adb5bd",
                            "id_cvv_input::placeholder"  : "color: #adb5bd",

                            "id_number_row": "margin-bottom: .6rem",

                            "id_expy_row"  : "width: 65%;float: left;",

                            "id_cvv_row"  : "width: 32%; float: right",

                        },

                        text     : {
                            number : {
                                label       : "Card Number *",
                                placeholder : "1111 2222 3333 4444"
                            },
                            cvv : {
                                label       : "CVV *",
                                placeholder : "123"
                            },
                            expy_double : {
                                label         : "Expires *",
                                placeholder_1 : "MM",
                                placeholder_2 : "YY"
                            }
                        },

                    }
                });

                setPaymentIframe(iframe);
                setIframeLoading(false)

            } catch(e) {
                toggleModal(false);
                toggleAlertBS(true, 'Please try again later.')
            }

        }

        loadFrame()

        return () => {
            const iframe = document.getElementById('iframe_container')
            iframe.innerHTML = ''
        }
        
    }, [showModal, toggleModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Update Payment Method</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary border-bottom">
                <p className="text-sm  mb-0">
                    You may use the form below to add or update your default card on file at any time.
                    {company.card_last_four ? <span> The card you currently have on file ends in <b className="text-underline">{company.card_last_four}</b>.</span> : null}
                </p>
            </div>

            <div className="modal-body">
                <div className="py-3">

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <label className="form-control-label">Address Line 1*</label>
                                <Input 
                                    type="text"
                                    value={billingAddress1}
                                    onChange={(e) => setBillingAddress1(e.target.value)}
                                    invalid={addressErrs.includes('billingAddress1')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <label className="form-control-label">Address Line 2*</label>
                                <Input 
                                    type="text"
                                    value={billingAddress2}
                                    onChange={(e) => setBillingAddress2(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-control-label">City*</label>
                                <Input 
                                    type="text"
                                    value={billingCity}
                                    onChange={(e) => setBillingCity(e.target.value)}
                                    invalid={addressErrs.includes('billingCity')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-control-label">State*</label>
                                <Input 
                                    type="text"
                                    value={billingState}
                                    onChange={(e) => setBillingState(e.target.value)}
                                    invalid={addressErrs.includes('billingState')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-control-label">Zip Code*</label>
                                <Input 
                                    type="text"
                                    value={billingPostalCode}
                                    onChange={(e) => setBillingPostalCode(e.target.value)}
                                    invalid={addressErrs.includes('billingPostalCode')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                   
                </div>
            </div>    

            <div className="modal-body border-top">
                <div className="py-3">
                    {iframeLoading ? <div className="py-6"><Circle /></div> : null}
                    <div id="iframe_container" />
                </div>

            </div>

            <div className="modal-body bg-secondary border-top">
                <p className="text-sm  mb-0">By adding a card above you authorize Zap Reports to use that card for future credit purchases and transaction made from the <b>Auto Reload</b> feature if enabled on your account.</p>
            </div>

            {errs.length ? (
                <div className="modal-body border-top">
                    {errs.map((err, i) => (
                    <p key={i} className="text-sm mb-0 text-danger">
                        {
                            err === 'number' ? `* Please check your card number.` : 
                            err === 'expy'   ? `* Please check your card's expiration date.` :
                            err === 'cvv'    ? `* Please check your card's CVV.` : 
                            err
                        }
                    </p>

                    ))}
                </div>
            ) : null}
            
            {/* <div className="modal-footer">
                <button onClick={onPurchase} className="btn btn-success">Add Card</button>
            </div> */}
            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left " /> Back
                </button>
                <button onClick={onSave} className="btn btn-success">
                    <i className="fas fa-plus mr-2 " /> Add Card
                </button>
            </div>

        </Modal>

    )

}

ModalUpdateCard.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
    company       : PropTypes.object.isRequired,
}

export default ModalUpdateCard