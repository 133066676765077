// import AllmandLaw from './AllmandLaw';
// import Intuidesk from './Intuidesk';
import Clio from './Clio';
import Zoho from './Zoho/index';

const CompanyIntegrations = ({company, setCompany}) => (
    <>
        {/* <Intuidesk
            company={company}
            setCompany={setCompany}
        />

        <AllmandLaw 
            company={company} 
            setCompany={setCompany} 
        /> */}

        <div className="alert alert-info mb-0 text-center">
            <i className="fas fa-info-circle mr-2 " /> 
            For assistance setting up a Zap Reports integration please reach out to john@zapreports.com 
        </div>

        <Clio 
            company={company} 
            setCompany={setCompany} 
        />
        <Zoho 
            company={company} 
            setCompany={setCompany} 
        />
    </>
)

export default CompanyIntegrations;
