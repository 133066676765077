import ReactQuill from 'react-quill';

import { useState, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardFooter, FormGroup, Input } from 'reactstrap';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _companies from '_functions/companies';

import InternalSidebar from 'components/markup/layout/InternalSidebar'

const emailLength = 500;


const Email = ({value, onChange}) => {

    const reactQuillRef = useRef();

    const checkCharacterCount = (event) => {
        const state = reactQuillRef.current.state;
        if (state.value.length > emailLength && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    return (
        <ReactQuill 
            onKeyDown={checkCharacterCount}
            value={value}
            ref={reactQuillRef}
            onChange={onChange} 
            theme="snow"
            modules={{
                toolbar: [
                    ["bold", "italic"],
                ]
            }}
        />
    )
}
       
const CompanyMessaging =  ({company, setCompany}) => {

    const defaultEmailFirst = `<p style="margin: 0">${company.name} has invited you to gather your documents in just a few clicks! Please copy your access code and click the link below to be done in minutes.</p>`;
    const defaultEmailReminder = `<p style="margin: 0">This is a reminder that your account with ${company.name} to gather your documents is still pending completion. Please copy your access code and click the link below to be done in minutes.</p>`;
    const defaultPhoneFirst = `${company.name} has invited you to gather your documents in just a few clicks! `;
    const defaultPhoneReminder = `This is a reminder that your account with ${company.name} to gather your documents is still pending completion. `;

    const [tab, setTab] = useState(0)

    const [emailFirst, setEmailFirst] = useState(
        company.messaging && company.messaging.email_first ? company.messaging.email_first : 
        defaultEmailFirst
    );
    const [emailReminder, setEmailReminder] = useState(
        company.messaging && company.messaging.email_reminder ? company.messaging.email_reminder : 
        defaultEmailReminder 
    );

    const [phoneFirst, setPhoneFirst] = useState(
        company.messaging && company.messaging.phone_first ? company.messaging.phone_first : 
        defaultPhoneFirst
    );
    const [phoneReminder, setPhoneReminder] = useState(
        company.messaging && company.messaging.phone_reminder ? company.messaging.phone_reminder : 
        defaultPhoneReminder
    );

    const onSave = async () => {

        if(!phoneFirst) return toggleAlertBS('info', `Invitation - Text cannot be left blank`);
        if(!phoneReminder) return toggleAlertBS('info', `Reminder - Text cannot be left blank`);

        if(phoneFirst.length < 10)

        toggleStandardLoader(true);
        const updated = await _companies.setMessaging(company._id, {
            email_first       : emailFirst,
            email_reminder    : emailReminder,
            phone_first       : phoneFirst,
            phone_reminder    : phoneReminder,
        })
        toggleStandardLoader(false);

        if(updated.success) {
            setCompany();
            toggleAlertBS(false, `Messaging Updated Successfully`)
        } else {
            toggleAlertBS(true, `Something's Not Right, Please Try Again`)
        }

    }

    return (
        <Card>

            <InternalSidebar 
                tab={tab}
                onClick={(route, tab) => setTab(tab)}
                routes={[
                    {name: 'Invitation - Email'},
                    {name: 'Invitation - Text'},
                    
                    {name: 'Reminder - Email'},
                    {name: 'Reminder - Text'},
                ]}
            >
                <div>

                
                    {tab === 0 ? (

                        <div>
                            <CardHeader>
                                <CardTitle className="mb-0">Invitation Email</CardTitle>
                            </CardHeader>

                            <CardHeader className="bg-secondary">
                                <p className="text-sm mb-0">
                                    This emails goes out to all contacts when a request for documents is sent to them. It will also contain their portal access code and information about the platform.
                                    <span onClick={() => setEmailFirst(defaultEmailFirst)} className="text-info cursor-pointer">Use Default Message</span>
                                </p>
                            </CardHeader>

                            <Email 
                                value={emailFirst}
                                onChange={setEmailFirst} 
                            />

                            <p className="text-sm mb-0 border-left pl-4">Max Email Length: {emailLength} Characters</p>

                        </div>
                    ) : tab === 1 ? (
                        <div>

                            <CardHeader>
                                <CardTitle className="mb-0">Invitation Text</CardTitle>
                            </CardHeader>

                            <CardHeader className="bg-secondary">
                                <p className="text-sm mb-0">
                                    This emails goes out to all contacts when a request for documents is sent to them. It will also contain their portal access code and information about the platform.
                                    <span onClick={() => setPhoneFirst(defaultPhoneFirst)} className="text-info cursor-pointer">Use Default Message</span>
                                </p>
                            </CardHeader>

                            <CardHeader>
                                <FormGroup>
                                    <Input type="text"
                                        value={phoneFirst}
                                        onChange={(e) => setPhoneFirst(e.target.value)}
                                    />
                                </FormGroup>
                            </CardHeader> 
                            </div>
                    ) : tab === 2 ? (

                        <div>
                            <CardHeader>
                                <CardTitle className="mb-0">Reminder Email</CardTitle>
                            </CardHeader>

                            <CardHeader className="bg-secondary">
                                <p className="text-sm mb-0">
                                    These emails go out to contacts who have not yet finished pulling their documents through Zap Reports. {' '}
                                    <span onClick={() => setEmailReminder(defaultEmailReminder)} className="text-info cursor-pointer">Use Default Message</span>
                                </p>
                            </CardHeader>

                            <Email 
                                value={emailReminder}
                                onChange={setEmailReminder} 
                            />

                            <p className="text-sm mb-0 border-left pl-4">Max Email Length: {emailLength} Characters</p>

                        </div>
                    ) : tab === 3 ? (
                        <div>

                            <CardHeader>
                                <CardTitle className="mb-0">Reminder Text</CardTitle>
                            </CardHeader>

                            <CardHeader className="bg-secondary">
                                <p className="text-sm mb-0">
                                    These text messages go out to contacts who have not yet finished pulling their documents through Zap Reports. {' '}
                                    <span onClick={() => setPhoneReminder(defaultPhoneReminder)} className="text-info cursor-pointer">Use Default Message</span>
                                </p>
                            </CardHeader>

                            <CardHeader>
                                <FormGroup>
                                    <Input type="text"
                                        maxLength={120}
                                        value={phoneReminder}
                                        onChange={(e) => setPhoneReminder(e.target.value)}
                                    />
                                </FormGroup>
                            </CardHeader> 
                            </div>
                    ) : null}
                    
                    <CardFooter className="text-right">
                        <button className="btn btn-success" onClick={onSave}>
                            <i className="fas fa-save mr-2 " /> Save
                        </button>
                    </CardFooter>

                </div>
            </InternalSidebar>

        </Card>
    )

}

export default CompanyMessaging;
