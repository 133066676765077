import React, { useState, useCallback, useEffect } from 'react';
import { CardHeader, CardBody, CardFooter, CardTitle, FormGroup, Input } from 'reactstrap';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _companies from '_functions/companies';

const SettingsInfoBilling = ({company, setCompany}) => {

    const [apiErr, setApiErr] = useState(0);
    const [contactPays, setContactPays] = useState(company.contact_pays)
    const [canChangePayType, setCanChangePayType] = useState(company.can_change_pay_type)

    const onUpdate = async () => {
        toggleStandardLoader(true)

        const updated = await _companies.update(company._id, {
            contact_pays: contactPays,
            can_change_pay_type: canChangePayType,
        })
        if(updated.success) {
            toggleAlertBS(false, 'Company settings updated successfully.')
            setCompany()
        } else {
            setApiErr(updated.message)
        }

        toggleStandardLoader(false)
    }

    const onContactPaysChange = useCallback((e) => {
        const value = e.target.value;

        let contact_pays = false
        if(value === "1") contact_pays = true;

        setContactPays(contact_pays)
    }, [])

    const onPayTypeChange = useCallback((e) => {
        const value = e.target.value;

        let can_change_pay_type = false
        if(value === "1") can_change_pay_type = true;

        setCanChangePayType(can_change_pay_type)
    }, [])

    useEffect(() => {
        const _company = JSON.parse(JSON.stringify(company));
        setContactPays(_company.contact_pays)
    }, [company])


    return (
        <div className="border-left">

            <CardHeader>
                <CardTitle className="mb-0">Default Billing</CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary">
                <p className="text-sm">The below option allows you to set your default setting for billing. </p>
                <p className="text-sm">If the below option is set to "yes" then, by default, when a request for documents is sent out the contact receiving the request will be required to pay the access price to Zap Reports in full before they can begin pulling their documents.</p>
                <p className="text-sm mb-0">If not, a deduction of credits will be used one they begin connecting their accounts.</p>
            </CardHeader>

            <CardBody>

                <FormGroup>
                    <label className="form-control-label">Pass Cost Along To Contacts</label>
                    <Input
                        type="select"
                        onChange={onContactPaysChange}
                        value={contactPays === true || contactPays === '1' ? '1' : 0}
                    >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </Input>
                </FormGroup>
                
                <FormGroup>
                    <label className="form-control-label">Staff Can Change Billing Type</label>
                    <Input
                        type="select"
                        onChange={onPayTypeChange}
                        value={canChangePayType === true || canChangePayType === '1' ? '1' : 0}
                    >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </Input>
                </FormGroup>


            </CardBody>
            
            {apiErr ? (
                <CardFooter>
                    {apiErr.map((e, i) => <p key={i} className="text-sm text-danger font-weight-bold mb-0">{e}</p>)}
                </CardFooter>
            ) : null}

            <CardFooter className="text-right">
                <button onClick={onUpdate} className="btn btn-success">
                    <i className="fas fa-save mr-2 " /> Save
                </button>
            </CardFooter>
        
        </div>

    )
}

export default SettingsInfoBilling;