import React from "react";
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom'

import keys from 'keys'

const NavbarMobile = (props) => (
    <div className="bg-white z-depth-3 py-3 mb-4">
        <Container>
            <Row>

                <Col xs={6} className="align-self-center">
                    <Link to="/dashboard/home">
                        <img src={keys.LOGO} style={{width: 200}} alt="logo" />
                    </Link>
                </Col>
                
                <Col xs={6} className="align-self-center text-right ml-auto">
                    <div className="ml-auto">
                        <div onClick={props.toggleSidenav} >
                            <div className="sidenav-toggler-inner ml-auto">
                                <i className="sidenav-toggler-line" />
                                <i className="sidenav-toggler-line" />
                                <i className="sidenav-toggler-line" />
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
        </Container>
    </div>
)

export default NavbarMobile;
