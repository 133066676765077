const styles = {
    icon: {
        position: 'absolute',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        padding: 10,
        top: -40,
        right: 0,
        width: 80,
        height: 80,
        zIndex: 1
    }
}

export default styles;