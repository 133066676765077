import React from "react";
import { connect } from 'react-redux';
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

import { toggleAlertBS } from 'store/functions/system/system';

import keys from 'keys';

import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Badge } from "reactstrap";

import _auth from '_functions/auth';

class Sidebar extends React.Component {
  
    state = {
        collapseOpen: false,
        sso: null,
        showSSO: false,
        routes: [

            //   {
            //     collapse: true,
            //     name: "Maps",
            //     icon: "ni ni-map-big text-primary",
            //     state: "mapsCollapse",
            //     views: [
            //       {
            //         path: "/google",
            //         name: "Google",
            //         layout: "/admin"
            //       },
            //       {
            //         path: "/vector",
            //         name: "Vector",
            //         layout: "/admin"
            //       }
            //     ]
            //   },
        
            // {
            //     path: "/dashboard/reports",
            //     name: "Reports",
            //     icon: "fas fa-file-upload text-green",
            //     counter: 57
            // },
        
        
            { divider: 'My Dashboard' },
        
            {
                path: "/dashboard/home",
                name: "Home",
                icon: "fas fa-home text-green",
            },
            {
                path: "/dashboard/contacts",
                name: "Clients",
                icon: "fas fa-users text-green",
            },
          
            {
                path: "/dashboard/documents",
                name: "Documents",
                icon: "fas fa-file text-green",
            },

            {
                path: "/dashboard/upload/csv",
                name: "Bulk Import",
                icon: "fas fa-user-plus text-green",
            },
         
            { divider: 'My Account' },
        
            {
                path: "/dashboard/settings",
                name: "Profile",
                icon: "fas fa-cogs text-warning",
            },
        
            {
                path: "/auth/logout",
                name: "Logout",
                icon: "fas fa-sign-out-alt text-warning",
            },
                    
        ]
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    // toggles collapse between opened and closed (true/false)
    toggleCollapse = () => {
        this.setState({ collapseOpen: !this.state.collapseOpen });
    };
    // closes the collapse
    closeCollapse = () => {
        this.setState({ collapseOpen: false });
    };
    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }

    // this is used on mobile devices, when a user navigates
    // the sidebar will autoclose
    closeSidenav = () => {
        if (window.innerWidth < 1000) {
            this.props.toggleSidenav();
        }
    };

    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = routes => {
        return routes.map((prop, key) => {

            if(prop.divider) return <li key={key} className="divider"> <h6 className="navbar-heading p-0 mb-0 text-muted">{prop.divider}</h6> </li>

            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !this.state[prop.state];
                return (
                    <NavItem key={key}>
                        <NavLink
                            href="#blank"
                            data-toggle="collapse"
                            aria-expanded={this.state[prop.state]}
                            className={classnames({ active: this.getCollapseInitialState(prop.views) })}
                            onClick={e => { e.preventDefault(); this.setState(st); }}
                        >
                            {prop.icon ? <i className={prop.icon} /> : null}
                            <span className="nav-link-text">{prop.name}</span>
                        </NavLink>
                        <Collapse isOpen={this.state[prop.state]}>
                            <Nav className="nav-sm flex-column">
                                {this.createLinks(prop.views)}
                            </Nav>
                        </Collapse>
                    </NavItem>
                );
            }

            return (
                <NavItem
                    className={this.activeRoute(prop.path)}
                    key={key}
                >
                    <NavLink
                        to={prop.path}
                        activeClassName="active"
                        onClick={this.closeSidenav}
                        tag={NavLinkRRD}
                    >
                        {prop.icon !== undefined ? (
                            <><i className={prop.icon} /> <span className="nav-link-text">{prop.name}</span></>
                        ) : (
                            prop.name
                        )}
                        {prop.counter && (
                            <span className="ml-auto">
                                <Badge style={{width: 28}} color={prop.counter < 10 ? 'success' : prop.counter < 99 ? 'warning' : 'danger'}>
                                    {prop.counter >= 99 ? '99+' : prop.counter}
                                </Badge>
                            </span>
                        )}
                    </NavLink>
                </NavItem>
            );
        });
    };

    ssoSignOn = async (company) => {
        const signedOn = await _auth.sso.signOn({company});
        if(signedOn.success) {
            window.location.reload();
        } else {
            toggleAlertBS(true, 'Unauthorized Access');
        }
    }

    fetchSSOCompanies = async () => {
        const sso = await _auth.sso.fetch();
        if(sso.data) this.setState({sso: sso.data})
    }

    componentDidMount = () => {
        const { viewing_user, company } = this.props;
        
        let routes = [
            { divider: 'My Dashboard' },
            {
                path: "/dashboard/home",
                name: "Home",
                icon: "fas fa-home text-green",
            },
            {
                path: "/dashboard/contacts",
                name: "Clients",
                icon: "fas fa-users text-green",
            },
          
            {
                path: "/dashboard/documents",
                name: "Documents",
                icon: "fas fa-file text-green",
            },

            {
                path: "/dashboard/upload/csv",
                name: "Bulk Import",
                icon: "fas fa-user-plus text-green",
            },
        ];

        if(company.forms_enabled) {
            routes = routes.concat([
                { divider: 'Forms' },
                {
                    path: "/dashboard/forms/all",
                    name: "Forms",
                    icon: "fas fa-edit text-success",
                },
                {
                    path: "/dashboard/forms/submissions",
                    name: "Submissions",
                    icon: "fas fa-user text-success",
                }
            ])
        } else {
            routes = routes.concat([
                { divider: 'Forms' },
                {
                    path: "/dashboard/forms/access",
                    name: "Forms",
                    icon: "fas fa-edit text-success",
                }
            ])
        }

        if(company.signing_enabled) {
            routes = routes.concat([
                { divider: 'Signing' },
                {
                    path: "/editor/signing/new",
                    name: "Send",
                    icon: "fas fa-plus text-success",
                },
                {
                    path: "/dashboard/signing/templates/all",
                    name: "Templates",
                    icon: "fas fa-file-alt text-success",
                },
                {
                    path: "/dashboard/signing/documents/all",
                    name: "Signed",
                    icon: "fas fa-signature text-success",
                }
            ])
        } else {
            routes = routes.concat([
                { divider: 'Signing' },
                {
                    path: "/dashboard/signing/access",
                    name: "Send",
                    icon: "fas fa-plus text-success",
                }
            ])
        }

        if(company.reviews_enabled) {
            routes = routes.concat([
                { divider: 'Reviews' },
                {
                    path: "/dashboard/reviews/all",
                    name: "Links",
                    icon: "fas fa-star text-yellow",
                },
            ])
        } else {
            routes = routes.concat([
                { divider: 'Reviews' },
                {
                    path: "/dashboard/reviews/access",
                    name: "Links",
                    icon: "fas fa-star text-yellow",
                },
            ])
        }

        if(viewing_user.is_admin) {
            routes = routes.concat([
                { divider: 'Company' },
                {
                    path: "/dashboard/users",
                    name: "Users",
                    icon: "fas fa-user text-primary",
                },
                {
                    path: "/dashboard/company/settings",
                    name: "Settings",
                    icon: "fas fa-building text-primary",
                },
                {
                    path: "/dashboard/company/billing",
                    name: "Billing",
                    icon: "fas fa-dollar-sign text-primary",
                }
            ])  
        }

        routes = routes.concat([
            { divider: 'My Account' },
        
            {
                path: "/dashboard/settings",
                name: "Profile",
                icon: "fas fa-cogs text-warning",
            },
        
            {
                path: "/auth/logout",
                name: "Logout",
                icon: "fas fa-sign-out-alt text-warning",
            },
                    
        ])

        this.getCollapseStates(routes);
        this.setState({routes})

        if(viewing_user.sso_enabled) {
            this.fetchSSOCompanies()
        }

    }
  
    render() {

        const { sidenavOpen } = this.props;
        const { routes, sso, showSSO } = this.state;

        const scrollBarInner = (
            <div className="scrollbar-inner">
                <div className="sidenav-header d-flex align-items-center">

                    <NavbarBrand to="/dashboard" tag={Link} >
                        <img alt={"Logo"} className="navbar-brand-img" src={keys.LOGO} />
                    </NavbarBrand>

                    <div className="ml-auto">
                        <Link to="/dashboard">
                            <div
                                className={classnames("sidenav-toggler d-xl-block", {active: sidenavOpen })}
                                // onClick={this.props.toggleSidenav}
                            >
                            {/* <div className="sidenav-toggler-inner">
                                <i className="sidenav-toggler-line" />
                                <i className="sidenav-toggler-line" />
                                <i className="sidenav-toggler-line" />
                            </div> */}
                            </div>
                        </Link>
                    </div>

                </div>

                <div className="navbar-inner">
                    <Collapse navbar isOpen={true}>
                        <Nav navbar>
                            {this.createLinks(routes)}
                            {sso ? (
                                <>
                                <li className="divider"> 
                                    <h6 className="navbar-heading p-0 mb-0 text-muted">ADMIN</h6> 
                                </li>
                                <NavItem>
                                    <NavLink
                                        href="#blank"
                                        data-toggle="collapse"
                                        className=""
                                        onClick={e => { e.preventDefault(); this.setState({showSSO: !showSSO}); }}
                                    >
                                        SSO
                                    </NavLink>
                                    <Collapse isOpen={showSSO}>
                                        <Nav className="nav-sm flex-column">
                                            {sso.map((company, i) => (
                                                <NavItem key={i} >
                                                    <NavLink
                                                        to=""
                                                        active={false}
                                                        activeClassName="null"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.ssoSignOn(company._id)
                                                        }}
                                                        tag={NavLinkRRD}
                                                    >
                                                        {company.name}
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                        </Nav>
                                    </Collapse>
                                </NavItem>
                                </>
                            ) : null}
                        </Nav>
                    </Collapse>
                </div>
            </div>
        );

        return (
            <Navbar
                className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white z-depth-1"
            >
                {navigator.platform.indexOf("Win") > -1 ? (
                    <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
                ) : (
                    scrollBarInner
                )}
        </Navbar>
        );
    }

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Sidebar);
