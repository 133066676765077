import Analyze from './Analyze'

const AnalyzePageDownload = ({match}) => (
    <Analyze 
        isSingle={true}
        isDownload={true}
        contactId={match.params.contact_id}
    />
)

export default AnalyzePageDownload;