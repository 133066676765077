import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle } from "reactstrap";
import { connect } from 'react-redux';
import { onDownloadDocument } from 'utils/documents';


const DashboardForms = ({info, device}) => {

    return (
        <Card className="mt-4" id="archk-tutorial-submissions">
            <CardHeader>
                <CardTitle className="mb-0">Recent Form Submissions</CardTitle>
            </CardHeader>
            <div className="table-responsive">
                {device.info.isMD ? (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Contact</th>
                            <th>Form</th>
                            <th className="text-right">Submitted</th>
                            <th className="text-right">View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {info.formSubmissions.length ? info.formSubmissions.map(submission => (
                            <tr key={submission._id}>
                                <td className="text-capitalize">
                                    {submission.contact ? (
                                        <Link to={`/dashboard/contacts/${submission.contact._id}`}>
                                            {submission.contact.display_name}
                                        </Link>
                                    ) : 'Contact Not FOund'}
                                </td>
                                <td>
                                    {submission.form ? submission.form.name : 'null'}
                                </td>
                                <td className="text-right">
                                    {moment.unix(submission.created_at).format('MMM Do, YYYY h:mmA')}
                                </td>

                                <td className="text-right">
                                    {submission.document ? (
                                        <button style={{minWidth: 70}} onClick={() => onDownloadDocument(submission.document)} className="btn btn-outline-success">
                                            <i className="fas fa-download mr-2" />
                                        </button>
                                    ) : null}
                                    
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Form Submissions</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                ) : (
                <table className="table" style={{tableLayout: 'fixed'}}>
                    <thead>
                        <tr>
                            <th>Contact</th>
                            <th>Form</th>
                            <th className="text-right">View</th>
                        </tr>
                    </thead>
                    <tbody>
                    {info.formSubmissions.length ? info.formSubmissions.map(submission => (
                            <tr key={submission._id}>
                                <td className="text-capitalize">
                                    {submission.contact ? (
                                        <Link to={`/dashboard/contacts/${submission.contact._id}`}>
                                            {submission.contact.display_name}
                                        </Link>
                                    ) : 'Contact Not FOund'}
                                </td>
                                <td>
                                    {submission.form ? submission.form.name : 'null'}
                                </td>

                                <td className="text-right">
                                    {submission.document ? (
                                        <button style={{minWidth: 70}} onClick={() => onDownloadDocument(submission.document)} className="btn btn-outline-success">
                                            <i className="fas fa-download mr-2" />
                                        </button>
                                    ) : null}
                                    
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Form Submissions</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                )}
            </div>
        </Card>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(DashboardForms);
