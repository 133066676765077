import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Row, Col } from "reactstrap";

import moment from 'moment'

import _pinwheel_analytics from '_functions/pinwheel_analytics'
import { formatCurrency } from 'utils/currency'

import Circle from 'components/markup/loading/Circle'
import DatePicker from 'react-datepicker'


const ModalPayStubAnalysis = ({showModal, toggleModal, contact, analysis}) => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState({});
    const [err, setErr] = useState(false);
    const [month, setMonth] = useState(new Date());
    const [stubList, setStubList] = useState([]);
    const [selectedStub, setSelectedStub] = useState(null);
    const [overview, setOverview] = useState(null);

   
    const fetchData = useCallback(async (_date, stub, _setStubList) => {
        setLoading(true);
        
        const date = _date ? _date : new Date();
        const stubId = stub ? stub._id : null;

        const result = await _pinwheel_analytics.analyze(analysis._id, moment(date).format('YYYY-MM-DD'), stubId);
        if(result.data) {
            setData(result.data);
            if(_setStubList) {
                setStubList(result.data.stubsAnalyzed)
                setOverview(result.data)
            }
            setLoading(false)
        } else {
            setErr(true);
        }
    }, [analysis._id])

    const onSetSelectedStub = useCallback((_date, stub) => {
        setSelectedStub(stub);
        fetchData(_date, stub);
    }, [fetchData])

    const onSetMonth = useCallback((_date) => {
        fetchData(_date, null, true)
        setSelectedStub(null)
        setMonth(_date)
    }, [fetchData])

    const renderCategory = useCallback((category) => {
        return Object.keys(category).map(key => {
            const value = category[key];
            if(!value || key.includes('_percent')) return null;
            return (
                <tr key={key}>
                    <td className="text-capitalize px-3">{key.replace(/_/g, ' ')}</td>
                    <td style={{width: 150}} className="text-right px-3">{category[key + '_percent']}%</td>
                    <td style={{width: 150}} className="text-right px-3">{formatCurrency(value / 100)}</td>
                </tr>
            )
        })
    }, [])

    // when closing reset modal
    useEffect(() => {
        if(!showModal && data) {
            setData(null)
            setLoading(false)
            setErr(false)
            setMonth(new Date())
            setSelectedStub(null)
        }
    }, [showModal, data])

    useEffect(() => {
        if(data || !showModal) return;
        return fetchData(null, null, true);
    }, [data, showModal, fetchData])

    return (
        <Modal
            className="modal-dialog-centered modal-pay-stub-analyze"
            isOpen={showModal}
            toggle={toggleModal}
            size={err ? 'md' : 'lg'}
        >

            <div className="modal-header">
                <h5 className="modal-title">
                    {contact.display_name}{' - '}
                    {analysis.document.name}{' - '}
                    {moment.unix(analysis.document.finished_at).format('MM/DD/YY')}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {err ? (
                <div className="alert alert-warning text-center mb-0">Please Try Again In A Few Minutes</div>
            ) : !data ? (
                <div className="py-6"><Circle /></div>
            ) : (
                 <div className="modal-body">

                    <Row>

                        <div className="col-auto border-right" style={{width: 200}}>
                            <div className="section border-bottom">
                                <FormGroup className="">
                                    <h4 className="position-relative">
                                        Filing Date
                                        {loading ? <span style={{position: 'absolute', right: 0, top: 27, zIndex: 10}}><Circle /></span> : null}
                                    </h4>
                                    <DatePicker
                                        selected={month}
                                        onChange={onSetMonth}                                    
                                        style={{textAlign: 'center'}}

                                        popperPlacement='bottom end'
                                        popperModifiers={{
                                            preventOverflow: {
                                                enabled: true,
                                            },
                                        }}
                                    />
                                </FormGroup>
                            </div>

                            <div className="section">
                                <h4>Pay Stub List</h4>

                                <ul className="mb- pl-0">
                                    <li onClick={() => onSetSelectedStub(month, null)} className={!selectedStub ? "active list-style-none" : "list-style-none"}>
                                        <small><b>Six Month Overview</b></small>
                                    </li>
                                    {stubList.map(stub => (
                                        <li 
                                            key={stub._id}
                                            onClick={() => onSetSelectedStub(month, stub)}  
                                            className={selectedStub && selectedStub._id === stub._id ? "active list-style-none" : "list-style-none"}
                                        >
                                            <small>
                                            {stub.pay_date} - ${(stub.gross_pay_amount / 100).toFixed(2)}
                                            {stub.id === analysis.recommended ? <i className="fas fa-star text-info ml-3 " /> : null}
                                            </small>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <Col>
                            <div className="section">
                                {selectedStub && selectedStub.id === analysis.recommended ? (
                                    <div className="border-bottom mb-3  ">
                                        <div className="border-success border-radius-50 px-4 py-2 mb-3 text-success mb-2">
                                            <i className="fas fa-check mr-2 " /> Suggested Average Pay Stub
                                        </div>
                                    </div>
                                ) : null}

                                <h4>{selectedStub ? <span>Pay Stub For: <span className="float-right">{selectedStub.pay_date}</span></span> : 'Six Month Overview'}</h4>

                                <div className="border">

                                    <table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th className="px-3">Earnings</th>
                                                <th className="text-right px-3">Percent</th>
                                                <th className="text-right px-3">Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {renderCategory(data.categoryEarnings)}
                                            <tr>
                                                <td className="px-3"><b>Total</b></td>
                                                <td></td>
                                                <td className="text-right">{formatCurrency(data.totalEarnings / 100)}</td>
                                            </tr>
                                        </tbody>

                                        <thead>
                                            <tr>
                                                <th className="px-3">Deductions</th>
                                                <th className="text-right px-3"></th>
                                                <th className="text-right px-3"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderCategory(data.categoryDeductions)}
                                            <tr>
                                                <td className="px-3"><b>Total</b></td>
                                                <td></td>
                                                <td className="text-right">{formatCurrency(data.totalDeductions / 100)}</td>
                                            </tr>
                                        </tbody>

                                        <thead>
                                            <tr>
                                                <th className="px-3">Taxes</th>
                                                <th className="text-right px-3"></th>
                                                <th className="text-right px-3"></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {renderCategory(data.categoryTaxes)}
                                            <tr>
                                                <td className="px-3"><b>Total</b></td>
                                                <td></td>
                                                <td className="text-right">{formatCurrency(data.totalTaxes / 100)}</td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th className="px-3">Net</th>
                                                <th className="text-right px-3"></th>
                                                <th className="text-right px-3"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="px-3"><b>Total</b></td>
                                                <td></td>
                                                <td className="text-right">{formatCurrency((data.totalEarnings - data.totalTaxes - data.totalDeductions) / 100)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {overview ? (
                                <div className="section">

                                    <div className="border">

                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Average 6 Months Pay</th>
                                                    <th className="text-right">Value</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td className="px-3"><b>Monthly</b></td>
                                                    <td className="text-right">{formatCurrency((overview.totalEarnings / 100) / 6)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="px-3"><b>Semi Monthly</b></td>
                                                    <td className="text-right">{formatCurrency((overview.totalEarnings / 100) / 12)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="px-3"><b>Bi Weekly</b></td>
                                                    <td className="text-right">{formatCurrency(((overview.totalEarnings / 100) * 2) / 26)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="px-3"><b>Weekly</b></td>
                                                    <td className="text-right">{formatCurrency(((overview.totalEarnings / 100) * 2) / 52)}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    {!data.hasLastMonthStubs && !selectedStub && !loading ? (
                                        <div className="alert alert-info my-3">
                                            <i className="fas fa-info-circle mr-2 " />
                                            Pay Stub history may not go back 6 months.
                                        </div>
                                    ) : !data.hasRecentMonthStubs && !selectedStub && !loading ? (
                                        <div className="alert alert-info my-3">
                                            <i className="fas fa-info-circle mr-2 " />
                                            Pay Stub history may not have recent months stubs for future filing date.
                                        </div>
                                    ) : null}

                                </div>
                            ) : null}


                        </Col>
                    </Row>
                </div>
            )}
           
            <div className="modal-footer text-right">
                <button className="btn btn-outline-warning" onClick={toggleModal}>Close</button>
            </div>

        </Modal>

    )

}

ModalPayStubAnalysis.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalPayStubAnalysis
