import { useEffect, useState, useCallback } from "react";
import _auth from '_functions/auth';

import Dots from 'components/markup/loading/Dots'

import { connect } from 'react-redux';
import { getCompanyLink } from 'utils/helpers';

const logout_bg_color = 'white'

const Logout = ({company}) => {

    const [error, setError] = useState(false)

    const runLogout = useCallback(async () => {
        const logout = await _auth.logout()

        let redirect = '';
        if(company && company.name) {
            redirect = `/${getCompanyLink(company)}`
        }

        if(logout.success) {
            window.location.href = `/auth/login${redirect}`;
        } else {
            setError(logout.message)
        }
    }, [company])

    useEffect(() => {
        runLogout();
    }, [runLogout])

    return (
        <div className="text-center d-flex position-fixed top-0 bottom-0 right-0 left-0" style={styles.bg}>
           {error ? (
               <div className="align-self-center w-100">
                   <h2>{error}</h2>
               </div>
           ): (
               <h3 className="font-weight-bold text-success text-center w-100 py-4">Logging You Out<Dots /></h3>
           )}
        </div>
    );

}

const styles = {

    bg: {
        background: logout_bg_color,
        zIndex: 99999999999,
    },

}

const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(Logout);