import { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import ConfirmationModal from 'components/functional/modals/Confirmation';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import formatText from 'utils/formatText';
import _contacts from '_functions/contacts';
import _credit_events from '_functions/credit_events';
// contact_pays
const SendRequestModal = ({showModal, toggleModal, onConfirm, contact, company}) => {

    const [contactPurchases, setContactPurchases] = useState(company.contact_pays);
    const [loading, setLoading] = useState(false);

    const onSetContactPays = useCallback(async (bool) => {
        setContactPurchases(bool)
        setLoading(true)
        const updated = await _contacts.setBilling(contact._id, {contact_purchases: bool})

        setLoading(false)

        if(!updated.success) {
            toggleModal();  
            toggleAlertBS(true, `Something's not right, please refresh your page and try again.`)
        }

    }, [contact._id, toggleModal])

    const onConfirmPressed = async () => {

        toggleStandardLoader(true);

        if(!contactPurchases) {
            const check = await _credit_events.check(contact._id, 'pinwheel');
            if(!check.data || (check.data && !check.data.allowed)) {
                toggleAlertBS('info', `Your account does not have enough credits to send a ${contact.partner ? 'joint ' : ''} request for documents.`)
                toggleStandardLoader(false);
                return toggleModal();
            }
        }
        
        const sent = await _contacts.request(contact._id);

        toggleStandardLoader(false);

        if(sent.success) {
            toggleAlertBS(false, `Portal Invitations Were Successfully Sent.`);

            // if populated partner request from partner
            if(contact.partner && contact.partner._id) {
                _contacts.request(contact.partner._id);
            // if unpopulated partner request from partner
            } else if(contact.partner) {
                _contacts.request(contact.partner);
            }
        } else {
            toggleAlertBS(true, `Something Went Wrong Send The Portal Invites.`)
        }

        if(onConfirm) onConfirm();

    }

    const canAdjustBilling = useCallback((_contact) => {
        if(_contact.contact_purchased_at) return false;
        if(_contact.mx_first_pulled_at) return false;
        if(_contact.yodlee_first_pulled_at) return false;
        if(_contact.pinwheel_first_pulled_at) return false;

        if(_contact.partner) {
            if(_contact.partner.contact_purchased_at) return false;
            if(_contact.partner.mx_first_pulled_at) return false;
            if(_contact.partner.yodlee_first_pulled_at) return false;
            if(_contact.partner.pinwheel_first_pulled_at) return false;
        }

        return true;
    }, [])

    useEffect(() => {
        if(!showModal) {
            setContactPurchases(company.contact_pays)
        } else {
            onSetContactPays(company.contact_pays)
        }
    }, [showModal, company.contact_pays, onSetContactPays])

    return (

        <ConfirmationModal 
            loading={loading}
            showModal={showModal}
            toggleModal={toggleModal}
            onConfirmation={onConfirmPressed}
            title="Send Request For Documents"
            body={(
                <span>

                    {!contact.given_name || !contact.family_name ? (
                        <div className="mb-4">
                            <i className="fas fa-exclamation-triangle mr-2 text-warning " /> This contact does not have both a first and last name on file. If possible add these fields before proceeding.
                        </div>
                    ) : null}

                    {!contact.email && !contact.phone ? (
                        <div>
                             <i className="fas fa-exclamation-triangle mr-2 text-warning " /> This contact does not have an email or phone on file. No notifications will be sent if you proceed.
                        </div>
                    ) : (
                        <div>
                            Request notifications will be sent to: {' '}
                            {contact.email ? contact.email : ''}{' '}
                            {contact.phone ? contact.email ? ` and ${formatText(contact.phone).phone()}` : formatText(contact.phone).phone() : null}

                            {contact.partner ? (
                                <span> and to their partner on file.</span>
                            ) : '.'}
                        </div>
                    )}

                    {contact.report_status === 'null' || !contact.report_status ? (
                        <div className='p-3 mt-3 bg-secondary border'>
                            Note that after a request for documents has been sent this file cannot change from{' '}
                            <span className="text-dark text-underline font-weight-bold">
                                {contact.partner ? 'a joint request into and individual one.' : 'an individual request to a joint one.'}
                            </span>
                        </div>
                    ) : null}

                    {canAdjustBilling(contact) && company.can_change_pay_type ? (
                        <div className='p-3 mt-3 bg-secondary border'>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="confirm-terms-of-service"
                                    type="checkbox"
                                    defaultChecked={contactPurchases}
                                    onChange={(e) => onSetContactPays(!contactPurchases)}
                                />
                                <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                                    Pass Cost Along To Client <i className="fas fa-info-circle text-info ml-2" id="archk-contact-pays" />
                                    <Tooltip delay={0} placement="bottom" target="archk-contact-pays">
                                        If checked this contact will be required to pay by debit card before they can begin pulling their documents. No credits will be deducted for their account. 
                                    </Tooltip>
                                </label>
                            </div>
                        </div>
                    ) : null}
                    
                    
                </span>
            )}
        />
    );

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(SendRequestModal);