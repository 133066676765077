/*
Documentation

prop "query" function
is given the following params inside an object
{ searchString, page, sizePerPage, skip, limit }

prop "downloadCSV" function
is given the following params inside an object
{ searchString, page, sizePerPage }

prop "onSelect" function
is given a single param which is an object of all rows currently selected

*/

import PropTypes from 'prop-types'
import Circle from 'components/markup/loading/Circle';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Input, Row, Col } from "reactstrap";
import * as _ from 'underscore';

import LoadingTable from 'components/markup/loading/Table.js'

export const downloadCSV = (data, options) => {
    if(!options) options = {}
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + data;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${options.name ? options.name : 'export'}.csv`;
    hiddenElement.click();
}

class Table extends React.Component {

    state = {
        searchString    : '',
        page            : 1,
        sizePerPage     : this.props.sizePerPage,
        checked         : {},
        selectedRows    : [],
        hasLoaded: false
    } 

    // show loading and tell the parent function to run a new query
    queryTableData = _.throttle(async (searchString, setPage1, customProperty) => {

        if(!customProperty) customProperty = 'searchString'
        this.setState({loading: true});

        const { sizePerPage } = this.state
        const page = setPage1 ? 1 : this.state.page
        const skip = (page - 1) * sizePerPage
        
        await this.props.query({ [customProperty]: searchString, page, sizePerPage, skip, limit: sizePerPage })
        this.setState({ loading: false, page, hasLoaded: true })

    }, 750)

    // grab relevant params and pass them back to the downloadCSV function
    downloadCSV = () => {
        const { searchString, page, sizePerPage } = this.state
        this.props.downloadCSV({ searchString, page, sizePerPage })
    }

    // handle table change events by query data on page or search change
    onTableChange = async (type, newState) => {
        if(type === 'search') {
            this.queryTableData(this.state.searchString, true)
        } else {
            this.setState({...newState}, () => { this.queryTableData(this.state.searchString) });
        }
    }

    onSelect = (isSelected, rows) => {
        
        const selectedRows = [...this.state.selectedRows]

        rows.forEach(row => {

            const index = selectedRows.findIndex(x => x._id === row._id)

            if(isSelected) {
                if( index === -1) selectedRows.push(row)
            } else {
                selectedRows.splice(index, 1)
            }

        })

        this.setState({selectedRows})
        this.props.onSelect(selectedRows)

    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // if document length changes and not from this component calling it via setting loading true
        // then we want to reset the page back to 1
        if(nextProps.totalDocuments !== undefined && this.props.totalDocuments !== undefined && nextProps.totalDocuments !== this.props.totalDocuments) {
            if(!this.state.loading) {
                this.setState({page: 1})
            }
        }
    }

    componentDidMount = () => {
        this.queryTableData()
    }

    render() {

        const { downloadCSV, data, totalDocuments, columns, onSelect, onDeletePressed, placeholder, hideSearch, description, tableClassNames, customSearch } = this.props;
        const { loading, page, sizePerPage, selectedRows, hasLoaded } = this.state;

        if(!columns) return <></>;

        return (

            <ToolkitProvider
                keyField="_id"
                data={data}
                columns={columns}
                search
            >
                {props => (
                    <>
                    {hideSearch === true && !onDeletePressed && !downloadCSV ? null : (
                        <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >
                            <Row>
                                <Col md={6} className="align-self-center">
                                    {hideSearch !== true ? (
                                        customSearch ? customSearch((value, property) => {
                                            this.queryTableData(value, true, property)
                                        }) : (
                                            <label>
                                                Search:
                                                <Input
                                                    className="form-control"
                                                    value={this.state.searchString}
                                                    placeholder={placeholder ? placeholder : '...'}
                                                    style={{width: 190}}
                                                    onChange={(e) => {
                                                        this.setState({searchString: e.target.value})
                                                        this.queryTableData(e.target.value, true)
                                                    }}
                                                />
                                                
                                                {loading && (
                                                    <span className="d-inline-block">
                                                        <Circle style={{display: 'inline-block', top: -1, position: 'absolute' }} />
                                                    </span>
                                                )}
                                            </label>
                                        )
                                    ) : null}

                                    {description ? description : null}
                                </Col>
                                <Col md={6} className="text-right align-self-center">
                                
                                    {onDeletePressed ? (
                                        <button onClick={() => onDeletePressed(selectedRows)} className="btn btn-outline-danger">
                                            <i className="fas fa-trash mr-2 " /> Delete
                                        </button>
                                    ) : null}
                                    {downloadCSV ? (
                                        <button onClick={this.downloadCSV} className="btn btn-outline-success">
                                            <i className="fas fa-file mr-2 " /> Export CSV
                                        </button>
                                    ) : null}
                                </Col>
                            </Row>
                        </div>
                    )}

                    {!hasLoaded ? (
                        <LoadingTable />
                    ) : (

                        <div className={`table-not-fixed table-responsive  table-vertical-align ${onSelect ? 'table-select' : ''} ${tableClassNames} `}>
                            <BootstrapTable
                                {...this.props}
                                {...props.baseProps}
                                selectRow={!onSelect ? undefined : { 
                                    mode: 'checkbox',
                                    classes: 'checkbox',
                                    onSelect:    (row, isSelect) => this.onSelect(isSelect, [row]),
                                    onSelectAll: (isSelect, rows) => this.onSelect(isSelect, rows)
                                }}
                                
                                pagination={paginationFactory({
                                    page,
                                    sizePerPage,
                                    totalSize: totalDocuments,
                                    alwaysShowAllBtns: true,
                                    showTotal: true,
                                    withFirstAndLast: true,
                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                        <div className="dataTables_length" id="datatable-basic_length">
                                            <label>
                                                Show{" "}
                                                <select
                                                    value={currSizePerPage}
                                                    name="datatable-basic_length"
                                                    aria-controls="datatable-basic"
                                                    className="form-control form-control-sm"
                                                    onChange={e => {
                                                        if(this.props.onSizePerPageChange) {
                                                            this.props.onSizePerPageChange(e.target.value)
                                                        }
                                                        onSizePerPageChange(e.target.value)
                                                    }}
                                                >
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                                {" entries. "}
                                                
                                            </label>
                                        </div>
                                    )
                                })}
                                bootstrap4={true}
                                bordered={false}
                                remote={{ search: true, pagination: true, sort: false, cellEdit: false }}
                                onTableChange={this.onTableChange}
                            />
                        </div>

                        )}
                    </>
                )}
            </ToolkitProvider>

        );
    }
}

Table.propTypes = {
    columns           : PropTypes.array.isRequired,
    query             : PropTypes.func.isRequired,
    sizePerPage       : PropTypes.number.isRequired,
    data              : PropTypes.array.isRequired,
    totalDocuments    : PropTypes.number.isRequired,
    downloadCSV       : PropTypes.func,
    onSelect          : PropTypes.func,
    onDeletePressed   : PropTypes.func,
    description       : PropTypes.element,
    placeholder       : PropTypes.string,
    hideSearch        : PropTypes.bool,
}

export default Table
