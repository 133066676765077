import { connect } from 'react-redux';

import { Card, CardHeader } from "reactstrap";

import formatText from 'utils/formatText';
import moment from 'moment';

import TableDocuments from 'views/dashboard/pages/_components/Tables/Documents';

import ModalBypassNameVerification from './ModalBypassNameVerification'
import ModalToggler from 'components/functional/modals/Toggler'


const ContactsOverview = ({contact, documents, fetchData, device}) => {
  
    return (

        <Card>

            <CardHeader className="p-0" id="archk-tutorial-contacts-view-info">
                <div className="table-responsive">

                    {device.info.isMD ? (
                        <table className="table">

                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th className="text-right">Created</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>

                                <td className="text-capitalize td-pre">
                                    {contact.display_name ? (
                                        <div>
                                            <ModalToggler component={ModalBypassNameVerification} contact={contact} fetchData={fetchData}>
                                                <i className={`fas fa-id-card mr-2 cursor-pointer ${contact.bypass_name_verification ? 'text-success' : 'text-info'} `} /> 
                                            </ModalToggler>
                                            {contact.display_name}
                                        </div>
                                    ) : (
                                        <span className="text-warning font-weight-bold">Name Not Given</span>
                                    )}
                                </td>

                                <td className="td-pre">
                                    <i className="fas fa-envelope mr-2 text-success " /> 
                                    {contact.email ? contact.email : '-'}
                                </td>

                                <td className="td-pre">
                                    <i className="fas fa-mobile mr-2 text-success " /> 
                                    {contact.phone ? formatText(contact.phone).phone() : '-'}
                                </td>


                                <td className="text-right td-pre">
                                    {moment.unix(contact.created_at).format('MM/DD/YYYY h:mm A')}
                                </td>

                            </tr>
                        </tbody>

                    </table>
                    ) : (
                    <table className="table">
                        <tbody>
                            
                            <tr>
                                <td style={styles.mobileTD}>Name</td>
                                <td className="text-capitalize td-pre">
                                    {contact.display_name ? (
                                        <div>
                                            <i className="fas fa-user mr-2 text-success " /> 
                                            {contact.display_name}
                                        </div>
                                    ) : (
                                        <span className="text-warning font-weight-bold">Name Not Given</span>
                                    )}
                                </td>
                            </tr>

                            <tr>
                                <td style={styles.mobileTD}>Email</td>
                                <td className="td-pre">
                                    <i className="fas fa-envelope mr-2 text-success " /> 
                                    {contact.email ? contact.email : '-'}
                                </td>
                            </tr>

                            <tr>
                                <td style={styles.mobileTD}>Phone</td>
                                <td className="td-pre">
                                    <i className="fas fa-mobile mr-2 text-success " /> 
                                    {contact.phone ? formatText(contact.phone).phone() : '-'}
                                </td>
                            </tr>


                            <tr>
                                <td style={styles.mobileTD}>Created</td>
                                <td className="text-right td-pre">
                                    {moment.unix(contact.created_at).format('MM/DD/YYYY h:mm A')}
                                </td>
                            </tr>

                        </tbody>

                    </table>
                    )}
                    
                </div>
            </CardHeader>
      
            <CardHeader className="p-0" id="archk-tutorial-contacts-view-documents">
                <TableDocuments documents={documents} refresh={fetchData} downloadAll={true} />
            </CardHeader>

        </Card>

    );

}

const styles = {
    mobileTD: {
        width: 100,
        maxWidth: 100
    }
}


const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(ContactsOverview);