/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    SETTINGS: {},
    CREDITS: {},
    SALES_TAX: {},
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_LOADED:

            return {
                ...state,
                SETTINGS: action.payload.SETTINGS,
                CREDITS: action.payload.CREDITS,
                SALES_TAX: action.payload.SALES_TAX,
            }

       
        default:

            return state;

    }

}

export default reducer;
