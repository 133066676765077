import { useState, useEffect, useCallback } from "react";
import { Helmet } from 'react-helmet';

import { Card, CardHeader, Row, Col, CardTitle } from "reactstrap";

import _credit_events from '_functions/credit_events';
import { formatMoney, formatCredits } from 'utils/currency';
import moment from 'moment'

import Circle from 'components/markup/loading/Circle';
import DatePicker from 'react-datepicker'

const Statements = ({setView}) => {

    const [month, setMonth] = useState(new Date());

    const [ data, setData ] = useState(null);
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(async (month) => {
        setData(null);
        const query = await _credit_events.getMonth(moment(month).toDate().toUTCString())        
        if(query.data) {
            setData(query.data)
        } else {
            setErr(true)
        }
    }, [])

    useEffect(() => [
        fetchData(month)
    ], [fetchData, month])

    if(err) return <div className="alert alert-danger">Something went wrong, please try again later.</div>
    if(!data) return <Circle />

    return (
        <>
    
            <Helmet>
                <title>{`Statements`}</title>
                <meta name="description" content="Statements" />
            </Helmet>

            <Card className="card-color card-primary table-fixed">

                <CardHeader>
                    <Row>
                        <Col xs={6} className="align-self-center">
                            <CardTitle className="mb-0">
                                All Statements
                                <button className="btn btn-info ml-3" onClick={() => setView(1)}>View History</button>
                            </CardTitle>
                        </Col>
                        <Col xs={6} className="align-self-center text-right">
                            <div className="statements-date-picker d-inline">
                                <DatePicker
                                    selected={month}
                                    onChange={setMonth}
                                    dateFormat="MMM yyyy"
                                    showMonthYearPicker
                                    style={{textAlign: 'center'}}
                                    portalId="portal-root"
                                    popperPlacement='bottom-end'
                                />
                            </div>
                        </Col>
                    </Row>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th className="text-right">Amount</th>
                                <th className="text-right">Credits</th>
                                <th className="text-right">USD</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Individual Financial</td>
                                <td className="text-right">{data.total_individual_financial}</td>
                                <td className="text-right">{formatCredits(data.credits_individual_financial)}</td>
                                <td className="text-right">{formatMoney(data.amount_individual_financial)}</td>
                            </tr>
                            <tr>
                                <td>Individual Employment</td>
                                <td className="text-right">{data.total_individual_pay_stubs}</td>
                                <td className="text-right">{formatCredits(data.credits_individual_pay_stubs)}</td>
                                <td className="text-right">{formatMoney(data.amount_individual_pay_stubs)}</td>
                            </tr>
                            <tr>
                                <td>Individual Packaged Financial</td>
                                <td className="text-right">{data.total_individual_package_financial}</td>
                                <td className="text-right">{formatCredits(data.credits_individual_package_financial)}</td>
                                <td className="text-right">{formatMoney(data.amount_individual_package_financial)}</td>
                            </tr>
                            <tr>
                                <td>Individual Packaged Financial</td>
                                <td className="text-right">{data.total_individual_package_pay_stubs}</td>
                                <td className="text-right">{formatCredits(data.credits_individual_package_pay_stubs)}</td>
                                <td className="text-right">{formatMoney(data.amount_individual_package_pay_stubs)}</td>
                            </tr>
                            <tr>
                                <td>Joint Financial</td>
                                <td className="text-right">{data.total_joint_financial}</td>
                                <td className="text-right">{formatCredits(data.credits_joint_financial)}</td>
                                <td className="text-right">{formatMoney(data.amount_joint_financial)}</td>
                            </tr>
                            <tr>
                                <td>Joint Pay Stubs</td>
                                <td className="text-right">{data.total_joint_pay_stubs}</td>
                                <td className="text-right">{formatCredits(data.credits_joint_pay_stubs)}</td>
                                <td className="text-right">{formatMoney(data.amount_joint_pay_stubs)}</td>
                            </tr>
                            <tr>
                                <td>Joint Packaged Financial</td>
                                <td className="text-right">{data.total_joint_package_financial}</td>
                                <td className="text-right">{formatCredits(data.credits_joint_package_financial)}</td>
                                <td className="text-right">{formatMoney(data.amount_joint_package_financial)}</td>
                            </tr>
                            <tr>
                                <td>Joint Packaged Financial</td>
                                <td className="text-right">{data.total_joint_package_pay_stubs}</td>
                                <td className="text-right">{formatCredits(data.credits_joint_package_pay_stubs)}</td>
                                <td className="text-right">{formatMoney(data.amount_joint_package_pay_stubs)}</td>
                            </tr>
                            <tr>
                                <td>Pay Stub Overages</td>
                                <td className="text-right">{data.total_pay_stub_overage}</td>
                                <td className="text-right">{formatCredits(data.credits_pay_stub_overage)}</td>
                                <td className="text-right">{formatMoney(data.amount_pay_stub_overage)}</td>
                            </tr>
                            <tr>
                                <td>Bank Statements</td>
                                <td className="text-right">{data.total_mx_statement + data.total_yodlee_statement}</td>
                                <td className="text-right">{formatCredits(data.credits_mx_statement + data.credits_yodlee_statement)}</td>
                                <td className="text-right">{formatMoney(data.amount_mx_statement + data.amount_yodlee_statement)}</td>
                            </tr>
                            <tr>
                                <td>TOTALS</td>
                                <td className="text-right"></td>
                                <td className="text-right">{formatCredits(data.credits)}</td>
                                <td className="text-right">{formatMoney(data.amount)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </Card>

        </>
    )

}

export default Statements
