import { useEffect, useState, useCallback } from 'react';
import { getUrlParameter, setUrlParameter } from 'utils/urls';

import _documents from '_functions/documents';
import _contacts from '_functions/contacts';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'

import Circle from 'components/markup/loading/Circle'

import Markup from './Markup'

const w = 563
const h = 750

const Analyze = ({isSingle, isDownload, contactId}) => {

    const [err, setErr] = useState(false);
    const [downloaded, setDownloaded] = useState(null);
    const [documentID, setDocumentID] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const [contact, setContact] = useState(null);

    const onDownloadSingle = useCallback(() => {
        setDownloaded(false)
        setTimeout(() => {

            const input = document.getElementById('to-download');

            const width = input.offsetWidth;
            const multiplier = w / input.offsetWidth;
            const newWidth = multiplier * width

            input.style.width = newWidth + 'px'
            input.style.height = (newWidth / w) *h + 'px'

            html2canvas(input, {width: w}).then((canvas) => {
                var width = canvas.width / 2;
                var height = canvas.height / 2;

                var imgData = canvas.toDataURL('image/png');
                var doc = new jsPDF("p", "pt", "letter", true);
                doc.deletePage(1);

                doc.addPage('p', 'pt', 'letter');
                doc.addImage(imgData, 'PNG', 40, 40, width - 40, height - 40);
                doc.save(`${contact ? contact.display_name : ''} Analysis.pdf`);
            
                setDownloaded(true)
            })

        }, 1500)
    }, [contact])

    const onDownloadAll = useCallback(() => {
          
        setDownloaded(false)

        setTimeout(() => {
         
            const containers = document.querySelectorAll('.print-wrapper')
            const withChildren = [];

            containers.forEach(c => {
                if(c.hasChildNodes()) withChildren.push(c)
            })

            let finished = []

            for (let i = 0; i < withChildren.length; i++) {
                const input = withChildren[i];

                const width = input.offsetWidth;
                const multiplier = w / input.offsetWidth;
                const newWidth = multiplier * width
    
                input.style.width = newWidth + 'px'
                input.style.height = (newWidth / w) *h + 'px'

                html2canvas(input, {width: w}).then((canvas) => {

                    finished.push(canvas);

                    if(finished.length === withChildren.length) {

                        var width = canvas.width / 2;
                        var height = canvas.height / 2;

                        var doc = new jsPDF("p", "pt", "letter", true);
                        doc.deletePage(1);
                        // doc.addPage([width + 80, height + 80], 'p ');
                        finished.forEach(f => {
                            var imgData = f.toDataURL('image/png');

                            doc.addPage('p', 'pt', 'letter');
                            doc.addImage(imgData, 'PNG', 40, 40, width - 40, height - 40);
                        })

                        doc.save(`${contact.display_name} Analysis.pdf`);
                    
                        setDownloaded(true)

                    }

                })                
            }

        }, 1500)
    }, [contact])

    const onDocumentIDChange = useCallback((e) => {
        const value = e.target.value;
        setDocumentID(value)
        setUrlParameter('document', value)
    }, [])

    const renderLoading = useCallback(() => <div className="py-6"><Circle /></div>, []);

    const load = useCallback(async () => {

        const values = await Promise.all([
            _contacts.findById(contactId),
            _documents.fetchAnalysis(contactId, true)
        ])

        const _contact = values[0].data;
        const _analytics = values[1].data;

        if(!_contact) return setErr('The contact you are looking for cannot be found.');
        if(!_analytics) return setErr('Please Try Again Later');

        setContact(_contact);
        setAnalytics(_analytics);

        if(isSingle) {
            const docToQuery = getUrlParameter('document');

            if(docToQuery) {
                setDocumentID(docToQuery)
            } else {
                setDocumentID(_analytics[0].document._id)
            }
        }

    }, [contactId, isSingle])

    useEffect(() => {
        if(isDownload) {
            const footerContainer = document.querySelector('#dashboard-footer .container-fluid')
            const mainContent = document.querySelector('.main-content')
            const sidebar = document.querySelector('.sidenav')
            const header = document.getElementById('desktop-navbar-top')
    
            if(sidebar) sidebar.style.display = 'none'
            if(header) header.style.display = 'none'
            if(mainContent) mainContent.classList.remove('content-wrapper')
            if(mainContent) mainContent.classList.add('m-auto')
            if(footerContainer) footerContainer.style.display = 'none'
    
            mainContent.style.width = w + 'px';
    
            return () => {
                if(sidebar) sidebar.style.display = 'block'
                if(header) header.style.display = 'block'
                if(mainContent) mainContent.classList.add('content-wrapper')
                if(mainContent) mainContent.classList.remove('m-auto')
                if(footerContainer) footerContainer.style.display = 'block'
                mainContent.style.width = '';
    
            }
        }
    }, [isDownload])

    useEffect(() => {
        if(!isDownload || !contact) return;

        if(isSingle)  {    
            onDownloadSingle();
        } else {
            onDownloadAll();
        }
    }, [isSingle, isDownload, contact, onDownloadSingle, onDownloadAll])

    useEffect(() => {
        load();
    }, [load])

    if(err) return (
        <div className="alert alert-danger  text-center">{err}</div>
    )

    if(!analytics || !contact) return renderLoading();
    if(isSingle && !documentID)  return renderLoading();


    return (
        <Markup 
            downloaded={downloaded}
            isDownload={isDownload}
            isSingle={isSingle}
            contact={contact}
            documentID={documentID}
            onDocumentIDChange={onDocumentIDChange}
            analytics={analytics}
            onDownloadAll={onDownloadAll}
            onDownloadSingle={onDownloadSingle}
        />
    );
}

export default Analyze