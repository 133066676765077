import { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import { Modal, Row, Col, Input, FormGroup } from "reactstrap";

import { setCompany } from 'store/functions/company/company'
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import { formatMoney, formatCredits } from 'utils/currency';

import _companies from '_functions/companies';
import _sales_tax from '_functions/sales_tax';

import LoadingModal from 'components/markup/loading/Modal';
import ErrorModal from 'components/markup/errors/Modal';

const ModalSetReloader = ({showModal, toggleModal, packageToReload, company, SETTINGS}) => {


    const [tax, setTax] = useState(null);
    const [taxErr, setTaxErr] = useState(false);
    const [threshold, setThreshold] = useState('10');
    const [checked, setChecked] = useState(false);
    const [checkedErr, setCheckedErr] = useState(false);

    const totalCredits = packageToReload.credits + packageToReload.free_credits;

    const getTax = useCallback(async () => {
        const tax = await _sales_tax.calculate(packageToReload._id)
        if(tax.data) {
            setTax(tax.data.taxAmount);
        } else {
            setTaxErr(true);
            toggleModal()
        }

    }, [packageToReload._id, toggleModal])

    const onPurchase = useCallback(async () => {

        if(!checked) {
            return setCheckedErr(true);
        } else {
            setCheckedErr(false)
        }

        toggleStandardLoader(true);
        const action = await _companies.setReloader(company._id, {
            reloader_package: packageToReload._id,
            reloader_threshold: parseInt(threshold)
        });

        if(action.success && action.data) {
            toggleAlertBS(false, `Auto Reloader Successfully Set`);
            setThreshold('10');
            toggleModal();
            setCompany();
        } else {

            if(action.message && action.message[0] === 'This package cannot be set to auto load credits') {
                toggleAlertBS(true, action.message[0]);
            } else {
                toggleAlertBS(true, `Please Try Again.`);
            }
        }

        toggleStandardLoader(false);

    }, [checked, toggleModal, packageToReload._id, threshold, company._id])

    useEffect(() => {
        if(showModal) {
            setChecked(false)
            setCheckedErr(false)
            setTaxErr(false)
            setTax(null)
            getTax()
        }
    }, [showModal, getTax]);

    if(taxErr) return <ErrorModal isOpen={!tax} />
    if(showModal && tax === null) return <LoadingModal isOpen={!tax} text="Calculating Tax" />

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Set Auto Recharge</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <Row>
                    <Col lg={6}>
                        <h4 className="mb-0"><b style={styles.divider}>Package:</b> {packageToReload.name}</h4>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Cost / Credit</b>
                            {formatMoney(((packageToReload.amount) / (totalCredits)) * SETTINGS.decimalMultiplier )}
                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Credits</b>
                            {formatCredits(packageToReload.credits)}
                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Free Credits</b>
                            {formatCredits(packageToReload.free_credits)}
                        </p>
                       
                    </Col>
                    <Col lg={6}>

                        <h4 className="mb-0"> <b style={styles.divider}>Amount:</b> {formatMoney(packageToReload.amount)}</h4>
                        
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Tax - {company.billing_state}</b>
                            {formatMoney(tax )}
                        </p>

                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Card On File</b>
                            ...1234
                        </p>
                        <p className="text-sm mb-0">
                            <b style={styles.divider}>Effective:</b>
                        </p>
                        <p className="text-sm mb-0">
                            When credits fall below <b>{threshold}.</b>
                        </p>

                    </Col>
                </Row>

            </div>

            <div className="modal-body border-top">

                <Row>
                    <Col lg={6}>
                        <h4 className="mb-0"> <b style={styles.divider}>Total Credits:</b> {formatCredits(totalCredits)}</h4>   
                    </Col>
                    <Col lg={6}>
                        <h4 className="mb-0"> <b style={styles.divider}>Total Cost:</b> {formatMoney(packageToReload.amount + tax)}</h4>   
                    </Col>
                </Row>

            </div>
            <div className="modal-body border-top">

                <FormGroup>
                    <label className="form-control-label">Auto Recharge Threshold</label>
                    <Input 
                        value={threshold}
                        type="select"
                        onChange={(e) => setThreshold(e.target.value)}
                    >
                        <option vale="10">10</option>
                        <option vale="25">25</option>
                        <option vale="50">50</option>
                    </Input>
                </FormGroup>

            </div>

            <div className="modal-body border-top bg-secondary">
                <p className="text-sm mb-0">By setting the above package to auto recharge your account will automatically purchase the credit package <b className="text-underline">{packageToReload.name}</b> for <b>{formatCredits(totalCredits)}</b> credits in the amount of <b>{formatMoney(packageToReload.amount + tax)} </b>whenever the credit balance on your account falls below the threshold of {threshold} credits.</p>
            </div>

            <div className="modal-body border-top bg-secondary">
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id="purchase-credits-checkbox"
                        type="checkbox"
                        onChange={() => setChecked(!checked)}
                        defaultChecked={checked}
                    />
                    <label className="custom-control-label" htmlFor="purchase-credits-checkbox">
                        I agree to the auto recharge terms above.
                    </label>
                </div>
                {checkedErr ? (
                    <p className="text-sm mb-0 text-warning font-weight-bold">
                        <i className="fas fa-arrow-up mr-2 " />
                        You must agree to the terms above before proceeding.
                    </p>
                ) : null}
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onPurchase}>
                    Set Auto Recharge
                </button>
            </div>

        </Modal>

    )

}

const styles = {
    divider: {
        display: 'inline-block',
        width: 110
    }
}

ModalSetReloader.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}


const mapStateToProps = state => {
    return {
        SETTINGS: state.load.SETTINGS,
    };
};

export default connect(mapStateToProps, '')(ModalSetReloader);