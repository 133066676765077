import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Row, Col, Badge } from 'reactstrap';
import { Helmet } from 'react-helmet';

import moment from 'moment';

import Circle from 'components/markup/loading/Circle'

import _credit_purchases from '_functions/credit_purchases'
import { formatMoney, formatCredits } from 'utils/currency';

const resultNum = 15;

const BillingHistory = ({company}) => {

    const [purchases, setPurchases] = useState(null);

    const [err, setErr] = useState(null);
    const [total, setTotal] = useState(0);
    const [done, setDone] = useState(false);

    const formattedCredits = company.credits ? formatCredits(company.credits) : 0

    const setDataToState = useCallback((prevPurchases, data) => {
        if(data) {

            const { documents, total_documents } = data;

            let newPurchases = prevPurchases ? JSON.parse(JSON.stringify(prevPurchases)).concat(documents) : documents;

            setPurchases(newPurchases);
            setTotal(newPurchases.length)

            if(newPurchases.length === total_documents) {
                setDone(true)
            }

        } else {
            setErr(true)
        }
    }, [])

    const fetchMorePurchases = useCallback(async () => {
        const foundPurchases = await _credit_purchases.search({limit: resultNum, skip: total});
        setDataToState(purchases, foundPurchases.data)
    }, [setDataToState, purchases, total])

    const getRecentPurchases = useCallback(async () => {
        const foundPurchases = await _credit_purchases.search({limit: resultNum});
        setDataToState(null, foundPurchases.data)
    }, [setDataToState])

    useEffect(() => {
        getRecentPurchases()
    }, [getRecentPurchases])

    if(err) return <div className="alert alert-danger">Something's not right, please try again later.</div>

    return (
        <>
         <Helmet>
            <title>Purchase History</title>
            <meta name="description" content="Purchase History" />
        </Helmet>

        <Card>

            <CardHeader>
                <Row>
                    <Col xs={6} className="align-self-center">
                        <CardTitle className="mb-0">Credit Orders</CardTitle>
                    </Col>
                    <Col xs={6} className="align-self-center text-right">
                        <span className="d-none d-sm-inline-block">Balance: </span>
                        <Badge 
                            color={formattedCredits > 5 ? 'success' : formattedCredits ? 'warning' : 'danger'} 
                            className="ml-3 px-4"
                        >
                            <b>{formattedCredits}</b>
                        </Badge>
                    </Col>
                </Row>
            </CardHeader>

            { purchases && purchases.length ? (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Card</th>
                                <th>Cost Per Credit</th>

                                <th>Credits</th>

                                <th className="text-right">Amount & Tax</th>

                            </tr>
                        </thead>
                        <tbody>
                            {purchases.map(p => (
                                <tr key={p._id}>
                                    <td>
                                        <div>{moment(p.date).format('MM/DD/YYYY h:mm A    ')}</div>
                                        <div className="text-capitalize">{p.user ? p.user.given_name + ' ' + p.user.family_name : 'Auto Loader'}</div>
                                    </td>
                                   
                                    <td style={{whiteSpace: 'pre'}}>
                                        <div>{p.card_brand}</div>
                                        <div>...{p.card_last_four}</div>
                                    </td>

                                    <td style={{whiteSpace: 'pre'}}>
                                        {formatMoney(p.usd_amount_per_credit)}
                                    </td>

                                    <td style={{whiteSpace: 'pre'}}>
                                        {formatCredits(p.credits)}
                                    </td>

                                    <td className="text-right" style={{whiteSpace: 'pre'}}>
                                        <div>{formatMoney(p.usd_amount)}</div>
                                        <div>{formatMoney(p.usd_tax)}</div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : purchases ? (
                <CardHeader>
                    <p className="text-sm mb-0"><i className="fas fa-info-circle text-info mr-2 " /> Your account has not purchased any credits</p>
                </CardHeader>
            ) : (
                <Circle />
            )}
            
        </Card>

        {purchases ? (
            done ? (
                <p className="text-sm mt-3 text-center">All purchases have been loaded for your account.</p>
            ) : (
                <div className="text-center mt-3">
                    <button className="btn btn-success" onClick={fetchMorePurchases}>
                        Load More
                    </button>
                </div>
            )
        ) : null}
        </>
    )

}


const mapStateToProps = state => {
	return {
        company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(BillingHistory);