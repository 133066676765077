import { useState } from "react";

import CreditEvents from './CreditEvents';
import Statements from './Statements';

const ContactsAll = () => {

    const [ view, setView ] = useState(1);
   
    return view === 1 ? (
        <CreditEvents setView={setView} />
    ) : (
        <Statements setView={setView} />
    )

}

export default ContactsAll