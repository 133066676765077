import React, { useState } from 'react';
import { CardHeader, CardFooter, CardTitle, FormGroup } from 'reactstrap';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _companies from '_functions/companies';

const SettingsInfoDocuments = ({company, setCompany}) => {

    const [apiErr, setApiErr] = useState(0);
    const [redactStatements, setRedactStatements] = useState(company.redact_statements)

    const onUpdate = async () => {

        toggleStandardLoader(true)
        const updated = await _companies.update(company._id, {
            redact_statements: redactStatements,
        })
        if(updated.success) {
            toggleAlertBS(false, 'Company settings updated successfully.')
            setCompany()
        } else {
            setApiErr(updated.message)
        }

        toggleStandardLoader(false)
    }

    return (
        <div className="border-left">

            <CardHeader>
                <CardTitle className="mb-0">Account Security</CardTitle>
            </CardHeader>
            
            <CardHeader>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="archk-accept-financial"
                            type="checkbox"
                            checked={redactStatements}
                            onChange={(e) => setRedactStatements(!redactStatements)}
                        />
                        <label className="custom-control-label" htmlFor="archk-accept-financial">
                            Redact phone numbers and email addresses on documents.
                        </label>
                    </div>
                </FormGroup>
            </CardHeader>

            {apiErr ? (
                <CardFooter>
                    {apiErr.map((e, i) => <p key={i} className="text-sm text-danger font-weight-bold mb-0">{e}</p>)}
                </CardFooter>
            ) : null}

            <CardFooter className="text-right">
                <button onClick={onUpdate} className="btn btn-success">
                    <i className="fas fa-save mr-2 " /> Save
                </button>
            </CardFooter>
        
        </div>

    )
}

export default SettingsInfoDocuments;