/*
Documentation

this file handles password recovery by sending a hashed security code to a users email
it then verifies the code and lets them update their password

*/

import React from "react";
import PropTypes from 'prop-types'
import { Card, CardHeader, CardBody, CardTitle, CardFooter, Form, FormGroup, Input } from "reactstrap";

import _auth from '_functions/auth';

class ForgotConfirmCode extends React.Component {

	state = {
        error: false,
        showEmail: true,
        showCodeVerification: false,
        showPasswordUpdate: false,
        success: null
    };

    //on enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {

            document.getElementById("showCodeVerificationButton").click();

		}
	}

    onConfirmedCode = async  (e) => {

        e.preventDefault();

        this.setState({error: null})

        const { code } = this.state;
        const { email, onConfirmedCode, company } = this.props;

        if(!this.state.code) return this.setState({error: 'Please enter the code sent to your email'});

        //confirm the security code they entered is valid
        //do not console.log this response in production as it holds
        //the code that was created and hashed
        const confirmedCode = await _auth.security_code.validate({email, code, company: company._id})

        if(confirmedCode.success) {
            onConfirmedCode(code)
        } else {
            this.setState({error: confirmedCode.message})
        }

    }

    componentDidMount = () => {
        const input = document.getElementById('forgot-code-input')
        if(input) input.focus();
    }

	render() {

		const { code, error } = this.state


		return (

            <Form>

                <Card className="bg-white border-0 mb-0 z-depth-2">

                    <CardHeader>
                        <CardTitle className="mb-0">Forgot Password - Step 2 of 3</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <FormGroup>
                            <label className="form-control-label">Enter The Code Send To Your Email.</label>
                            <Input
                                id="forgot-code-input"
                                onKeyDown={(e) => this._handleKeyDown(e)}
                                placeholder="Code"
                                type="number"
                                value={code	 || ''}
                                onChange={(e) => this.setState({code: e.target.value})}
                            />
                        </FormGroup>

                    </CardBody>

                    {error && (
                        <CardFooter>
                            <p className="text-sm text-danger font-weight-bold my-2 ">{error}</p>
                        </CardFooter>
                    )}

                    <CardFooter className="text-right">
                        <button id="showCodeVerificationButton" onClick={this.onConfirmedCode} className="btn btn-outline-success">
                            Verify Code
                        </button>
                    </CardFooter>

                 </Card>
            </Form>

		);
	}
}

ForgotConfirmCode.propTypes = {
    email             : PropTypes.string.isRequired,
    onConfirmedCode   : PropTypes.func.isRequired
}

export default ForgotConfirmCode

