import apiWorker from '../apiWorker'
import fileDownloader from 'js-file-download';
import Axios from 'axios';
import keys from 'keys';

import { toggleAlertBS } from 'store/functions/system/system';

const credit_events = {
    search    : (data)              => apiWorker('post', `/v1/billing/credit_events/search`, data),
    getMonth  : (month)             => apiWorker('get', `/v1/billing/credit_events/month/${month}`),
    check     : (contact_id, type)  => apiWorker('get', `/v1/billing/credit_events/check/${contact_id}/${type}`),
    download : async (contact_id)  => {
        try {

            const result = await Axios({
                method: 'get',
                url: keys.API_URL + `/v1/billing/credit_events/contact/${contact_id}/download`,
                responseType:'arraybuffer',
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
            })

            fileDownloader(result.data, 'Receipt.pdf') 
        
        } catch(e) {
        
            toggleAlertBS(true, `Please try again.`)
        
        }
    }
}

export default credit_events;
