import { useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import moment from 'moment';

import { Card, Container, Alert, CardHeader, CardTitle, Row, Col, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import Table from 'components/functional/tables/Standard';
import React from "react";

import { formatMoney, formatCredits } from 'utils/currency';

import _credit_events from '_functions/credit_events';



const ContactsAll = ({device, company, setView, CREDITS}) => {

    const [ type, setType ] = useState(undefined);
    const [ joint, setJoint ] = useState(undefined);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)

    const formattedCredits = company.credits ? formatCredits(company.credits) : 0;

    const onSetType = (e) => {

        e.preventDefault();
        let type = e.target.innerHTML;

        let formattedType = type === 'All' ? undefined : 
        type === 'Individual Financial' ? CREDITS.INDIVIDUAL_FINANCIAL : 
        type === 'Individual Package Financial' ? CREDITS.INDIVIDUAL_PACKAGE_FINANCIAL : 
        type === 'Individual Pay Stubs' ? CREDITS.INDIVIDUAL_PAY_STUBS : 
        type === 'Individual Package Pay Stubs' ? CREDITS.INDIVIDUAL_PACKAGE_PAY_STUBS : 
        type === 'Joint Financial' ? CREDITS.JOINT_FINANCIAL : 
        type === 'Joint Package Financial' ? CREDITS.JOINT_PACKAGE_FINANCIAL : 
        type === 'Joint Pay Stubs' ? CREDITS.JOINT_PAY_STUBS : 
        type === 'Joint Package Pay Stubs' ? CREDITS.JOINT_PACKAGE_PAY_STUBS : 
        type === 'Pay Stub Overage' ? CREDITS.PAY_STUB_OVERAGE : 
        type === 'Bank Statement' ? CREDITS.MX_STATEMENT : 
        type === 'Auto Policy' ? CREDITS.FIZE_CONNECTION : 
        undefined;
        
        query({type: formattedType, joint, page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage })
        setType(formattedType)
    }
    
    const onSetJoint = (e) => {

        e.preventDefault();
        let joint = e.target.innerHTML;

        let formattedJoint = joint === 'All' ? undefined : 
        joint === 'Joint' ? true : 
        joint === 'Individual' ? false : 
        undefined;
        query({type, joint: formattedJoint, page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage })
        setJoint(formattedJoint)

    }

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { type, joint, page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await _credit_events.search({ ...params, isCSV })

        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

  
    const columns = device.info.isMD ? [
        {
            dataField: "_id",
            text: "Contact(s)",
            formatter: (cell, row) => {
                if(row.name === 'no data') return (
                    <div>No Data To Show</div>
                )
                return row.contacts ? row.contacts.map(c => (
                    <div key={c._id}>{c.display_name}</div>
                )) : '-'
            },
        },
        {
            dataField: "type",
            text: "Type",
            headerStyle: {width: 240},
            headerFormatter: (cell, row) => (
                <UncontrolledDropdown  id="type-input">

                    <DropdownToggle caret>{type ? type === CREDITS.MX_STATEMENT ? 'Bank Statement' : type.replace(/_/g, ' ') : 'Type'}</DropdownToggle>

                        <DropdownMenu >
                            <DropdownItem className="text-capitalize" onClick={onSetType}>All</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Individual Financial</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Individual Package Financial</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Individual Pay Stubs</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Individual Package Pay Stubs</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Joint Financial</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Joint Package Financial</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Joint Pay Stubs</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Joint Package Pay Stubs</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Pay Stub Overage</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Bank Statement</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetType}>Auto Policy</DropdownItem>
                        </DropdownMenu>

                </UncontrolledDropdown>
            ),
            formatter: (cell, row) => (
                <span className="text-capitalize">
                    {row.type === 'fize_connection' ? 'Auto Policy' : row.type === 'yodlee_statement' || row.type === 'mx_statement' ? 'Bank Statement' : row.type.replace(/_/g, ' ')}
                </span>
            )
        },
        {
            dataField: "credits",
            text: "Credits",
            formatter: (cell, row) => (
                row.credits === undefined ? '-' : row.credits < 0 ? <b className="text-success">{formatCredits(row.credits * -1)}</b> : formatCredits(row.credits)
            )
        },
       
        {
            dataField: "joint",
            text: "Joint",
            headerFormatter: (cell, row) => (
                <UncontrolledDropdown  id="joint-input">

                    <DropdownToggle caret>{joint === true ? 'Joint' : joint === false ? 'Individual' : 'Partner'}</DropdownToggle>

                        <DropdownMenu >
                            <DropdownItem className="text-capitalize" onClick={onSetJoint}>All</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetJoint}>Joint</DropdownItem>
                            <DropdownItem className="text-capitalize" onClick={onSetJoint}>Individual</DropdownItem>
                        </DropdownMenu>

                </UncontrolledDropdown>
            ),
            formatter: (cell, row) => (
                row.joint ? <i className="fas fa-check mr-2 " /> : '-'
            )
        },
        {
            dataField: "date",
            text: "Date",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                row.date ? (
                    <div className="text-right">
                        {moment(row.date).format('MM/DD/YYYY h:mm A')}
                    </div>
                ) : '-'
                
            )
        },

    ] : [
        {
            dataField: "contacts",
            text: "Contact(s)",
            formatter: (cell, row) => (
                row.contacts.map(c => (
                    <div>{c.display_name}</div>
                ))
            ),
        },
        {
            dataField: "credits",
            text: "Credits",
            formatter: (cell, row) => (
                row.credits < 0 ? <b className="text-success">{formatCredits(row.credits * -1)}</b> : formatCredits(row.credits)
                
            )
        },
        {
            dataField: "usd_amount",
            text: "USD",
            formatter: (cell, row) => (
                formatMoney(row.usd_amount)
            )
        },
        {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => (
                <span className="text-capitalize">
                    {row.type === 'yodlee_statement' || row.type === 'mx_statement' ? 'Bank Statement' : row.type.replace(/_/g, ' ')}
                </span>
            )
        },

        {
            dataField: "joint",
            text: "Joint",
            formatter: (cell, row) => (
                row.join ? <i className="fas fa-check mr-2 " /> : '-'
            )
        },
        {
            dataField: "date",
            text: "Date",
            formatter: (cell, row) => (
                moment(row.date).format('MM/DD/YYYY h:mm A')
            )
        },
        
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <>
    
            <Helmet>
                <title>{`Credit History`}</title>
                <meta name="description" content="Credit History" />
            </Helmet>
    
            <Card className="card-color card-primary table-fixed">
                <CardHeader>
                    <Row>
                        <Col xs={6} className="align-self-center">
                            <CardTitle className="mb-0">
                                Credit History
                                <button className="btn btn-info ml-3" onClick={() => setView(2)}>View Statements</button>
                            </CardTitle>
                        </Col>
                        <Col xs={6} className="align-self-center text-right">
                            <span className="d-none d-sm-inline-block">Balance: </span>
                            <Badge 
                                color={formattedCredits > 5 ? 'success' : formattedCredits ? 'warning' : 'danger'} 
                                className="ml-3 px-4"
                            >
                                <b>{formattedCredits}</b>
                            </Badge>
                        </Col>
                    </Row>
                </CardHeader>

                <Table
                    hideSearch={true}
                    tableClassNames="table-fixed table-overflow-show"
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </>
    )

}

const mapStateToProps = state => {
	return {
        CREDITS: state.load.CREDITS,
        company: state.company.company,
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(ContactsAll);