

import { useEffect, useState } from 'react';

const ClioAddDevelopment = () => {

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        setRedirect(`http://localhost:3000/redirects/clio/add${window.location.search}`)
    }, [])

    if(redirect) return window.location.href = redirect;

    return <div></div>
}

export default ClioAddDevelopment