import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, Card, CardBody } from "reactstrap";

// const tabs = [ 'Info', 'Branding', 'Integrations', 'API Keys' ]
const tabs = [ 
    'Info', 
    'Messaging',
    'Portal', 
    'API Keys',
    // 'Integrations',
]

const CompanyNavigation = ({tab, setTab, viewing_user}) => {

    const getClasses = (tabToCheck) => {

        if(tabToCheck === tab) {
            return 'mb-sm-3 mb-md-0 active'
        }

        return 'mb-sm-3 mb-md-0'

    }

    return (

        <Card>
            <CardBody>
                <Nav className="nav-fill flex-column flex-sm-row" pills role="tablist" >
                    {tabs.map((t, i) => (
                        <NavItem key={i}>
                            <NavLink
                                aria-selected={tab === i}
                                className={getClasses(i)}
                                onClick={e => setTab(i)}
                                role="tab"
                            >
                                {t}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </CardBody>
        </Card>
        
    );

}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(CompanyNavigation);