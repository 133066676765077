import { connect } from 'react-redux';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";

import { Link } from 'react-router-dom';

import _forms from '_functions/forms';
import { onDownloadDocument } from 'utils/documents';

import Circle from 'components/markup/loading/Circle'
import A from 'components/markup/links/A'

import moment from 'moment';

import SendFormRequestModal from 'views/dashboard/pages/_components/SendFormRequestModal'
import { getCompanyLink } from 'utils/helpers';

import keys from 'keys'

const ContactForms = ({contact_id, company, accessCode}) => {

    const [showModal, setShowModal] = useState(false);
    const [state, setState] = useState({});
    const { answers, submissions, contact } = state;

    const fetchData = useCallback(async () => {
        const data = await _forms.getContactAnswersAll(contact_id)
        setState({...data.data, loaded: true})
    }, [contact_id])

    const onRequestSend = useCallback(() => {
        setShowModal(false)
        fetchData();
    }, [fetchData])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    if(!answers) return <div className="py-6"><Circle /></div>

    if(!contact || !answers || !submissions) {
        return (
            <Container fluid>
                <div className="alert alert-danger text-center"><i className="fas fa-exclamation-triangle mr-2 " /> This submisson is unable to be viewed</div>
            </Container>
        )
    }
  
    return (
        <>

            <SendFormRequestModal 
                showModal={showModal}
                toggleModal={() => setShowModal(false)}
                onConfirm={onRequestSend}
                contact={contact}
            />
    
            <Helmet>
                <title>{`Contact Forms`}</title>
                <meta name="description" content="Contact Forms" />
            </Helmet>

            <Card className="mb-4">    
                <CardHeader>
                    <Row>
                        <Col xs={6} className="align-self-center">
                            <CardTitle className="mb-0">Contact Submissions</CardTitle>
                        </Col>
                        <Col xs={6} className="align-self-center text-right">
                            <button onClick={() => setShowModal(true)} className="btn btn-outline-success"><i className="fas fa-paper-plane mr-2 " /> Request Form</button>
                        </Col>
                    </Row>
                </CardHeader>

                {submissions.length || answers.length ? (
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        Form
                                    </th>
                                    <th>
                                        Type
                                    </th>
                                    <th className="text-right">
                                        Updated/Submitted
                                    </th>
                                    <th className="text-right">
                                        View
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {submissions.length ? submissions.map((submission, i) => (
                                    <tr key={i}>
                                        <td>
                                            {submission.form ? submission.form.name : 'Form Not Found'}
                                        </td>
                                        <td>
                                            <Link 
                                                to={`/dashboard/forms/submissions/${submission._id}`}
                                                style={{minWidth: 70}}
                                            >
                                                Form Submission
                                            </Link>
                                        </td>
                                        <td className="text-right">{moment.unix(submission.submitted_at).format('MMM Do, YYYY - h:mm A')}</td>
                                        <td className="text-right">
                                            {submission.document ? (
                                                <button className="btn btn-outline-success" style={{minWidth: 70}} onClick={() => onDownloadDocument(submission.document)}>
                                                    <i className="fas fa-download mr2 " />
                                                </button>
                                            ) : (
                                                <span className="d-inline-block" style={{minWidth: 70}}></span>
                                            )}
                                            <Link 
                                                className="btn btn-outline-info" 
                                                to={`/dashboard/forms/submissions/${submission._id}`}
                                                style={{minWidth: 70}}
                                            >
                                                <i className="fas fa-eye " />
                                            </Link>
                                        </td>
                                    </tr>
                                )) : null}
                                {answers.length ? answers.map((answer, i) => (
                                    <tr key={i}>
                                         <td>
                                            {answer.form ? answer.form.name : 'Form Not Found'}
                                        </td>
                                        <td className="text-dark font-weight-bold">
                                            <Link 
                                                to={`/dashboard/forms/submissions/answers/${answer._id}`}
                                                style={{minWidth: 70}}
                                            >
                                                Current Answers ({answer.completion_percent}%)
                                            </Link>
                                        </td>
                                        <td className="text-right">{moment.unix(answer.updated_at).format('MMM Do, YYYY - h:mm A')}</td>
                                        <td className="text-right">
                                            {answer.form ? (
                                                <A 
                                                    className="btn btn-outline-success" 
                                                    href={`${keys.FORM_URL}/${getCompanyLink(company)}/${answer.form.form_number}?i=${contact.email}&a=${accessCode}`}
                                                    style={{minWidth: 70}}
                                                >
                                                    <i className="fas fa-link " />
                                                </A>
                                            ) : ''}
                                          
                                            <Link 
                                                className="btn btn-outline-info" 
                                                to={`/dashboard/forms/submissions/answers/${answer._id}`}
                                                style={{minWidth: 70}}
                                            >
                                                <i className="fas fa-eye " />
                                            </Link>
                                        </td>
                                    </tr>
                                )) : null}
                               
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <CardBody>
                        <p className="text-sm mb-0">There are no submissions for this contact.</p>
                    </CardBody>
                )}

            </Card>


        </>
    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(ContactForms);