import { memo } from 'react';
import Question from './Question';

const MapQuestions = ({_questions, onChange, answers, form, errors, nest = 0, loopIndex = 0, parentIsLoop}) => (
    <div className="question-list position-relative" style={{paddingLeft: nest * 20}}>

        <i className="indent-icon fas fa-reply text-dark fa-rotate-180" style={{marginLeft: (nest - 1) * 20}} />

        <div className="question-list-inner">

            {parentIsLoop ? (
                <h2 className="item-identifier">ITEM: {loopIndex}</h2>
            ) : null}

            {_questions.map((question, i) => (
                <Question 
                    key={question.id}
                    question={question}
                    onChange={onChange}
                    answers={answers}
                    form={form}
                    nest={nest}
                    loopIndex={loopIndex}
                    errors={errors}
                    parentIsLoop={parentIsLoop}
                    i={i}
                />
            ))}

        </div>
    </div>
)

export default memo(MapQuestions);