import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup, Input, Row, Col } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'
import simulateTabOnEnter from '../_utils/simulateTabOnEnter'
import focusNextQuestionFromSelect from '../_utils/focusNextQuestionFromSelect'

import SelectMenu from 'react-select';

const stateOptions = [
    { value: "AL", label: "Alabama"},
    { value: "AK", label: "Alaska"},
    { value: "AZ", label: "Arizona"},
    { value: "AR", label: "Arkansas"},
    { value: "CA", label: "California"},
    { value: "CO", label: "Colorado"},
    { value: "CT", label: "Connecticut"},
    { value: "DE", label: "Delaware"},
    { value: "DC", label: "District Of Columbia"},
    { value: "FL", label: "Florida"},
    { value: "GA", label: "Georgia"},
    { value: "HI", label: "Hawaii"},
    { value: "ID", label: "Idaho"},
    { value: "IL", label: "Illinois"},
    { value: "IN", label: "Indiana"},
    { value: "IA", label: "Iowa"},
    { value: "KS", label: "Kansas"},
    { value: "KY", label: "Kentucky"},
    { value: "LA", label: "Louisiana"},
    { value: "ME", label: "Maine"},
    { value: "MD", label: "Maryland"},
    { value: "MA", label: "Massachusetts"},
    { value: "MI", label: "Michigan"},
    { value: "MN", label: "Minnesota"},
    { value: "MS", label: "Mississippi"},
    { value: "MO", label: "Missouri"},
    { value: "MT", label: "Montana"},
    { value: "NE", label: "Nebraska"},
    { value: "NV", label: "Nevada"},
    { value: "NH", label: "New Hampshire"},
    { value: "NJ", label: "New Jersey"},
    { value: "NM", label: "New Mexico"},
    { value: "NY", label: "New York"},
    { value: "NC", label: "North Carolina"},
    { value: "ND", label: "North Dakota"},
    { value: "OH", label: "Ohio"},
    { value: "OK", label: "Oklahoma"},
    { value: "OR", label: "Oregon"},
    { value: "PA", label: "Pennsylvania"},
    { value: "PR", label: "Puerto Rico"},
    { value: "RI", label: "Rhode Island"},
    { value: "SC", label: "South Carolina"},
    { value: "SD", label: "South Dakota"},
    { value: "TN", label: "Tennessee"},
    { value: "TX", label: "Texas"},
    { value: "UM", label: "United States Minor Outlying Islands"},
    { value: "UT", label: "Utah"},
    { value: "VT", label: "Vermont"},
    { value: "VA", label: "Virginia"},
    { value: "VI", label: "Virgin Islands"},
    { value: "WA", label: "Washington"},
    { value: "WV", label: "West Virginia"},
    { value: "WI", label: "Wisconsin"},
    { value: "WY", label: "Wyoming"},
]

const Address = ({question, answers, onChange, setActive, loopIndex, errors}) => {
    
    const [_question, _setQuestion] = useState({})
    const [val, setVal] = useState({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: ''
    })

    const addressId = _question.id + 'address';
    const hasAddressError = errors.includes(_question.id) && !val.address_line_1 ? true : false;
    const hasCityError = errors.includes(_question.id) && !val.city ? true : false;
    const hasStateError = errors.includes(_question.id) && !val.state ? true : false;
    const hasZipError = errors.includes(_question.id) && !val.zip ? true : false;

    const handleStateChange = useCallback((e) => {
        const value = e.value && e.value === '__FALSE__' ? '' : e.value;

        const _val = JSON.parse(JSON.stringify(val));
        _val['state'] = value;
        setVal(_val);
        onChange(_question, _val)

        focusNextQuestionFromSelect(value, {id: addressId});
    }, [_question, onChange, val, addressId])


    const handleChange = useCallback((field, e) => {
        const _val = JSON.parse(JSON.stringify(val));
        _val[field] = e.target.value;
        setVal(_val);
        onChange(_question, _val)
    }, [_question, onChange, val])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        if(answers[formattedQuestion.id]) {
            setVal(answers[formattedQuestion.id])
        }
    }, [question, loopIndex, answers])

    return (
        <div id={_question.id}>
            <FormGroup>
                <label className="form-control-label">{_question.name}</label>
                {question.description ? (
                    <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
                ) : null}
            </FormGroup>  
            
            <Row className="question-address-wrapper">
                <Col lg={6}>
                    <FormGroup key={_question._id}>
                        <label className={`form-control-label ${hasAddressError ? 'text-danger' : ''}`}>
                            {hasAddressError ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                            Address Line 1
                        </label>
                        <Input 
                            autoComplete="address-line1"
                            value={val.address_line_1}
                            type="text"
                            onChange={(e) => handleChange('address_line_1', e)}
                            onBlur={() => setActive(false)}
                            onFocus={() => setActive(true)}
                            onKeyDown={(e) => simulateTabOnEnter(e, _question)}
                            invalid={hasAddressError}
                        />
                    </FormGroup>  
                </Col>
                <Col lg={6}>
                    <FormGroup key={_question._id}>
                        <label className="form-control-label">Address Line 2</label>
                        <Input 
                            autoComplete="address-line2"
                            value={val.address_line_2}
                            type="text"
                            onChange={(e) => handleChange('address_line_2', e)}
                            onBlur={() => setActive(false)}
                            onFocus={() => setActive(true)}
                            onKeyDown={(e) => simulateTabOnEnter(e, true)}
                        />
                    </FormGroup>  
                </Col>
            </Row>
            
            <Row className="question-address-wrapper">
                <Col lg={4}>
                    <FormGroup key={_question._id}>
                        <label className={`form-control-label ${hasCityError ? 'text-danger' : ''}`}>
                            {hasCityError ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                            City
                        </label>
                        <Input 
                            autoComplete="address-level2"
                            value={val.city}
                            type="text"
                            onChange={(e) => handleChange('city', e)}
                            onBlur={() => setActive(false)}
                            onFocus={() => setActive(true)}
                            onKeyDown={simulateTabOnEnter}
                            invalid={hasCityError}
                        />
                    </FormGroup>  
                </Col>
                <Col lg={4}>
                    <FormGroup key={_question._id}>
                        <label className={`form-control-label ${hasStateError ? 'text-danger' : ''}`}>
                            {hasStateError ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                            State
                        </label>
                        <SelectMenu 
                            options={stateOptions}           
                            onChange={handleStateChange}
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            openMenuOnFocus={true}
                            value={stateOptions.find(option => option.value === val.state)}
                        />
                        <input type="hidden" id={addressId} />
                    </FormGroup>  
                </Col>
                <Col lg={4}>
                    <FormGroup key={_question._id}>
                        <label className={`form-control-label ${hasZipError ? 'text-danger' : ''}`}>
                            {hasZipError ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                            Zip Code
                        </label>
                        <Input 
                            autoComplete="postal-code"
                            value={val.zip}
                            type="text"
                            onChange={(e) => handleChange('zip', e)}
                            onBlur={() => setActive(false)}
                            onFocus={() => setActive(true)}
                            onKeyDown={simulateTabOnEnter}
                            invalid={hasZipError}
                        />
                    </FormGroup>  
                </Col>
            </Row>
            
        </div>
    )
}

export default memo(Address);