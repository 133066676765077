import { Modal } from 'reactstrap';

import SearchMain from './SearchMain';

const Search = ({showModal, toggleModal}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"
        id="archk-search-modal"
    >

        <div className="modal-header">
            <h5 className="modal-title">Institutions Search</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <SearchMain />

        <div className="modal-footer text-right">
            <button className="btn btn-success" onClick={toggleModal}>
                Close
            </button>
        </div>

    </Modal>
)

export default Search;