import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import ContactSearchInput from 'components/system/Search/ContactsInput'

import A from 'components/markup/links/A'

const SelectContact = ({showModal, toggleModal, onAddContact, configuration, contacts}) => {

    const [contact, setContact] = useState({});
    const canAdd = contacts.length < configuration.MAX_PARTIES

    const onSelectContact = useCallback(() => {
        if(!contact) return;
        toggleModal();
        onAddContact(contact)
    }, [contact, toggleModal, onAddContact])

    useEffect(() => {
        if(!showModal) setContact({})
    }, [showModal])

    if(!canAdd) return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Document Limit Exceeded</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <p className="text-sm mb-0">A document can be signed by a maximum of {configuration.MAX_PARTIES} parties.</p>
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" />
                    Close
                </button>
            </div>

        </Modal>
    )

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Search Contacts</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <p className="text-sm">
                    Type to find the client(s) you wish to send this document to for signature. If you have not already created the client you wish to send this document out to{' '}
                    <span className="font-weight-bold text-underline">
                        <A href="/dashboard/contacts?invite=individual">click here to add them.</A>
                    </span>
                </p>
                {showModal ? (
                     <ContactSearchInput 
                        value={contact}
                        hideTitle={true}
                        onSelect={(contact) => setContact(contact)}
                    />
                ) : null}
            </div>

            {contact._id ? (
                <>
                 <div className="modal-header border-top bg-secondary">
                    <h5 className="modal-title">Selected Client</h5>
                </div>
                <div className="modal-body ">

                    <p className="mb-0 text-capitalize">
                        <span style={{width: 100, display: 'inline-block'}}>First Name:</span>{' '}
                        {contact.given_name || '-'}
                    </p>
                    <p className="mb-0 text-capitalize">
                        <span style={{width: 100, display: 'inline-block'}}>Last Name: </span>{' '}
                        {contact.family_name || '-'}
                    </p>
                    <p className="mb-0">
                        <span style={{width: 100, display: 'inline-block'}}>Email: </span>{' '}
                        {contact.email || '-'}
                    </p>
                    <p className="mb-0 text-capitalize">
                        <span style={{width: 100, display: 'inline-block'}}>Phone: </span>{' '}
                        {contact.phone || '-'}
                    </p>
                </div>
                </>
            ) : null}
          

            <div className="modal-footer text-right">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" />
                    Cancel
                </button>
                <button className="btn btn-success" onClick={onSelectContact}>
                    <i className="fas fa-user-plus mr-2" />
                    Confirm Client
                </button>
            </div>

        </Modal>

    )

}

SelectContact.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default SelectContact
