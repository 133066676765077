import React from "react";
import { connect } from 'react-redux';

const Notifications = ({company}) => {

    return (
        <div className="dashboard-alerts">
            {!company.credits || company.credits <= 0 ? (
                <div className="dashboard-alert"><i className="fas fa-exclamation-triangle mr-2 " /> Your account is currently out of credits</div>  
            ) : null}
        </div>
    )

}

const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(Notifications);
