import { memo, useState, useCallback, useRef, useEffect } from 'react';
import React from "react";
import SignatureCanvas from 'react-signature-canvas';
import { FormGroup, Row, Col } from "reactstrap";

import setQuestionWithId from '../_utils/setQuestionWithId'

const Signature = ({question, answers, onChange, loopIndex, errors}) => {

    const [dimensions, setDimensions] = useState({width: 0, height: 0})
    const [val, setVal] = useState('')
    const [_question, _setQuestion] = useState({})
    
    const hasErr = errors.includes(_question.id) && !val ? true : false;

    const { width, height } = dimensions;
    const sigPadSignature = useRef({});

    const  clearSigPad = useCallback((e) => {
        e.preventDefault();
        sigPadSignature.current.clear();
        onChange(_question, '')
        setVal('')
    }, [_question, onChange])

    const resizeCanvas = useCallback(() => {
        const box = document.getElementById('signBox')

        if(box) {
            const width = box.offsetWidth
            const height = width / 3

            setDimensions({width, height});
            if(sigPadSignature.current) {
                let signature64 = sigPadSignature.current.getTrimmedCanvas()
                document.querySelector('.sigCanvas').width = width
                document.querySelector('.sigCanvas').height = height
                sigPadSignature.current.fromDataURL(signature64.toDataURL('image/png'))
            }

        }
    }, [])

    const onConfirm = useCallback((e) => {
        e.preventDefault();

        const signature = sigPadSignature.current.getTrimmedCanvas().toDataURL('image/png')

        var image = new Image(); 
        image.src = signature; 

        image.onload = () => {
            if(signature.length > 1000) {
                const base64 = signature.split(',')[1]
                onChange(_question, base64)
                setVal(base64)
            }
        };
    }, [_question, onChange])

    useEffect(() => {
        resizeCanvas();
        window.addEventListener('resize', resizeCanvas)
        return () => {
            window.removeEventListener('resize', resizeCanvas)
        }
    }, [resizeCanvas])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        const value = answers[formattedQuestion.id];
        _setQuestion(formattedQuestion);

        if(value) setVal(value.toString('base64'))
    }, [question, loopIndex, answers])


    return (
        <FormGroup id={_question.id}>
              <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                {val ? <i className="fas fa-check-circle text-success mr-2 " /> : null}
                {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                {_question.name}
            </label>

            {question.description ? (
                <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
            ) : null}
            {val ? (
                <Row className="mb-3 border-top border-bottom py-3">
                    <Col sm={6} className="align-self-center">
                        <p className=" mb-0 font-weight-bold">Your Current Signature:</p>
                    </Col>
                    <Col sm={6} className="align-self-center text-right">
                        <img src={`data:image/png;base64,${val}`} className="" style={{maxHeight: 75, maxWidth: '100%'}} alt="uploaded file" />
                    </Col>
                </Row>
            ) : null}

            <div className="signature-box bg-secondary border" id="signBox">
                <SignatureCanvas
                    width={width}
                    height={height}
                    clearOnResize={false}
                    backgroundColor="rgba(255,255,255,.0)"
                    penColor='black'
                    throttle={4}
                    maxWidth={3}
                    minDistance={3}
                    canvasProps={{className: 'sigCanvas' }}
                    ref={sigPadSignature}
                />
            </div>

            <div className="text-right mt-4">
                <button className="btn btn-warning" onClick={clearSigPad}>Clear Signature</button>
                <button className="btn btn-success" onClick={onConfirm}>Save Signature</button>
            </div>
        </FormGroup>
    )

}

export default memo(Signature)