import keys from 'keys';
import A from 'components/markup/links/A'

import { Badge } from 'reactstrap';

import * as actionTypes from 'store/actions';
import store from 'store';

const homeSteps = [
    {
        id: 'archk-add-individual',
        title: 'Add An Individual Contact',
        body: (
            <p className="text-sm mb-0">Click this button at any time to request documents from an individual.</p>
        )
    },
    {
        id: 'archk-add-joint',
        title: 'Add A Contact With A Partner',
        body: (
            <p className="text-sm mb-0">Alternatively, click this button at any time to request documents from a joint matter.</p>
        )
    },
    {
        id: 'archk-tutorial-overview',
        title: 'Monthly Overview',
        body: (
            <p className="text-sm mb-0">Here you can see an overview of your activity with Zap Reports since the start of the current month.</p>
        )
    },
    {
        id: 'archk-tutorial-documents-completed',
        title: 'Completed Documents',
        body: (
            <p className="text-sm mb-0">The number of documents that were completed and are ready for download this month.</p>
        )
    },
    {
        id: 'archk-tutorial-documents-in-queue',
        title: 'In Queue Documents',
        body: (
            <p className="text-sm mb-0">The number of documents where an account connection has been made and the document is awaiting processing.</p>
        )
    },
    {
        id: 'archk-tutorial-documents-pending',
        title: 'Pending Documents',
        body: (
            <p className="text-sm mb-0">The number of documents where a Zap Reports staff member is checking over the document, they should be available for download shortly.</p>
        )
    },
    {
        id: 'archk-tutorial-documents-failed',
        title: 'Failed Documents',
        body: (
            <p className="text-sm mb-0">The number of documents where Zap Reports was unable to retrieve the document. At this point Zap Reports will no longer make an attempt at to fetch the document.</p>
        )
    },
    {
        id: 'archk-tutorial-documents-total',
        title: 'Total Documents',
        body: (
            <p className="text-sm mb-0">This number shows the total number of document related to your Zap Reports account this month.</p>
        )
    },
    {
        id: 'archk-tutorial-contacts-finished',
        title: 'Finished Contacts',
        body: (
            <p className="text-sm mb-0">This number represents the total number of contacts who have finished going through the client portal this month.</p>
        )
    },
    {
        id: 'archk-tutorial-contacts-in-progress',
        title: 'In Progress Contacts',
        body: (
            <p className="text-sm mb-0">This number represents how many clients logged into their portal but have not marked their accounts as fully connected.</p>
        )
    },
    {
        id: 'archk-tutorial-contacts-requested',
        title: 'Requested Contacts',
        body: (
            <p className="text-sm mb-0">This number represents how many clients have had a request for documents sent to them but have not attempted to log into the portal.</p>
        )
    },
    {
        id: 'archk-tutorial-contacts-no-status',
        title: 'Contacts Without A Status',
        body: (
            <p className="text-sm mb-0">This number represents how many clients have been created this month that do not have a request for documents sent.</p>
        )
    },
    {
        id: 'archk-tutorial-contacts-total',
        title: 'Total Contacts',
        body: (
            <p className="text-sm mb-0">The total number of contacts created in Zap Reports since the beginning of the month.</p>
        )
    },
    {
        id: 'archk-tutorial-contacts',
        title: 'Contacts Overview',
        body: (
            <p className="text-sm mb-0">This table shows the 10 most recent contacts created in Zap Reports and their current status.</p>
        )
    },
    {
        id: 'archk-tutorial-documents',
        title: 'Documents Overview',
        body: (
            <p className="text-sm mb-0">This table shows the 10 most recent completed documents on your account.</p>
        )
    },

]

export const showTutorial = (page) => {

    let steps = [];

    switch (page) {
        case 'TUTORIAL_START':
            steps = [
                {
                    id: 'archk-tutorial-icon',
                    title: 'Zap Reports Tutorials',
                    body: (
                        <>
                        <p className="text-sm mb-0">Welcome To Zap Reports! If you see this icon on any page feel free to click it to launch a tutorial on how to use the dashboard.</p>
                        {/* <p className="text-sm mb-0">Welcome To Zap Reports!. If you see this icon on any page feel free to click it to launch a tutorial on how to use each page in Zap Reports.</p> */}
                        </>
                    )
                },
            ].concat(homeSteps)
            break
        case '/dashboard/home':
            steps = homeSteps
            break;

        case '/dashboard/contacts':
            steps = [
                {
                    id: 'archk-tutorial-contacts-name',
                    title: 'Name',
                    body: (
                        <p className="text-sm mb-0">This column shows the display name of the contact.</p>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-contacts',
                    title: 'Contact',
                    body: (
                        <p className="text-sm">This column shows the email/phone of the contact that Zap Reports will send notifications and portal invites to.</p>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-status',
                    title: 'Status',
                    body: (
                        <>
                        <p className="text-sm">This column shows the current progress a contact has made going through their portal. Values can be "Finished", "Requested", "In Progress", "None".</p>
                        <p className="text-sm mb-0">
                            <A className="text-info" href={`${keys.SITE_URL}/faq?_id=archk-docs-sidebar-tab-1-0&i1=1&i2=0`}>What Do These Statuses Mean?</A>
                        </p>
                        </>

                    )
                },
                {
                    id: 'archk-tutorial-contacts-expires',
                    title: 'Expires',
                    body: (
                        <p className="text-sm mb-0">This column shows if/when the contact is set to have their connections ended to either financial or employment accounts.</p>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-actions',
                    title: 'Actions',
                    body: (
                        <p className="text-sm mb-0">This column let's you view or delete a contact.</p>
                    )
                },
            ]
            break;

        case '/dashboard/documents':
            steps = [
                {
                    id: 'archk-tutorial-documents-name',
                    title: 'Name',
                    body: (
                        <p className="text-sm mb-0">This column shows the display name of a document as well as an optional <Badge color="success">NEW<i className="fas fa-exclamation ml-2 " /> </Badge> icon if the document has succeeded and is available for download.</p>
                    )
                },
                {
                    id: 'archk-tutorial-documents-status',
                    title: 'Status',
                    body: (
                        <>
                        <p className="text-sm">This column shows the current status of a document. Values can be "Completed", "In Queue", "Pending", "Failed".</p>
                        <p className="text-sm mb-0">
                            <A className="text-info" href={`${keys.SITE_URL}/faq?_id=archk-docs-sidebar-tab-1-0&i1=1&i2=0`}>What Do These Statuses Mean?</A>
                        </p>
                        </>

                    )
                },
                {
                    id: 'archk-tutorial-documents-date',
                    title: 'Date',
                    body: (
                        <p className="text-sm mb-0">This column shows when a document was completed OR when it's next attempt at processing is.</p>
                    )
                },
                {
                    id: 'archk-tutorial-documents-actions',
                    title: 'Actions',
                    body: (
                        <p className="text-sm mb-0">This column let's you download or analyze a document (If for an Employment / Transaction History Document).</p>
                    )
                },
            ]
            break;
        case '/dashboard/contacts/view':
            steps = [
                {
                    id: 'archk-tutorial-contacts-view-navigation',
                    title: 'Navigation',
                    body: (
                        <p className="text-sm mb-0">This row allows you to navigate between different tabs on a contacts profile.</p>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-navigation-update',
                    title: 'Update',
                    body: (
                        <p className="text-sm mb-0">This tab allows you to update a contacts information such as their name, email, phone, etc.</p>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-navigation-timeline',
                    title: 'Timeline',
                    body: (
                        <p className="text-sm mb-0">Here you can see events about a contact such as what actions they have taken inside their portal.</p>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-navigation-connect',
                    title: 'Connect',
                    body: (
                        <>
                        <p className="text-sm">Here you can manually link contact accounts as well as re-pull reports as needed.</p>
                        <p className="text-sm mb-0">Note that before a contact's account can be manually connected the contact must accept the Terms of Service inside their portal.</p>
                        </>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-view-joint-1',
                    title: 'File Type',
                    body: (
                        <p className="text-sm mb-0">Here you can see if a contact is marked as joint.</p>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-view-joint-2',
                    title: 'Adjust File Type',
                    body: (
                        <p className="text-sm mb-0">To adjust a joint file, you can click on this icon. Note that after a request for documents has been sent you cannot adjust an individual file to a joint one or vice versa.</p>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-view-access-code',
                    title: 'Access Code',
                    body: (
                        <>
                        <p className="text-sm">This number represent the access code a client needs to use to login to their portal. </p>
                        <p className="text-sm mb-0">To gain access a contact needs to add this code and either the email address or phone number that is assigned to their account.</p>
                        </>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-view-status',
                    title: 'Portal Status',
                    body: (
                        <>
                        <p className="text-sm">Here you can view the progress of a contact as they proceed through their portal.</p>
                        <p className="text-sm mb-0">
                            <A className="text-info" href={`${keys.SITE_URL}/faq?_id=archk-docs-sidebar-tab-1-0&i1=1&i2=0`}>What Do These Statuses Mean?</A>
                        </p>
                        </>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-view-info',
                    title: 'Information',
                    body: (
                        <>
                        <p className="text-sm mb-0">This area shows the basic information associated with a contact.</p>
                        </>
                    )
                },
                {
                    id: 'archk-tutorial-contacts-view-documents',
                    title: 'Navigation',
                    body: (
                        <>
                        <p className="text-sm">This table shows all documents available for a contact.</p>
                        <p className="text-sm mb-0">
                            <A className="text-info" href={`${keys.SITE_URL}/faq?_id=archk-docs-sidebar-tab-1-0&i1=1&i2=0`}>What Do These Statuses Mean?</A>
                        </p>
                        </>
                    )
                },
            ]
            break;
    
        default:
            break;
    }

    store.dispatch({
        type: actionTypes.SET_TUTORIAL,
        payload: {
            showTutorial : steps.length ? true : false,
            steps: steps
        }
    });

}

export const closeTutorial = () => {

    store.dispatch({
        type: actionTypes.SET_TUTORIAL,
        payload: {
            showTutorial : false,
            steps: []
        }
    });

}
