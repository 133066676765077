/*
Documentation

this component renders the connect widget with MX

*/

import { useState, useCallback, useEffect } from "react";
import { Card, CardFooter, CardHeader, CardBody, CardTitle, Col, Row } from "reactstrap";
import Circle from 'components/markup/loading/Circle'
import moment from 'moment';
import ModalToggler from 'components/functional/modals/Toggler'

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import _mx_logs from '_functions/mx_logs'
import _integrations from '_functions/integrations';
import _credit_events from '_functions/credit_events';

import MXConnectIcon from './images/MXConnect.svg'

import ModalStatus from './ModalStatus'
import ConfirmationModal from 'components/functional/modals/Confirmation'

import styles from './styles/styles'

const ConnectMXConnect = ({foundContact, foundCompany, setView, noMxUser, fetchData, setShowMX, memberNeedsVerification}) => {

    const [showUnverified, setShowUnverified] = useState(false);
    const [connectLoaded, setConnectLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalWidth, setModalWidth] = useState(435);
    const [memberToDelete, setMemberToDelete] = useState(false);
    const [members, setMembers] = useState(null);
    const [err, setErr] = useState(null);

    const onMoveOn = useCallback((byPassUnverified) => {

        const hasUnverified = members.some(m => memberNeedsVerification(m))

        if(hasUnverified && !byPassUnverified) {
            return setShowUnverified(true);
        } else {
            setShowUnverified(false)
        }

        setShowMX(false);

    }, [memberNeedsVerification, members, setShowMX])

    const getConnectionName = useCallback((name) => {
        if(!name) return;
        try {
            return (
                <span>
                    {name.slice(0,1)}
                    <span className="text-lowercase">{name.slice(1, name.length)}</span>
                </span>
            )
        } catch(e) {
            return name
        }
    }, [])


    const closeConnect = useCallback(async () => {

        setShowModal(false);
        setConnectLoaded(false);
        setModalWidth(435)
        // setView('VerifyMembers')
        var connectIFrame = document.querySelector('iframe[title="Connect"]')
        if(connectIFrame) connectIFrame.remove();

    }, [])

    const fetchMembers = useCallback(async () => {

        const foundMembers = await _integrations.mx.members.list({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
        });

        if(foundMembers.data) {
            setMembers(foundMembers.data);
        } else {

             // if a user has not tried to use mx let them pass
            if(noMxUser(foundMembers.message)) {
                return setMembers([]);
            }

            setErr(true)
        }

    }, [foundContact._id, foundContact.access_code, foundCompany._id, noMxUser])

    const onDeleteMember = useCallback(async (memberToDelete) => {

        toggleStandardLoader(true)

        await _integrations.mx.members.delete({
            contact_id: foundContact._id,
            company_id: foundCompany._id,
            access_code: foundContact.access_code,
            member_id: memberToDelete.guid
        });

        setMemberToDelete(false)
        fetchMembers();
        toggleStandardLoader(false)

    }, [fetchMembers, foundCompany._id, foundContact.access_code, foundContact._id])

    const openConnect = useCallback(async (current_member_guid) => {
        
        setShowModal(true);

        const url = await _integrations.mx.request_url({
            contact_id: foundContact._id,
            company_id: foundCompany._id,
            access_code: foundContact.access_code,
            current_member_guid
        })

        var mxConnect = new window.MXConnect({
            id: "connect-launcher",
            iframeTitle: "Connect",
            config: { ui_message_version: 4 },
            targetOrigin: "*",
        })

        // we have success, load the widget
        if(url.data && url.data.url) {
            return mxConnect.load(url.data.url);
        }

        // we have an error, send the correct message
        if(url.message && url.message[0] && url.message[0].retry) {
            const time = moment.unix(url.message[0].retry).format('h:mm A')
            toggleAlertBS('info', `We are getting your account ready, please try again in a few minutes. Estimated time: ${time}`)
        } else if(url.message && url.message[0] && url.message[0] === 'The terms of use must be accepted before pulling documents.') {
            toggleAlertBS(true, `The terms of use must be accepted before pulling documents.`)
        } else if(url.message && url.message[0] && url.message[0] === 'staging') {
            toggleAlertBS(true, `Document Generation Is Not Allowed In The Staging Environment.`)
        } else {
            toggleAlertBS(true, `We are experiencing technical difficulties, please try again in a few minutes. If this problem persists please contact support.`)
        }

        setShowModal(false);


    }, [foundCompany._id, foundContact._id, foundContact.access_code])
    

    const checkCredits = useCallback(async () => {
        const check = await _credit_events.check(foundContact._id, 'mx');
        if(!check.data || (check.data && !check.data.allowed)) {
            closeConnect();
            if(foundContact.contact_purchases) {
                toggleAlertBS(true, `This account requires payment before proceeding.`)
            } else {
                toggleAlertBS(true, `Your account does not have enough credits to link employer documents.`)
            }
        }
    }, [foundContact._id, foundContact.contact_purchases, closeConnect])

    const handleEvent = useCallback((event) => {
        if (event.data.mx) {
            switch (event.data.type) {
                case 'mx/connect/loaded':
                    setConnectLoaded(true)
                    break;
                case 'mx/connect/memberConnected':
                    setView('MXVerifyIdentity', event.data.metadata.member_guid)
                    _mx_logs.create({
                        contact: foundContact._id,
                        company: foundCompany._id,
                        event: 'member connected',
                        data: event.data.metadata,
                    })
                    break;
                case 'mx/connect/stepChange':
                    if(event.data.metadata.current === 'search' && event.data.metadata.previous === 'disclosure') {
                        setModalWidth(435)
                    }
                    break;
            
                default: break;
            }
        }
    }, [setView, foundCompany._id, foundContact._id])

    useEffect(() => {
        if(showModal === true) {
            checkCredits();
            window.addEventListener('message', handleEvent)
        } else {
            window.removeEventListener('message', handleEvent)
        }
        return () => {
            window.removeEventListener('message', handleEvent)
        }
    }, [showModal, checkCredits, handleEvent])

    useEffect(() => {
        fetchMembers();
        fetchData();
    }, [fetchMembers, fetchData])

    // return 'hi'

    if(!members && !err) return <div className="pt-4"><Circle /></div>

    return (

        <>

        <ConfirmationModal 
            prevButtonClasses="btn btn-success"
            nextButtonClasses="btn btn-outline-warning"
            prevButtonText="Verify"
            nextButtonText="Proceed"
            showModal={showUnverified}
            toggleModal={() => setShowUnverified(false)}
            title="Proceed With Unverified Accounts"
            body={(
                <span>
                    Before proceeding please verify all of your accounts. If you do not, no documents will be able to be retrieved from any account marked as needing verification.<br /><br /> 
                    <b>
                        <span className="text-underline">To verify your account(s)</span>, click the{' '}
                        <span className="text-warning">ORANGE BUTTON</span>{' '}
                        next to their name.
                    </b>
                </span>
            )}
            onConfirmation={() => onMoveOn(true)}
        />

        <ConfirmationModal 
            showModal={memberToDelete ? true : false}
            toggleModal={() => setMemberToDelete(false)}
            title="Remove Account"
            body={<span>Are you sure you wish to remove the account: <b className="text-underline">{memberToDelete ? memberToDelete.name : 'null'}?</b> This Institution will need to be re-authenticated in the future if you wish to have reports include their statements.</span>}
            onConfirmation={() => onDeleteMember(memberToDelete)}
        />


        <Card className='bg-secondary'>
            <CardBody className="py-6 pt-8">

                <Row className="justify-content-center">
                    <Col xl="5" lg="8" md="9">

                        <Card className="bg-white border-0 mb-0 position-relative" >

                            <img src={MXConnectIcon} className="bg-white flow-icon" style={styles.icon} alt="..." />

                            <CardHeader>
                                <CardTitle className="mb-0">Let's connect your accounts!</CardTitle>
                            </CardHeader>

                            <CardHeader>
                                <p className="text-sm">Click the <b className="text-info">BLUE</b> button below to begin linking {foundContact.display_name}'s accounts. This may include banks, Cash App / PayPal, etc.</p>

                                <button  onClick={() => openConnect()} className=" btn btn-info btn-block my-2 btn-lg">
                                    <i className="fas fa-link mr-2 " /> Connect Accounts
                                </button>
                            </CardHeader>

                            <CardHeader className="bg-secondar">    
                                <CardTitle className="mb-0">
                                    Connected Accounts
                                    <ModalToggler component={ModalStatus}>
                                        <span className="mb-0 text-warning font-weight-bold text-underline cursor-pointer">
                                            <i className="fas fa-info-circle text-info float-right " />
                                        </span>
                                    </ModalToggler>
                                </CardTitle>
                                
                                </CardHeader>

                                {members && members.length ? (
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Account</th>
                                                <th className="text-right" style={{minWidth: 130}}>Status</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {members.map((member, i) => (
                                            <tr key={i}>
                                                <td>
                                                    {member.name}
                                                </td>
                                                <td className="text-right" style={{whiteSpace: 'pre-line'}}>

                                                    {!member.isPartner && memberNeedsVerification(member) ? (
                                                        <button onClick={() => setView('MXVerifyIdentity', member.guid, 'MXConnect')} className="btn btn-warning btn-sm">
                                                            <i className="fas fa-lock mr-2 " /> Verify
                                                        </button>
                                                    ) : 
                                                        member.connection_status ? getConnectionName(member.connection_status) : '-'
                                                    }
                                                </td>
                                                <td className="text-right">
                                                    {member.isPartner ? (
                                                        <span className="text-warning">&mdash;</span>
                                                    ) : (
                                                        <button className="btn btn-sm btn-danger" style={{minWidth: 0}} onClick={() => setMemberToDelete(member)}>
                                                            <i className="fas fa-times mr-2 " />Remove
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                ) : (
                                <CardFooter>
                                    <p className="text-sm mb-0 text-warning font-weight-bold">
                                    No financial accounts have been connected.
                                    </p>
                                </CardFooter>
                                )}
                            
                            <CardFooter className="text-center">
                                <button  onClick={() => onMoveOn(false)} className=" btn btn-success btn-block my-2 btn-lg">
                                    Finish <i className="fas fa-check ml-2 " />
                                </button>
                            </CardFooter>

                        </Card>

                    </Col>
                </Row>
            </CardBody>
        </Card>

     
        <div className={showModal ? "modal fade show" : "modal fade"} role="dialog" tabIndex="-1" style={{display: showModal ? 'block' : 'none'}}>
            <div className="modal-backdrop fade show" />
            <div id="test" className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{maxWidth: modalWidth, borderRadius: 10, margin: 0, marginLeft: 'auto', marginRight: 'auto', zIndex: 99999999}}>
                <div className="modal-content">
                    <div className="modal-body p-0" style={{minHeight: 500, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                        {!connectLoaded ? <div style={{position: 'relative', top: 325}}><Circle /></div> : null}
                        <div id="connect-launcher" />
                    </div>
                    <div className="modal-footer mt--4 border-top" >
                        <button onClick={closeConnect} className="btn btn-success btn-block btn-lg">
                            <i className="fas fa-check mr-2 "></i> DONE!
                        </button>
                    </div>
                </div>
            </div>
        </div>

        </>

    )
}

export default ConnectMXConnect

