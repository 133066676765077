import { useCallback, useEffect, useState } from "react";
import _form_answers from '_functions/form_answers';

import Circle from 'components/markup/loading/Circle';

import Render from './Render'

const FormSubmissionsViewAnswers = ({match}) => {

    const [state, setState] = useState({});

    const { form, answers, contact, loaded } = state;

    const fetchData = useCallback(async () => {
        const data = await _form_answers.fetch(match.params.form_answers_id)
        setState({...data.data, loaded: true})
    }, [match.params.form_answers_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!loaded) return <div className="py-6"><Circle /></div>

    return (
        <Render 
            form={form}
            answers={answers}
            contact={contact}
            isAnswers={true}
        />
    )
  
}

export default FormSubmissionsViewAnswers;