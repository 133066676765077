import { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Badge, Card, CardHeader, CardTitle } from "reactstrap";
import formatText from 'utils/formatText';
import { connect } from 'react-redux';


const DashboardContacts = ({info, device}) => {

    const [ startOfDay ] = useState(parseInt(moment().startOf('day').format('X')));

    return (
        <Card className="mt-4" id="archk-tutorial-contacts">
            <CardHeader>
                <CardTitle className="mb-0">Recent Contacts</CardTitle>
            </CardHeader>
            <div className="table-responsive">
                {device.info.isMD ? (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Contact</th>
                            <th>Status</th>
                            <th>Created</th>
                            <th className="text-right">View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {info.contacts.length ? info.contacts.map(contact => (
                            <tr key={contact._id}>
                                <td className="text-capitalize">
                                    <Link to={`/dashboard/contacts/${contact._id}`}>
                                        {contact.display_name}
                                    </Link>
                                </td>
                                <td>
                                    <div style={{whiteSpace: 'pre'}}>{contact.email ? contact.email : '-'}</div>
                                    <div>{contact.phone ? formatText(contact.phone).phone() : '-'}</div>
                                </td>
                                <td>
                                    {  contact.report_status === 'null' ? '-' :
                                    contact.report_status === 'requested'   ? <Badge color="warning" style={styles.badge}>Requested</Badge> : 
                                    contact.report_status === 'in progress' ? <Badge color="info" style={styles.badge}>In Progress</Badge> : 
                                    contact.report_status === 'complete'    ? <Badge color="success" style={styles.badge}>COMPLETE</Badge>
                                    : '-'}
                                </td>
                                <td style={{whiteSpace: 'pre'}}> 
                                    {contact.created_at > startOfDay ? (
                                        moment.unix(contact.created_at).format('h:mm A')
                                    ) : (
                                        moment.unix(contact.created_at).format('MM/DD/YYYY')
                                    )}
                                </td>
                                <td className="text-right">
                                    <Link to={`/dashboard/contacts/${contact._id}`} className="btn btn-outline-success">
                                        View
                                    </Link>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Contacts Created</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                ) : (
                <table className="table" style={{tableLayout: 'fixed'}}>
                    <thead>
                        <tr>
                            <th>Contact</th>
                            <th className="text-right" style={{width: 90}}>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {info.contacts.length ? info.contacts.map(contact => (
                            <tr key={contact._id}>
                                <td className="text-capitalize">
                                    {   
                                        contact.report_status === 'null' ? 
                                            <Badge color="success" style={styles.badgeMobile}>-</Badge> :
                                        contact.report_status === 'requested'   ? 
                                            <Badge color="warning" style={styles.badgeMobile}><i className="fas fa-paper-plane " /></Badge> : 
                                        contact.report_status === 'in progress' ? 
                                            <Badge color="info" style={styles.badgeMobile}><i className="fas fa-hourglass " /></Badge> : 
                                        contact.report_status === 'complete'    ? 
                                            <Badge color="success" style={styles.badgeMobile}><i className="fas fa-check " /></Badge>
                                        :   <Badge color="success" style={styles.badgeMobile}>-</Badge>
                                    }

                                    <Link to={`/dashboard/contacts/${contact._id}`}>
                                        {contact.display_name}
                                    </Link>
                                </td>
                                <td className="text-right" style={{width: 90}}>
                                    <Link to={`/dashboard/contacts/${contact._id}`} className="btn btn-outline-success" style={{minWidth: 70}}>
                                        View
                                    </Link>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Contacts Created</td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                )}
            </div>
        </Card>
    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 120
    },
    badgeMobile: {
        display: 'inline-block',
        width: 30,
        marginRight: 10
    }
}

const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(DashboardContacts);
