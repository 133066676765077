import { useCallback, useEffect, useState } from "react";
import _form_submissions from '_functions/form_submissions';

import Circle from 'components/markup/loading/Circle';

import Render from './Render'

const FormSubmissionsViewSubmissions = ({match}) => {

    const [state, setState] = useState({});

    const { form, answers, contact, loaded, document } = state;

    const fetchData = useCallback(async () => {
        const data = await _form_submissions.findById(match.params.form_submission_id)
        setState({...data.data, loaded: true})
    }, [match.params.form_submission_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!loaded) return <div className="py-6"><Circle /></div>

    return (
        <Render 
            form={form}
            answers={answers}
            contact={contact}
            document={document}
        />
    )
  
}

export default FormSubmissionsViewSubmissions;