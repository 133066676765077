// corresponds to https://docs.mx.com/api#core_resources_members_connection_statuses

import { Modal } from "reactstrap";

const TR = ({status, meaning, type}) => (
    <tr 
        className={
            type === 'info' ? 'bg-gradient-info text-white' :
            type === 'success' ? 'bg-gradient-success text-white' :
            type === 'error' ? 'bg-gradient-danger text-white' : 
            ''
        }
    >
        <td className="text-capitalize " style={{width: 130, paddingLeft: 20}}>
            {status}
        </td>
        <td className="">{meaning}</td>
    </tr>
)

const ConnectMXModalStatus = ({showModal, toggleModal}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"

    >

        <div className="modal-header">
            <h5 className="modal-title">Connection Status</h5>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggleModal} >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">
            <p className="text-sm ">If the status of your accounts are <b className="text-underline text-success">green</b> you are all set to move on from this page.</p>
            <p className="text-sm mb-0">If an account has a <b className='text-underline text-danger'>dash</b> under actions it means it was connected this contacts partner and cannot be changed unless on that account.</p>
        </div>

        <div className="modal-body p-0" style={{maxHeight: 400, overflow: 'auto'}}>

            <div className="table-responsive">
                <table className="table mb-0">

                    <tbody>
                        <TR  status="connected"    type="success" meaning="Your account is ready to go." />
                        <TR  status="created"      type="success" meaning="You account is connecting now." />
                        <TR  status="updated"      type="success" meaning="You account is connecting now." />
                        <TR  status="resumed"      type="success" meaning="You account is connecting now." />
                        <TR  status="reconnected"  type="success" meaning="Reconnecting to your account now." />

                        <TR  status="-"            type="info" meaning="Account not valid" />
                        <TR  status="pending"      type="info" meaning="Please finish authenticating your account" />
                        <TR  status="prevented"    type="info" meaning="The last 3 attempts to connect have failed. Please re-enter your credentials to continue importing data." />
                        <TR  status="denied"       type="info" meaning="The credentials entered do not match your credentials at this institution. Please re-enter your credentials to continue importing data." />
                        <TR  status="challenged"   type="info" meaning="To authenticate your connection please answer the following challenge(s)." />
                        <TR  status="rejected"     type="info" meaning="The answer or answers provided were incorrect. Please try again." />
                        <TR  status="degraded"     type="info" meaning="We are upgrading this connection. Please try again later." />
                        <TR  status="delayed"      type="info" meaning="Importing your data may take a while. Please check back later." />
                        <TR  status="failed"       type="info" meaning="There was a problem validating your credentials. Please try again later." />
                        <TR  status="imported"     type="info" meaning="You must re-authenticate before your data can be imported. Please enter your credentials for the account again." />
                        <TR  status="expired"      type="info" meaning="The answer or answers were not provided in time. Please try again." />
                        <TR  status="impaired"     type="info" meaning="You must re-authenticate before your data can be imported. Please enter your credentials." />
                        
                        <TR  status="locked"       type="error" meaning="Your account is locked. Please log in to the appropriate website and follow the steps to resolve the issue." />
                        <TR  status="impeded"      type="error" meaning="Your attention is needed at this institution’s website. Please log in to the appropriate website and follow the steps to resolve the issue." />
                        <TR  status="closed"       type="error" meaning="This connection has been closed. You may track this account manually. If reopened, you may connect the institution again." />
                        <TR  status="disconnected" type="error" meaning="It looks like your data for this account cannot be imported. We are working to resolve the issue." />
                        <TR  status="discontinued" type="error" meaning="Connections to this institution are no longer supported. You may create a manual account and use manual transactions to track data for this account." />
                        <TR  status="disabled"     type="error" meaning="Importing data from this institution has been disabled. Please contact us if you believe it has been disabled in error." />

                    </tbody>

                </table>
            </div>

        </div>

        <div className="modal-footer" style={{zIndex: 20}}>
            <button className="btn btn-outline-warning" onClick={toggleModal} >
                Close
            </button>
        </div>

    </Modal>
)

export default ConnectMXModalStatus

