import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Badge } from "reactstrap";

import moment from 'moment';
import { getDownloadName, onDownloadDocument } from 'utils/documents';

import { needsExtension } from 'utils/documents';

import { toggleAlertBS } from 'store/functions/system/system';

const TableDocuments = ({documents, refresh, downloadAll, device}) => {

    const [spin, setSpin] = useState(false)

    const onRefresh = useCallback(() => {
        setSpin(true);
        setTimeout(() => setSpin(false), 1500);
        refresh();
    }, [refresh])

    const onDownloadAll = useCallback(() => {

        const allDocs = documents.filter(d => d.status === 'completed' && !needsExtension(d));
        console.log(allDocs)

        if(allDocs.length === 0) return toggleAlertBS('info', 'There are no documents to download')

        const downloadDocuments = (index) => {
            const doc = allDocs[index];
            onDownloadDocument(doc, doc.contact);
            if(allDocs[index + 1]) {
                setTimeout(() => {
                    downloadDocuments(index + 1);
                }, 2 * 1000)
            } else {
                console.log('done')
            }
        }

        downloadDocuments(0)

    }, [documents])
    
    const startOfDay = parseInt(moment().startOf('day').format('X'));

    return (
        <div className="table-responsive">
            {device.info.isMD ? (
            <table className="table">
                <thead>
                    <tr>
                        <th>Document</th>
                        <th>Status</th>
                        <th className="text-right">Attempting / Finished</th>
                        <th className="text-right">
                            {refresh ? (
                                <i 
                                    className={`fas fa-sync mr-3 cursor-pointer ${spin ? ' fa-spin ' : ''}`}
                                    style={{position: 'relative', top: 1}} 
                                    onClick={onRefresh} 
                                />
                            ) : null}
                            {downloadAll ? (
                                <span className="cursor-pointer" onClick={onDownloadAll}>
                                     <i className="fas fa-download ml-2 text-info " />
                                </span>
                            ) : 'Actions'}
                           
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {documents.length ? documents.map(document => {

                    let message = document.messages[document.messages.length -1];
                    if(message && message.length > 40) message = message.slice(0,40) + '...'
                    const downloadExpired = needsExtension(document)

                    return (
                        <tr key={document._id}>
                            <td style={{width: 400}}>
                                <span className={downloadExpired ? 'text-muted' : ''}>
                                    {document.status === 'completed' ? (
                                    document.contact ? (
                                        <span className="cursor-pointer">
                                            <Link className={downloadExpired ? 'text-muted' : ''} to={`/dashboard/contacts/${document.contact._id}`}>
                                                {getDownloadName(document, document.contact)}
                                            </Link>
                                        </span>
                                    ) : (
                                            getDownloadName(document, document.contact)
                                    )
                                    ): (
                                        getDownloadName(document, document.contact)
                                    )}
                                </span>

                                <div style={{maxWidth: 200, whiteSpace: 'pre-lne'}} className="text-sm text-muted">
                                    <i className="fas fa-comments mr-2 text-info " /> {message}
                                </div>
                            </td>
                            <td>
                                {document.status === 'failed' ? (
                                    <Badge color="danger" styles={styles.badge}>{document.status}</Badge>
                                ) : document.status === 'completed' ? (
                                    <Badge color="success" styles={styles.badge}>Complete</Badge>
                                )  : document.status === 'needs verification' ? (
                                    <Badge color="warning" styles={styles.badge}>Verifying</Badge>
                                ) : (
                                    <Badge color="warning" styles={styles.badge}>{document.status}</Badge>
                                )}
                            </td>
                            <td className="text-right">
                                {
                                document.status === 'needs verification' ? '-' :
                                document.status !== 'failed' && document.status !== 'completed' ? (
                                    document.next_retry_at > startOfDay ? (
                                        moment.unix(document.next_retry_at).format('h:mm A')
                                    ) : (
                                        moment.unix(document.next_retry_at).format('MM/DD/YY')
                                    )
                                ) : document.finished_at ? (
                                    <span>
                                        {moment.unix(document.finished_at).format('MM/DD/YY')}
                                        <div> {moment.unix(document.finished_at).format('h:mm A')}</div>
                                    </span>
                                ) : '-'}
                            </td>
                            <td className="text-right" style={{minWidth: 185}}>
                                {document.status === 'completed' ? (
                                    <>
                                        {document.contact ? (
                                            <Link 
                                                className="btn btn-outline-warning" 
                                                to={`/dashboard/analyze/${document.contact._id}?document=${document._id}`}
                                                style={{minWidth: 70}}
                                            >
                                                <i className="fas fa-chart-line mr2 " />
                                            </Link>
                                        ) : null }
                                        <button className="btn btn-outline-success" style={{minWidth: 70}} onClick={() => onDownloadDocument(document, document.contact)}>
                                            <i className="fas fa-download mr2 " />
                                        </button>
                                    </>
                                ) : '-'}

                                {/* <button className="btn btn-outline-success" style={{minWidth: 70}} onClick={() => onDownloadDocument(document, document.contact)}>
                                    <i className="fas fa-download mr2 " />
                                </button> */}

                            </td>
                        </tr>
                    )}) : (
                        <tr>
                            <td>No Documents Created</td>
                            <td></td>
                            <td></td>

                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
            ) : (
            <table className="table" style={{tableLayout: 'fixed'}}>
                <thead>
                    <tr>
                        <th>Document</th>
                        <th className="text-right" style={{width: 90}}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.length ? documents.map(document => (
                        <tr key={document._id}>
                            <td>
                                {
                                    document.status === 'failed' ? (
                                        <Badge color="danger" className="tex-sm" style={styles.badgeMobile}><i className="fas fa-times " /></Badge>
                                    ) : document.status === 'completed' ? (
                                        <Badge color="success" className="tex-sm" style={styles.badgeMobile}><i className="fas fa-check " /></Badge>
                                    ) : (
                                        <Badge color="warning" className="tex-sm" style={styles.badgeMobile}><i className="fas fa-sync " /></Badge>
                                    )
                                }
                                {document.status === 'completed' ? (
                                    document.contact ? (
                                        <span className="cursor-pointer">
                                            <Link to={`/dashboard/contacts/${document.contact._id}`}>
                                                {getDownloadName(document, document.contact)}
                                            </Link>
                                        </span>
                                    ) : (
                                        getDownloadName(document, document.contact)
                                    )
                                ): (
                                    getDownloadName(document, document.contact)
                                )}
                                
                            </td>
                            <td className="text-right" style={{width: 90}}>
                                {document.status === 'completed' ? (
                                    <>
                                        <button 
                                            className="btn btn-outline-success" 
                                            onClick={() => onDownloadDocument(document, document.contact)}
                                            style={{minWidth: 70}}
                                        >
                                            <i className="fas fa-download mr2 " />
                                        </button>
                                    </>
                                ) : '-'}
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td>No Documents Created</td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
            )}
        </div>

    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 120
    },
    badgeMobile: {
        display: 'inline-block',
        width: 30,
        marginRight: 10
    }
}

const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(TableDocuments);