import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import moment from 'moment';
import { Card, Container, CardBody, CardHeader, CardTitle, CardFooter } from "reactstrap";

import reactHTMLParser from 'react-html-parser'

import _notifications from '_functions/notifications';

import Circle from 'components/markup/loading/Circle'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

const NotificationsAll = ({match}) => {

    const [notification, setNotification] = useState(null);
    const [redirect, setRedirect] = useState(false);

    const toggleSeen = useCallback((notification_id) => {
        _notifications.seen(notification_id);

        const newState = JSON.parse(JSON.stringify(notification));
        newState.marked_seen_at = newState.marked_seen_at ? 0 : 123;
        setNotification(newState);
    }, [notification])

    const fetchNotification = useCallback(async () => {
        const data = await _notifications.findById(match.params.notification_id);
        if(data.data) return setNotification(data.data)

        setRedirect(`/dashboard/notifications/all`)
    }, [match.params.notification_id])

    useEffect(() => {
        fetchNotification()
    }, [fetchNotification])

    if(redirect) return <Redirect to={redirect} />
    if(!notification) return <div className="py-4"><Circle /></div>
   
    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Documents`}</title>
                <meta name="description" content="Documents" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Notifications"
                breadcrumb_2="View"
                icon="fas fa-comment"
                actionComponent={(
                    <div>
                        <button 
                            onClick={() => toggleSeen(match.params.notification_id)}
                            className={notification.marked_seen_at ? "btn btn-info" : 'btn btn-success'}
                        >
                            {notification.marked_seen_at ? "Mark Unseen" : 'Mark Seen'}
                        </button>
                        <Link to="/dashboard/notifications/all" className="btn btn-outline-warning">
                            <i className="fas fa-arrow-left mr-2" />
                            Back
                        </Link>
                    </div>
                )}
            />
    
            <Card className="card-color card-primary">    
                <CardHeader>
                    <CardTitle className="mb-0" >
                        <b className="text-dark">Subject: </b>
                        {notification.subject}
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {reactHTMLParser(notification.body)}
                </CardBody>

                <CardFooter>
                    <p className="text-sm mb-0">
                    {moment(notification.date).format('MMM Do, YYYY - h:mm A')}
                    </p>
                </CardFooter>

            </Card>
    
        </Container>
    )

}

export default NotificationsAll;