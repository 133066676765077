import React from "react";
import { Modal } from "reactstrap";
import formatObject from 'utils/formatObject';
import formatText from 'utils/formatText';

import _users from '_functions/users';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system'

const UsersModalDelete = ({showModal, toggleModal, selectedRows, query}) => {

    const onDelete = () => {

        toggleStandardLoader(true);

        let counter = 0;
        let passed  = 0;

        // for each contact run the endpoint to delete them
        selectedRows.forEach(async user => {

            const deleted = await _users.delete(user._id);
            
            // see how many passed deletion
            if(deleted.success) passed++;
            counter++;

            // when all deletion events ran
            if(counter === selectedRows.length) {

                // matched passed deletion to total contacts, if they match we have total success
                if(passed === selectedRows.length) {
                    toggleAlertBS(false, 'Contacts Deleted Successfully');
                } else {
                    toggleAlertBS(true, 'Some or all of the specified contacts were not deleted.')
                }

                // kill the modal, stop the load, fire the onFinish prop function
                toggleModal(false)
                toggleStandardLoader(false);
                query();

            }

        })

    }

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => toggleModal(false)}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Set User Inactive</h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleModal(false)} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom">
                <p className="text-sm mb-0">
                    <i className="fas fa-exclamation-triangle mr-2 text-danger " />
                    This will prevent the following user(s) from being able to log in:
                </p>
            </div>

            <div className="table-responsive px-3 bg-secondary">
                <table className="table mb-0">

                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th className="text-right">Phone</th>
                        </tr>
                    </thead>

                    <tbody>
                        {selectedRows.length && selectedRows[0] !== false ? selectedRows.map(user => (
                            <tr key={user._id}>
                                <td className="text-capitalize">{formatObject(user).name()}</td>
                                <td>{user.email ? user.email : '-'}</td>
                                <td className="text-right">{user.phone ? formatText(user.phone).phone() : '-'}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td>You have not selected any users</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>

            <div className="modal-footer text-right">
                <button onClick={() => toggleModal(false)} className="btn btn-neutral">
                    <i className="fas fa-arrow-left mr-2 " /> Cancel
                </button>
                <button disabled={selectedRows.length ? false : true} onClick={onDelete} className="btn btn-danger">
                    <i className="fas fa-trash mr-2 " /> Disable User
                </button>
            </div>

        </Modal>
    
    )

}

export default UsersModalDelete
