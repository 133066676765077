/*
Documentation

this page holds all of our layouts and functions
that need to fire before the app runs

*/

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Auth from 'views/auth/layout';
import Dashboard from 'views/dashboard/layout';
import Editor from 'views/editor/layout';
import Onboarding from 'views/onboarding/layout';
import Actions from 'views/actions/layout';
import Errors from 'views/errors/layout';
import Redirects from 'views/redirects/layout';

import FailedConnection from 'views/errors/pages/errors/FailedConnection';

import SystemAlertBS from 'components/markup/system/AlertBS'
import SystemAlert from 'components/markup/system/Alert'
import SystemLoader from 'components/markup/system/Loader'
import StandardLoader from 'components/markup/system/StandardLoader'

import LoggedInRoute from 'components/system/Routing/LoggedInRoute'

import init from './init'

class App extends Component {

    state = {
        loaded: false,
        error: false
    };

    componentDidMount = async () => {

        const loadedSuccessfully = await init();
        this.setState({loaded: true, error: !loadedSuccessfully})

    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        if(this.state.shouldRedirect) {
            return window.location.href = this.state.shouldRedirect
        }

        if(!this.state.loaded) {
            return <div></div>
        }

        if(this.state.error) {
            return <FailedConnection />
        }

        return (

            <>

                <SystemAlertBS />
                <SystemAlert />
                <SystemLoader />
                <StandardLoader />

                <BrowserRouter>
                    <Switch>

                        <Route path="/auth" render={props => <Auth {...props} /> } />

                        <Route path="/redirects" render={props => 
                            <Redirects>
                                <Dashboard {...props} /> 
                            </Redirects>
                        } />
                        <Route path="/dashboard" render={props => 
                            <LoggedInRoute>
                                <Dashboard {...props} /> 
                            </LoggedInRoute>
                        } />
                        <Route path="/editor" render={props => 
                            <LoggedInRoute>
                                <Editor {...props} /> 
                            </LoggedInRoute>
                        } />
                        <Route path="/onboarding" render={props => 
                            <LoggedInRoute>
                                <Onboarding {...props} /> 
                            </LoggedInRoute>
                        } />
                        <Route path="/actions" render={props => 
                            <LoggedInRoute>
                                <Actions {...props} /> 
                            </LoggedInRoute>
                        } />
                        <Route path="/errors" render={props => <Errors {...props} /> } />

                        <Route path="/" render={props => <Redirect to="/auth/login" /> } />

                    </Switch>
                </BrowserRouter>

            </>

        )

    }

}

export default App
