import ClioApprove     from '../pages/Clio/Approve';
import ClioApproveDevelopment     from '../pages/Clio/ApproveDevelopment';
import ClioAdd     from '../pages/Clio/Add';
import ClioAddDevelopment     from '../pages/Clio/AddDevelopment';
import ClioDeactivate     from '../pages/Clio/Deactivate';
import ClioDeactivateDevelopment     from '../pages/Clio/DeactivateDevelopment';

import ZohoApproveDevelopment     from '../pages/Zoho/ApproveDevelopment';
import ZohoApprove     from '../pages/Zoho/Approve';

const routes = () =>  [


    /***********************
    DEVELOPMENT
    ************************/
    {
        path: "/clio/a",
        component: ClioApprove,
    },
    {
        path: "/clio/a/development",
        component: ClioApproveDevelopment,
    },
    {
        path: "/clio/d",
        component: ClioDeactivate,
    },
    {
        path: "/clio/d/development",
        component: ClioDeactivateDevelopment,
    },
    {
        path: "/clio/add",
        component: ClioAdd,
    },
    {
        path: "/clio/add/development",
        component: ClioAddDevelopment,
    },
    
    
    
    {
        path: "/zoho/a/development",
        component: ZohoApproveDevelopment,
    },
    {
        path: "/zoho/a",
        component: ZohoApprove,
    },
   
]

export default routes;
