import { useState, useCallback } from "react";
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Form, FormGroup, Row, Progress } from "reactstrap";

import _companies from '_functions/companies';

import Icon from './_images/PinwheelLink.svg'
import Dots from 'components/markup/loading/Dots'

const VerificationAccess = ({viewing_user, company, onSetView}) => {

    const [loading, setLoading] = useState(false)
    const [acceptFinancial, setAcceptFinancial] = useState(company.accept_financial)
    const [acceptEmployment, setAcceptEmployment] = useState(company.accept_employment)
    const [acceptAutoPolicies, setAcceptAutoPolicies] = useState(company.accept_auto_policies)

    const [err, setErr] = useState(false);

    const onSave = useCallback(async (e) => {

        e.preventDefault();
        setErr(null);

        if(!acceptFinancial && !acceptEmployment && !acceptAutoPolicies) {
            return setErr([`You must select at least one of the options above.`])
        }

        setLoading(true)
        const saved = await _companies.update(viewing_user.company, {
            accept_financial:  acceptFinancial,
            accept_employment:  acceptEmployment,
            accept_auto_policies:  acceptAutoPolicies,
        })

        if(saved.success) {
            onSetView('ReplyTo')
        } else {
            setLoading(false);
            setErr(saved.message)
        }

    }, [acceptFinancial, acceptEmployment, viewing_user.company, onSetView, acceptAutoPolicies])

    return (

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Form role="form" style={{zIndex: 2, position: 'relative'}}>

                        <Card className="bg-white border-0 mb-0 position-relative" >

                            <img src={Icon} alt="..." className="flow-icon bg-white" />

                            <CardHeader>
                                <CardTitle className="flow-icon-header mb-0">Welcome To Zap Reports!</CardTitle>
                            </CardHeader>

                            <CardHeader>
                                <div className="progress-wrapper mt--3 pt-0">
                                    <div className="progress-sucess">
                                        <div className="progress-label" style={{display: 'inline'}}>
                                            <span className="text-info" style={{position: 'relative', top: 13,}}>Onboarding</span>
                                        </div>
                                        <div className="progress-percentage">
                                        <span style={{position: 'relative', top: -12}}>50%</span>
                                        </div>
                                    </div>
                                    <Progress max="100" value={50} color="" />
                                </div>
                            </CardHeader>

                            <CardHeader className="bg-secondary">
                                <p className="text-sm mb-0">Now, what type of documents are you looking to collect?</p>
                            </CardHeader>

                            <CardHeader >

                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-accept-financial"
                                            type="checkbox"
                                            checked={acceptFinancial}
                                            onChange={(e) => setAcceptFinancial(!acceptFinancial)}
                                        />
                                        <label className="custom-control-label" htmlFor="archk-accept-financial">
                                            Financial Accounts, Banks, Apps, etc.
                                        </label>
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-accept-employment"
                                            type="checkbox"
                                            checked={acceptEmployment}
                                            onChange={(e) => setAcceptEmployment(!acceptEmployment)}
                                        />
                                        <label className="custom-control-label" htmlFor="archk-accept-employment">
                                            Employment Records, Pay Stubs, Unemployment, etc.
                                        </label>
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-accept-auto"
                                            type="checkbox"
                                            checked={acceptAutoPolicies}
                                            onChange={(e) => setAcceptAutoPolicies(!acceptAutoPolicies)}
                                        />
                                        <label className="custom-control-label" htmlFor="archk-accept-auto">
                                            Auto Insurance Policies and Declaration Pages
                                        </label>
                                    </div>
                                </FormGroup>

                            </CardHeader>

                            {err ? (
                            <CardHeader>
                                {err.map((e, i) => <p key={i} className="mb-0 text-sm text-danger font-weight-bold">{e}</p>)}
                            </CardHeader>
                            ) : null}

                            <CardFooter className="text-center">
                                {loading ? (
                                     <button disabled={true} className="my-2 btn-block btn btn-success btn-lg">
                                        Move On<Dots />
                                    </button>
                                ) : (
                                    <button onClick={onSave} className="my-2 btn-block btn btn-success btn-lg">
                                        Move On <i className="fas fa-arrow-right ml-2 " />
                                    </button>
                                )}
                            </CardFooter>
                        </Card>

                        <p className="text-sm text-center mt-3">
                            <span onClick={() => onSetView('SetEmail')} className="cursor-pointer">
                                <i className="fas fa-arrow-left mr-2 " /> Back
                            </span>
                        </p>

                    </Form>

                </Col>
            </Row>
        </Container>

    )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(VerificationAccess);

