import keys from 'keys';

import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

import { useState, useEffect, useCallback } from 'react';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import Circle from 'components/markup/loading/Circle';

import _integrations from '_functions/integrations';

import ImageClio from './_images/Clio.png'
import ImageClioLogo from './_images/ClioLogo.jpg'

import ConfirmationModal from 'components/functional/modals/Confirmation'

const IntegrationsClio = ({company, setCompany}) => {

    const [showModal, setShowModal] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [clio, setClio] = useState({
        name: 'clio',
        enabled: false,
        fields: {
            api_endpoint: '',
            api_key: '',
            api_secret: '',
        }
    });

    const onRemove = useCallback(async () => {

        toggleStandardLoader(true);

        const updated = await _integrations.clio.disable()

        if(updated.success) {
            toggleAlertBS(false, 'Your integration with Clio has been removed.');
            setCompany();
        } else {
            toggleAlertBS(true, updated.message);
        }

        toggleStandardLoader(false);
    }, [setCompany])

    useEffect(() => {
        if(company.integrations && company.integrations.length) {
            const _clio = company.integrations.find(i => i.name === 'clio')
            if(_clio) setClio(_clio)
        }
        setLoaded(true)
    }, [company.integrations])

    // needed so useEffect can run
    if(!loaded) return <Circle />


    return (
        <Card className="mb-3">

            <ConfirmationModal 
                showModal={showModal}
                toggleModal={() => setShowModal(false)}
                title="Remove Clio Integration"
                body={(
                    <span>
                      Are you sure you wish to remove your integration with Clio? You will not be able to add contacts from you Clio Dashboard and no documents will populate inside your Clio Matters From Zap Reports.
                    </span>
                )}
                onConfirmation={onRemove}
            />

            <CardHeader>
                <Row>
                    <Col lg={6} className="align-self-center">
                        <CardTitle className="mb-0">
                            <img src={ImageClioLogo} alt="Clio" className='' style={{width: 100}} />

                        </CardTitle>
                    </Col>
                    <Col lg={6} className="align-self-center text-left text-sm-right">
                        <label className="custom-toggle">
                            <input 
                                defaultChecked={clio.enabled}
                                disabled={true}
                                type="checkbox" 
                                onChange={() => {}} 
                                value={clio.enabled}
                            />
                            <span 
                                className="custom-toggle-slider rounded-circle" 
                                data-label-off={clio.enabled ? "ON" : 'OFF'}
                                data-label-on={clio.enabled ? "ON" : 'OFF'}
                            />
                        </label>
                    </Col>
                </Row>
            </CardHeader>
    
            {clio.enabled ? (
                <CardBody className="bg-secondary">
                    <Row>
                        <Col xl={7} lg={6} className="align-self-center">

                            <p style={{maxWidth: 500}} className='text-sm'>You're account is currently <b className="text-dark">connected with Clio!</b></p>
                            <p style={{maxWidth: 500}} className='text-sm'>All documents gathered with Zap Reports contacts who came from Clio will go <b className="text-dark">directly into their associated Clio Matter.</b> </p>

                            <p className="text-sm mb-0 text-warning font-weight-bold">
                                <span className="cursor-pointer" onClick={() => setShowModal(true)}><i className="fas fa-trash mr-2 " /> Disconnect Clio</span>
                            </p>
                        </Col>
                        <Col xl={5} lg={6} className="align-self-center">
                            <img src={ImageClio} alt="Clio" className='w-100 z-depth-3 border rounded' />
                        </Col>
                    </Row>
                </CardBody>
            ) : (
                <CardBody className="bg-secondary">
                    <Row>
                        <Col xl={7} lg={6} className="align-self-center">

                            <p style={{maxWidth: 500}} className='text-sm'>Zap Reports makes importing contacts from your Clio account as simple as <b className="text-dark">just two clicks.</b></p>
                            <p style={{maxWidth: 500}} className='text-sm'>We send all document gathered with Zap Reports <b className="text-dark">directly into your Clio Matters</b> and it works for both individual and joint cases.</p>
                            <p style={{maxWidth: 500}} className='text-sm mb-5'>All it takes it to log into your clio account you'll notice a new <b className="text-dark">button</b> inside of all <b className="text-dark">Clio Matters</b> that says "Order Zap Report".</p>
                            <p style={{maxWidth: 500}} className='text-sm mb-5'>For Clio extension installation and assistance please reach out to john@zapreports.com</p>

                            <a 
                                href={`https://app.clio.com/oauth/authorize?response_type=code&client_id=${keys.CLIO_APP_KEY}&redirect_uri=${keys.CLIO_REDIRECT_URI}&state=${company.created_at}`}
                                className="btn btn-outline-success"
                            >
                                <i className="fas fa-link mr-2 " /> Connect With CLIO!
                            </a> 
                        </Col>
                        <Col xl={5} lg={6} className="align-self-center">
                            <img src={ImageClio} alt="Clio" className='w-100 z-depth-3 border rounded' />
                        </Col>
                    </Row>
                </CardBody>
            )}

        </Card>
    )

}
export default IntegrationsClio;
