import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';

const DashboardAggregation = ({info}) => (
    <Card className="card-aggregation" id="archk-tutorial-overview">
        <CardBody className="py-0">

            <Row className="text-center">

                <Col lg={2} xs={6} className="align-self-center">
                     <Link to="/dashboard/documents">
                        <div className="py-3 archk-dashboard-box">
                            <p className="mb-0 text-sm">Documents </p>
                            <div className="py-1">
                                <i className="fas fa-file text-info-original mr-2 display-3 mt--3 mb-0"/>
                            </div>
                        </div>
                    </Link>
                </Col>
                
                <Col lg={2} xs={6}>
                    <Link to="/dashboard/documents?filter=completed" >
                        <div className="py-3 archk-dashboard-box" id="archk-tutorial-documents-completed">
                            <p className="mb-0 text-sm">Completed </p>
                            <div className="py-1">
                                <h2 className="display-3 mt--3 mb-0 text-success">{info.aggregation.completed}</h2>
                            </div>  
                        </div>
                    </Link>
                </Col>

                <Col lg={2} xs={6}>
                    <Link to="/dashboard/documents?filter=in queue">
                        <div className="py-3 archk-dashboard-box" id="archk-tutorial-documents-in-queue">
                            <p className="mb-0 text-sm">In Queue </p>
                            <div className="py-1">
                                <h2 className="display-3 mt--3 mb-0 text-warning">{info.aggregation.in_queue}</h2>
                            </div>  
                        </div>
                    </Link>
                </Col>

                <Col lg={2} xs={6}>
                    <Link to="/dashboard/documents?filter=pending">
                        <div className="py-3 archk-dashboard-box" id="archk-tutorial-documents-pending">
                            <p className="mb-0 text-sm">Pending </p>
                            <div className="py-1">
                                <h2 className="display-3 mt--3 mb-0">{info.aggregation.retrying + info.aggregation.aggregating + info.aggregation.needs_verification}</h2>
                            </div>
                        </div>
                    </Link>
                </Col>

                <Col lg={2} xs={6}>
                    <Link to="/dashboard/documents?filter=failed">
                        <div className="py-3 archk-dashboard-box" id="archk-tutorial-documents-failed">
                            <p className="mb-0 text-sm">Failed </p>
                            <div className="py-1">
                                <h2 className="display-3 mt--3 mb-0 text-danger">{info.aggregation.failed}</h2>
                            </div>
                        </div>
                    </Link>
                </Col>

                <Col lg={2} xs={6} className="text-center border-left align-self-center">
                    <Link to="/dashboard/documents">
                        <div className="py-3 archk-dashboard-box" id="archk-tutorial-documents-total">
                            <p className="mb-0 text-sm">Total </p>
                            <div className="py-1">
                                <h2 className="display-3 mt--3 mb-0 text-success">
                                    {info.aggregation.total}
                                </h2>
                            </div>
                        </div>
                    </Link>
                </Col>

            </Row>
        </CardBody>

        <CardBody className="py-0 border-top">

            <Row className="text-center">

                <Col lg={2} xs={6} className="align-self-center">
                    <Link to="/dashboard/contacts">
                        <div className="py-3 archk-dashboard-box">
                            <p className="mb-0 text-sm">Clients </p>
                            <div className="py-1">
                                <i className="fas fa-users text-info-original mr-2 display-3 mt--3 mb-0"/>
                            </div>
                        </div>
                    </Link>
                </Col>

                <Col lg={2} xs={6}>
                    <Link to="/dashboard/contacts?filter=complete" >
                        <div className="py-3 archk-dashboard-box" id="archk-tutorial-contacts-finished">
                            <p className="mb-0 text-sm">Finished </p>
                            <div className="py-1">
                                <h2 className="display-3 mt--3 mb-0 text-success">{info.status.complete}</h2>
                            </div>
                        </div>
                    </Link>
                </Col>

                <Col lg={2} xs={6}>
                    <Link to="/dashboard/contacts?filter=in progress" >
                        <div className="py-3 archk-dashboard-box" id="archk-tutorial-contacts-in-progress">
                            <p className="mb-0 text-sm">In Progress </p>
                            <div className="py-1">
                                <h2 className="display-3 mt--3 mb-0 text-warning">{info.status.in_progress}</h2>
                            </div>
                        </div>
                    </Link>
                </Col>

                <Col lg={2} xs={6}>
                    <Link to="/dashboard/contacts?filter=requested" >
                        <div className="py-3 archk-dashboard-box" id="archk-tutorial-contacts-requested">
                            <p className="mb-0 text-sm">Requested </p>
                            <div className="py-1">
                                <h2 className="display-3 mt--3 mb-0">{info.status.requested}</h2>
                            </div>
                        </div>
                    </Link>
                </Col>

                <Col lg={2} xs={6}>
                      <Link to="/dashboard/contacts?filter=null" >
                        <div className="py-3 archk-dashboard-box" id="archk-tutorial-contacts-no-status">
                            <p className="mb-0 text-sm">No Status </p>
                            <div className="py-1">
                                <h2 className="display-3 mt--3 mb-0 text-danger">{info.status.null}</h2>
                            </div>
                        </div>
                    </Link>
                </Col>

                <Col lg={2} xs={6} className="text-center border-left align-self-center">
                    <Link to="/dashboard/contacts">
                        <div className="py-3 archk-dashboard-box" id="archk-tutorial-contacts-total">
                            <p className="mb-0 text-sm">Total </p>
                            <div className="py-1">
                                <h2 className="display-3 mt--3 mb-0 text-success">
                                    {info.status.total}
                                </h2>
                            </div>
                        </div>
                    </Link>
                </Col>

            </Row>

        </CardBody>
    </Card>
)

export default DashboardAggregation
