import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup, Input } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'
import simulateTabOnEnter from '../_utils/simulateTabOnEnter'
import focusNextQuestion from '../_utils/focusNextQuestion'

const Date = ({question, answers, onChange, setActive, loopIndex, errors}) => {

    const [val, setVal] = useState('')
    const [_question, _setQuestion] = useState({})

    const hasErr = errors.includes(_question.id) && !val ? true : false;

    const handleChange = useCallback((e) => {
        const value = e.target.value;
        setVal(value);
        onChange(_question, e)
        setActive(false);
        if(value) focusNextQuestion(e);
    }, [_question, onChange, setActive])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        setVal(answers[formattedQuestion.id] || '')
    }, [question, loopIndex, answers])

    return (
        <FormGroup className="position-relative">        
            <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`} htmlFor={_question.id}>
                {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                {_question.name}
            </label>
            {question.description ? (
                <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
            ) : null}
            <Input 
                id={_question.id}
                value={val}
                type="date"
                onChange={handleChange}
                invalid={hasErr}
                onBlur={() => setActive(false)}
                onFocus={() => {
                    setTimeout(() => {
                        try {
                            const el = document.getElementById(_question.id)
                            if(el === document.activeElement) {
                                el.showPicker()
                            } else {
                                setActive(false)
                            }
                        } catch(e) {
                        }
                    }, 250)
                    setActive(true)
                }}
                onKeyDown={simulateTabOnEnter}
            />
        </FormGroup>  
    )
}

export default memo(Date);