import LOGO from 'assets/img/brand/ZapReportsCut.png'
import LOGO_WHITE from 'assets/img/brand/ZapReportsLogo.png'

let keys = {
    LOGO,
    LOGO_WHITE,
    COMPANY: 'Zap Reports',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'client.zapreports.com') {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-58163098451',
        MX_CLIENT_ID              : '',
        API_URL                   : 'https://api.zapreports.com',
        REPORT_URL                : 'https://reports.zapreports.com',
        SITE_URL                  : 'https://zapreports.com',
        FORM_URL                  : 'https://forms.zapreports.com',
        SIGN_URL                  : 'https://sign.zapreports.com',
        REVIEW_URL                : 'https://review.zapreports.com',
        FAST_LINK_URL             : 'https://fl4.prod.yodlee.com/authenticate/USDevexProd3-263/fastlink?channelAppName=usdevexprod3',
        FAST_LINK_CONFIGURATION   : 'Config1',
        BLUE_FIN_API_URL          : 'https://secure.payconex.net',
        BLUE_FIN_ACCOUNT_ID       : '120615600564',
        CLIO_APP_KEY              : 'ZsVx82G37CSbkKU1PqkJv6xhnJW5QYubdKqhAHJO',
        CLIO_REDIRECT_URI         : 'https://client.zapreports.com/redirects/clio/a',
        ZOHO_CLIENT_ID            : '1000.C2YFWPL3QCUKA4O3K7926VK7XUXOBK',
        ZOHO_REDIRECT_URI         : 'https://client.zapreports.com/redirects/zoho/a',
    }

//using staging keys
} else if(HOSTNAME !== 'localhost') {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-946154718',
        MX_CLIENT_ID              : '4f7d5f10-c1fb-4d43-b034-7d8d2296e1ba',
        API_URL                   : 'https://staging.api.zapreports.com',
        REPORT_URL                : 'https://staging.reports.zapreports.com',
        SITE_URL                  : 'https://staging.zapreports.com',
        FORM_URL                  : 'https://staging.forms.zapreports.com',
        SIGN_URL                  : 'https://staging.sign.zapreports.com',
        REVIEW_URL                : 'https://staging.review.zapreports.com',
        FAST_LINK_URL             : 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd3-3/fastlink?channelAppName=usdevexpreprod3',
        FAST_LINK_CONFIGURATION   : 'Config1',
        BLUE_FIN_API_URL          : 'https://cert.payconex.net',
        BLUE_FIN_ACCOUNT_ID       : '220614985101',
        CLIO_APP_KEY              : '0M0XXBI1DJ8MgD2UfGnxmEpDHAAlLix43hljDid7',
        CLIO_REDIRECT_URI         : 'https://staging.client.zapreports.com/redirects/clio/a',
        ZOHO_CLIENT_ID            : '1000.A3MZVHB05885F574T8903UQWV8SH2T',
        ZOHO_REDIRECT_URI         : 'https://staging.client.zapreports.com/redirects/zoho/a',

    }

//using development keys
} else {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-',
        MX_CLIENT_ID              : '4f7d5f10-c1fb-4d43-b034-7d8d2296e1ba',
        API_URL                   : 'http://localhost:5004',
        REPORT_URL                : 'http://localhost:3001',
        SITE_URL                  : 'http://localhost:3003',
        FORM_URL                  : 'http://localhost:3004',
        SIGN_URL                  : 'http://localhost:3006',
        REVIEW_URL                : 'http://localhost:3007',
        FAST_LINK_URL             : 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd3-3/fastlink?channelAppName=usdevexpreprod3',
        FAST_LINK_CONFIGURATION   : 'Config1',
        BLUE_FIN_API_URL          : 'https://cert.payconex.net',
        BLUE_FIN_ACCOUNT_ID       : '220614985101',
        CLIO_APP_KEY              : 'UrEkbNSVTVbkLjamxZiiP38tuLwFz79w8uiz7Shr',
        CLIO_REDIRECT_URI         : 'https://staging.client.zapreports.com/redirects/clio/a/development',
        ZOHO_CLIENT_ID            : '1000.8B2GSK6MKI78JEYXVGJ73RK79JYJ2D',
        ZOHO_REDIRECT_URI         : 'https://staging.client.zapreports.com/redirects/zoho/a/development',

    }

}

export default keys
