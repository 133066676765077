import { connect } from 'react-redux';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Card } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import _dashboard from '_functions/dashboard';

import Aggregation from './Aggregation';
import Contacts from './Contacts';
import Documents from './Documents';
import Forms from './Forms';

import LoadingTable from 'components/markup/loading/Table.js'

const Dashboard = ({company}) => {

    const [info, setInfo] = useState(null);

    const fetchInfo = async () => {
        const foundInfo = await _dashboard.info()
        if(foundInfo.data) setInfo(foundInfo.data)
    }

    useState(() => {
        fetchInfo()
    }, [])

    return (
        <Container fluid>

            <Helmet>
                <title>{`Contacts`}</title>
                <meta name="description" content="Contacts" />
            </Helmet>

            <DashHeaderOpen 
                id="archk-tutorial-documents"
                title={<span>Dashboard</span>}
                breadcrumb_1="Month Overview"
                icon="fas fa-chart-line"
            />

            {info ? (
                <>
                    <Aggregation info={info} />
                    {company.forms_enabled ? <Forms info={info} />: null}
                    <Contacts info={info} />
                    <Documents info={info} />
                </>
            ) : (   
                <>
                    <Card><LoadingTable rows={4}/></Card>
                    <Card className="mt-4"><LoadingTable rows={10}/></Card>
                    <Card className="mt-4"><LoadingTable rows={10}/></Card>
                </>
            )}
           
        </Container>
    )

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(Dashboard);