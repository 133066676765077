import { Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import ModalToggler from 'components/functional/modals/Toggler'

import ModalSettings from '../ModalSettings'

const FormHeader = ({form, onSetForm, onSaveToDB, unsavedForm, config}) => {

    const history = useHistory();

    return (
        
        <div className="form-header py-3">
            <Row>
                
                <Col lg={6} className="align-self-center">
                    <h2 className="display-5 mb-0 mt-0">
                        <span className="cursor-pointer">
                            <ModalToggler form={form} onSetForm={onSetForm} config={config} component={ModalSettings}>
                                <i className="fab fa-wpforms mr-2 text-success mr-4 pr-2" /> 
                                {form.name}{' '}
                            </ModalToggler>
                        </span>
                    </h2>
                </Col>

                <Col lg={6} className="align-self-center text-right">
                    {unsavedForm ? (
                        // a is used here to trigger the beforeunload event if form is not saved
                        <a href="/dashboard/forms/all" className="btn btn-outline-danger mr-3">
                            <i className="fas fa-arrow-left " /> Back
                        </a>
                    ) : (
                        <button onClick={() => history.goBack()} className="btn btn-outline-danger mr-3">
                            <i className="fas fa-arrow-left " /> Back
                        </button>
                    
                    )}
                   
                    <ModalToggler form={form} onSetForm={onSetForm} config={config} component={ModalSettings}>
                        <button className="btn btn-s btn-outline-info mr-3">
                            <i className="fas fa-cogs mr-2 " /> Form Settings
                        </button>
                    </ModalToggler>
                    
                    <button 
                        disabled={!unsavedForm}
                        className={unsavedForm ? `btn btn-success` : `btn btn-outline-success`} 
                        onClick={onSaveToDB}
                    >
                        {unsavedForm ? (
                            <span><i className="fas fa-save mr-2 " /> Save Changes</span>
                        ) : (
                            <span><i className="fas fa-check mr-2 " />Form Saved</span>
                        ) }
                        
                    </button>
                </Col>

            </Row>
        </div>
    )
}

export default FormHeader;