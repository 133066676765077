import keys from 'keys';

import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

import { useState, useEffect, useCallback } from 'react';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import Circle from 'components/markup/loading/Circle';

import _integrations from '_functions/integrations';

import ZohoScreenshot from './images/ZohoScreenshot.png'
import ZohoLogo from './images/ZohoLogo.png'

import ConfirmationModal from 'components/functional/modals/Confirmation'

const IntegrationsZoho = ({company, setCompany}) => {

    const [showModal, setShowModal] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [zoho, setZoho] = useState({
        name: 'zoho',
        enabled: false,
        fields: {
            api_endpoint: '',
            api_key: '',
            api_secret: '',
        }
    });

    const onRemove = useCallback(async () => {

        toggleStandardLoader(true);

        const updated = await _integrations.zoho.disable()

        if(updated.success) {
            toggleAlertBS(false, 'Your integration with Zoho has been removed.');
            setCompany();
        } else {
            toggleAlertBS(true, updated.message);
        }

        toggleStandardLoader(false);
    }, [setCompany])

    useEffect(() => {
        if(company.integrations && company.integrations.length) {
            const _zoho = company.integrations.find(i => i.name === 'zoho')
            if(_zoho) setZoho(_zoho)
        }
        setLoaded(true)
    }, [company.integrations])

    // needed so useEffect can run
    if(!loaded) return <Circle />


    return (
        <Card className="mb-3">

            <ConfirmationModal 
                showModal={showModal}
                toggleModal={() => setShowModal(false)}
                title="Remove Zoho Integration"
                body={(
                    <span>
                      Are you sure you wish to remove your integration with Zoho? This will prevent all document from automatically going into a contacts file within your Zoho account.
                    </span>
                )}
                onConfirmation={onRemove}
            />

            <CardHeader>
                <Row>
                    <Col lg={6} className="align-self-center">
                        <CardTitle className="mb-0">
                            <img src={ZohoLogo} alt="Zoho" className='' style={{width: 100}} />

                        </CardTitle>
                    </Col>
                    <Col lg={6} className="align-self-center text-left text-sm-right">
                        <label className="custom-toggle">
                            <input 
                                defaultChecked={zoho.enabled}
                                disabled={true}
                                type="checkbox" 
                                onChange={() => {}} 
                                value={zoho.enabled}
                            />
                            <span 
                                className="custom-toggle-slider rounded-circle" 
                                data-label-off={zoho.enabled ? "ON" : 'OFF'}
                                data-label-on={zoho.enabled ? "ON" : 'OFF'}
                            />
                        </label>
                    </Col>
                </Row>
            </CardHeader>
    
            {zoho.enabled ? (
                <CardBody className="bg-secondary">
                    <Row>
                        <Col xl={7} lg={6} className="align-self-center">

                            <p style={{maxWidth: 500}} className='text-sm'>You're account is currently <b className="text-dark">connected with Zoho!</b></p>
                            <p style={{maxWidth: 500}} className='text-sm'>All documents gathered with Zap Reports contacts who came from Zoho will go <b className="text-dark">directly into their associated Zoho contact file.</b> </p>

                            <p className="text-sm mb-0 text-warning font-weight-bold">
                                <span className="cursor-pointer" onClick={() => setShowModal(true)}><i className="fas fa-trash mr-2 " /> Disconnect Zoho</span>
                            </p>
                        </Col>
                        <Col xl={5} lg={6} className="align-self-center">
                            <img src={ZohoScreenshot} alt="Zoho" className='w-100 z-depth-3 border rounded' />
                        </Col>
                    </Row>
                </CardBody>
            ) : (
                <CardBody className="bg-secondary">
                    <Row>
                        <Col xl={7} lg={6} className="align-self-center">

                            <p style={{maxWidth: 500}} className='text-sm'>Zap Reports makes importing contacts from your Zoho account as simple as <b className="text-dark">just two clicks.</b></p>
                            <p style={{maxWidth: 500}} className='text-sm'>We send all document gathered with Zap Reports <b className="text-dark">directly into their Zoho file.</b> </p>
                            <p style={{maxWidth: 500}} className='text-sm mb-5'>All it takes it to log into your Zoho account you'll notice a new <b className="text-dark">button</b> inside of all <b className="text-dark">Zoho Contact Pages</b> that says "Order Zap Report".</p>
                            <p style={{maxWidth: 500}} className='text-sm mb-5'>For Zoho extension installation and assistance please reach out to john@zapreports.com</p>

                            <a 
                                href={`https://accounts.zoho.com/oauth/v2/auth?scope=WorkDrive.files.CREATE,ZohoCRM.modules.Contacts.ALL,ZohoCRM.modules.Deals.ALL,ZohoCRM.modules.attachments.ALL&client_id=${keys.ZOHO_CLIENT_ID}&response_type=code&access_type=offline&redirect_uri=${keys.ZOHO_REDIRECT_URI}`}
                                className="btn btn-outline-success"
                            >
                                
                                <i className="fas fa-link mr-2 " /> Connect With Zoho!
                            </a> 
                        </Col>
                        <Col xl={5} lg={6} className="align-self-center">
                            <img src={ZohoScreenshot} alt="Zoho" className='w-100 z-depth-3 border rounded' />
                        </Col>
                    </Row>
                </CardBody>
            )}

        </Card>
    )

}
export default IntegrationsZoho;
