/*
Documentation

this component renders the connect widget with MX

*/

import { useState, useCallback, useEffect } from "react";
import { Card, CardBody, Progress, Col, Row } from "reactstrap";
import Circle from 'components/markup/loading/Circle'
import moment from 'moment';

import { toggleAlertBS } from 'store/functions/system/system';

import _events from '_functions/events'
import _integrations from '_functions/integrations';

import Dots from 'components/markup/loading/Dots'


const ConnectMXVerifyExtended = ({foundContact, foundCompany, setView, memberToVerify, redirectAfterVerify}) => {

    const [progress, setProgress] = useState(0);
    const [connectLoaded, setConnectLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalWidth, setModalWidth] = useState(435);

    const closeConnect = useCallback(async () => {

        setShowModal(false);
        setConnectLoaded(false);
        setModalWidth(435)
        // setView('VerifyMembers')
        var connectIFrame = document.querySelector('iframe[title="Connect"]')
        if(connectIFrame) connectIFrame.remove();

        toggleAlertBS('info', `Account verification did not finish, please try again.`)
        return setView(redirectAfterVerify ? redirectAfterVerify : 'MXConnect')

    }, [setView, redirectAfterVerify])

    const openConnect = useCallback(async () => {
        _events.create({
            contact: foundContact._id, 
            company: foundCompany._id, 
            access_code: foundContact.access_code, 
            type: 'system', 
            body: `Began search and authorization of financial institutions.`
        })

        setShowModal(true);

        const url = await _integrations.mx.request_url({
            contact_id: foundContact._id,
            company_id: foundCompany._id,
            access_code: foundContact.access_code,
            current_member_guid: memberToVerify
        })

        var mxConnect = new window.MXConnect({
            id: "connect-launcher",
            iframeTitle: "Connect",
            config: { ui_message_version: 4 },
            targetOrigin: "*",
        })

        // we have success, load the widget
        if(url.data && url.data.url) {
            return mxConnect.load(url.data.url);
        }

        // we have an error, send the correct message
        if(url.message && url.message[0] && url.message[0].retry) {
            const time = moment.unix(url.message[0].retry).format('h:mm A')
            toggleAlertBS('info', `We are getting your account ready, please try again in a few minutes. Estimated time: ${time}`)
        } else if(url.message && url.message[0] && url.message[0] === 'The terms of use must be accepted before pulling documents.') {
            toggleAlertBS(true, `The terms of use must be accepted before pulling documents.`)
        } else if(url.message && url.message[0] && url.message[0] === 'staging') {
            toggleAlertBS(true, `Document Generation Is Not Allowed In The Staging Environment.`)
        } else {
            toggleAlertBS(true, `We are experiencing technical difficulties, please try again in a few minutes. If this problem persists please contact support.`)
        }

        setShowModal(false);


    }, [foundCompany._id, foundContact._id, foundContact.access_code, memberToVerify])
    
    const handleEvent = useCallback((event) => {
        if (event.data.mx) {
            switch (event.data.type) {
                case 'mx/connect/loaded':
                    setConnectLoaded(true)
                    break;
                case 'mx/connect/memberConnected':
                    setView('MXVerifyStatements');
                    break;
                case 'mx/connect/stepChange':
                    if(event.data.metadata.current === 'search' && event.data.metadata.previous === 'disclosure') {
                        setModalWidth(435)
                    }
                    break;
            
                default: break;
            }
        }
    }, [setView]);

    const runProgress = useCallback((newProgress) => {
        if(newProgress === 100) return;
        const p = newProgress + 15 > 100 ? Math.floor(Math.random() * 10) : newProgress + 15
        setProgress(p);
        setTimeout(() => {
            runProgress(p)
        }, 900)
    }, [])

    const runVerifyIdentity = useCallback(async() => {
        runProgress(0)
        const verified = await _integrations.mx.members.extend({
            contact_id: foundContact._id, 
            company_id: foundCompany._id, 
            access_code: foundContact.access_code, 
            member_id: memberToVerify
        })

        if(!verified.data) {
            toggleAlertBS(true, `Account verification failed, please try again.`)
            return setView(redirectAfterVerify ? redirectAfterVerify : 'MXConnect')
        }
        if(verified.data && verified.data.needMFA) {
            setProgress(null);
            openConnect();
        } else {
            setView('MXVerifyStatements');
        }

    }, [foundCompany._id, foundContact._id, foundContact.access_code, memberToVerify, openConnect, setView, runProgress, redirectAfterVerify])

    useEffect(() => {
        if(showModal === true) {
            window.addEventListener('message', handleEvent)
        } else {
            window.removeEventListener('message', handleEvent)
        }
        return () => {
            window.removeEventListener('message', handleEvent)
        }
    }, [showModal, handleEvent])

    useEffect(() => {
        runVerifyIdentity()
    }, [runVerifyIdentity])

    return (

        <>

        <Card className='bg-secondary'>
            <CardBody className="py-6 pt-8">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">
                        <Card>
                            <CardBody>
                                {progress !== null ? (
                                    <div className="progress-wrapper pt-3">
                                        <div className="progress-sucess">
                                            <div className="progress-label" style={{display: 'inline'}}>
                                                <span className="text-sucess" style={{position: 'relative', top: 13,}}>Progress</span>
                                            </div>
                                            <div className="progress-percentage">
                                            <span style={{position: 'relative', top: -3}}>{progress}%</span>
                                            </div>
                                        </div>
                                        <Progress max="100" value={progress} color="sucess" />
                                    </div>
                                ) : null}
                                <h4 className="text-center">Verifying Transactions<Dots /></h4>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </CardBody>
        </Card>

        <div className={showModal ? "modal fade show" : "modal fade"} role="dialog" tabIndex="-1" style={{display: showModal ? 'block' : 'none'}}>
            <div className="modal-backdrop fade show" />
            <div id="test" className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{maxWidth: modalWidth, borderRadius: 10, margin: 0, marginLeft: 'auto', marginRight: 'auto', zIndex: 99999999}}>
                <div className="modal-content">
                    <div className="modal-body p-0" style={{minHeight: 500, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                        {!connectLoaded ? <div style={{position: 'relative', top: 325}}><Circle /></div> : null}
                        <div id="connect-launcher" />
                    </div>
                    <div className="modal-footer mt--4 border-top" >
                        <button onClick={closeConnect} className="btn btn-outline-warning btn-block btn-lg">
                            <i className="fas fa-arrow-left mr-2 "></i> LEAVE
                        </button>
                    </div>
                </div>
            </div>
        </div>

        </>

    )
}

export default ConnectMXVerifyExtended

