import { Helmet } from 'react-helmet';
import { Card, Container, CardBody } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

const ReviewsAccess = () => (
    <Container fluid>

        <Helmet>
            <title>{`Access`}</title>
            <meta name="description" content="Reviews" />
        </Helmet>

        <DashHeaderOpen 
            col1Size={5}
            col2Size={7}
            title={<span>Dashboard</span>}
            breadcrumb_1="Reviews"
            icon="fas fa-edit"
        />

        <Card className="card-color card-primary">    
            <CardBody>
                <p>Zap Reviews offer an easy way to gain more, high quality reviews for your firm.</p>
                <p>Not only can you clients leave a review quickly and easily, clients who are not satisfied are redirect to NOT leave a review on Google, Facebook, etc. but to send and <b>unofficial</b> review back to you via email.</p>
                <p className="mb-0">For access to the Zap Reviews, please send an email to john@zapreports.com</p>
            </CardBody>
        </Card>

    </Container>
)

export default ReviewsAccess;