import { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup } from "reactstrap";

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import formatText from 'utils/formatText';
import _contacts from '_functions/contacts';

import ContactSearchInput from 'components/system/Search/ContactsInput'

const ModalPartner = ({showModal, toggleModal, contact, fetchData}) => {

    const [partner, setPartner] = useState(contact.partner ? contact.partner : {});
    const [err, setErr] = useState(null);

    const onSetPartner = useCallback((newPartner) => {

        if(!newPartner || (newPartner && !newPartner._id)) return;

        if(newPartner._id === contact._id) return setErr(`A contact cannot be set as a partner to their own file.`);
        if(newPartner.report_status !== 'null') return setErr(`${newPartner.display_name} has already had a request for documents sent and cannot be linked to another file.`)

        setPartner(newPartner);
        setErr(false);

    }, [contact._id, setErr, setPartner])

    const onRemovePartner = useCallback(async () => {
        toggleStandardLoader(true);

        const saved = await _contacts.removePartner(contact._id)
        if(saved.success) {
            fetchData();
            toggleModal();
            toggleAlertBS(false, 'Contact set to an individual file.')
        } else {
            setErr(saved.message);
        }

        toggleStandardLoader(false);
    }, [contact._id, fetchData, toggleModal])
   
     const onSave = useCallback(async () => {
        if(!partner._id) return setErr(`A partner must be selected.`);

        toggleStandardLoader(true);

        const saved = await _contacts.setPartner(contact._id, partner._id)
        if(saved.success) {
            fetchData();
            toggleModal();
            toggleAlertBS(false, 'Contact set as a joint request for documents.')
        } else {
            setErr(saved.message);
        }

        toggleStandardLoader(false);
    }, [contact._id, partner._id, fetchData, toggleModal])

    useEffect(() => {
        if(showModal === true) {
            setPartner(contact.partner ? contact.partner : {})
        }
    }, [showModal, contact.partner]);

    

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Joint File</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary">

                {partner && partner._id ? (
                    <>
                        <p className="text-sm mb-0">This file will proceed as a joint request for documents with:</p>
                        <p className="text-sm mb-0">{partner.display_name}</p>
                        {partner.email ? <p className="text-sm mb-0">{partner.email}</p> : null}
                        {partner.phone ? <p className="text-sm mb-0">{formatText(partner.phone).phone()}</p> : null}
                    </>
                ) : (
                    <p className="text-sm mb-0">No partner has been selected, this file will proceed as an individual request for document.</p>
                )}    
            </div>

            <div className="modal-body">
                <FormGroup>
                    <label className="form-control-label">Select Partner*</label>
                    <ContactSearchInput 
                        hideTitle={true}
                        value={partner}
                        onSelect={onSetPartner}
                    />
                </FormGroup>
            </div>

            {err ? (
                <div className="modal-body bg-secondary border-top">
                    <p className="text-sm font-weight-bold text-danger mb-0">
                        <i className="fas fa-info mr-3 " />
                        {err}
                    </p>
                </div>
            ) : null}

            <div className="modal-footer text-right">
                {contact.partner ? (
                    <button className="btn btn-warning" onClick={onRemovePartner}>
                        Remove Partner
                    </button>
                ) : null}
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Set Partner
                </button>
            </div>

        </Modal>

    )

}

ModalPartner.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalPartner
