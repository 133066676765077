import keys from 'keys'
import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, Container, Alert, UncontrolledTooltip } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import { Link } from 'react-router-dom';
import _forms from '_functions/forms';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import Circle from 'components/markup/loading/Circle'
import CopyToClipboard from 'react-copy-to-clipboard'

import { toggleAlertBS, toggleStandardLoader } from "store/functions/system/system";
import { getCompanyLink } from 'utils/helpers';

const FormsAll = ({company, viewing_user}) => {

    const [ copiedKey, setCopyKey ] = useState(false);
    const [ hideTable, setHideTable ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: null, total_documents: 0 })
    const [ error, setError ] = useState(false)
    const [ formToDelete, setFormToDelete ] = useState(false)

    const onSetCopiedKey = useCallback((key) => {
        setCopyKey(key);
        setTimeout(() => {
            setCopyKey(false)
        }, 1500)
    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }

        const query = await _forms.search({ ...params, isCSV })
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    }), [sizePerPage])


    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const onDelete = useCallback(async(_formToDelete) => {
        toggleStandardLoader(true)
        const response = await _forms.delete(_formToDelete._id);
        if(response.success) {
            setHideTable(true);
            setTimeout(() => {
                setHideTable(false)
            }, 250)
            toggleAlertBS('info', 'Form Successfully Deleted')
        } else {
            toggleAlertBS(true, `Something's not right, please try again`)
        }
        toggleStandardLoader(false)
        setFormToDelete(false)
    }, [])

    const columns = [
        {
            dataField: "name",
            headerStyle: {width: 400},
            text: "name",
            formatter: (cell, row) => (
                <Link to={`/dashboard/forms/requests/${row._id}`}>
                    {row.name}
                </Link>
            )
        },
        {
            dataField: "created_at",
            text: "Link",
            formatExtraData: copiedKey,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if(row.name === 'No Forms Found') return '';

                const url = `${keys.FORM_URL}/${getCompanyLink(company)}/${row.form_number}`
                const splitURL = url.length > 20 ? '...' + url.slice(url.length - 20, url.length) : url
                const target = 'form-link-' + row._id
                return (
                    <div>
                        <CopyToClipboard 
                            text={url}
                            onCopy={() => onSetCopiedKey(row._id)}
                        >
                            <i className="fas fa-copy cursor-pointer text-success mr-3" />
                        </CopyToClipboard>
                        {formatExtraData === row._id ? (<span className="text-warning px-2">COPIED!</span>) : null}
                        <span id={target} >
                            {splitURL}
                        </span>
                        <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target={target}
                        >
                            {url}
                        </UncontrolledTooltip>
                    </div>
                )
            }
        },
        {
            dataField: "submissions",
            text: "Submissions",
            headerStyle: { textAlign: 'right' },
            formatter: (cell, row) => (
                row.name === 'No Forms Found' ? '' :
                <div className="text-right">{row.submissions}</div>
            )
        },
        {
            dataField: "active",
            text: "Active",
            headerStyle: { textAlign: 'right' },
            formatter: (cell, row) => (
                row.name === 'No Forms Found' ? '' :
                <div className="text-right">
                    {row.active ?  <i className="fas fa-check text-success " /> : <i className="fas fa-times text-danger " />}
                </div>
            )
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right'},
            text: "actions",
            headerFormatter: () => {
                return <div id="archk-tutorial-documents-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'No Forms Found' ? '' :
                    <div className="text-right">
                        {viewing_user.is_admin ? (
                            <button className="btn btn-outline-danger" style={{minWidth: 70}} onClick={() => setFormToDelete(row)}>
                                <i className="fas fa-trash mr2 " />
                            </button>
                        ) : null}
                         
                        <Link 
                            className="btn btn-outline-success" 
                            to={`/dashboard/forms/requests/${row._id}`}
                            style={{minWidth: 70}}
                        >
                            <i className="fas fa-eye mr2 " />
                        </Link>
                    </div>
                )
            }
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`All Forms`}</title>
                <meta name="description" content="Forms" />
            </Helmet>

            <ConfirmationModal 
                showModal={formToDelete ? true : false}
                toggleModal={() => setFormToDelete(false)}
                title="Delete Form"
                body={(
                    <span>
                        Are you sure you wish to delete the form{' '}
                        "<b className="text-underline">{formToDelete ? formToDelete.name : null}</b>"?{' '}
                        All questions and pages for it will be erased
                    </span>
                )}
                onConfirmation={() => onDelete(formToDelete)}
            />

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Forms"
                breadcrumb_2="All"
                icon="fas fa-comments"
                actionComponent={(
                    viewing_user.is_admin ? (
                        <Link to="/editor/forms/new" className="btn btn-outline-success">
                            <i className="fas fa-plus mr-2" /> Add new
                        </Link>
                    ) : null
                    
                )}
            />
    
            <Card className="card-color card-primary">    
                {hideTable ? <Circle /> : (
                    <Table
                        placeholder="Form Name"
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'No Forms Found'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />
                )}
                
            </Card>
    
        </Container>
    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    company: state.company.company,
	};
};

export default connect(mapStateToProps, '')(FormsAll);
