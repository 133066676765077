const focusNextQuestion = (e) => {
    var form = e.target.form;
    var index = Array.prototype.indexOf.call(form, e.target);
    if(index === -1) return;

    const el = form.elements[index + 1];
    if(!el) return;

    form.elements[index + 1].focus();
    e.preventDefault();

    el.scrollIntoView({block: 'center', behavior: 'smooth'})
}

export default focusNextQuestion;