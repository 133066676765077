import PropTypes from 'prop-types';
import React from "react";
import ObjectSearchInput from 'components/functional/inputs/ObjectSearchInput'
import _forms from '_functions/forms';
import * as _ from 'underscore';

class FormSearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            showResults: false,
            selected: {},
            dataToShow: [],
        }
       this.queryFormsThrottled = _.throttle(this.queryForms, 750)
    }


    onSelect = (selected) => {
        this.setState({selected, filter: '', showResults: false})
        this.props.onSelect(selected)
    }

    queryForms = async (value) => {
        const contacts = await _forms.search({
            searchString: value,
            limit: 50,
        })

        if(contacts.success) {
            this.setState({dataToShow: contacts.data.documents, showResults: this.state.filter ? true : false})
        }
    }

    onChange = async (value) => {
        this.setState({filter: value})

        if(value) {
            this.queryFormsThrottled(value)
        } else {
            this.setState({filter: null, showResults: false})
        }
    }

    componentDidMount = () => {
        const { value } = this.props
        this.setState({ selected: value ? value : {} })
        this.queryForms();
    }

    render() {

        const { dataToShow, selected } = this.state
        const { titleFormatter, hideTitle } = this.props

        return (
            <ObjectSearchInput 
                data={dataToShow}
                onChange={this.onChange}
                hideTitle={hideTitle}
                onSelect={this.onSelect}
                placeholderFormatter={selected && selected._id ? selected.name : 'Type To Search'}
                titleFormatter={
                    titleFormatter ? titleFormatter(selected) : 
                        selected ? <span>Selected Form: <b className="text-success text-capitalize">{selected.name}</b></span> : 
                        'Select Form'
                }
                resultsFormatter={(result) => (
                    <li key={result._id} onClick={() => this.onSelect(result)}>
                        <p className="text-sm mb-0 text-capitalize">
                            <i className="fas fa-edit mr-2" />
                            {result.name}
                        </p>
                    </li>
                )}
            />
        );
    }
}

FormSearchInput.propTypes = {
    onSelect        : PropTypes.func.isRequired,
    titleFormatter  : PropTypes.func,
    wrapperStyles   : PropTypes.object,
    formGroupStyles : PropTypes.object,
    value           : PropTypes.object,
    hideTitle       : PropTypes.bool,
    title           : PropTypes.string,
}

export default FormSearchInput
