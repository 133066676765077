import { useState, useEffect, useCallback } from 'react';
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { formatMoney, formatCredits } from 'utils/currency';
import _packages from '_functions/packages';

import Circle from 'components/markup/loading/Circle';
import ModalToggler from 'components/functional/modals/Toggler'

import ModalSetReloader from './ModalSetReloader';
import ModalCancelReloader from './ModalCancelReloader';

const BillingAddCredits = ({SETTINGS, company}) => {

    const formattedCredits = company.credits ? formatCredits(company.credits) : 0

    const [packageToReload, setPackageToReload] = useState({});
    const [packages, setPackages] = useState(null);
    const [err, setErr] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchPackages = useCallback(async () => {

        const foundPackages = await _packages.find();
        if(foundPackages.success) {
            setPackages(foundPackages.data);
            setLoading(false);
        } else {
            setErr(true)
        }

    }, [])

    useEffect(() => {
        fetchPackages()
    }, [fetchPackages])

    if(err) return <div className="alert alert-danger">Something went wrong, please try again.</div>
    if(loading) return <Circle />

    return (
        <>

        <Helmet>
            <title>Auto Recharge</title>
            <meta name="description" content="Auto Recharge" />
        </Helmet>

        <Card>
            <CardHeader>
                <Row>
                    <Col xs={6} className="align-self-center">
                        <CardTitle className="mb-0">Auto Recharge</CardTitle>
                    </Col>
                    <Col xs={6} className="align-self-center text-right">
                        <span className="d-none d-sm-inline-block">Balance: </span>
                        <Badge 
                            color={formattedCredits > 5 ? 'success' : formattedCredits ? 'warning' : 'danger'} 
                            className="ml-3 px-4"
                        >
                            <b>{formattedCredits}</b>
                        </Badge>
                    </Col>
                </Row>
            </CardHeader>

            {!company.card_token ? (
                <div className="alert alert-warning mb-0 px-4">
                    <i className="fas fa-info-circle mr-2 " /> You must add a default card to your account before you can enable auto recharge.
                </div>
            ) : null}

            {company.reloader_package ? (
                <CardHeader>
                    <p className="text-sm mb-0">
                        <i className="fas fa-check text-success mr-2" /> 
                        Auto reload is currently set on your account to purchase the credit package:{' '}
                        <b className="text-underline">{company.reloader_package.name}.</b>{' '}
                    </p>
                    <p className="text-sm">
                        Whenever your credit balance falls below{' '}
                        <b className="text-underline">{formatCredits(company.reloader_threshold)}</b> credits your account will automatically be recharged
                    </p>
                    {/* <p className="text-sm mb-0">
                        You may change the recharge package below at any time or{' '}
                        <ModalToggler component={ModalCancelReloader} company={company}>
                            <span className="text-underline text-danger font-weight-bold cursor-pointer">Click Here</span>
                        </ModalToggler>{' '}
                        to disable it.
                    </p> */}
                </CardHeader>
            ) : (
                <CardHeader>
                    <p className="text-sm mb-0">
                        <i className="fas fa-info-circle mr-2 text-info " />
                        Your account does not have auto recharge enabled. Select a package below to allow your account to automatically purchase credits once your balance falls below a certain point.
                    </p>
                </CardHeader>
            )}

            {packages.length ? (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Package</th>
                                <th>Cost (USD)</th>
                                <th>Credits</th>
                                <th>Free Credits</th>
                                <th>Cost / Credit</th>
                                <th className="text-right">Purchase</th>
                            </tr>
                        </thead>
                        <tbody>
                            {packages.map(p => (
                                !p.can_reload ? null : 
                                <tr key={p._id}>
                                    <td>{p.name}</td>
                                    <td>{formatMoney(p.amount)}</td>
                                    <td>{formatCredits(p.credits)}</td>
                                    <td>{formatCredits(p.free_credits)}</td>
                                    <td>{formatMoney((p.amount / (p.credits + p.free_credits)) * SETTINGS.decimalMultiplier )}</td>
                                    <td className="text-right">
                                        {
                                            !company.card_id ? '-' :
                                            company.reloader_package && company.reloader_package._id === p._id ? (
                                                <ModalToggler component={ModalCancelReloader} company={company}>
                                                    <button className="btn btn-outline-danger">
                                                        Remove
                                                    </button>
                                                </ModalToggler>
                                            ) : (
                                                <ModalToggler component={ModalSetReloader} packageToReload={packageToReload} company={company}>
                                                    <button onClick={() => setPackageToReload(p)} className="btn btn-outline-success">
                                                        Set Recharge
                                                    </button>
                                                </ModalToggler>
                                            )
                                        }
                                       
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <CardBody>
                    <p className="text-sm">There are no credits available for purchase at the moment</p>
                </CardBody>
            )}


        </Card>
        </>
    )

}

const mapStateToProps = state => {
    return {
        SETTINGS: state.load.SETTINGS,
        company: state.company.company,
    };
};

export default connect(mapStateToProps, '')(BillingAddCredits);