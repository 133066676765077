import { memo, useState } from 'react';

import Text from './questions/Text'
import Textarea from './questions/Textarea'
import Address from './questions/Address'
import Select from './questions/Select'
import Checkbox from './questions/Checkbox'
import Date from './questions/Date'
import Upload from './questions/Upload'
import YesNo from './questions/YesNo'
import Signature from './questions/Signature'
import Loop from './questions/Loop'

import getNestIdentifier from './_utils/getNestIdentifier'

// const getClassNameAndTitle = (question) => {
//     if(question.type === 'address') return { title: 'Address', 'className': 'text-success', fa: 'fas fa-map-pin' };
//     if(question.type === 'select') return { title: 'Select One', 'className': 'text-success', fa: 'fas fa-hand-pointer' };
//     if(question.type === 'checkbox') return { title: 'Select', 'className': 'text-success', fa: 'fas fa-hand-pointer' }; 
//     if(question.type === 'text') return { title: 'Open Ended', 'className': 'text-success', fa: 'fas fa-edit' }; 
//     if(question.type === 'textarea') return { title: 'Open Ended', 'className': 'text-success', fa: 'fas fa-edit' }; 
//     if(question.type === 'date') return { title: 'Select Date', 'className': 'text-success', fa: 'fas fa-calendar' };
//     if(question.type === 'upload') return { title: 'Upload File', 'className': 'text-success', fa: 'fas fa-upload' };
//     if(question.type === 'yes-no') return { title: 'Yes or No', 'className': 'text-success', fa: 'fas fa-hand-pointer' };
//     if(question.type === 'signature') return { title: 'Signature', 'className': 'text-success', fa: 'fas fa-signature' };
//     if(question.type === 'loop')return { title: 'Select', 'className': 'text-success', fa: 'fas fa-hand-pointer' };
//     return {}
// }

const Question = ({question, i, onChange, answers, form, errors, nest = 0, loopIndex = 0}) => {

    const [active, setActive] = useState(false)

    // const {title, fa, className} = getClassNameAndTitle(question)

    return (
        <div className={`question-wrapper ${active ? 'active' : ''}`}>
            <p className="text-sm mb-0">
                <span className='font-weight-light question-number'> {nest === 0 ? 'Question' : "Part"}  {getNestIdentifier(nest, i)}</span>
                {/* {' - '}
                <b className={className}>
                    <i className={fa} />
                </b> */}
            </p>
            {question.type === 'address' ? (
                <Address
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                />
            ) : question.type === 'select' ? (
                <Select
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    nest={nest}
                    form={form}
                />
            ) : question.type === 'checkbox' ? (
                <Checkbox
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    nest={nest}
                    form={form}
                />
            ) : question.type === 'text' ? (
                <Text
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                />
            ) : question.type === 'textarea' ? (
                <Textarea
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                />
            ) : question.type === 'date' ? (
                <Date
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                />
            ) : question.type === 'upload' ? (
                <Upload
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    form={form}
                />
            ) : question.type === 'yes-no' ? (
                <YesNo
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    nest={nest}
                    form={form}
                />
            ) : question.type === 'signature' ? (
                <Signature
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                />
            ) : question.type === 'loop' ? (
                <Loop
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    nest={nest}
                    loopIndex={loopIndex}
                    form={form}
                />
            ) : <div className='bg-danger text-white'>invalid question type</div>}

            <div className="question-separator"></div>
            
        </div>    
    )

}

export default memo(Question);