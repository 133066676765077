import { memo, useState, useEffect, useCallback } from 'react';
import { FormGroup } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import setQuestionWithId from '../_utils/setQuestionWithId'

import MapQuestions from '../MapQuestions'

const Checkbox = ({question, answers, onChange, loopIndex, errors, form, nest, setActive}) => {

    const [val, setVal] = useState([])
    const [ids, setIds] = useState([])
    const [_question, _setQuestion] = useState({answers: []})

    const hasErr = errors.includes(_question.id) && !val.length ? true : false;

    const handleChange = useCallback((answer) => {
        let _val = JSON.parse(JSON.stringify(val));

        if(_val.includes(answer)) {
            _val = _val.filter(v => v !== answer);
        } else {
            _val.push(answer)
        }

        setVal(_val)
        onChange(_question, _val)
    }, [_question, onChange, val])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        setVal(answers[formattedQuestion.id] || [])

        let _ids = [];
        question.answers.forEach(() => _ids.push(uuidv4()))
        setIds(_ids)
    }, [question, loopIndex, answers])

    if(!ids.length) return <></>;

    return (
        <>
        <FormGroup id={_question.id}>        
            <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                {_question.name}
            </label>
            {question.description ? (
                <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
            ) : null}
            {_question.answers.map((answer, i) => {
                const selected = val.includes(answer)
                return (
                    <div key={i} className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={ids[i]}
                            type="checkbox"
                            onChange={() => handleChange(answer)}
                            checked={selected}
                            onBlur={() => setActive(false)}
                            onFocus={() => setActive(true)}
                        />
                        <label className="custom-control-label" htmlFor={ids[i]}>
                            {answer}
                        </label>
                    </div>
                )
            })}
        </FormGroup>  
        {val && val.some(v =>_question.branch_on_answers.includes(v) )? (
            <MapQuestions 
                _questions={_question.questions} 
                onChange={onChange}
                answers={answers}
                form={form}
                nest={nest + 1}
                loopIndex={loopIndex}
                errors={errors}
            />
        ) : null}
        </>
    )
}

export default memo(Checkbox);