import apiWorker from '../apiWorker'

const users =  {

    search : (data)      => apiWorker('post',    `/v1/api/users/search`, data),
    find : (query)       => apiWorker('get',    `/v1/api/users/find${query}`),
    findOne : (string)   => apiWorker('get',    `/v1/api/users/find/${string}`),
    create : (data)      => apiWorker('post',   `/v1/api/users/`, data, true),
    update : (_id, data) => apiWorker('patch',  `/v1/api/users/${_id}`, data, true),
    delete : (_id)       => apiWorker('delete', `/v1/api/users/${_id}`),

}

export default users;