import React, { useState, useCallback, useEffect } from 'react';
import { CardHeader, CardBody, CardFooter, CardTitle, FormGroup, Input } from 'reactstrap';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _companies from '_functions/companies';

const SettingsInfoSuspiciousActivity = ({company, setCompany}) => {

    const [apiErr, setApiErr] = useState(0);
    const [transactionThreshold, seTransactionThreshold] = useState('0')

    const onUpdate = async () => {
        toggleStandardLoader(true)

        const updated = await _companies.update(company._id, {
            transaction_threshold: transactionThreshold,
        })
        if(updated.success) {
            toggleAlertBS(false, 'Company settings updated successfully.')
            setCompany()
        } else {
            setApiErr(updated.message)
        }

        toggleStandardLoader(false)
    }

    const onContactPaysChange = useCallback((e) => {
        const value = e.target.value;

        let transaction_threshold = false
        if(value === "1") transaction_threshold = true;

        seTransactionThreshold(transaction_threshold)
    }, [])

    useEffect(() => {
        const _company = JSON.parse(JSON.stringify(company));
        seTransactionThreshold(_company.transaction_threshold)
    }, [company])


    return (
        <div className="border-left">

            <CardHeader>
                <CardTitle className="mb-0">Suspicious Activity</CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary">
                <p className="text-sm">Your transaction alert threshold is the value in which a transaction on a financial account must exceed before it shows up as an alert inside the document analysis.</p>
                <p className="text-sm mb-0">Any amount of money going into or out of the account greater than that number will be flagged. It can be set to "0" to disable flagging entirely.</p>
            </CardHeader>

            <CardBody>

                <FormGroup>
                    <label className="form-control-label">Transaction Alert Threshold</label>
                    <Input
                        type="select"
                        onChange={onContactPaysChange}
                        value={transactionThreshold}
                    >
                        <option value="0">0 (Disabled)</option>
                        <option value="50">$50.00</option>
                        <option value="100">$100.00</option>
                        <option value="250">$250.00</option>
                        <option value="500">$500.00</option>
                        <option value="750">$750.00</option>
                        <option value="1000">$1,000.00</option>
                        <option value="1500">$1,500.00</option>
                        <option value="2000">$2,000.00</option>
                        <option value="2500">$2,500.00</option>
                        <option value="3000">$3,000.00</option>
                        <option value="4000">$4,000.00</option>
                        <option value="5000">$5,000.00</option>
                    </Input>
                </FormGroup>

            </CardBody>
            
            {apiErr ? (
                <CardFooter>
                    {apiErr.map((e, i) => <p key={i} className="text-sm text-danger font-weight-bold mb-0">{e}</p>)}
                </CardFooter>
            ) : null}

            <CardFooter className="text-right">
                <button onClick={onUpdate} className="btn btn-success">
                    <i className="fas fa-save mr-2 " /> Save
                </button>
            </CardFooter>
        
        </div>

    )
}

export default SettingsInfoSuspiciousActivity;