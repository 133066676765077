import * as actionTypes from '../../actions';
import store from '../../index';
import _auth from '_functions/auth';

export const setViewingUser = async() => {
    return new Promise( async resolve => {


        //see if we know who the user is
        //we include a cookie with there id here to account for cors issues
        const viewing_user = await _auth.viewing_user()

        if(viewing_user.success) {

            store.dispatch({ type: actionTypes.SET_VIEWING_USER, payload: { objects: viewing_user.data } });
            resolve({success: true, logged_in: viewing_user.data ? true : false});

        } else {

            resolve({success: false});

        }

    })

}
