/*
Documentation

This page handles updating a users profile

*/


import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Col, Container, Row } from "reactstrap";
import BaseInfo from './BaseInfo';
import Password from './Password';

const AccountSettings = ({company}) => {

    return (
        <Container fluid>

            <Helmet>
                <title>Settings</title>
                <meta name="description" content="Settings" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>My Account</span>}
                breadcrumb_1="Profile"
                icon="fas fa-lock"
            />

            <Row>
                <Col lg={12}>
                    <BaseInfo />
                </Col>

                <Col lg={12}>
                    <Password />
                </Col>
            </Row>

        </Container>
    )

}

const mapStateToProps = state => {
    return {
        company: state.company.company,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);