import { Helmet } from 'react-helmet';
import { Card, Container, CardBody } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

const FormsAccess = () => (
    <Container fluid>

        <Helmet>
            <title>{`Access`}</title>
            <meta name="description" content="Form Submissions" />
        </Helmet>

        <DashHeaderOpen 
            col1Size={5}
            col2Size={7}
            title={<span>Dashboard</span>}
            breadcrumb_1="Forms"
            icon="fas fa-edit"
        />

        <Card className="card-color card-primary">    
            <CardBody>
                <p>Zap Forms offers a fast and fully customizable way of gaining information from you clients in a safe and secure manner.</p>
                <p>Not only can these forms be tailored specifically to your you and your firm, you can make changes to them quickly and easily with our drag and drop form builder.</p>
                <p>Clients can then fill out these forms from any device and a PDF will be sent to you every time a clients hits submit.</p>
                <p>As a way of saving time, we have also developed an all encompassing client questionnaire for bankruptcy filers that can be used on its own or as a starting point to save you time in getting up and running.</p>
                <p>For access to the Zap Forms and the bankruptcy questionnaire, please send an email to john@zapreports.com</p>
            </CardBody>
        </Card>

    </Container>
)

export default FormsAccess;