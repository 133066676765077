import { memo, useEffect, useState } from 'react';
import { FormGroup, Input, Badge } from 'reactstrap';

const ActionOnAnswers = ({answers, possibleAnswers, question, onChange, type, errs, config}) =>  {
    const [loopOptions, setLoopOptions] = useState([])
    // adds an answer
    const onAddAnswer = (e) => {
        // validate answer is not blank and does not already exist

        const value = e.target.value;
        if(!value || value === '__FALSE__') return;
        if(answers.includes(value)) return;

        // add the answer and fire the onChange event
        const newAnswers = JSON.parse(JSON.stringify(answers));
        newAnswers.push(value);

        onChange(newAnswers)
    }

    // remove an answer from state and fire the onChange vent
    const onRemoveAnswer = (answer) => {
        let newAnswers = JSON.parse(JSON.stringify(answers));
        newAnswers = newAnswers.filter(a => a !== answer);

        onChange(newAnswers)
    }

    useEffect(() => {
        let _loopOptions = []
        for (let i = 0; i < config.MAX_LOOP_SIZE; i++) {
            _loopOptions.push(<option value={i}>{i}</option>)
        }
        setLoopOptions(_loopOptions)
    }, [config.MAX_LOOP_SIZE])

    const hasBranchErr = errs.includes('branchOnAnswers') && (answers.length < 1) ? true : false;
    const hasHighlightErr = errs.includes('highlightOnAnswers') && (answers.length < 2) ? true : false;
    const hasErr = hasBranchErr ? true : hasHighlightErr ? true : false
    // branchOnAnswers
    return (
        <div className="">
             <FormGroup>
                <label className="form-control-label">
                    Possible Answers {type === "highlight" ? 'To Highlight' : type === 'branch' ? 'To Move To New Branch' : null}
                </label>
                {hasErr ? (
                    <p className="text-sm text-danger mb-0">You must add at least 1 answer if this box is selected.</p>
                ) : null}
                <Input 
                    type="select"
                    value=""
                    onChange={onAddAnswer}
                    valid={!hasErr}
                    invalid={hasErr}
                >
                    {question.type === 'yes-no' ? (
                        <>
                            <option value="__FALSE__"></option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </>
                    ) : question.type === 'state' ? (
                        <>
                            <option value="__FALSE__"></option>
                            {config.stateOptions.map((o, i) => (
                                <option key={i} value={o.value}>{o.label}</option>
                            ))}
                        </>
                    ) : question.type === 'loop' ? (
                        loopOptions
                    ) : (
                        <>
                            <option value="__FALSE__"></option>
                            {possibleAnswers.map((a, i) => (
                                <option key={i}>{a}</option>
                            ))}
                        </>
                    )}
                </Input>
            </FormGroup>

            {answers.length ? (
                <div className="possible-options">
                    {answers.map((a, i) => (
                        <Badge color="success" className="mb-3 mr-0 ml-3" key={i}>
                            {a}<i onClick={() => onRemoveAnswer(a)} className="cursor-pointer fas fa-times mx-3 " />
                        </Badge>
                    ))}
                </div>
            ) : (
                <div className="possible-options">
                    <Badge color="warning" className="mb-0 mr-0 ml-3">No Answers Selected</Badge>
                </div>
            )}

        </div>
    )
};

export default memo(ActionOnAnswers);

