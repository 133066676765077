import { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import { setCompany } from 'store/functions/company/company'
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import _companies from '_functions/companies'

const ModalCancelReloader = ({showModal, toggleModal, company}) => {

    const [checked, setChecked] = useState(false);
    const [checkedErr, setCheckedErr] = useState(false);

    const onDisable = useCallback(async () => {

        if(!checked) {
            return setCheckedErr(true);
        } else {
            setCheckedErr(false)
        }

        toggleStandardLoader(true);
        const action = await _companies.setReloader(company._id, { remove: true });

        if(action.success && action.data) {
            toggleAlertBS(false, `Auto Reloader Disabled`);
            toggleModal();
            setCompany();
        } else {
            toggleAlertBS(true, `Please Try Again.`);
        }

        toggleStandardLoader(false);

    }, [checked, toggleModal, company._id])

    useEffect(() => {
        if(showModal) setChecked(false)
    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Remove Auto Recharge</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-top">
                <p className="text-sm">Canceling the auto recharge on your account means you are responsible for purchasing any credits required. Zap Reports will no longer fund credits to your account when they are needed.</p>
            </div>

            <div className="modal-body border-top bg-secondary">
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id="purchase-credits-checkbox"
                        type="checkbox"
                        onChange={() => setChecked(!checked)}
                        defaultChecked={checked}
                    />
                    <label className="custom-control-label" htmlFor="purchase-credits-checkbox">
                        I wish to cancel auto recharge and understand credits will need to be purchased manually.
                    </label>
                </div>
                {checkedErr ? (
                    <p className="text-sm mb-0 text-warning font-weight-bold">
                        <i className="fas fa-arrow-up mr-2 " />
                        You must agree to the terms above before proceeding.
                    </p>
                ) : null}
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-danger" onClick={onDisable}>
                    Cancel Auto Recharge
                </button>
            </div>

        </Modal>

    )

}

ModalCancelReloader.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalCancelReloader