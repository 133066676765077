import apiWorker from '../apiWorker'

const documents = {
    search              : (data)        =>  apiWorker('post',  `/v1/api/documents/search`, data),
    getReport           : (report_id)   =>  apiWorker('get',  `/v1/api/documents/get_report/${report_id}`, null, true),
    byContact           : (contact_id)  =>  apiWorker('get',  `/v1/api/documents/contact/${contact_id}`, null, true),
    credit              : (data)        => apiWorker('post', `/v1/api/documents/credit/mx/${data.contact}/${data.company}/${data.access_code}`, data),
    connectedMX         : (data)        => apiWorker('post', `/v1/api/documents/connected/mx/${data.contact}/${data.company}/${data.access_code}`, data),
    connectedPinwheel   : (data)        => apiWorker('post', `/v1/api/documents/connected/pinwheel/${data.contact}/${data.company}/${data.access_code}`, data),
    findById            : (document_id) => apiWorker('get', `/v1/api/documents/${document_id}`, null, true),
    fetchAnalysis       : (contact_id)  => apiWorker('get', `/v1/api/documents/contact/${contact_id}/analyze/`, null, true),
    createList          : (data)        => apiWorker('post', `/v1/api/documents/createList/`, data, true),
    markSeen            : (document_id) => apiWorker('patch', `/v1/api/documents/${document_id}/seen`, null, true),
    download            : (document_id) => apiWorker('get', `/v1/api/documents/${document_id}/download`, null, true),
}                                                            

export default documents;