import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, Badge, CardTitle } from "reactstrap";

import Circle from 'components/markup/loading/Circle';

import ModalToggler from 'components/functional/modals/Toggler'
import ModalPartner from './ModalPartner'

const ContactsUpdate = ({loading, contact, token, device, fetchData}) => {

    if(loading) return <Circle />

    return (

        <Card className="mb-0">
            
            <CardHeader>
                <Row>
                    <Col xs={7} className="align-self-center" id="archk-tutorial-contacts-view-joint-1">
                        <CardTitle className="mb-0"><i className="fas fa-users mr-4 text-info " />
                            Joint File
                            {contact.partner ? 
                                <i className="fas fa-check text-success ml-2 " /> :
                                <i className="fas fa-times text-warning ml-2 " />
                            }
                        </CardTitle>
                    </Col>
                    <Col xs={5} className="align-self-center text-right" id="archk-tutorial-contacts-view-joint-2" >
                        {contact.partner ? (
                            contact.partner.display_name
                        ): (
                            '-'
                        )}
                        <ModalToggler component={ModalPartner} contact={contact} fetchData={fetchData}>
                            <i className="fas fa-edit ml-3 text-success cursor-pointer " />
                        </ModalToggler>
                    </Col>
                </Row>
            </CardHeader>

            <CardHeader>
                <Row>
                    <Col xs={6} className="align-self-center" id="archk-tutorial-contacts-view-access-code">
                        <CardTitle className="mb-0"><i className="fas fa-lock mr-4 text-warning " />
                        <b className="mr-3">Access Code: </b>
                        {token ? token._id : contact.access_code}
                        </CardTitle>
                    </Col>
                    <Col xs={6} className="align-self-center text-right" id="archk-tutorial-contacts-view-status">
                        <CardTitle className="mb-0">
                            {contact.report_status === 'null' ? <Badge color="danger" className={device.isMD ? 'text-sm' : ''}>Not Requested</Badge>  :
                            contact.report_status === 'requested'   ? <Badge color="warning" className={device.isMD ? 'text-sm' : ''}>Requested</Badge> : 
                            contact.report_status === 'in progress' ? <Badge color="info" className={device.isMD ? 'text-sm' : ''}>In Progress</Badge> : 
                            contact.report_status === 'complete'    ? <Badge color="success" className={device.isMD ? 'text-sm' : ''}>COMPLETE</Badge>
                        : '-'}
                        </CardTitle>
                    </Col>

                </Row>
            </CardHeader>

        </Card>

    );

}


const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(ContactsUpdate);